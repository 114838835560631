import { ReactNode } from 'react'

interface IHeaderProps {
  /** Style variant according to Headlines in Alice Design System */
  variant?:
    | 'recoleta-xl'
    | 'recoleta-l'
    | 'recoleta-m'
    | 'recoleta-s'
    | 'recoleta-xs'
    | 'circular-s'
    | 'circular-ms'
  /** Text or components to render with this Typography */
  children: ReactNode
  /** Optional additional class names for styling */
  className?: string
  tag?:
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
}

export const Header = ({ variant, children, className, tag }: IHeaderProps) => {
  let classes = 'font-recoleta text-42px leading-50' // Default = recoleta-xl

  switch (variant) {
    case 'recoleta-l': {
      classes = 'font-recoleta text-32px leading-38 font-normal'
      break
    }
    case 'recoleta-m': {
      classes = 'font-recoleta text-28px leading-34 font-normal'
      break
    }
    case 'recoleta-s': {
      classes = 'font-recoleta text-24px leading-39'
      break
    }
    case 'recoleta-xs': {
      classes = 'font-recoleta text-20px leading-[28px]'
      break
    }
    case 'circular-s': {
      classes = 'font-circular text-24px leading-39'
      break
    }
    case 'circular-ms': {
      classes = 'font-circular-medium text-18px leading-22'
      break
    }
    default:
      classes = 'font-recoleta text-42px leading-50'
  }

  if (className) {
    classes += ` ${className}`
  }

  switch (tag) {
    case 'h1' : return <h1 className={classes}>{children}</h1>
    case 'h2' : return <h2 className={classes}>{children}</h2>
    case 'h3' : return <h3 className={classes}>{children}</h3>
    case 'h4' : return <h4 className={classes}>{children}</h4>
    default: return <h1 className={classes}>{children}</h1>
  }

}
