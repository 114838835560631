import {
  CART_ACTION,
  ICartStore,
  cartActions,
  CHECKOUT_STEPS,
} from '../../interfaces'
import { initialCartState } from './../cart/index'

export const cartReducer = (
  state: ICartStore,
  action: cartActions
): ICartStore => {
  switch (action.type) {
    case CART_ACTION.SET_DELIVERY_ADDRESS: {
      return {
        ...state,
        cart: {
          ...state.cart,
          deliveryAddress: action.deliveryAddress,
        },
        checkoutStep: CHECKOUT_STEPS.BILLING_4,
      }
    }
    case CART_ACTION.SET_BILLING: {
      return {
        ...state,
        cart: {
          ...state.cart,
          billing: action.billing,
        },
      }
    }
    case CART_ACTION.TOGGLE_CART: {
      return {
        ...state,
        showCart:
          typeof action.showCart !== 'undefined'
            ? action.showCart
            : !state.showCart,
      }
    }
    case CART_ACTION.STORE_MEMBERSHIPS_INFO: {
      return {
        ...state,
        membershipProducts: {
          annual: action.membershipProducts.annualMembership,
          monthly: action.membershipProducts.monthlyMembership,
          deliveryFee: action.membershipProducts.deliveryFee,
        },
      }
    }
    case CART_ACTION.FORCE_CHECKOUT_STEP: {
      return {
        ...state,
        checkoutStep: action.step,
      }
    }
    case CART_ACTION.SET_SEARCH_RESULTS: {
      return {
        ...state,
        searchTerm: action.searchTerm,
        searchResults: action.searchResults,
      }
    }
    case CART_ACTION.SET_SEARCH_TOP_PRODUCTS: {
      return {
        ...state,
        searchTopProducts: action.topProducts,
      }
    }
    case CART_ACTION.ADD_LINE_QUEUE: {
      return {
        ...state,
        cart: {
          ...state.cart,
          queuedCart: [...state.cart.queuedCart, ...action.lines],
        },
      }
    }
    case CART_ACTION.REMOVE_LINE_QUEUE: {
      return {
        ...state,
        cart: {
          ...state.cart,
          queuedCart: [
            ...state.cart.queuedCart.filter(
              (p) => p.productId !== action.productId
            ),
          ],
        },
      }
    }
    case CART_ACTION.UPDATE_LINE_QUEUE: {
      return {
        ...state,
        cart: {
          ...state.cart,
          queuedCart: [
            ...state.cart.queuedCart.map((l) => {
              if (l.productId === action.productId) {
                return {
                  ...l,
                  quantity: action.newQuantity,
                }
              } else {
                return l
              }
            }),
          ],
        },
      }
    }
    case CART_ACTION.CLEAR_LINE_QUEUE: {
      return {
        ...state,
        cart: {
          ...state.cart,
          queuedCart: [],
        },
      }
    }
    case CART_ACTION.SET_API_CART: {
      return {
        ...state,
        cart: {
          ...state.cart,
          apiCart: action.apiCart,
        },
      }
    }
    case CART_ACTION.RESET_CART: {
      return {
        ...state,
        stripeToken: undefined,
        cart: {
          queuedCart: [],
        },
        checkoutStep: CHECKOUT_STEPS.NOT_STARTED,
        coupons: [],
        searchResults: undefined,
        searchTerm: undefined,
        showCart: false,
      }
    }
    case CART_ACTION.SET_BASIC_FILTERS: {
      return {
        ...state,
        basicFilters: action.basicFilters,
      }
    }
    case CART_ACTION.SET_FULL_FILTERS: {
      return {
        ...state,
        fullFilters: action.fullFilters,
      }
    }
    case CART_ACTION.SET_CREDITS: {
      return {
        ...state,
        creditConsumption: action.creditConsumption ?? undefined,
      }
    }
    case CART_ACTION.SET_SHOW_SEARCH_MODAL: {
      return {
        ...state,
        showSearchModal: action.showSearchModal,
      }
    }
    case CART_ACTION.SET_SHOW_REGISTRY_SEARCH: {
      return {
        ...state,
        showRegistrySearch: action.open,
      }
    }
    case CART_ACTION.REGISTRY_CART_ADD_ITEM: {
      return {
        ...state,
        registryCart: {
          ...state.registryCart,
          lines: [
            ...state.registryCart.lines.filter(
              (p) => p.productId !== action.product.productId
            ),
            action.product,
          ],
        },
      }
    }
    case CART_ACTION.REGISTRY_CART_REMOVE_ITEM: {
      return {
        ...state,
        registryCart: {
          ...state.registryCart,
          lines: [
            ...state.registryCart.lines.filter(
              (p) => p.productId !== action.productId
            ),
          ],
        },
      }
    }
    case CART_ACTION.REGISTRY_CART_REMOVE_GIFT: {
      return {
        ...state,
        registryCart: {
          ...state.registryCart,
          giftCertificates: [],
        },
      }
    }
    case CART_ACTION.REGISTRY_CART_UPDATE_ITEM: {
      return {
        ...state,
        registryCart: {
          ...state.registryCart,
          lines: [
            ...state.registryCart.lines.map((p) =>
              p.productId === action.productId
                ? { ...p, quantity: action.newQuantity }
                : p
            ),
          ],
        },
      }
    }
    case CART_ACTION.REGISTRY_CART_TOKENS: {
      return {
        ...state,
        registryCart: {
          ...state.registryCart,
          stripePaymentIntent: {
            stripePaymentIntentId: action.stripePaymentIntentId,
            stripePaymentIntentSecret: action.stripePaymentIntentSecret,
          },
          cardDiscounts: action.cardDiscounts,
        },
      }
    }
    case CART_ACTION.REGISTRY_SET_ID: {
      return {
        ...state,
        registryCart: {
          ...state.registryCart,
          loopRegistryId: action.registryId,
          name: action.name,
        },
      }
    }
    case CART_ACTION.ADD_GIFT_CERTIFICATE: {
      return {
        ...state,
        registryCart: {
          ...state.registryCart,
          giftCertificates: [
            ...state.registryCart.giftCertificates,
            action.giftCertificate,
          ],
        },
      }
    }
    case CART_ACTION.REMOVE_GIFT_CERTIFICATE: {
      return {
        ...state,
        registryCart: {
          ...state.registryCart,
          giftCertificates: [
            ...state.registryCart.giftCertificates.filter(
              (cert, idx) => idx !== action.index
            ),
          ],
        },
      }
    }
    case CART_ACTION.SET_GIFTER_DATA: {
      return {
        ...state,
        registryCart: {
          ...state.registryCart,
          gifterName: action.name,
          gifterEmail: action.email,
        },
      }
    }
    case CART_ACTION.DELIVERY_OPTION: {
      return {
        ...state,
        deliveryOption: action.deliveryOption,
      }
    }
    case CART_ACTION.SET_CLEAR: {
      return {
        ...initialCartState,
      }
    }
    case CART_ACTION.SET_SEARCH_VISIBLE: {
      return {
        ...state,
        isSearchVisible: action.isSearchVisible,
      }
    }
    case CART_ACTION.SET_HELPER_FUNCTIONS: {
      return {
        ...state,
        resetCart: action.cartReset,
        forceCartRefresh: action.forceCartRefresh,
      }
    }
    default:
      return state
  }
}
