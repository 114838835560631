import { URLS } from '../../config'
import { HP_LINKS, getHpUrl } from '../../config/homepage.constants'
import { ImagePin } from '../ImagePin'
import Image from 'next/image'

export interface ShoppableImageLink {
  cssPositions: string
  title: string
  titleUrl: string
  subTitle?: string
}

export interface ShoppableImageProps {
  imageSrc: string
  imageAlt: string
}

export const ShoppableImage = () => {
  return (
    <div className="flex flex-col content-center justify-center w-full">
      <div className="md:hidden w-full relative">
        <Image
          src="/images/homepage/shoppable-image-mobile.jpeg"
          alt="homepage"
          width={960}
          height={960}
        />
        <ImagePin
          title="Loop Toy Box"
          description="Rent for $28/mo"
          className="bottom-[15%] right-[22%]"
          destinationUrl={`${URLS.PRODUCTS.INDEX}/${getHpUrl(
            HP_LINKS.SHOPPABLE_IMAGE.TOYBOX
          )}`}
        />
        <ImagePin
          title="Lovevery® Play Tunnel"
          description="Rent for $8/mo"
          className="top-[35%] right-[42%]"
          destinationUrl={`${URLS.PRODUCTS.INDEX}/${getHpUrl(
            HP_LINKS.SHOPPABLE_IMAGE.TUNNEL
          )}`}
        />
      </div>
      <div className="hidden md:block w-full relative">
        <ImagePin
          title="Loop Toy Box"
          description="Rent for $28/mo"
          className="bottom-[35%] right-[10%]"
          destinationUrl={`${URLS.PRODUCTS.INDEX}/${getHpUrl(
            HP_LINKS.SHOPPABLE_IMAGE.TOYBOX
          )}`}
        />
        <ImagePin
          title="Lovevery® Play Tunnel"
          description="Rent for $8/mo"
          className="top-[30%] right-[42%]"
          destinationUrl={`${URLS.PRODUCTS.INDEX}/${getHpUrl(
            HP_LINKS.SHOPPABLE_IMAGE.TUNNEL
          )}`}
        />
        <img
          src="/images/homepage/shoppable-image-desktop.jpeg"
          alt="Home"
          className="object-cover md:min-h-[423px] lg:min-h-[520px] xl:min-h-[700px]"
        />
      </div>
    </div>
  )
}
