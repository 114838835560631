import { DateTime } from 'luxon'
import router from 'next/router'
import NumberFormat from 'react-number-format'
import ReactTooltip from 'react-tooltip'
import { URLS } from '../../config'
import { Button } from '../Button'
import { Headline } from '../Headline'
import { Pgraph } from '../Pgrah'
import { ApiResultStatus, ISubscription } from '../../interfaces'
import { useAuthStore, useCartStore } from '../../store'
import { useMemo } from 'react'
import { Loading } from '../Loading'

interface IMembershipAndBillingProps {
  subscriptionRes?: ISubscription
  status: ApiResultStatus
  refetchPaymentMethods: () => void
}

export const MembershipAndBilling = ({
  subscriptionRes,
  status,
  refetchPaymentMethods,
}: IMembershipAndBillingProps) => {
  const { user, paymentMethods } = useAuthStore()
  const { membershipProducts } = useCartStore()

  const handleManageMembershipClick = async () => {
    router.push(URLS.ACCOUNT.MANAGE_MEMBERSHIP)
  }

  const handleUpdatePaymentClick = async () => {
    router.push(URLS.ACCOUNT.UPDATE_PAYMENT)
  }

  const calculateInvoiceCharges = (amount: number) => {
    let calculatedAmount = 0
    if (amount) {
      calculatedAmount = amount / 100
      if (user?.subscriptionCredit != null && user.subscriptionCredit > 0) {
        calculatedAmount = amount / 100 - user?.subscriptionCredit / 100
      }
    }
    return `$${(calculatedAmount < 0 ? 0 : calculatedAmount).toFixed(2)}`
  }

  const invoiceInfo = useMemo(
    () => subscriptionRes?.upcomingInvoices[0],
    [subscriptionRes]
  )

  const subscription = useMemo(
    () => subscriptionRes?.membership,
    [subscriptionRes]
  )

  const legacySubscription = useMemo(
    () => subscriptionRes?.hasLegacySubscription,
    [subscriptionRes]
  )

  const defaultCard = useMemo(() => {
    if (paymentMethods?.defaultCard && paymentMethods.cards) {
      const defCard = paymentMethods.cards.find(
        (c) => c.id === paymentMethods.defaultCard
      )
      if (defCard) {
        return defCard
      }
    }
    if (paymentMethods?.cards?.length) {
      return paymentMethods.cards[0]
    }
    return undefined
  }, [paymentMethods])

  const shareReferralCode = () => {
    if (!user?.customer.referralCode) return

    window.open(
      `mailto:?subject=Loop%20Referral&body=Hi%21%0D%0A%0D%0AUse%20my%20Loop%20referral%20code%20%22${user.customer.referralCode}%22%20for%20extra%20savings%20on%20Loop%21%0D%0AVisitloop.baby`,
      '_blank'
    )
  }

  return (
    <div className="bg-snow-white px-3 md:px-4 lg:px-0 pb-[33px]">
      <div className="max-w-5xl mx-auto">
        <Headline
          variant="recoleta-s"
          className="text-24px leading-29 md:text-24px md:leading-29 lg:text-24px lg:leading-29 py-[32px] md:text-center"
        >
          Membership &amp; billing
        </Headline>
        {status === 'error' && (
          <Pgraph variant="p-14">
            There was an error loading your payment information. Please click{' '}
            <span
              className="underline cursor-pointer"
              onClick={refetchPaymentMethods}
            >
              here
            </span>{' '}
            to try again.
          </Pgraph>
        )}
        {(status === 'loading' || status === 'idle') && (
          <div className="h-[400px]">
            <Loading />
          </div>
        )}
        {status === 'success' && (
          <div className="md:flex md:gap-4">
            <div className="bg-snow-white pt-[32px] pl-4  md:p-5 md:w-1/2">
              <Headline
                variant="recoleta-s"
                className="text-24px leading-29 md:text-24px md:leading-29 lg:text-24px lg:leading-29"
              >
                Membership
              </Headline>
              <div className="md:flex">
                <div className="md:w-1/2">
                  {subscription && (
                    <Pgraph
                      variant="l-16"
                      className="mt-[24px] xs:text-14px xs:leading-17 md:text-14px md:leading-17 lg:text-16px lg:leading-19 text-neutral-7"
                    >
                      Membership
                    </Pgraph>
                  )}
                  <Pgraph variant="p-14" className="mt-[12px] text-neutral-5">
                    {subscription
                      ? subscription.interval === 'year'
                        ? 'Active'
                        : 'Monthly, Active'
                      : legacySubscription
                      ? 'Active'
                      : `Join for $${membershipProducts.annual?.price} / yr. and unlock item savings, VIP delivery, and more`}
                  </Pgraph>
                </div>
                {subscription?.periodEnd && (
                  <div className="md:w-1/2">
                    {subscription && (
                      <Pgraph
                        variant="l-16"
                        className="mt-[24px] xs:text-14px xs:leading-17 md:text-14px md:leading-17 lg:text-16px lg:leading-19 text-neutral-7"
                      >
                        Renewal date
                      </Pgraph>
                    )}
                    <Pgraph variant="p-14" className="mt-[12px] text-neutral-5">
                      {DateTime.fromMillis(
                        subscription.periodEnd * 1000
                      ).toFormat('MM/dd/yyyy')}
                    </Pgraph>
                  </div>
                )}
              </div>
              {subscription ? (
                <Button
                  className="my-[32px]"
                  size="small"
                  label="Manage Membership"
                  onClick={handleManageMembershipClick}
                />
              ) : (
                <Button
                  primary={true}
                  className="my-[32px]"
                  size="small"
                  label="Learn more"
                  onClick={() => router.push(URLS.MEMBERSHIP_LANDING)}
                />
              )}
              {user?.customer.referralCode && (
                <>
                  <Headline variant="recoleta-s">Referral Code</Headline>
                  <div className="my-4">
                    Your referral code:{' '}
                    <span className="font-bold">
                      {user?.customer.referralCode}
                    </span>
                  </div>
                  <Button
                    size="small"
                    label="Share"
                    onClick={shareReferralCode}
                  />
                </>
              )}
            </div>

            <div className="bg-snow-white mt-3 pt-[32px] pl-4 md:mt-0 md:p-5 md:w-1/2 ">
              <Headline
                variant="recoleta-s"
                className="text-24px leading-29 md:text-24px md:leading-29 lg:text-24px lg:leading-29"
              >
                Payment
              </Headline>

              <div className="md:flex">
                <div className="md:w-1/2">
                  <Pgraph
                    variant="l-16"
                    className="mt-[24px] xs:text-14px xs:leading-17 md:text-14px md:leading-17 lg:text-16px lg:leading-19 text-neutral-7"
                  >
                    Payment Method
                  </Pgraph>
                  <Pgraph
                    variant="p-14"
                    className="mt-[12px] font-normal text-14px leading-18 md:text-14px md:leading-18 lg:text-14px lg:leading-18 text-neutral-5 font-circular"
                  >
                    {!paymentMethods && 'No payment methods on file'}
                    {defaultCard &&
                      `${defaultCard.brand.toUpperCase()} ending in ${
                        defaultCard.last4
                      }`}
                  </Pgraph>
                  <Button
                    className="my-[32px]"
                    size="small"
                    label="Update Payment"
                    onClick={handleUpdatePaymentClick}
                  />
                </div>
                <div className="md:w-1/2">
                  <Pgraph
                    variant="l-16"
                    className="mt-4 xs:text-14px xs:leading-17 md:text-14px md:leading-17 lg:text-16px lg:leading-19 text-neutral-7"
                  >
                    Upcoming charges
                  </Pgraph>

                  <Pgraph
                    variant="p-14"
                    className="mt-[12px] font-normal text-14px leading-18 md:text-14px md:leading-18 lg:text-14px lg:leading-18 text-neutral-5 font-circular"
                  >
                    {invoiceInfo?.amountDue
                      ? calculateInvoiceCharges(invoiceInfo.amountDue)
                      : ''}
                    {invoiceInfo?.nextAttemptedPayment &&
                      ` on ${DateTime.fromMillis(
                        invoiceInfo?.nextAttemptedPayment * 1000
                      ).toFormat('MM/dd/yyyy')}`}
                    {!invoiceInfo &&
                      'Renewal date will be based on first delivery date'}
                  </Pgraph>
                </div>
              </div>
              <div className="md:flex">
                <div className="md:w-1/2">
                  <Pgraph
                    variant="l-16"
                    className="md:mt-4 xs:text-14px xs:leading-17 lg:text-16px lg:leading-19 text-neutral-7 flex items-center"
                  >
                    Credit balance{' '}
                    <a
                      data-for="custom-event"
                      data-tip="custom show"
                      data-event="click"
                      data-event-off="dblclick"
                      data-class="loop-tooltip"
                      className="ml-2"
                    >
                      <img
                        className="max-w-full"
                        alt="info"
                        src="/images/info.png"
                        width="17px"
                        height="17px"
                      />
                    </a>
                  </Pgraph>

                  <ReactTooltip
                    class="w-[290px]"
                    id="custom-event"
                    className="loop-tooltip"
                    multiline={true}
                    effect="solid"
                    type="light"
                    place="right"
                  >
                    <Pgraph
                      variant="p-14"
                      className=" text-neutral-7 xs:font-circular-medium mb-1"
                    >
                      Your credit balance
                    </Pgraph>
                    <Pgraph variant="p-14" className="text-neutral-7">
                      Credits include any gift cards and will apply
                      automatically at checkout. Your credits may/can also apply
                      to upcoming bills.
                    </Pgraph>
                  </ReactTooltip>
                  <Pgraph
                    variant="p-14"
                    className="mt-[12px] font-normal text-14px leading-18 md:text-14px md:leading-18 lg:text-14px lg:leading-18 text-neutral-5 font-circular"
                  >
                    <NumberFormat
                      value={
                        (user?.giftCertificateCredit || 0) / 100 +
                        (user?.registryCredit || 0) / 100 -
                        (user?.balance || 0) / 100
                      }
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'$'}
                      decimalScale={0}
                      fixedDecimalScale={true}
                    />
                  </Pgraph>
                  <Button
                    className="mb-[32px] mt-[17px]"
                    size="small"
                    label="Redeem Gift Card"
                    onClick={() => {
                      router.push(URLS.ACCOUNT.REDEEM_GIFTCARD)
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
