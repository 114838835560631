import { getRequestHeaders } from '../config'
import { fetchTimeoutRetry } from '../config/utilities'
import {
  ICartApiCart,
  ICartApiProduct,
  ICheckoutApi,
  ICheckoutNote,
} from '../interfaces'
import { ICartQueuedLine } from '../interfaces/cart.interface'

// const fetchCart = async (
//   accessToken: string
// ): Promise<ICartApiCart | undefined> => {
//   try {
//     const fetchRes = await fetchTimeoutRetry(
//       `${process.env.NEXT_PUBLIC_CART_URL}/carts`,
//       {
//         method: 'GET',
//         headers: getRequestHeaders(accessToken),
//       }
//     )
//     if (!fetchRes.ok) {
//       return undefined
//     }
//     const json = await fetchRes.json()
//     const cart = json.cart as ICartApiCart
//     const pricing = json.pricing as ICartApiPricing[]

//     const productsWithNonMemberPrice = cart.lineItems.physicalItems.map(
//       (i) => ({
//         ...i,
//         listPrice: pricing.find((p) => p.lineItemId === i.id)?.price || 0,
//         salePrice: pricing.find((p) => p.lineItemId === i.id)?.price || 0,
//         nonMemberPrice:
//           pricing.find((p) => p.lineItemId === i.id)?.nonMemberPrice || 0,
//       })
//     )

//     return {
//       ...cart,

//       lineItems: {
//         ...cart.lineItems,
//         physicalItems: productsWithNonMemberPrice,
//       },
//     }
//   } catch (error) {
//     return undefined
//   }
// }

const addCartItem = async (
  accessToken: string,
  products:
    | ICartQueuedLine[]
    | { productId: number; quantity: number; variantId?: number }[]
) => {
  try {
    const bodyObj = {
      lineItems: [
        ...products.map((product) => ({
          productId: product.productId,
          quantity: product.quantity,
          variantId: product.variantId,
        })),
      ],
    }

    const addRes = await fetchTimeoutRetry(
      `${process.env.NEXT_PUBLIC_CART_V2_URL}/v3/carts/items`,
      {
        method: 'POST',
        headers: getRequestHeaders(accessToken),
        body: JSON.stringify(bodyObj),
      }
    )
    if (!addRes.ok) throw new Error('Error saving cart')
    const res = await addRes.json()
    return res.cart as ICartApiCart
  } catch (error) {
    return null
  }
}

const updateCartItem = async (
  accessToken: string,
  cartItemId: string,
  quantity: number
) => {
  try {
    const updRes = await fetchTimeoutRetry(
      `${process.env.NEXT_PUBLIC_CART_URL}/carts/items`,
      {
        method: 'PATCH',
        headers: getRequestHeaders(accessToken),
        body: JSON.stringify({
          id: cartItemId,
          quantity,
        }),
      }
    )
    if (updRes.ok) {
      const json = await updRes.json()
      return json.item as ICartApiProduct
    }
    return null
  } catch (error) {
    return null
  }
}

const removeCartItem = async (accessToken: string, cartProductId: string) => {
  try {
    const updRes = await fetchTimeoutRetry(
      `${process.env.NEXT_PUBLIC_CART_V2_URL}/v2/carts/items/${cartProductId}`,
      {
        method: 'DELETE',
        headers: getRequestHeaders(accessToken),
      }
    )
    if (updRes.ok) {
      return await updRes.json()
    }
    return null
  } catch (error) {
    return null
  }
}

const setCart = async (accessToken: string, products: ICartQueuedLine[]) => {
  try {
    const bodyObj = {
      lineItems: [
        ...products.map((product) => ({
          productId: product.productId,
          quantity: product.quantity,
          variantId: product.variantId,
        })),
      ],
    }

    const addRes = await fetchTimeoutRetry(
      `${process.env.NEXT_PUBLIC_CART_URL}/carts/items`,
      {
        method: 'PUT',
        headers: getRequestHeaders(accessToken),
        body: JSON.stringify(bodyObj),
      }
    )
    if (!addRes.ok) throw new Error('Error reseting cart')
    const res = await addRes.json()
    return res.cart as ICartApiCart
  } catch (error) {
    return null
  }
}

const completeCheckout = async (
  accessToken: string,
  notes?: ICheckoutNote[]
) => {
  try {
    const checkoutRes = await fetch(
      `${process.env.NEXT_PUBLIC_CART_URL}/checkout`,
      {
        method: 'POST',
        headers: getRequestHeaders(accessToken),
        body: JSON.stringify({ lineItemNotes: notes }),
      }
    )
    return await checkoutRes.json()
  } catch (error) {
    return null
  }
}

const fetchCheckout = async (
  accessToken: string
): Promise<ICheckoutApi | null> => {
  try {
    const fetchRes = await fetchTimeoutRetry(
      `${process.env.NEXT_PUBLIC_CART_URL}/checkout`,
      {
        method: 'GET',
        headers: getRequestHeaders(accessToken),
      },
      {
        currentAttempt: 0,
        maxRetries: 3,
        timeoutMs: 60000,
      }
    )

    const checkoutRes = (await fetchRes.json()) as ICheckoutApi
    const cartPricing = checkoutRes.cartPricing

    const productsWithNonMemberPrice =
      checkoutRes.checkout.cart.lineItems.physicalItems.map((i) => ({
        ...i,
        listPrice: cartPricing.find((p) => p.lineItemId === i.id)?.price || 0,
        salePrice: cartPricing.find((p) => p.lineItemId === i.id)?.price || 0,
        nonMemberPrice:
          cartPricing.find((p) => p.lineItemId === i.id)?.nonMemberPrice || 0,
      })) as any

    const cartWithNonMemberPrices = {
      ...checkoutRes,
      checkout: {
        ...checkoutRes.checkout,
        cart: {
          ...checkoutRes.checkout.cart,
          lineItems: {
            ...checkoutRes.checkout.cart.lineItems,
            physicalItems: productsWithNonMemberPrice,
          },
        },
      },
    }

    return cartWithNonMemberPrices
  } catch (error) {
    console.log(error)
    return null
  }
}

const addCoupon = async (accessToken: string, couponCode: string) => {
  try {
    const fetchRes = await fetchTimeoutRetry(
      `${process.env.NEXT_PUBLIC_CART_URL}/coupons`,
      {
        method: 'POST',
        headers: getRequestHeaders(accessToken),
        body: JSON.stringify({
          couponCode,
        }),
      }
    )
    if (!fetchRes.ok) {
      const json = await fetchRes.json()
      if (
        json.errors &&
        Array.isArray(json.errors) &&
        json.errors[0]?.message
      ) {
        return json.errors[0].message as string
      }
      return null
    }
    const json = await fetchRes.json()
    return json.cart as ICartApiCart
  } catch (error) {
    return null
  }
}

const removeCoupon = async (accessToken: string, couponCode: string) => {
  try {
    const fetchRes = await fetchTimeoutRetry(
      `${process.env.NEXT_PUBLIC_CART_URL}/coupons/${couponCode}`,
      {
        method: 'DELETE',
        headers: getRequestHeaders(accessToken),
      }
    )
    if (!fetchRes.ok) {
      return null
    }
    const json = await fetchRes.json()
    return json.cart as ICartApiCart
  } catch (error) {
    return null
  }
}

export {
  addCartItem,
  updateCartItem,
  removeCartItem,
  setCart,
  // fetchCart,
  completeCheckout,
  fetchCheckout,
  addCoupon,
  removeCoupon,
}
