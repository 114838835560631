import React from 'react'
import { IProductSummary } from '../../interfaces'
import { Pgraph } from '../Pgrah'
import Image from 'next/image'
import NumberFormat from 'react-number-format'
import { PRODUCT_CONFIG, PRODUCT_CUSTOM_FIELDS, URLS } from '../../config'
import { useRouter } from 'next/router'
import { getProductSeoName } from '../../services/products_api'
import { Headline } from '../Headline'
import Link from 'next/link'

interface IProductCarouselCardProps {
  /**
   * Product Cart Type
   */
  product: IProductSummary

  /**
   * Optional click handler
   */
  onClick?: () => void

  /** Optional Additional classes for styling */
  className?: string

  /** Optional Additional classes for styling */
  componentBackground?: string
}

/**
 * Primary UI component for user interaction
 */
export const ProductCarouselCard = ({
  product,
  className,
  componentBackground,
  ...props
}: IProductCarouselCardProps) => {
  const router = useRouter()

  const handleViewProductClick = (product: IProductSummary) => {
    router.push(
      `${URLS.PRODUCTS.INDEX}/${getProductSeoName(product)}/${
        product.bigCommerceProductId
      }?variant=${
        product.variants.length ? product.variants[0].bigCommerceVariantId : 0
      }`
    )
  }

  const getUrl = () => {
    return `${URLS.PRODUCTS.INDEX}/${getProductSeoName(product)}/${
      product.bigCommerceProductId
    }?variant=${
      product.variants.length ? product.variants[0].bigCommerceVariantId : 0
    }`
  }

  const customPriceLabel = product?.customFields.find(
    (f) => f.name === PRODUCT_CUSTOM_FIELDS.CUSTOM_PRICE_LABEL
  )?.value

  return (
    <div
      className={
        (componentBackground ? componentBackground : 'bg-white' + ' ') +
        ' pr-2 py-4 md:pr-4'
      }
    >
      <Link href={getUrl()} passHref className="bg-white border border-neutral-2 rounded flex flex-col p-4 min-h-[384px] md:min-h-[392px] lg:max-w-[376px] lg:min-h-[438px]">
          <div className="mx-auto w-[95%]">
            <Image
              src={product.images[0].url || '/images/loop-red.png'}
              alt={product.images[0].description}
              width="284"
              height="284"
            ></Image>
          </div>
          <div className="mt-auto">
            <Headline
              variant="recoleta-s"
              className="xs:text-14px text-neutral-6 leading-snug md:text-18px lg:text-20px"
            >
              {product.title}
            </Headline>
            <div className="flex mt-[10px]">
              <div className="w-4/5">
                <Pgraph
                  variant="l-14"
                  className="xs:text-14px text-neutral-7 md:text-16 lg:text-[20px]"
                  as="span"
                >
                  {!!customPriceLabel ? (
                    customPriceLabel
                  ) : (
                    <NumberFormat
                      value={product.price}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'$'}
                      decimalScale={0}
                      fixedDecimalScale={true}
                      suffix={` / ${PRODUCT_CONFIG.rentingPeriod}`}
                    />
                  )}
                </Pgraph>
                <Pgraph
                  as="span"
                  variant="p-12"
                  className="line-through text-neutral-4 block md:ml-2 md:inline-block lg:text-14px"
                >
                  <NumberFormat
                    value={product.retailPrice}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'Retails for $'}
                    decimalScale={0}
                  />
                </Pgraph>
              </div>
              <div className="w-1/5 flex justify-end">
                <div className="bg-cinderella rounded-full w-[32px] h-[32px] text-center leading-[36px]">
                  <i className="loop-icon-arrow-long text-18px text-snow-white"></i>
                </div>
              </div>
            </div>
          </div>
      </Link>
    </div>
  )
}
