import React, { useMemo } from 'react'
import { DELIVERY_OPTIONS, PRODUCT_CONFIG } from '../../config'
import { useCartStore, useHooks } from '../../store'
import { Pgraph } from '../Pgrah'
import Image from 'next/image'
import NumberFormat from 'react-number-format'
import {
  ICart,
  ICartApiProduct,
  ICreditConsumption,
  IGetCartLinesParams,
  IRegistryCart,
} from '../../interfaces'
import { CouponForm } from './couponForm'
import { ServiceFeeModule } from '../ServicFeeModule'

export enum ORDER_SUMMARY_TYPE {
  REGULAR,
  GUEST,
}

export type ORDER_SUMMARY_DATA =
  | {
      type: ORDER_SUMMARY_TYPE.REGULAR
      apiCart: ICart
      creditConsumption?: ICreditConsumption
    }
  | {
      type: ORDER_SUMMARY_TYPE.GUEST
      registryCart: IRegistryCart
    }

interface IOrderSummaryProps {
  open: boolean
  setOpen: (open: boolean) => void
  showCouponForm?: boolean
  summaryData: ORDER_SUMMARY_DATA
  invalidItems?: ICartApiProduct[]
}

function OrderSummary({
  open,
  setOpen,
  showCouponForm,
  summaryData,
  invalidItems,
}: IOrderSummaryProps) {
  const {
    membershipProducts: { annual },
  } = useCartStore()
  const { getCartData } = useHooks()

  const getCartDataParams: IGetCartLinesParams = useMemo(
    () =>
      summaryData.type === ORDER_SUMMARY_TYPE.REGULAR
        ? {
            cart: summaryData.apiCart,
            creditConsumption: summaryData.creditConsumption,
          }
        : {
            registryCart: summaryData.registryCart,
          },
    [summaryData]
  )

  const { credits, coupons, lines, subTotal, total, delivery, discounts } =
    useMemo(
      () => getCartData(getCartDataParams),
      [getCartData, getCartDataParams]
    )

  const lineItems = lines.map((line) => ({
    line,
    invalidItem: invalidItems?.find(
      (invalid) => invalid.productId === line.productId
    ),
  }))

  return (
    <div className="w-full md:2/3 border-y-neutral-3 border border-x-0 my-4">
      <div
        className="flex flex-row justify-between items-center cursor-pointer w-full py-4"
        onClick={() => setOpen(!open)}
      >
        <Pgraph variant="p-14">Order summary</Pgraph>
        <i
          className={`loop-icon-caret-down-bold text-20px ${
            !open ? 'rotate-180' : ''
          }`}
        ></i>
      </div>
      <div
        className={`transition-all ${!open ? 'h-0 opacity-0' : 'opacity-100'}`}
      >
        <div className="flex flex-col">
          {lineItems.map(({ line, invalidItem }, idx) => (
            <div key={idx} className={'flex flex-col w-full'}>
              <div className="flex justify-between mt-4 h-full">
                <div className="max-w-[70%]">
                  <Pgraph
                    variant="p-12"
                    className="text-neutral-7 font-circular-medium mb-2"
                  >
                    {line.name}
                  </Pgraph>
                  <div className="w-7 h-7 relative">
                    <Image src={line.imageUrl} alt={line.name} fill={true} />
                  </div>
                </div>

                <div className="min-w-[30%] flex flex-col">
                  <Pgraph variant="p-12" className="text-neutral-7 text-right">
                    <NumberFormat
                      value={
                        delivery > 0
                          ? line.nonMemberPrice ?? line.salePrice
                          : line.salePrice
                      }
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'$'}
                      decimalScale={0}
                      fixedDecimalScale={true}
                      suffix={
                        summaryData.type === ORDER_SUMMARY_TYPE.GUEST
                          ? ''
                          : ' / ' +
                            (line.productId === annual?.bigCommerceProductId
                              ? PRODUCT_CONFIG.membershipPeriod.year
                              : PRODUCT_CONFIG.rentingPeriod)
                      }
                    />
                  </Pgraph>

                  <Pgraph
                    variant="p-14"
                    className="text-neutral-7 mt-auto mb-2 text-right"
                  >
                    Quantity: {line.quantity}
                  </Pgraph>
                </div>
              </div>
              {/* */}
              <div className="mt-4 border-b-[1px] border-solid border-neutral-2 h-[1px]"></div>
            </div>
          ))}
          {showCouponForm && <CouponForm />}
          <div className="flex justify-between items-center mb-4 mt-4 text-neutral-6">
            <Pgraph variant="p-14">Subtotal</Pgraph>
            <Pgraph variant="p-14">
              <NumberFormat
                value={subTotal}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'$'}
                decimalScale={2}
                fixedDecimalScale={true}
              ></NumberFormat>
            </Pgraph>
          </div>
          <ServiceFeeModule delivery={delivery}></ServiceFeeModule>
          {coupons.map((coupon) => (
            <div
              key={coupon.id}
              className="flex justify-between mb-4 items-center text-neutral-6"
            >
              <Pgraph variant="p-14">Promo Code: {coupon.code}</Pgraph>
              <Pgraph variant="p-14">
                <NumberFormat
                  value={coupon.discountedAmount}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={'-$'}
                  decimalScale={2}
                  fixedDecimalScale={true}
                ></NumberFormat>
              </Pgraph>
            </div>
          ))}
          {credits > 0 && (
            <div className="flex justify-between mb-4 items-center text-neutral-6">
              <Pgraph variant="p-14">Credit applied</Pgraph>
              <Pgraph variant="p-14">
                {' '}
                <NumberFormat
                  value={credits}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={'-$'}
                  decimalScale={2}
                  fixedDecimalScale={true}
                ></NumberFormat>
              </Pgraph>
            </div>
          )}
          {discounts > 0 && (
            <div className="flex justify-between mb-4 items-center text-neutral-6">
              <Pgraph variant="p-14">Savings</Pgraph>
              <Pgraph variant="p-14">
                {' '}
                <NumberFormat
                  value={discounts}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={'-$'}
                  decimalScale={2}
                  fixedDecimalScale={true}
                ></NumberFormat>
              </Pgraph>
            </div>
          )}
          <div className="flex justify-between items-center mb-4">
            <Pgraph variant="l-14">Total</Pgraph>
            <Pgraph variant="l-14">
              <NumberFormat
                value={total}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'$'}
                decimalScale={2}
                fixedDecimalScale={true}
                data-cy="ORDER_SUMMARY_PRICE_TOTAL"
              ></NumberFormat>
            </Pgraph>
          </div>
        </div>
      </div>
    </div>
  )
}

export { OrderSummary }
