import React, {
  ReactFragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useForm } from 'react-hook-form'
import { Pgraph } from '../Pgrah'
import Image from 'next/image'
import { Button } from '../Button'
import router, { useRouter } from 'next/router'
import { Headline } from '../Headline'
import { LOCAL_STORAGE, REG_EX, URLS } from '../../config'
import { InputText } from '../InputText'
import { trackEvent } from '../../config/analytics'
import { sanitizeFields } from '../../config/utilities'
import {
  removeLocalStorage,
  setLocalObject,
  useAuthDispatch,
  useAuthStore,
} from '../../store'
import { CustomersApi } from '../../services'
import { AUTH_ACTION, IEmailZipForm } from '../../interfaces'
import { Loading } from '../Loading'
import { StringParam, useQueryParams } from 'use-query-params'

interface IZipModalProps {
  onClose: () => void
  onCloseSuccess: () => void
}

interface IZipForm {
  zipCode: string
}

interface IEmailForm {
  emailAddress: string
}

function ZipModal({ onClose, onCloseSuccess }: IZipModalProps) {
  const {
    register: registerZip,
    handleSubmit: handleSubmitZip,
    formState: { errors: errorsZip },
    setError: setErrorZip,
    setValue,
  } = useForm<IZipForm>()

  const {
    register: registerEmail,
    handleSubmit: handleSubmitEmail,
    formState: { errors: errorsEmail },
  } = useForm<IEmailForm>()

  const { user, zipModalOpen } = useAuthStore()
  const authDispatch = useAuthDispatch()

  const [processing, setProcessing] = useState(false)
  const [success, setSuccess] = useState<boolean | undefined>(undefined)
  const [interestedZip, setInterestedZip] = useState<string>()
  const [zipInterestSent, setZipInterestSent] = useState(false)

  const [_, setQueryParams] = useQueryParams({
    place: StringParam,
  })
  const router = useRouter()

  const onCopyLinkClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    navigator.clipboard.writeText('https://loop.baby')
    setCopyLinkLabel(shareLinkSnippets.shared)
  }

  const shareLinkSnippets = {
    notShared: (
      <>
        <a
          className="text-poppy underline cursor-pointer text-14px"
          onClick={onCopyLinkClick}
          rel="noreferrer"
        >
          Copy this link
        </a>
        &nbsp;to share with a friend!
      </>
    ),
    shared: (
      <a
        className="text-poppy underline cursor-pointer text-14px"
        href="sms://&body=Hi%20there!%20Did%20you%20know%20you%20can%20rent%20baby%20gear%3F%20Check%20it%20out%20at%20https%3A%2F%2Floop.baby"
        target="_blank"
        rel="noreferrer"
      >
        Link is copied to your clipboard!
      </a>
    ),
  }
  const [copyLinkLabel, setCopyLinkLabel] = useState<JSX.Element>(
    shareLinkSnippets.notShared
  )

  const onSubmitZip = async (data: IZipForm) => {
    try {
      sanitizeFields(data)
      setProcessing(true)
      setErrorZip('zipCode', {})
      setSuccess(undefined)

      trackEvent('Zip Code Validation', {
        loopCustomerId: user?.customer?.loopCustomerId,
        zip: data.zipCode,
      })

      const location = await CustomersApi.validateLocation(data.zipCode)

      if (!location) {
        setInterestedZip(data.zipCode)
        authDispatch({
          type: AUTH_ACTION.SET_VALID_LOCATION,
          validLocation: false,
        })
        authDispatch({
          type: AUTH_ACTION.SET_LOCATION_FILTER,
          locationFilter: null,
        })
        removeLocalStorage([LOCAL_STORAGE.ZIP_CODE])
        setSuccess(false)
        return
      }

      // Valid zip
      if (router.pathname === URLS.PRODUCTS.INDEX) {
        setQueryParams(
          {
            place: location,
          },
          'pushIn'
        )
      }
      authDispatch({
        type: AUTH_ACTION.SET_VALID_LOCATION,
        validLocation: true,
      })
      const locationFilter = {
        zipcode: data.zipCode,
        location,
      }
      authDispatch({
        type: AUTH_ACTION.SET_LOCATION_FILTER,
        locationFilter,
      })
      authDispatch({
        type: AUTH_ACTION.SET_PROMPT_ZIP,
        promptZip: false,
      })

      setLocalObject(LOCAL_STORAGE.ZIP_CODE, locationFilter)
      clearState()
      onCloseSuccess()
    } catch (error) {
      console.error(error)
      setErrorZip('zipCode', {
        message: error instanceof Error ? error.message : 'Unknown error',
      })
    } finally {
      setProcessing(false)
    }
  }

  const onSubmitEmail = (data: IEmailForm) => {
    if (!interestedZip) return
    trackEvent('Interested zip-email', {
      email: data.emailAddress,
      zip: interestedZip,
    })
    setZipInterestSent(true)
  }

  const tryAnotherZipCode = () => {
    setSuccess(undefined)
  }

  const clearState = useCallback(() => {
    setSuccess(undefined)
    setInterestedZip(undefined)
    setZipInterestSent(false)
    setCopyLinkLabel(shareLinkSnippets.notShared)
  }, [shareLinkSnippets.notShared])

  const cleanClose = useCallback(() => {
    clearState()
    onClose()
  }, [clearState, onClose])

  useEffect(() => {
    const handleEsc = (event: globalThis.KeyboardEvent) => {
      if (zipModalOpen && event.key === 'Escape') {
        cleanClose()
      }
    }
    window.addEventListener('keydown', handleEsc)

    return () => {
      window.removeEventListener('keydown', handleEsc)
    }
  }, [cleanClose, zipModalOpen])

  const isGifting = useMemo(
    () => router.asPath.includes('gifting'),
    [router.asPath]
  )

  const updateZip = (value: any) => {
    setValue('zipCode', value)
  }

  if (!zipModalOpen) return <></>

  return (
    <div
      className="bg-modal-overlay fixed top-0 left-0 z-50 w-full h-full md:flex justify-center"
      onClick={cleanClose}
    >
      <div>
        <div
          className="relative flex flex-col bg-heavy-cream md:mt-[140px] md:w-[696px] lg:w-[820px] h-screen md:h-[600px] px-3 md:px-0"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="cursor-pointer" onClick={cleanClose}>
            <i className="absolute right-0 top-0 m-4 loop-icon-times text-24px text-neutral-7"></i>
          </div>
          <div className="mx-auto relative mt-[40px]">
            <div className="w-[52px] h-[14px]">
              <Image
                src="/images/loop-logo.svg"
                alt="Loop.baby"
                priority
                sizes="20vw"
                style={{ cursor: 'pointer ' }}
                width={100}
                height={100}
              />
            </div>
          </div>

          {success !== false && (
            <>
              <div className="mx-auto">
                <Headline
                  variant="recoleta-s"
                  className="text-center my-[40px]"
                >
                  {isGifting ? (
                    <>
                      What&apos;s your recipient&apos;s zip code? <br />
                      🤞 Hopefully we&apos;re delivering in their area
                    </>
                  ) : (
                    <>
                      What&apos;s your zip code?
                      <br />
                      🤞 Hopefully we&apos;re delivering in your area
                    </>
                  )}
                </Headline>
              </div>
              <form onSubmit={handleSubmitZip(onSubmitZip)}>
                {processing && (
                  <div className="mx-auto mt-6">
                    <Loading />
                  </div>
                )}
                {!processing && (
                  <div className="mx-auto max-w-[188px]">
                    <Pgraph
                      variant="p-14"
                      className="mb-[8px] font-normal text-14px leading-18 text-neutral-6 font-circular text-left"
                    >
                      Zip code
                    </Pgraph>
                    <InputText
                      type="numeric"
                      additionalClassName={'text-neutral-5'}
                      borderColor={'border-neutral-3'}
                      placeHolder="Zip code"
                      {...registerZip(`zipCode`, {
                        required: 'Required',
                        validate: {
                          format: (v) =>
                            v === '' ||
                            REG_EX.us_zip.test(v) ||
                            'Please enter a valid US zip code',
                        },
                      })}
                      validationText={errorsZip.zipCode?.message}
                      onChange={(e) => updateZip(e.target.value)}
                    />
                    <Button
                      type="submit"
                      label="Submit"
                      primary={true}
                      className="w-full mt-[28px]"
                    />
                  </div>
                )}
              </form>
            </>
          )}

          {success === false && (
            <>
              <div className="relative">
                <div className="hidden lg:block absolute left-0 top-[180px]">
                  <img src="/images/hand_leaves.png" alt="Hand leaf"></img>
                </div>
              </div>
              <div className="mx-auto">
                <Headline
                  variant="recoleta-s"
                  className="text-center my-[34px]"
                >
                  {isGifting ? (
                    <>Loop doesn&apos;t deliver where they are. Yet!</>
                  ) : (
                    <>Loop doesn&apos;t deliver where you are. Yet!</>
                  )}
                </Headline>
                <Pgraph
                  variant="p-14"
                  className="max-w-[460px] text-center leading-18"
                >
                  We&apos;re currently servicing:{' '}
                  <span className="font-circular-medium">
                    San Francisco Bay Area, New York Tri-State Area, and Greater
                    Philadelphia.
                  </span>{' '}
                  <br /> <br />
                  Subscribe for future location updates!
                </Pgraph>
              </div>
              {zipInterestSent && (
                <div className="mx-auto mt-6 leading-18">
                  <Pgraph variant="p-14">
                    Thanks, your info was submitted. Keep an eye on your email
                    for updates!{' '}
                  </Pgraph>
                </div>
              )}
              {!zipInterestSent && (
                <form onSubmit={handleSubmitEmail(onSubmitEmail)}>
                  <div className="mx-auto max-w-[270px]">
                    <Pgraph
                      variant="p-14"
                      className="mb-[8px] mt-4 font-normal text-14px leading-18 text-neutral-6 font-circular text-left"
                    >
                      Email address
                    </Pgraph>
                    <InputText
                      type="text"
                      additionalClassName={'text-neutral-5'}
                      borderColor={'border-neutral-3'}
                      placeHolder="Email address"
                      {...registerEmail(`emailAddress`, {
                        required: 'Required',
                        validate: {
                          format: (v) =>
                            v === '' ||
                            REG_EX.email.test(v) ||
                            'Please enter a valid email',
                        },
                      })}
                      validationText={errorsZip.zipCode?.message}
                    />
                    <div className="max-w-[188px] mx-auto">
                      <Button
                        type="submit"
                        label="Submit"
                        primary={true}
                        className="w-full mt-[28px]"
                      />
                    </div>
                  </div>
                  <div className="flex items-center justify-center mt-4 text-14px">
                    <img
                      src="/images/share.svg"
                      alt="Share this link with a friend!"
                    ></img>
                    <div className="ml-[6px] inline-block md:hidden">
                      <a
                        className="text-poppy underline cursor-pointer"
                        href="sms://&body=Hi%20there!%20Did%20you%20know%20you%20can%20rent%20baby%20gear%3F%20Check%20it%20out%20at%20https%3A%2F%2Floop.baby&utm_source=referrals&utm_medium=modal"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Share this link
                      </a>{' '}
                      with a friend!
                    </div>
                    <div className="ml-[6px] md:inline-block hidden">
                      {copyLinkLabel}
                    </div>
                  </div>
                  <Pgraph
                    variant="p-14"
                    className="flex justify-center mt-[20px] md:mt-[23px] text-neutral-5 underline cursor-pointer"
                    onClick={tryAnotherZipCode}
                  >
                    Try another zip code
                  </Pgraph>
                  <Pgraph
                    variant="p-14"
                    className="flex justify-center mt-[20px] md:mt-[18px] text-neutral-5 underline cursor-pointer"
                    onClick={cleanClose}
                  >
                    No thanks
                  </Pgraph>
                </form>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export { ZipModal }
