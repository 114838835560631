import { ReactNode } from 'react'

interface IHeadlineProps {
  /** Style variant according to Headlines in Alice Design System */
  variant?:
    | 'recoleta-no-fontsize'
    | 'recoleta-xl'
    | 'recoleta-l'
    | 'recoleta-m'
    | 'recoleta-s'
    | 'recoleta-xs'
    | 'circular-s'
    | 'circular-xs'
  /** Text or components to render with this Typography */
  children: ReactNode
  /** Optional additional class names for styling */
  className?: string
  onClick?: () => void,
  onMouseEnter?: (e: React.MouseEvent) => void
  /** Optional onMouseLeave handler */
  onMouseLeave?: (e: React.MouseEvent) => void
}

export const Headline = ({
  variant,
  children,
  className,
  onClick,
  onMouseEnter,
  onMouseLeave,
  ...props
}: IHeadlineProps) => {
  let classes = 'font-recoleta text-42px leading-50' // Default = recoleta-xl

  switch (variant) {
    case 'recoleta-no-fontsize': {
      classes = 'font-recoleta font-normal'
      break
    }
    case 'recoleta-l': {
      classes = 'font-recoleta text-32px leading-38 font-normal'
      break
    }
    case 'recoleta-m': {
      classes = 'font-recoleta text-28px leading-34 font-normal'
      break
    }
    case 'recoleta-s': {
      classes = 'font-recoleta text-24px leading-39'
      break
    }
    case 'recoleta-xs': {
      classes = 'font-recoleta text-20px leading-[28px]'
      break
    }
    case 'circular-s': {
      classes = 'font-circular text-24px leading-39'
      break
    }
    case 'circular-xs': {
      classes = 'font-circular text-18px leading-22'
      break
    }
    default:
      classes = 'font-recoleta text-42px leading-50'
  }

  if (className) {
    classes += ` ${className}`
  }

  return (
    <div className={classes} onClick={onClick} {...props}>
      {children}
    </div>
  )
}
