import { IMembersPerks } from '../interfaces'
import { Pgraph } from '../components/Pgrah/index'

export const MembershipPerks: IMembersPerks[] = [
  {
    perk: (
      <>
        <Pgraph variant="p-14" className="leading-19">
          <b className="font-circular-medium">Save 33% or more</b> on all
          rentals and delivery with exclusive member pricing
        </Pgraph>
      </>
    ),
  },
  {
    perk: (
      <>
        <Pgraph variant="p-14" className="leading-19">
          <b className="font-circular-medium">VIP delivery,</b> includes
          assembly, in-home setup, pickup, and free on orders over $100
        </Pgraph>
      </>
    ),
  },
  {
    perk: (
      <>
        <Pgraph variant="p-14" className="leading-19">
          <b className="font-circular-medium">Try before you buy,</b> and put the cost of your last month&apos;s rental towards your purchase.
        </Pgraph>
      </>
    ),
  },
  {
    perk: (
      <>
        <Pgraph variant="p-14" className="leading-19">
          <b className="font-circular-medium">Loop coverage,</b> membership
          covers normal wear + tear and minor damage
        </Pgraph>
      </>
    ),
  },
  {
    perk: (
      <>
        <Pgraph variant="p-14" className="leading-19">
          <b className="font-circular-medium">Exclusive member benefits</b>{' '}
          including special promotions and offers, access to warehouse sales,
          savings on travel rentals through BabyQuip, and more
        </Pgraph>
      </>
    ),
  },
]

export const GiftingMembershipPerks: IMembersPerks[] = [
  {
    perk: (
      <>
        <Pgraph variant="p-14" className="leading-19">
          <b className="font-circular-medium">Exclusive member pricing,</b> they
          save 33% or more on all rentals
        </Pgraph>
      </>
    ),
  },
  {
    perk: (
      <>
        <Pgraph variant="p-14" className="leading-19">
          <b className="font-circular-medium">VIP Deliveries,</b> they can try
          and swap whenever they need
        </Pgraph>
      </>
    ),
  },
  {
    perk: (
      <>
        <Pgraph variant="p-14" className="leading-19">
          <b className="font-circular-medium">Loop coverage,</b> membership
          covers normal wear + tear and minor damage
        </Pgraph>
      </>
    ),
  },
  {
    perk: (
      <>
        <Pgraph variant="p-14" className="leading-19">
          <b className="font-circular-medium">Exclusive member benefits</b>{' '}
          including special promotions and offers, access to warehouse sales,
          savings on travel rentals through BabyQuip, and more.
        </Pgraph>
      </>
    ),
  },
  {
    perk: (
      <>
        <Pgraph variant="p-14" className="leading-19">
          <b className="font-circular-medium">$75 order credit,</b> when you
          gift a Membership, a $75 credit gets automatically applied to the
          recipient&apos;s account
        </Pgraph>
      </>
    ),
  },
]
