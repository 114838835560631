import { DELIVERY_OPTIONS } from '../config'
import { CardDiscount } from '../services/payment_api'
import { ICartApiCart, ICoupon } from './cart.api.interfaces'
import { ICreditConsumption } from './checkout.interfaces'
import { ICartGiftCertificate, IGiftCard } from './giftcard.interfaces'
import {
  IMetaProductResponse,
  IProductFilters,
  IProductSummary,
} from './product.interface'

export enum CART_ACTION {
  SET_MEMBERSHIP = 'SET_MEMBERSHIP',
  SET_DELIVERY_ADDRESS = 'SET_DELIVERY_ADDRESS',
  SET_BILLING = 'SET_BILLING',
  TOGGLE_CART = 'TOGGLE_CART',
  STORE_MEMBERSHIPS_INFO = 'STORE_MEMBERSHIPS_INFO',
  FORCE_CHECKOUT_STEP = 'FORCE_CHECKOUT_STEP',
  SET_SEARCH_RESULTS = 'SET_SEARCH_RESULTS',
  ADD_LINE_QUEUE = 'ADD_LINE_QUEUE',
  REMOVE_LINE_QUEUE = 'REMOVE_LINE_QUEUE',
  UPDATE_LINE_QUEUE = 'UPDATE_LINE_QUEUE',
  CLEAR_LINE_QUEUE = 'CLEAR_CART_QUEUE',
  SET_API_CART = 'SET_API_CART',
  RESET_CART = 'RESET_CART',
  SET_BASIC_FILTERS = 'SET_BASIC_FILTERS',
  SET_FULL_FILTERS = 'SET_FULL_FILTERS',
  SET_CREDITS = 'SET_CREDITS',
  SET_SHOW_SEARCH_MODAL = 'SET_SHOW_SEARCH_MODAL',
  REGISTRY_CART_ADD_ITEM = 'REGISTRY_CART_ADD_ITEM',
  REGISTRY_CART_REMOVE_ITEM = 'REGISTRY_CART_REMOVE_ITEM',
  REGISTRY_CART_REMOVE_GIFT = 'REGISTRY_CART_REMOVE_GIFT',
  REGISTRY_CART_UPDATE_ITEM = 'REGISTRY_CART_UPDATE_ITEM',
  REGISTRY_CART_TOKENS = 'REGISTRY_CART_TOKENS',
  REGISTRY_SET_ID = 'REGISTRY_SET_ID',
  ADD_GIFT_CERTIFICATE = 'ADD_GIFT_CERTIFICATE',
  REMOVE_GIFT_CERTIFICATE = 'REMOVE_GIFT_CERTIFICATE',
  SET_GIFTER_DATA = 'SET_GIFTER_DATA',
  SET_SHOW_REGISTRY_SEARCH = 'SET_SHOW_REGISTRY_SEARCH',
  DELIVERY_OPTION = 'DELIVERY_OPTION',
  SET_SEARCH_TOP_PRODUCTS = 'SET_SEARCH_TOP_PRODUCTS',
  SET_CLEAR = 'SET_CLEAR',
  SET_SEARCH_VISIBLE = 'SET_SEARCH_VISIBLE',
  SET_HELPER_FUNCTIONS = 'SET_HELPER_FUNCTIONS',
}

export enum CHECKOUT_STEPS {
  NOT_STARTED,
  MEMBERSHIP_1,
  ACCOUNT_2,
  ADDRESS_3,
  BILLING_4,
  FINISHED,
}

export type cartActions =
  | {
      type: CART_ACTION.SET_DELIVERY_ADDRESS
      deliveryAddress: ICart['deliveryAddress']
    }
  | {
      type: CART_ACTION.SET_BILLING
      billing: ICart['billing']
    }
  | {
      type: CART_ACTION.TOGGLE_CART
      showCart?: boolean
    }
  | {
      type: CART_ACTION.STORE_MEMBERSHIPS_INFO
      membershipProducts: IMetaProductResponse
    }
  | {
      type: CART_ACTION.FORCE_CHECKOUT_STEP
      step: CHECKOUT_STEPS
    }
  | {
      type: CART_ACTION.SET_SEARCH_RESULTS
      searchTerm?: string
      searchResults?: IProductSummary[]
    }
  | {
      type: CART_ACTION.ADD_LINE_QUEUE
      lines: ICartQueuedLine[]
    }
  | {
      type: CART_ACTION.UPDATE_LINE_QUEUE
      productId: number
      newQuantity: number
    }
  | {
      type: CART_ACTION.REMOVE_LINE_QUEUE
      productId: number
    }
  | {
      type: CART_ACTION.CLEAR_LINE_QUEUE
    }
  | {
      type: CART_ACTION.SET_API_CART
      apiCart?: ICartApiCart
    }
  | {
      type: CART_ACTION.RESET_CART
    }
  | {
      type: CART_ACTION.SET_CLEAR
    }
  | {
      type: CART_ACTION.SET_BASIC_FILTERS
      basicFilters?: IProductFilters
    }
  | {
      type: CART_ACTION.SET_FULL_FILTERS
      fullFilters?: IProductFilters
    }
  | {
      type: CART_ACTION.SET_CREDITS
      creditConsumption?: ICreditConsumption
    }
  | {
      type: CART_ACTION.SET_SHOW_SEARCH_MODAL
      showSearchModal: boolean
    }
  | {
      type: CART_ACTION.REGISTRY_CART_ADD_ITEM
      product: IRegistryCartLine
    }
  | {
      type: CART_ACTION.REGISTRY_CART_REMOVE_ITEM
      productId: number
    }
  | {
      type: CART_ACTION.REGISTRY_CART_REMOVE_GIFT
    }
  | {
      type: CART_ACTION.REGISTRY_CART_UPDATE_ITEM
      productId: number
      newQuantity: number
    }
  | {
      type: CART_ACTION.REGISTRY_CART_TOKENS
      stripePaymentIntentId: string
      stripePaymentIntentSecret: string
      cardDiscounts: CardDiscount[]
    }
  | {
      type: CART_ACTION.REGISTRY_SET_ID
      registryId?: string
      name?: string
    }
  | {
      type: CART_ACTION.ADD_GIFT_CERTIFICATE
      giftCertificate: ICartGiftCertificate
    }
  | {
      type: CART_ACTION.REMOVE_GIFT_CERTIFICATE
      index: number
    }
  | {
      type: CART_ACTION.SET_GIFTER_DATA
      name: string
      email: string
    }
  | {
      type: CART_ACTION.SET_SHOW_REGISTRY_SEARCH
      open: boolean
    }
  | {
      type: CART_ACTION.DELIVERY_OPTION
      deliveryOption: typeof DELIVERY_OPTIONS[number]
    }
  | {
      type: CART_ACTION.SET_SEARCH_TOP_PRODUCTS
      topProducts: IProductSummary[]
    }
  | {
      type: CART_ACTION.SET_SEARCH_VISIBLE
      isSearchVisible: boolean
    }
  | {
      type: CART_ACTION.SET_HELPER_FUNCTIONS
      cartReset: () => void
      forceCartRefresh: () => Promise<void>
    }

export interface ICartQueuedLine {
  name: string
  quantity: number
  productId: number
  variantId: number
  imageUrl: string
  listPrice: number
  salePrice: number
  nonMemberPrice: number
  deliveryOption?: typeof DELIVERY_OPTIONS[number]
}

export interface ICart {
  apiCart?: ICartApiCart
  queuedCart: ICartQueuedLine[]
  deliveryAddress?: {
    firstName: string
    lastName: string
    address1: string
    address2?: string
    city: string
    state: string
    zip: string
  }
  billing?: {
    method: 'credit_card' | 'apple_pay'
    cardNumber: string
    cardExp: string
    cardCVC: string
    cardCountry: string
    cardZip: string
  }
}

export interface ICartStore {
  cart: ICart
  checkoutStep: CHECKOUT_STEPS
  showCart: boolean
  membershipProducts: {
    annual?: IProductSummary
    monthly?: IProductSummary
    deliveryFee?: IProductSummary
  }
  stripeToken?: string
  searchTerm?: string
  searchResults?: IProductSummary[]
  searchTopProducts?: IProductSummary[]
  coupons: ICoupon[]
  basicFilters?: IProductFilters
  fullFilters?: IProductFilters
  creditConsumption?: ICreditConsumption
  showSearchModal: boolean
  showRegistrySearch: boolean
  registryCart: IRegistryCart
  deliveryOption?: typeof DELIVERY_OPTIONS[number]
  resetCart: () => void
  forceCartRefresh: () => Promise<void>
  isSearchVisible: boolean
}

export interface IRegistryCart {
  loopRegistryId?: string
  lines: IRegistryCartLine[]
  giftCertificates: ICartGiftCertificate[]
  stripePaymentIntent?: {
    stripePaymentIntentId: string
    stripePaymentIntentSecret: string
  }
  gifterEmail?: string
  gifterName?: string
  name?: string
  cardDiscounts?: CardDiscount[]
}

export interface IRegistryCartLine extends ICartQueuedLine {
  rentalLength: number
  rentalPeriod: string
  entryId: number
}
