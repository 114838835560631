import { PRODUCT_CUSTOM_FIELDS } from '../../config'
import { IProduct, IProductSummary } from '../../interfaces'

export function calculateSavings(product: IProductSummary | IProduct) {
  const rentalData =
    product.customFields.find(
      (p) => p.name === PRODUCT_CUSTOM_FIELDS.AVG_RENTAL_PERIOD
    )?.value || '0'
  const rentalPeriod = parseInt(rentalData) || 0
  return product.retailPrice - product.price * rentalPeriod
}
