import { IFeaturedInBrand } from '../interfaces'

export const FEATURED_IN_BRANDS: IFeaturedInBrand[] = [
  // {
  //   name: 'Mother',
  //   url: 'https://www.mothermag.com/loop-baby-rental/',
  //   image: '/images/featured-in/mother.svg',
  //   width: '108px',
  //   height: '36px',
  //   largeLogo: false,
  // },
  {
    name: 'ROMPER',
    url: 'https://www.romper.com/parenting/loop-rent-baby-gear',
    image: '/images/featured-in/Romper.svg',
    width: '110px',
    height: '20px',
    largeLogo: false,
  },
  {
    name: 'Forbes',
    height: '50px',
    width: '110px',
    largeLogo: false,
    url: 'https://www.forbes.com/sites/allenadamson/2023/01/11/a-new-way-to-gear-up-for-life-with-a-new-baby',
    image: '/images/featured-in/forbes.svg',
  },
  {
    name: 'WSJ',
    url: 'https://www.wsj.com/articles/baby-gear-rental-startup-loop-hires-former-glossier-marketing-chief-as-ceo-11669846386',
    image: '/images/featured-in/wsj.svg',
    width: '90px',
    height: '50px',
    largeLogo: false,
  },
  {
    name: 'NBC',
    url: 'https://www.nbcbayarea.com/entertainment/california-live/stop-buying-start-looping/2638935/',
    image: '/images/featured-in/nbc.svg',
    width: '80px',
    height: '50px',
    largeLogo: false,
  },
  {
    name: 'Street Insider',
    url: 'https://www.streetinsider.com/Press+Releases/Loop%2C+Baby+Gear+and+Toy+Rental+Membership+Subscription+Service%2C+Joins+Forces+with+Ottobie/19919579.html',
    image: '/images/featured-in/si.svg',
    width: '80px',
    height: '50px',
    largeLogo: true,
  },
  {
    name: 'iHeart Radio',
    url: 'https://www.iheart.com/podcast/658-the-art-of-improvement-29091199/episode/co-founder-and-ceo-at-loopbaby-henry-95963040/',
    image: '/images/featured-in/i-heart-radio.svg',
    width: '70px',
    height: '50px',
    largeLogo: true,
  },
  /*{
    name: 'BirthSmarter Webinar',
    url: 'https://us02web.zoom.us/rec/play/jN8uc0m1yu9MklV-f5KYBIrunAUwxNx09rBpsDMmAiql-7_IIyxHMgjj1Js7PFuw890fjFmsb_s4WvOh.ZO56hf3glT84i-V2?continueMode=true&_x_zm_rtaid=H4yaNSWOTMGKrjQX3Ktqlw.1650797056930.5c5ad268d0ed172ab2fdece9bc835704&_x_zm_rhtaid=557',
    image: '/images/featured-in/birthsmarter.png',
  },*/
  {
    name: 'The Purist',
    url: 'https://thepuristonline.com/2022/06/baby-on-board/',
    image: '/images/featured-in/purist.png',
    width: '110px',
    height: '50px',
    largeLogo: false,
  },
  {
    name: 'Babe by Hatch',
    url: 'https://babe.hatchcollection.com/loop-rent-baby-gear/',
    image: '/images/featured-in/babe.svg',
    width: '110px',
    height: '50px',
    largeLogo: false,
  },
]
