import router from 'next/router'
import { logout } from '../../services/auth_api'
import { Headline } from '../Headline'
import { Pgraph } from '../Pgrah'
import { useAuthStore, useHooks } from '../../store'

export const MoreResources = () => {
  const { credentials } = useAuthStore()
  const { logout } = useHooks()

  const handleLogoutClick = async () => {
    try {
      if (!credentials) return
      logout()
      router.push('/')
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <div className="bg-white px-3 pb-[33px]">
      <Headline
        variant="recoleta-s"
        className="text-24px leading-29 md:text-24px md:leading-29 lg:text-24px lg:leading-29 pt-[56px] pb-[32px] md:text-center"
      >
        More resources
      </Headline>
      <div className="pb-[100px]">
        <div
          className="relative py-[24px] border-t-[1px] border-b-[1px] border-solid border-neutral-3 hover:cursor-pointer md:mx-[25%]"
          onClick={() => router.push('/contact-us')}
        >
          <div className="flex">
            <Pgraph
              variant="p-14"
              className="text-16px leading-22 md:text-16px md:leading-22 lg:text-16px lg:leading-22 font-normal text-neutral-7"
            >
              Contact support
            </Pgraph>
            <div className="w-[50%]">
              <div className="m-auto w-[10px] h-[10px] absolute right-0 top-[25px]">
                <i className="loop-icon-caret-right-bold"></i>
              </div>
            </div>
          </div>
        </div>
        <div
          className="relative py-[24px] border-t-[1px] border-b-[1px] border-solid border-neutral-2 hover:cursor-pointer md:mx-[25%]"
          onClick={() => router.push('/faq')}
        >
          <div className="flex">
            <Pgraph
              variant="p-14"
              className="text-16px leading-22 md:text-16px md:leading-22 lg:text-16px lg:leading-22 font-normal text-neutral-7"
            >
              FAQ
            </Pgraph>
            <div className="w-[50%]">
              <div className="m-auto w-[10px] h-[10px] absolute right-0 top-[25px]">
                <i className="loop-icon-caret-right-bold"></i>
              </div>
            </div>
          </div>
        </div>
        <div
          className="relative pt-[24px] border-t-[1px] border-solid border-neutral-2 hover:cursor-pointer md:mx-[25%]"
          onClick={handleLogoutClick}
        >
          <div className="flex">
            <Pgraph
              variant="p-14"
              className="text-16px leading-22 md:text-16px md:leading-22 lg:text-16px lg:leading-22 font-normal text-neutral-7"
            >
              Logout
            </Pgraph>
            <div className="w-[50%]">
              <div className="m-auto w-[10px] h-[10px] absolute right-0 top-[25px]">
                <i className="loop-icon-caret-right-bold"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
