import React, { useEffect, useState } from 'react'
import { Headline } from '../Headline'
import { Button } from '../Button'
import { Pgraph } from '../Pgrah'

interface IConfirmationModalProps {
  onClose: () => void
  onConfirm: () => void
  onCancel: () => void
  title: string
  description: string
  confirmationText: string
  cancelText: string
  isLoading?: boolean
}

function ConfirmationModal({
  onClose,
  onConfirm,
  onCancel,
  title,
  description,
  confirmationText,
  cancelText,
  isLoading = false,
}: IConfirmationModalProps) {
  useEffect(() => {
    const handleEsc = (event: globalThis.KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose()
      }
    }
    window.addEventListener('keydown', handleEsc)

    return () => {
      window.removeEventListener('keydown', handleEsc)
    }
  }, [onClose])
  return (
    <div>
      <div className="bg-modal-overlay fixed top-0 left-0 z-100 w-full h-full md:flex justify-center z-[100]">
        <div className="relative px-[27px] py-4 bg-snow-white md:p-5 md:rounded w-full md:w-1/2 lg:w-1/3 h-full md:h-fit m-[0px] md:m-auto max-w-full md:max-w-2xl ">
          <div className="cursor-pointer" onClick={onClose}>
            <i className="absolute right-0 top-0 m-4 loop-icon-times text-24px text-neutral-7"></i>
          </div>
          <Headline
            variant="recoleta-m"
            className="mb-4 text-center text-neutral-7"
          >
            {title}
          </Headline>
          <div>
            <Pgraph variant="p-14" className="text-center mb-4">
              {description}
            </Pgraph>

            <div className="text-center mb-4">
              <Button
                label={confirmationText}
                primary={true}
                onClick={onConfirm}
                disabled={isLoading}
                loading={isLoading}
              ></Button>
            </div>

            <div className="text-center">
              <Pgraph
                variant="p-14"
                className="inline-block underline xs:font-circular-medium hover:cursor-pointer"
                onClick={onCancel}
              >
                {cancelText}
              </Pgraph>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { ConfirmationModal }
