import React, { useEffect, useState } from 'react'
import { Headline, ProductCarouselCard } from '..'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import { IProduct, IProductSummary } from '../../interfaces'
import { getRecentlyViewedProducts } from '../../store'
import { PRODUCT_CUSTOM_FIELDS } from '../../config'
import { DateTime } from 'luxon'

interface IButtonGroupProps {
  next: () => void
  previous: () => void
  carouselState: {
    currentSlide: number
    totalItems: number
  }
}

const Slides_Per_Page = 2

const ButtonGroup = ({ next, previous, carouselState }: IButtonGroupProps) => {
  // use carouselState to disable button when in the last slide, for example.
  return (
    <div className="absolute -bottom-0 right-0 mr-3 hidden lg:block">
      <span onClick={() => previous()} className="mr-4 cursor-pointer">
        <i
          className={`loop-icon-arrow-long text-24px text-black transition ease-in duration-400 rotate-180 inline-block ${
            carouselState.currentSlide === 0
              ? 'cursor-not-allowed opacity-25'
              : ''
          }`}
        ></i>
      </span>
      <span onClick={() => next()} className="cursor-pointer">
        <i
          className={`loop-icon-arrow-long text-24px text-black transition ease-in duration-400 inline-block ${
            carouselState.totalItems - carouselState.currentSlide ===
            Slides_Per_Page
              ? 'cursor-not-allowed opacity-25'
              : ''
          }`}
        ></i>
      </span>
    </div>
  )
}

interface IRecentlyViewedProductProps {
  currentProduct?: IProduct
}

function RecentlyViewedProducts({
  currentProduct,
}: IRecentlyViewedProductProps) {
  const [products, setProducts] = useState<IProductSummary[]>([])

  useEffect(() => {
    try {
      const localProducts = getRecentlyViewedProducts()

      const recentProducts = localProducts
        .filter(
          (product) =>
            DateTime.fromMillis(product.viewedAt).diffNow('day').days > -7
        )
        .filter(
          (product) =>
            product.product.bigCommerceProductId !==
            currentProduct?.bigCommerceProductId
        )
      const productSummary: IProductSummary[] = [
        ...recentProducts.map((prod) => ({
          ages: [],
          bigCommerceProductId: prod.product.bigCommerceProductId,
          brand: [prod.product.brand],
          categories: prod.product.categories,
          customFields: prod.product.customFields,
          images: prod.product.images,
          nonMemberPrice: parseInt(
            prod.product.customFields.find(
              (f) => f.name === PRODUCT_CUSTOM_FIELDS.NON_MEMBER_PRICE
            )?.value || '0'
          ),
          price: prod.product.price,
          retailPrice: prod.product.retailPrice,
          sortOrder: 0,
          title: prod.product.title,
          variants: [],
          options: [],
        })),
      ]
      setProducts(productSummary)
    } catch (error) {
      console.error(error)
      setProducts([])
    }
  }, [currentProduct])

  if (products.length < 2) {
    return <></>
  }

  return (
    <section className="w-full">
      <div className="max-w-6xl mx-auto py-5 overflow-hidden">
        <div className="">
          <Headline
            variant="recoleta-s"
            className="text-center text-neutral-7 md:text-28px lg:text-32px"
          >
            Recently Viewed
          </Headline>
        </div>

        <div className="max-w-5xl mx-auto">
          <div className="px-[20px] relative pb-5">
            <Carousel
              swipeable={true}
              showDots={false}
              arrows={false}
              infinite={false}
              partialVisible={true}
              autoPlay={false}
              autoPlaySpeed={5000}
              renderButtonGroupOutside={true}
              responsive={{
                xxsmobile: {
                  breakpoint: { max: 400, min: 0 },
                  items: 1,
                  partialVisibilityGutter: 50,
                },
                xsmobile: {
                  breakpoint: { max: 500, min: 400 },
                  items: 1,
                  partialVisibilityGutter: 50,
                },
                mobile: {
                  breakpoint: { max: 640, min: 500 },
                  items: 1,
                  partialVisibilityGutter: 100,
                },
                smtablet: {
                  breakpoint: { max: 740, min: 640 },
                  items: Slides_Per_Page,
                  partialVisibilityGutter: 0,
                },
                tablet: {
                  breakpoint: { max: 1024, min: 740 },
                  items: Slides_Per_Page,
                  partialVisibilityGutter: 50,
                },
                desktop: {
                  breakpoint: { max: 9000, min: 1024 },
                  items: 3,
                  partialVisibilityGutter: 0,
                },
              }}
              //@ts-ignore
              customButtonGroup={products.length > 3 ? <ButtonGroup /> : <></>}
            >
              {products.map((product, index) => {
                return <ProductCarouselCard key={index} product={product} />
              })}
            </Carousel>
          </div>
        </div>
      </div>
    </section>
  )
}

export { RecentlyViewedProducts }
