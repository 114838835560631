interface IHpLink {
  dev: string
  prod: string
}

export const HP_LINKS = {
  HERO: {
    BOTTLE_STERILIZER: {
      dev: 'baby-brezza-one-step-bottle-sterilizer-dryer/3004',
      prod: 'baby-brezza-one-step-bottle-sterilizer-dryer/199',
    },
    PLAY_COUCH: {
      dev: 'the-figgy-play-couch/3305/',
      prod: 'the-figgy-play-couch/469/',
    },
  },
  SHOPPABLE_IMAGE: {
    TUNNEL: {
      dev: 'lovevery%C2%AE-play-tunnel/3123/?variant=3121',
      prod: 'lovevery%C2%AE-play-tunnel/318/?variant=387',
    },
    TOYBOX: {
      dev: 'loop-toy-box/3216?variant=3218',
      prod: 'loop-toy-box/453?variant=532',
    },
  },
  LETS_MAKE_SOME_SPACE: {
    LITTLE_COLORADO: {
      dev: '/products/little-colorado-montessori-toddler-one-tier-shelf/3224/?variant=3242',
      prod: '/products/little-colorado-montessori-toddler-one-tier-shelf/217/?variant=286',
    },
  },
} as const

export const getHpUrl = (link: IHpLink): string => {
  return process.env.NEXT_PUBLIC_ENV === 'prod' ? link.prod : link.dev
}
