import React from 'react'
import { Headline, Pgraph } from '..'

function PHLWhyLoop() {
  return (
    <section className="bg-neutral-1 w-full">
      <div className="flex flex-col content-center justify-center relative md:flex-row md:py-6 lg:max-w-[1074px] lg:mx-auto">
        <div className="md:w-1/2 md:order-2 px-4 pt-6 pb-2 mt-2 md:px-0 md:mt-0 md:pt-0 md:flex md:items-center">
          <img
            src="/images/hlw/hlw-ilustration.png"
            alt="Why Loop in Philly?"
            className="max-w-[165px] mx-auto md:max-w-[225px] lg:max-w-[300px]"
          />
        </div>
        <div className="md:w-1/2 pb-4 md:items-center">
          <div className="text-center mx-auto px-4 pb-4 lg:max-w-[450px]">
            <Headline
              variant="recoleta-s"
              className="text-neutral-6 text-center mx-auto md:text-left md:text-32px md:leading-38 mt-3"
            >
              Why Loop in Philly?
            </Headline>

            <Pgraph
              variant="p-14"
              className="text-neutral-6 text-center md:text-left mt-3 xs:leading-22 md:text-16px"
            >
              Loop is launching our baby gear delivery service in Philadelphia!
              We know how stressful it can be to prepare for a new arrival, so
              let us take some of the burden off by delivering all the baby gear
              you need right to your doorstep fully assembled.{' '}
            </Pgraph>

            <Pgraph
              variant="p-14"
              className="text-neutral-6 text-center md:text-left mt-3  xs:leading-22 md:text-16px"
            >
              Our service offers a wide variety of baby gear including toys,
              strollers, carriers, and more. We deliver to all major cities in
              the Philadelphia metro area, including Lower Merion, Conshohocken,
              and Bala Cynwyd. With our service, you can have peace of mind
              knowing that everything you need for your new baby will be taken
              care of, leaving you more time to focus on the important things.
            </Pgraph>
          </div>
        </div>
      </div>
    </section>
  )
}

export { PHLWhyLoop }
