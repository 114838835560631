import React from 'react'
import { Pgraph } from '../Pgrah'
import Image from 'next/image'

interface IReviewsIOProps {
  /** Optional Additional classes for styling */
  className?: string
  variant: 'hero' | 'social'
}

/**
 * Primary UI component for user interaction
 */
export const ReviewsIO = ({
  className,
  variant = 'hero',
  ...props
}: IReviewsIOProps) => {
  const defaultClasses = 'my-4'
  let classes = 'font-recoleta text-42px leading-50' // Default = recoleta-xl

  switch (variant) {
    case 'hero': {
      classes = 'text-center md:text-left'
      break
    }
    case 'social': {
      classes = 'text-center'
      break
    }
  }

  return (
    <a
      href="https://www.reviews.io/company-reviews/store/loop.baby"
      target="blank"
      className="inline-block"
    >
      <div className={[defaultClasses, className].join(' ')}>
        <div className="flex items-center">
          {variant === 'social' && (
            <Pgraph variant="p-14" className="text-neutral-6 text-center mr-2">
              Excellent
            </Pgraph>
          )}
          {variant === 'hero' && (
            <Pgraph
              variant="p-14"
              className="text-neutral-6 text-center mr-2 hidden md:block"
            >
              Excellent
            </Pgraph>
          )}
          {variant === 'hero' && (
            <Pgraph variant="p-14" className="text-neutral-6 mr-2 md:hidden">
              <Pgraph as="span" variant="l-14">
                5.0
              </Pgraph>{' '}
              from Reviews.io
            </Pgraph>
          )}

          {[1, 2, 3, 4, 5].map((_, idx) => (
            <div className="mr-1 last:mr-0" key={idx}>
              <Image
                key={idx}
                alt="5.0 from Reviews.io"
                src={'/images/star-review.svg'}
                width="14"
                height="14"
              />
            </div>
          ))}
        </div>

        {variant === 'hero' && (
          <div className={classes + ' hidden md:block'}>
            <Pgraph variant="p-12" className="mt-2 text-neutral-6">
              <Pgraph as="span" variant="l-14">
                5.0
              </Pgraph>{' '}
              from Reviews.io
            </Pgraph>
          </div>
        )}
        {variant === 'social' && (
          <div className={classes}>
            <Pgraph variant="p-12" className="mt-2 text-neutral-6">
              <Pgraph as="span" variant="l-14">
                5.0
              </Pgraph>{' '}
              from Reviews.io
            </Pgraph>
          </div>
        )}
      </div>
    </a>
  )
}
