import { MenuProps } from '../../interfaces/'
import { Dispatch, ReactNode, SetStateAction, useState } from 'react'
import { URLS } from '../../config'
import { useRouter } from 'next/router'

export const staticLinks: { href: string; label: string }[] = [
  {
    label: 'How Loop Works',
    href: URLS.HLW,
  },
  {
    label: 'Membership',
    href: URLS.MEMBERSHIP,
  },
  {
    label: 'Gifts',
    href: URLS.GIFTS,
  },
  {
    label: 'Registry',
    href: URLS.REGISTRY,
  },
  {
    label: 'About Us',
    href: URLS.ABOUT_US,
  },
  {
    label: 'Read',
    href: URLS.BLOG.INDEX,
  },
]

interface ILevelZeroMenuItemProps {
  menuItem: MenuProps
  node?: ReactNode
  closeMenu: () => void
}

function LevelZeroMenuItem({
  menuItem,
  node,
  closeMenu,
}: ILevelZeroMenuItemProps): JSX.Element {
  const [expanded, setExpanded] = useState(false)
  const router = useRouter()

  if (menuItem.hideFromMobile) return <></>

  const handleClick = () => {
    if (node) {
      setExpanded(!expanded)
    } else {
      const query = menuItem.filters || undefined
      router.push({ pathname: menuItem.link, query })
      closeMenu()
    }
  }

  return (
    <div
      className={`flex flex-col my-[10px] first:mt-[20px] last:mb-[20px] font-recoleta text-16px font-medium`}
    >
      <div
        className={`flex items-center w-fit ${
          expanded ? 'border-b-[1.5px] border-b-poppy' : ''
        }`}
        onClick={handleClick}
      >
        <div>{menuItem.label}</div>
        {node && (
          <i
            className={`loop-icon-caret-down ml-2 text-16px transition-all duration-500 ${
              expanded ? 'rotate-180' : ''
            }`}
          ></i>
        )}
      </div>
      <div
        className={`ease-in-out duration-500 transition-all ${
          expanded ? 'h-auto' : 'h-0 overflow-hidden'
        }`}
      >
        {node && <div>{node}</div>}
      </div>
    </div>
  )
}

interface ILevelOneMenuItemProps {
  menuItem: MenuProps
  setMinorCateogry: Dispatch<SetStateAction<MenuProps | undefined>>
  node?: ReactNode
  closeMenu: () => void
}

function LevelOneMenuItem({
  menuItem,
  setMinorCateogry,
  node,
  closeMenu,
}: ILevelOneMenuItemProps): JSX.Element {
  const router = useRouter()

  if (menuItem.hideFromMobile) return <></>

  if (menuItem.items && menuItem.items.length === 1) {
    return (
      <LevelOneMenuItem
        menuItem={menuItem.items[0]}
        setMinorCateogry={setMinorCateogry}
        closeMenu={closeMenu}
      />
    )
  }

  const handleClick = () => {
    if (node) {
      setMinorCateogry(menuItem)
    } else {
      const query = menuItem.filters || undefined
      router.push({ pathname: menuItem.link, query })
      closeMenu()
    }
  }

  return (
    <div className="flex flex-col font-circular text-14px leading-18 font-normal py-2 first:mt-3 last:mb-3">
      <div
        className="flex items-center w-fit cursor-pointer"
        onClick={handleClick}
      >
        <div>{menuItem.label}</div>
        {node && (
          <i
            className={
              'loop-icon-caret-down -rotate-90 ml-2 text-16px transition-all duration-500'
            }
          ></i>
        )}
      </div>
    </div>
  )
}

interface MobileMenuProps {
  menu: MenuProps[]
  setMinorCateogry: Dispatch<SetStateAction<MenuProps | undefined>>
  closeMenu: () => void
  depth?: number
  minorCategory?: MenuProps
}

export function MobileMenuBuilder({
  menu,
  setMinorCateogry,
  closeMenu,
  depth = 0,
  minorCategory,
}: MobileMenuProps): JSX.Element {
  return (
    <div>
      {minorCategory && (
        <MinorCategory
          minorCategory={minorCategory}
          clearCategory={() => setMinorCateogry(undefined)}
          closeMenu={closeMenu}
        />
      )}

      <div className={`${!!minorCategory ? 'hidden' : 'flex flex-col'}`}>
        {menu.map((item: MenuProps, index: number) => {
          if (!item.label) return
          let node: JSX.Element | undefined = undefined
          if (item.items && item.items.length) {
            node = (
              <MobileMenuBuilder
                closeMenu={closeMenu}
                menu={item.items}
                setMinorCateogry={setMinorCateogry}
                depth={depth + 1}
                key={index}
                minorCategory={minorCategory}
              />
            )
          }
          if (item.label) {
            switch (depth) {
              case 0: {
                return (
                  <LevelZeroMenuItem
                    key={index}
                    menuItem={item}
                    node={node}
                    closeMenu={closeMenu}
                  />
                )
              }
              case 1: {
                return (
                  <LevelOneMenuItem
                    key={index}
                    menuItem={item}
                    setMinorCateogry={setMinorCateogry}
                    node={node}
                    closeMenu={closeMenu}
                  />
                )
              }
              case 2: {
                break
              }
              default: {
                return <div key={`${depth}-${index}`}>Render error</div>
              }
            }
          } else {
            if (node) {
              return <div key={`${depth}-${index}-n`}>{node}</div>
            }
            return
          }
        })}
      </div>
    </div>
  )
}

interface IMinorCategoryProps {
  minorCategory: MenuProps
  clearCategory: () => void
  closeMenu: () => void
}

export function MinorCategory({
  minorCategory,
  clearCategory,
  closeMenu,
}: IMinorCategoryProps) {
  const router = useRouter()

  const handleClick = (item: MenuProps) => {
    router.push({ pathname: item.link, query: item.filters })
    clearCategory()
    closeMenu()
  }

  return (
    <div className="flex flex-col font-circular text-14px leading-18 mt-5">
      <div
        className="mb-2 font-circular-medium flex items-center cursor-pointer"
        onClick={clearCategory}
      >
        <i className="loop-icon-caret-down mr-2 text-16px transition-all duration-500 rotate-90"></i>
        <div>{minorCategory.label}</div>
      </div>
      {minorCategory.items
        ?.filter((item) => item.type !== 'seperator')
        .map((item, idx) => (
          <div
            key={idx}
            className="my-2 last:mb-[20px] cursor-pointer"
            onClick={() => handleClick(item)}
          >
            {item.label}
          </div>
        ))}
    </div>
  )
}
