import Script from 'next/script'

export const ScriptJustUno = () => {
  return (
    <Script
      id="JustUnoScript"
      // strategy="worker" - Causing recording problems?
      data-cfasync="false"
      dangerouslySetInnerHTML={{
        __html: `window.ju_num="B937C3A1-946E-4528-A8FE-D69DAB60E3A4";window.asset_host='//cdn.jst.ai/';(function(i,s,o,g,r,a,m){i[r]=i[r]||function(){(i[r].q=i[r].q||[]).push(arguments)};a=s.createElement(o),m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)})(window,document,'script',asset_host+'vck.js','juapp');`,
      }}
    ></Script>
  )
}
