import { SOCIAL_MEDIA } from '../config'

export function getRequestHeaders(accessToken?: string) {
  const obj: any = {
    'Content-type': 'application/json',
    Accept: 'application/json',
    'Accept-Encoding': 'gzip, deflate, br',
    Connection: 'keep-alive',
    Authorization: accessToken ? `Bearer ${accessToken}` : null,
  }
  return new Headers(obj)
}

export function createSocialRequest(type: SOCIAL_MEDIA) {
  return `${
    process.env.NEXT_PUBLIC_AUTH0_URL
  }/authorize?response_type=token&client_id=${
    process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID
  }&state=${generateState()}&connection=${type}&redirect_uri=${
    window.origin
  }/login/callback&scope=openid email profile`
}

export function generateState() {
  return (Math.random() + 1).toString(36).substring(4)
}
