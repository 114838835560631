import React from 'react'
import { Headline, HeadlineHighlight, Pgraph, Button } from '..'
import { useRouter } from 'next/router'


import Image from 'next/image'

function StrollerHero() {

    function openWaitlistPopup() {
        (window as any)._klOnsite = (window as any)._klOnsite || [];
        (window as any)._klOnsite.push(['openForm', 'WXVkf8']);
      }

    return (
        <section className='w-full'>
          <div className='flex bg-no-repeat bg-snow-white flex-col-reverse justify-center relative gap-5 md:flex-row '>
            <img 
                src={"/images/Illustration-SwirlArm.svg"} 
                alt="Swirl Arm" 
                className='absolute z-3 left-0 bottom-2 hidden lg:block lg:w-[120px] xl:w-[180px] '
            />
            <div className='flex flex-col gap-3 mb-[25px] mx-auto items-center text-center justify-center max-w-[500px] md:max-w-[400px] md:w-1/2 md:text-left md:items-start' >
                <Headline
                    variant='recoleta-xl'
                    className=''
                >
                    Stroller Showcase
                    <HeadlineHighlight />
                </Headline>
                <Pgraph
                    variant='p-16'
                    className=''
                >
                    A personalized stroller shopping experience in the comfort of your home. Limited rollout, join our waitlist today to reserve your spot! 
                </Pgraph>
                <Button
                    onClick={openWaitlistPopup}
                    label='Join Waitlist'
                    primary
                    className='my-20px max-w-[250px]'
            />
            
            </div>
            <img 
                src={'/images/stroller-showcase/hero-wave.svg'}
                alt='hero-wave'
                className='absolute -z-2 h-9 bottom-0 hidden lg:block'
                />
            <div className='flex md:w-1/2 md:ml-auto' >
                <Image
                    src={'/images/stroller-showcase/stroller-hero.png'}
                    width={1200}
                    height={800}
                    alt='Stroller Showcase Image'
                />
            </div>
          </div>                   
        </section>
    )

}

export { StrollerHero } 