import React from 'react'
import { Button, Headline, Header, HeadlineHighlight, Pgraph } from '..'
import { URLS } from '../../config'
import { IMembersPerks } from '../../interfaces'
import Link from 'next/link'

interface IMembershipPerksProps {
  title: string
  subTitle?: string
  perks: IMembersPerks[]
  imagePath?: string
  cta?: string
  linkTo?: string
  className?: string
  imageClassName?: string
  customCTA?: () => void
}

function MembershipMolecule({
  title,
  subTitle,
  perks,
  imagePath,
  cta,
  linkTo,
  className,
  customCTA,
  imageClassName,
}: IMembershipPerksProps) {
  return (
    <section
      className={[
        'bg-snow-white w-full md:bg-ilustration-hand-md md:bg-no-repeat md:bg-[40%_top] md:pt-5 lg:bg-ilustration-hand-lg lg:bg-[length:300px_200px] lg:pt-5 lg:bg-[42%_top] lg:pb-7',
        className,
      ].join(' ')}
    >
      <div className="max-w-5xl mx-auto pb-4 pt-2 md:pt-6 px-4 md:flex">
        <div className="w-full text-center mx-auto md:w-1/2 md:order-2 md:pl-3">
          <img
            src={imagePath || '/images/hlw/hlw-membership.jpg'}
            alt="Mom and baby reading a book together"
            className={['max-w-[100%]', imageClassName].join()}
          />
        </div>
        <div className="w-full md:w-1/2 md:my-4 md:mx-5 md:order-1 md:pr-2 md:mt-0 lg:pt-7">
          {subTitle && (
            <Pgraph
              variant="l-12"
              className="text-center uppercase text-neutral-6 mt-4 mb-3 md:text-left md:mt-0"
            >
              {subTitle}
            </Pgraph>
          )}

          <Header
            tag="h2"
            variant="recoleta-s"
            className="text-center text-poppy inline-block pr-3 pt-1 md:text-24px md:text-left lg:text-28px lg:pr-4 lg:text-left lg:pt-0 xs:mt-4 md:mt-0"
          >
            {title}
            <HeadlineHighlight />
          </Header>

          <div className="font-circular text-14px">
            $16.67/month ($200 paid annually)
          </div>

          <ul className="pt-3 mx-2 md:ml-0">
            {perks.map((item, index) => {
              return (
                <li className="text-neutral-6 flex mb-3 text-14px" key={index}>
                  <i className="loop-icon-checkmark text-32px mr-3 text-poppy"></i>
                  <div>{item.perk}</div>
                </li>
              )
            })}
          </ul>

          {customCTA && cta ? (
            <div className="text-center mt-5 mb-4 md:text-left">
              <Button label={cta} primary onClick={customCTA}></Button>
            </div>
          ) : (
            cta && (
              <div className="text-center mt-5 mb-4 md:text-left">
                <Link passHref href={linkTo || URLS.MEMBERSHIP_LANDING}>
                  <span className="rounded-r-full rounded-l-full inline-block font-circular-medium relative bg-poppy hover:bg-queen-2 text-white transition-colors duration-500 text-14px leading-22 py-11px px-32px lg:min-w-[210px] lg:text-center">
                    {cta}
                  </span>
                </Link>
              </div>
            )
          )}
        </div>
      </div>
    </section>
  )
}

export { MembershipMolecule }
