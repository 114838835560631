import { useRouter } from 'next/router'
import { useAuthStore } from '../../store'
import { Button } from '../Button'
import { Headline } from '../Headline'
import { Pgraph } from '../Pgrah'
import { URLS } from '../../config'
import { useMemo } from 'react'
import { Loading } from '../Loading'

export const ManageAccount = () => {
  const { status } = useAuthStore()

  return (
    <div className="bg-snow-white px-3 md:px-4 lg:px-0 pb-[33px]">
      <div className="max-w-5xl mx-auto">
        <Headline
          variant="recoleta-s"
          className="text-24px leading-29 md:text-24px md:leading-29 pt-[32px] pb-5 md:text-center"
        >
          Manage Account
        </Headline>
        <div className="md:flex md:gap-[20px]">
          <div className="relative bg-snow-white pl-4 pt-[32px] md:w-1/2 lg:w-2/3">
            <Headline
              variant="recoleta-s"
              className="text-24px leading-29 md:text-24px md:leading-29 lg:text-24px lg:leading-29"
            >
              Member Info
            </Headline>
            {status === 'authenticated' && <MemberInfo />}
            {status === 'initial' && (
              <div className="h-[250px]">
                <Loading />
              </div>
            )}
          </div>
          <div className="relative bg-snow-white pl-4 mt-3 pt-[32px] md:mt-0 md:w-1/2 lg:w-1/3">
            <Headline
              variant="recoleta-s"
              className="text-24px leading-29 md:text-24px md:leading-29 lg:text-24px lg:leading-29"
            >
              Delivery Address
            </Headline>
            {status === 'authenticated' && <DeliveryAddress />}
            {status === 'initial' && (
              <div className="h-[250px]">
                <Loading />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

const MemberInfo = () => {
  const { user } = useAuthStore()
  const router = useRouter()

  const handleChangePasswordClick = async () => {
    router.push(URLS.ACCOUNT.CHANGE_PASSWORD)
  }

  const handleUpdateAccountClick = async () => {
    router.push(URLS.ACCOUNT.UPDATE_ACCOUNT)
  }

  // Password option should only be displayed for auth0 based accounts, not social
  const showPasswordReset = useMemo(
    () => user?.account?.authProviderCustomerId.indexOf('auth0') !== -1,
    [user]
  )

  const firstChild = useMemo(
    () => user?.account.childBirthdays && user?.account.childBirthdays[0],
    [user]
  )

  const children = useMemo(() => {
    const otherChildren = user?.account.childBirthdays
      ? [...user?.account.childBirthdays]
      : []
    otherChildren.shift()
    return otherChildren
  }, [user])

  if (status === 'initial') {
  }

  return (
    <div>
      <div className="md:flex md:flex-wrap">
        <div className="md:w-1/2 lg:w-1/3">
          <Pgraph
            variant="l-16"
            className="mt-[24px] text-14px leading-17 md:text-14px md:leading-17 lg:text-16px lg:leading-19 text-neutral-7"
          >
            Name
          </Pgraph>
          <Pgraph
            variant="p-14"
            className="mt-[12px] font-normal xs:text-14px xs:leading-18 md:text-14px md:leading-18 lg:text-14px lg:leading-18 text-neutral-5 font-circular"
          >
            {user?.customer?.firstName} {user?.customer?.lastName}
          </Pgraph>
        </div>
        <div className="md:w-1/2 lg:w-1/3">
          <Pgraph
            variant="l-16"
            className="mt-[24px] text-14px leading-17 md:text-14px md:leading-17 lg:text-16px lg:leading-19 text-neutral-7"
          >
            Email
          </Pgraph>
          <Pgraph
            variant="p-14"
            className="mt-[12px] font-normal xs:text-14px xs:leading-18 md:text-14px md:leading-18 lg:text-14px lg:leading-18 text-neutral-5 font-circular"
          >
            {user?.account?.emailAddress}
          </Pgraph>
        </div>

        <div className="md:w-1/2 lg:w-1/3">
          <Pgraph
            variant="l-16"
            className="mt-[24px] font-medium text-14px leading-17 md:text-14px md:leading-17 lg:text-16px lg:leading-19 text-neutral-7"
          >
            Phone Number
          </Pgraph>
          <Pgraph
            variant="p-14"
            className="mt-[12px] font-normal xs:text-14px xs:leading-18 md:text-14px md:leading-18 lg:text-14px lg:leading-18 text-neutral-5 font-circular"
          >
            {user?.customer?.cellPhone || 'Not given'}
          </Pgraph>
        </div>

        <div className="md:w-1/2 lg:w-1/3">
          <Pgraph
            variant="l-16"
            className="mt-[24px] font-medium text-14px leading-17 md:text-14px md:leading-17 lg:text-16px lg:leading-19 text-neutral-7"
          >
            Child&apos;s name
          </Pgraph>
          <Pgraph
            variant="p-14"
            className="mt-[12px] font-normal xs:text-14px xs:leading-18 md:text-14px md:leading-18 lg:text-14px lg:leading-18 text-neutral-5 font-circular"
          >
            {firstChild?.name ? firstChild?.name : 'Not given'}
          </Pgraph>
        </div>
        <div className="md:w-1/2 lg:w-1/3">
          <Pgraph
            variant="l-16"
            className="mt-[24px] font-medium text-14px leading-17 md:text-14px md:leading-17 lg:text-16px lg:leading-19 text-neutral-7"
          >
            Child&apos;s Birthday
          </Pgraph>
          <Pgraph
            variant="p-14"
            className="mt-[12px] font-normal xs:text-14px xs:leading-18 md:text-14px md:leading-18 lg:text-14px lg:leading-18 text-neutral-5 font-circular"
          >
            {firstChild?.birthdayDate.month &&
            firstChild?.birthdayDate.day &&
            firstChild?.birthdayDate.year
              ? firstChild.birthdayDate.month +
                '/' +
                firstChild.birthdayDate.day +
                '/' +
                firstChild.birthdayDate.year
              : 'Not given'}
          </Pgraph>
        </div>

        {showPasswordReset ? (
          <div className="md:w-1/2 lg:w-1/3">
            <Pgraph
              variant="l-16"
              className="mt-[24px] font-medium text-14px leading-17 md:text-14px md:leading-17 lg:text-16px lg:leading-19 text-neutral-7"
            >
              Password
            </Pgraph>
            <Pgraph
              variant="p-14"
              className="mt-[12px] font-normal xs:text-14px xs:leading-18 md:text-14px md:leading-18 lg:text-14px lg:leading-18 text-neutral-5 font-circular cursor-pointer"
            >
              <a className="underline" onClick={handleChangePasswordClick}>
                Change Password
              </a>
            </Pgraph>
          </div>
        ) : (
          <div className="md:1/2 lg:w-1/3"></div>
        )}
      </div>

      {children.length > 0 &&
        children.map((birthday, idx) => (
          <div className="md:flex md:flex-wrap" key={idx}>
            <div className="md:w-1/2 lg:w-1/3">
              <Pgraph
                variant="l-16"
                className="mt-[24px] font-medium text-14px leading-17 md:text-14px md:leading-17 lg:text-16px lg:leading-19 text-neutral-7"
              >
                Child&apos;s name
              </Pgraph>
              <Pgraph
                variant="p-14"
                className="mt-[12px] font-normal xs:text-14px xs:leading-18 md:text-14px md:leading-18 lg:text-14px lg:leading-18 text-neutral-5 font-circular"
              >
                {birthday.name ? birthday.name : 'Not given'}
              </Pgraph>
            </div>
            <div className="md:w-1/2 lg:w-1/3">
              <Pgraph
                variant="l-16"
                className="mt-[24px] font-medium text-14px leading-17 md:text-14px md:leading-17 lg:text-16px lg:leading-19 text-neutral-7"
              >
                Child&apos;s Birthday
              </Pgraph>
              <Pgraph
                variant="p-14"
                className="mt-[12px] font-normal xs:text-14px xs:leading-18 md:text-14px md:leading-18 lg:text-14px lg:leading-18 text-neutral-5 font-circular"
              >
                {birthday.birthdayDate.month &&
                birthday.birthdayDate.day &&
                birthday.birthdayDate.year
                  ? birthday.birthdayDate.month +
                    '/' +
                    birthday.birthdayDate.day +
                    '/' +
                    birthday.birthdayDate.year
                  : 'Not given'}
              </Pgraph>
            </div>
            <div
              className="hidden md:block md:w-1/2 lg:w-1/3
          "
            ></div>
          </div>
        ))}

      <Button
        className="my-[32px]"
        size="small"
        label="Update Account"
        onClick={handleUpdateAccountClick}
      />
    </div>
  )
}

const DeliveryAddress = () => {
  const { user } = useAuthStore()
  const router = useRouter()

  const handleUpdateAddressClick = async () => {
    router.push(URLS.ACCOUNT.DELIVERY_ADDRESS)
  }

  return (
    <div>
      <Pgraph
        variant="l-16"
        className="mt-[24px] font-medium text-14px leading-17 md:text-14px md:leading-17 lg:text-16px lg:leading-19 text-neutral-7"
      >
        Address
      </Pgraph>
      <div className="mt-[12px]">
        {user?.customer?.shippingAddress?.streetAddress1 && (
          <Pgraph
            variant="p-14"
            className="font-normal xs:text-14px xs:leading-18 md:text-14px md:leading-18 lg:text-14px lg:leading-18 text-neutral-5"
          >
            {user?.customer?.shippingAddress?.streetAddress1}
          </Pgraph>
        )}
        {user?.customer?.shippingAddress?.streetAddress2 && (
          <Pgraph
            variant="p-14"
            className="font-normal xs:text-14px xs:leading-18 md:text-14px md:leading-18 lg:text-14px lg:leading-18 text-neutral-5"
          >
            {user?.customer?.shippingAddress?.streetAddress2}
          </Pgraph>
        )}
        {user?.customer?.shippingAddress?.city && (
          <div className="inline-block">
            <Pgraph
              variant="p-14"
              className="font-normal xs:text-14px xs:leading-18 md:text-14px md:leading-18 lg:text-14px lg:leading-18 text-neutral-5"
            >
              {user?.customer?.shippingAddress?.city}
            </Pgraph>
          </div>
        )}
        {user?.customer?.shippingAddress?.state && (
          <div className="inline-block">
            <Pgraph
              variant="p-14"
              className="font-normal xs:text-14px xs:leading-18 md:text-14px md:leading-18 lg:text-14px lg:leading-18 text-neutral-5"
            >
              {', '}
              {user?.customer?.shippingAddress?.state}
            </Pgraph>
          </div>
        )}
        {user?.customer?.shippingAddress?.postalCode && (
          <Pgraph
            variant="p-14"
            className="font-normal xs:text-14px xs:leading-18 md:text-14px md:leading-18 lg:text-14px lg:leading-18 text-neutral-5"
          >
            {user?.customer?.shippingAddress?.postalCode}
          </Pgraph>
        )}
      </div>
      <div className="relative md:absolute bottom-0">
        <Button
          className="my-[32px]"
          size="small"
          label="Update Address"
          onClick={handleUpdateAddressClick}
        />
      </div>
    </div>
  )
}
