import { useCallback, useMemo } from 'react'
import { IQueryParamProps } from '../../pages/products'
import { useCartStore } from '../../store'
import { PILL_COLORS } from '../../config'

interface ICategoryPillProps {
  filterDataEvent: (
    data: IQueryParamProps,
    clearFilters: boolean,
    clearSearch: boolean
  ) => void
  queryParams: IQueryParamProps
  stackOption: 'overflow-x-scroll' | 'flex flex-col'
  className?: string
}

const basePillClasses =
  'flex items-center my-0 md:my-1 xl:my-2 mx-2 xl:mx-0 py-2 pr-5 px-[12px] text-14px whitespace-nowrap border w-fit border-none rounded-full mb-2 xl:mb-0'

export const CategoryPill = ({
  queryParams,
  filterDataEvent,
  stackOption,
  className,
}: ICategoryPillProps) => {
  const { fullFilters: filters } = useCartStore()

  const checkAgeOption = (id: number) => {
    if (!queryParams.ages) {
      filterDataEvent(
        {
          ...queryParams,
          ages: [id],
        },
        false,
        false
      )
      return
    }
    const ages = isAgeChecked(id)
      ? [...queryParams.ages?.filter((v) => v !== id)]
      : [...queryParams.ages, id]
    if (Array.isArray(ages)) {
      filterDataEvent(
        {
          ...queryParams,
          ages,
        },
        false,
        false
      )
    }
  }

  const checkCategoryOption = (id: number) => {
    if (!queryParams.categories) {
      filterDataEvent(
        {
          ...queryParams,
          categories: [id],
        },
        false,
        false
      )
      return
    }
    const categories = isCategoryChecked(id)
      ? [...queryParams.categories?.filter((v) => v !== id)]
      : [...queryParams.categories, id]
    if (Array.isArray(categories)) {
      filterDataEvent(
        {
          ...queryParams,
          categories,
        },
        false,
        false
      )
    }
  }

  const checkBrandOption = (id: number) => {
    if (!queryParams.brands) {
      filterDataEvent(
        {
          ...queryParams,
          brands: [id],
        },
        false,
        false
      )
      return
    }
    const brands = isBrandChecked(id)
      ? [...queryParams.brands?.filter((v) => v !== id)]
      : [...queryParams.brands, id]
    if (Array.isArray(brands)) {
      filterDataEvent(
        {
          ...queryParams,
          brands: brands,
        },
        false,
        false
      )
    }
  }

  const isAgeChecked = useCallback(
    (id: number) => {
      if (!queryParams.ages) return false

      const checked = queryParams.ages.find((v) => v === id)
      return !!checked
    },
    [queryParams.ages]
  )

  const isCategoryChecked = useCallback(
    (id: number) => {
      if (!queryParams.categories) return false

      const checked = queryParams.categories.find((v) => v === id)
      return !!checked
    },
    [queryParams.categories]
  )

  const isBrandChecked = useCallback(
    (id: number) => {
      if (!queryParams.brands) return false

      const checked = queryParams.brands.find((v) => v === id)
      return !!checked
    },
    [queryParams.brands]
  )

  const agePills = useMemo(
    () =>
      filters?.ages
        .filter((age) => isAgeChecked(age.id))
        .map((age) => ({ ...age, color: PILL_COLORS.AGES })),
    [filters?.ages, isAgeChecked]
  )

  const categoryPills = useMemo(
    () =>
      filters?.categories
        .filter((category) => isCategoryChecked(category.id))
        .map((category) => ({ ...category, color: PILL_COLORS.CATEGORIES })),
    [filters?.categories, isCategoryChecked]
  )

  const brandPills = useMemo(
    () =>
      filters?.brands
        .filter((brand) => isBrandChecked(brand.id))
        .map((brand) => ({ ...brand, color: PILL_COLORS.BRANDS })),
    [filters?.brands, isBrandChecked]
  )

  return (
    <div
      className={[
        'bg-snow-white mx-0 flex pillbar md:rounded-[5px] max-w-[100vw]',
        stackOption,
        className,
      ].join(' ')}
    >
      {agePills &&
        agePills.map((agePill) => (
          <div
            key={agePill.id}
            className={`${basePillClasses} ${agePill.color}`}
          >
            <div className="ml-1 font-circular ">{agePill.name}</div>
            <button
              className="cursor-pointer relative font-circular "
              onClick={() => checkAgeOption(agePill.id)}
            >
              <i className="loop-icon-times absolute -top-[12px] left-1 bottom-0 text-24px cursor-pointer" />
            </button>
          </div>
        ))}
      {categoryPills &&
        categoryPills.map((categoryPill) => (
          <div
            key={categoryPill.id}
            className={`${basePillClasses} ${categoryPill.color}`}
          >
            <div className="ml-1 font-circular ">{categoryPill.name}</div>
            <button
              className="cursor-pointer relative font-circular "
              onClick={() => checkCategoryOption(categoryPill.id)}
            >
              <i className="loop-icon-times absolute -top-[12px] left-1 bottom-0 text-24px cursor-pointer" />
            </button>
          </div>
        ))}
      {brandPills &&
        brandPills.map((brandPill) => (
          <div
            key={brandPill.id}
            className={`${basePillClasses} ${brandPill.color}`}
          >
            <div className="ml-1 font-circular ">{brandPill.name}</div>
            <button
              className="cursor-pointer relative font-circular "
              onClick={() => checkBrandOption(brandPill.id)}
            >
              <i className="loop-icon-times absolute -top-[12px] left-1 bottom-0 text-24px cursor-pointer" />
            </button>
          </div>
        ))}
    </div>
  )
}
