import React from 'react'
import { Pgraph } from '../Pgrah'
import { Header } from '../Header'
import { Button } from '../Button'
import { useAuthDispatch } from '../../store'
import { AUTH_ACTION } from '../../interfaces'
import Image from 'next/image'

interface ICheckYourAreaProps {
  variant?: 'regular' | 'grey'
  cta: string
}

function CheckYourArea({ variant = 'regular', cta }: ICheckYourAreaProps) {
  const authDispatch = useAuthDispatch()

  const checkTheirZipcode = () => {
    authDispatch({
      type: AUTH_ACTION.SET_ZIP_MODAL_OPEN,
      open: true,
    })
  }
  return (
    <section
      className={` ${variant !== 'grey' ? 'bg-heavy-cream' : 'bg-cold'} w-full`}
    >
      <div className="max-w-5xl mx-auto relative">
        <div className="py-[64px]">
          <div className="text-center lg:absolute lg:right-6 lg:top-0 lg:bottom-0 my-auto lg:flex lg:items-center">
            <Image
              src={`/images/icons/location-${
                variant !== 'grey' ? 'cities' : 'map'
              }.svg`}
              alt="Location"
              width="100"
              height="86"
              className="max-w-[125px] mx-auto"
            />
          </div>

          <div className="max-w-[260px] mx-auto md:max-w-[385px] lg:max-w-[460px]">
            <Header
              variant="recoleta-s"
              className="text-neutral-6 text-center mt-4 mb-3 md:text-28px md:leading-34"
            >
              Where we&apos;re Looping
            </Header>

            <Pgraph
              variant="p-14"
              className="xs:leading-18 text-neutral-6 text-center mx-auto"
            >
              We&apos;re currently servicing the{' '}
              <span
                className={`font-circular-medium ${
                  variant !== 'grey' ? 'text-poppy' : 'text-madhatter'
                }`}
              >
                San Francisco Bay Area, New York Tri-State Area and Philadelphia
                and parts of the Delaware Valley.
              </span>
            </Pgraph>

            <div className="text-center">
              <Button
                label={cta}
                className="mt-4 mx-auto"
                onClick={checkTheirZipcode}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export { CheckYourArea }
