import { Query } from '@prismicio/types'
import { useRouter } from 'next/router'
import { BlogPostDocument } from '../../.slicemachine/prismicio'
import { Button } from '../Button'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import { useEffect, useState } from 'react'
import { Pgraph } from '..'

interface IBlogMoleculeProps {
  posts: Query<BlogPostDocument<string>>
}

const PrevArrow = (clickHandler: () => void, hasPrev: boolean) => {
  return (
    <button
      className="hidden md:inline-block top-0 bottom-0 absolute z-[2] p-1 left-0 xl:left-[100px] 2xl:left-[250px] opacity-100"
      onClick={clickHandler}
    >
      <i
        className={`loop-icon-arrow-long cursor-pointer rotate-180 text-24px text-black transition ease-in duration-400 inline-block`}
      ></i>
    </button>
  )
}

const NextArrow = (
  clickHandler: () => void,
  hasNext: boolean,
  label: string
) => {
  return (
    <button
      className="hidden md:inline-block top-0 bottom-0 absolute z-[2] p-1 right-0 xl:right-[100px] 2xl:right-[250px] opacity-100"
      onClick={clickHandler}
    >
      <i
        className={`loop-icon-arrow-long cursor-pointer text-24px text-black transition ease-in duration-400 inline-block`}
      ></i>
    </button>
  )
}

const Indicator = (
  clickHandler: (
    e: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>
  ) => void,
  isSelected: boolean
) => {
  const styles = isSelected
    ? 'inline-block cursor-pointer mx-[6px] w-2 h-2 rounded-full bg-neutral-5'
    : 'inline-block cursor-pointer mx-[6px] w-2 h-2 rounded-full border border-neutral-4'
  return <div className={styles} onClick={clickHandler}></div>
}

export const BlogMolecule = ({ posts }: IBlogMoleculeProps) => {
  const [isMobile, setIsMobile] = useState(true)
  const router = useRouter()

  useEffect(() => {
    const watcher = function () {
      if (window.innerWidth >= 1024) {
        setIsMobile(false)
      } else {
        setIsMobile(true)
      }
    }
    window.addEventListener('resize', watcher)
    return window.removeEventListener('resize', watcher)
  }, [])

  const goToPost = (post: BlogPostDocument<string>) => {
    router.push(`/blog/post/${post.uid}`)
  }

  return (
    <section className="bg-neutral-1">
      <div className="pb-5">
        <Carousel
          autoPlay={!isMobile}
          interval={5000}
          showThumbs={false}
          showStatus={false}
          showArrows={!isMobile}
          renderArrowNext={NextArrow}
          renderArrowPrev={PrevArrow}
          renderIndicator={Indicator}
        >
          {posts?.results.map((post) => (
            <div
              key={post.uid}
              className="flex flex-col-reverse md:flex-row px-[20px] md:px-0 pt-5 md:pt-7 pb-2 md:pb-[50px] max-w-4xl mx-auto"
            >
              <div className="text-neutral-6 mx-4 md:mx-5 md:w-50% lg:ml-auto md:my-auto">
                <div className="flex flex-col items-center md:items-start lg:max-w-[400px] lg:ml-auto">
                  <Pgraph
                    variant="l-14"
                    className="text-neutral-6 md:text-right md:text-16px md:leading-18 mt-4 md:mt-0"
                  >
                    REAL LIFE RECOMMENDATIONS
                  </Pgraph>
                  <div className="font-recoleta font-medium mt-3 text-neutral-6 md:text-left text-28px leading-34 md:text-32px md:leading-38 lg:text-42px lg:leading-50">
                    {post.data.molecule_title || post.data.title}
                  </div>
                  <Pgraph
                    variant="p-14"
                    className="text-neutral-6 px-4 md:px-0 max-w-xs mx-auto text-center xs:leading-18 md:text-left mt-3 mb-4 md:mx-0 md:pr-0 md:max-w-[295px] lg:max-w-sm  lg:text-16px lg:leading-22"
                  >
                    {post.data.molecule_summary || post.data.summary}
                  </Pgraph>
                  <Button
                    label={post.data.molecule_cta || 'View article'}
                    primary={true}
                    onClick={() => goToPost(post)}
                    className="mb-5"
                  />
                </div>
              </div>
              <div className="md:w-50% mx-4 md:mx-0 lg:ml-4 lg:mr-auto relative">
                <img
                  className="md:mx-auto lg:mx-0 max-w-[100%]"
                  src={
                    post.data.molecule_image?.url ||
                    post.data.header_image?.url ||
                    '/images/logo.png'
                  }
                  alt={
                    post.data.molecule_image?.alt ||
                    post.data.header_image?.alt ||
                    post.data.title ||
                    'Blog post'
                  }
                />
                <div className="absolute -top-[100px] -left-[280px] hidden md:block">
                  <img
                    src="/images/handillustration.svg"
                    alt="Real life recommendations"
                  />
                </div>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </section>
  )
}
