function HeadlineHighlight() {
  return (
    <div className="relative -top-[49px] -right-[24px]">
      <div className="md:w-[7px] w-[5px] right-[20px] top-[3px] h-[30px] m-auto absolute md:-top-[13px] md:right-[25px]">
        <img
          src="/images/tick.svg"
          alt="Product Image"
          width="100%"
          height="100%"
        />
      </div>
      <div className="md:w-[11px] w-[8px] right-[12px] top-[9px] h-[30px] m-auto absolute md:-top-[3px] md:right-[12px]">
        <img
          src="/images/tick2.svg"
          alt="Product Image"
          width="100%"
          height="100%"
        />
      </div>
      <div className="md:w-[14px] w-[10px] right-[6px] top-[19px] h-[30px] m-auto absolute md:top-[10px] md:right-[1px]">
        <img
          src="/images/tick3.svg"
          alt="Product Image"
          width="100%"
          height="100%"
        />
      </div>
    </div>
  )
}

export { HeadlineHighlight }
