import Image from 'next/image'
import { Button } from '../Button'
import { Pgraph } from '../Pgrah'
import Link from 'next/link'
import { HP_LINKS, getHpUrl } from '../../config/homepage.constants'
import { useRouter } from 'next/router'
import { URLS } from '../../config'

export const LetsMakeSomeSpaceMolecule = () => {
  const router = useRouter()

  const goToAboutUs = () => {
    router.push(URLS.ABOUT_US)
  }

  return (
    <section className="flex bg-heavy-cream w-full justify-center">
      <div className="flex flex-col md:flex-row md:items-center md:justify-center md:px-4 w-full max-w-4xl">
        <div className="w-full md:w-auto md:mt-5 md:mr-4">
          <div className="overflow-hidden relative w-full md:w-[340px] lg:w-[463px] h-[320px] md:h-[339px] lg:h-[462px]">
            <video
              className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
              autoPlay
              loop
              muted
              playsInline
            >
              <source
                src="/images/homepage/lets-make-some-space.mp4"
                type="video/mp4"
              />
              Sorry, your browser doesn&apos;t support videos.
            </video>
          </div>

          <div className="font-circular text-left ml-3 md:ml-0 mt-2 mb-4 mx-0 md:my-4 md:mt-2 md:mb-5 text-[13px]  md:w-[340px] lg:w-[463px]">
            Baby Boots plays with toys on her{' '}
            <span className="cursor-pointer text-poppy underline font-circular">
              <Link
                href={getHpUrl(HP_LINKS.LETS_MAKE_SOME_SPACE.LITTLE_COLORADO)}
              >
                Little Colorado Montessori Shelf
              </Link>
            </span>
          </div>
        </div>

        <div className="flex flex-col relative pb-[72px] md:pb-0 max-w-[390px] md:justify-center md:ml-4">
          <div className="absolute -left-4 md:left-auto lg:left-[20px] -top-4 md:-top-[75px] lg:-top-7 md:right-7 lg:right-auto">
            <Image
              src={'/images/yellow-triangle.svg'}
              alt="Yellow Triangle"
              width={75}
              height={79}
            />
          </div>
          <div className="absolute right-2 md:right-4 bottom-3 md:-bottom-7">
            <Image
              src={'/images/green-rectangle.svg'}
              alt="Green Rectangle"
              width={75}
              height={75}
            />
          </div>
          <div className="font-recoleta font-medium text-28px md:text-32px lg:text-42px leading-29 md:leading-[46px] lg:leading-[52px] text-center mt-4 md:mt-0 text-neutral-6">
            Let&apos;s Make Some Space
          </div>
          <div className="font-circular text-neutral-6 mx-4 md:mx-0 mt-3 mb-4 md:ml-0 text-center xs:leading-18 lg:text-16px lg:leading-22">
            More joy and less clutter is just a small part of what we do. Our
            mission is to free up more physical (and mental) space for things
            that matter–one Loop at a time.
          </div>
          <div className="flex justify-center">
            <Button label="About Us" primary onClick={goToAboutUs} />
          </div>
        </div>
      </div>
    </section>
  )
}
