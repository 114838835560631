import React, { useRef, KeyboardEvent, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { IRegistrySearchResponse } from '../../interfaces'
import { RegistryApi } from '../../services'
import { Pgraph } from '../Pgrah'
import { useRouter } from 'next/router'
import { useAuthStore, useHooks } from '../../store'
import { trackEvent } from '../../config/analytics'
import { URLS } from '../../config'
import { Loading } from '../Loading'

interface ISearchRegistryModalProps {
  open: boolean
  onClose: () => void
}

interface ISearchForm {
  searchTerm: string
}

function SearchRegistryModal({ onClose, open }: ISearchRegistryModalProps) {
  const {
    register,
    formState: { errors },
    trigger,
    watch,
  } = useForm<ISearchForm>()
  const [results, setResults] = useState<IRegistrySearchResponse | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const { user } = useAuthStore()
  const { reportBug } = useHooks()

  const router = useRouter()
  const searchTimeout = useRef<NodeJS.Timeout | undefined>(undefined)

  const onSubmit = async ({ searchTerm }: ISearchForm) => {
    if (!searchTimeout.current) {
      searchTimeout.current = setTimeout(async () => {
        trackEvent(
          localStorage.getItem('mobileView')
            ? 'search-mobile-registry-opened'
            : 'search-registry-opened',
          {
            loopCustomerId: user?.customer?.loopCustomerId,
            url: window?.location?.href,
            searchTerm: searchTerm,
          }
        )
        try {
          setIsLoading(true)
          const result = await RegistryApi.searchRegistries(searchTerm)
          setResults(result)
        } catch (error) {
          console.error(error)
          reportBug(error)
        } finally {
          setIsLoading(false)
        }
      }, 200)
      return
    } else {
      window.clearTimeout(searchTimeout.current)
      searchTimeout.current = undefined
      onSubmit({ searchTerm })
    }
  }

  const handleChange = () => {
    trigger()
    const searchTerm = watch('searchTerm')
    if (searchTerm.length > 2) {
      // event for registry search
      trackEvent('registrysearched', {
        loopCustomerId: user?.customer?.loopCustomerId,
        searchTerm: searchTerm,
      })
      onSubmit({ searchTerm })
    } else {
      setResults(undefined)
    }
  }

  useEffect(() => {
    const handleEsc = (event: globalThis.KeyboardEvent) => {
      if (open && event.key === 'Escape') {
        onClose()
      }
    }
    window.addEventListener('keydown', handleEsc)

    return () => {
      window.removeEventListener('keydown', handleEsc)
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const checkEnterSubmit = (e: KeyboardEvent<HTMLInputElement>) => {
    if (errors.searchTerm) return
    if (e.code === 'Enter') {
      onSubmit({ searchTerm: watch('searchTerm') })
    }
  }

  const goToRegistry = (registryId: string) => {
    router.push(`${URLS.REGISTRY}/${registryId}`)
    onClose()
  }

  return open ? (
    <div className="bg-snow-white fixed top-0 left-0 z-10 w-full h-full flex justify-center">
      <div
        className="cursor-pointer"
        onClick={() => {
          onClose()
        }}
      >
        <i className=" absolute right-0 top-0 m-4 loop-icon-times text-24px text-neutral-7"></i>
      </div>
      <div className="flex flex-col mt-7 w-full items-center">
        <div className="w-full max-w-[500px]">
          <form onSubmit={(e) => e.preventDefault()}>
            <div className="w-full flex flex-row">
              <input
                className="border-b-2 border-b-queen-2 font-recoleta text-24px md:text-28px w-full ml-4 focus:outline-none placeholder:text-neutral-7 placeholder:text-24px"
                type="text"
                {...register('searchTerm', {
                  onChange: handleChange,
                  minLength: {
                    value: 3,
                    message: 'Please enter at least 3 characters.',
                  },
                })}
                onKeyUp={checkEnterSubmit}
                placeholder="Search by first and last name"
                data-cy="REGISTRY_SEARCH_INPUT"
              ></input>
              <button
                type="button"
                className="mr-4 pt-2 p-2 border-b-queen-2 border-b-2"
                data-cy="REGISTRY_SEARCH_SUBMIT"
              >
                <i className="loop-icon-search-bold text-28px md:text-32px"></i>
              </button>
            </div>
          </form>
        </div>

        {errors.searchTerm && (
          <Pgraph variant="p-16" className="text-caution-2 mt-2">
            {errors.searchTerm.message}
          </Pgraph>
        )}

        {isLoading && <Loading />}

        {!isLoading && results && results.customers.length > 0 && (
          <>
            <div
              className="w-full max-w-[500px]"
              data-cy="REGISTRY_SEARCH_RESULTS_LABEL"
            >
              <Pgraph variant="l-14" className="self-start pl-4 mt-32px mb-3">
                Results
              </Pgraph>
            </div>

            {results?.customers.map((customer) => (
              <div
                className="flex justify-between w-full max-w-[500px] px-4"
                key={customer.loopCustomerId}
              >
                <div className="w-full flex justify-between text-neutral-7 py-4 border-b border-b-neutral-2">
                  <Pgraph
                    variant="p-14"
                    className="cursor-pointer"
                    onClick={() => goToRegistry(customer.registryId)}
                    data-cy="REGISTRY_SEARCH_RESULT"
                  >
                    {customer.firstName} {customer.lastName}{' '}
                    {customer.partnerFirstName
                      ? customer.partnerLastName
                        ? `& ${customer.partnerFirstName} ${customer.partnerLastName}`
                        : `& ${customer.partnerFirstName}`
                      : ''}
                  </Pgraph>
                  <Pgraph variant="p-14">
                    {customer.city ?? ''}
                    {customer.state ? `, ${customer.state}` : undefined}
                  </Pgraph>
                </div>
              </div>
            ))}
          </>
        )}

        {!isLoading && results && !results.customers.length && (
          <div className="max-w-[500px] px-4 mt-5">
            <Pgraph variant="p-16">
              No results for{' '}
              <Pgraph variant="l-16" className="font-bolder" as="span">
                &ldquo;{watch('searchTerm')}&rdquo;
              </Pgraph>
            </Pgraph>
          </div>
        )}
      </div>
    </div>
  ) : (
    <></>
  )
}

export { SearchRegistryModal }
