import React, { useState } from 'react'
import { Button, Headline, InputText, Pgraph } from '..'
import { IEmailZipForm } from '../../interfaces'
import { useForm } from 'react-hook-form'
import { sanitizeFields } from '../../config/utilities'
import { KlaviyoService } from '../../services'
import { useHooks } from '../../store'

function PHLHero() {
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState<string>()
  const [success, setSuccess] = useState<string>()
  const { reportBug } = useHooks()

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<IEmailZipForm>({
    defaultValues: {
      email: '',
      zipcode: '',
    },
  })

  const onSubmit = async (data: IEmailZipForm) => {
    try {
      setProcessing(true)
      setError(undefined)
      setSuccess(undefined)
      // wrap all forms with the following function to fix values such as whitespaces
      sanitizeFields(data)
      const result = await KlaviyoService.subscribeToAList({
        g: process.env.NEXT_PUBLIC_KLAVIYO_PHL_WAITLIST,
        email: data.email,
        $fields: '$zip',
        $zip: data.zipcode,
      })

      if (result) {
        setSuccess('Submitted')
        reset()
      } else {
        throw new Error('Error saving your data')
      }
    } catch (error) {
      console.error(error)
      reportBug(error, data)
      setError(error instanceof Error ? error.message : 'Unknown error')
    } finally {
      setProcessing(false)
    }
  }

  const updateZip = (value: any) => {
    setValue('zipcode', value)
  }

  return (
    <section className="bg-neutral-1 w-full">
      <div className="flex flex-col content-center justify-center relative md:flex-row lg:max-w-[1074px] lg:mx-auto">
        <div className="md:w-1/2 md:order-2 px-4 mt-2 md:px-0 md:mt-0">
          <img
            src="/images/phl/phl-hero.jpg"
            alt="Rent toys & baby gear in Philadelphia"
          />
        </div>
        <div className="md:w-1/2 pb-4">
          <div className="text-center mx-auto px-5 lg:pt-7 lg:max-w-[450px]">
            <Headline
              variant="recoleta-s"
              className="text-poppy text-center mx-auto md:text-left md:text-32px md:leading-38 mt-3"
            >
              Rent toys & baby gear in{' '}
              <span className="inline-block border-b-2 border-poppy">
                Philadelphia
              </span>
            </Headline>

            <Pgraph
              variant="p-14"
              className="text-neutral-6 text-center md:text-left mt-4 xs:leading-22"
            >
              Loop has arrived in the City of Brotherly Love! Place your orders
              now and sign up for more info on Loop, special promotions, and
              more!
            </Pgraph>
          </div>
          <div className="mx-auto px-5 mt-[30px] lg:max-w-[450px]">
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="max-w-[270px] mx-auto md:mx-0"
            >
              <div className="mt-4">
                <InputText
                  type="text"
                  additionalClassName={'text-neutral-5'}
                  borderColor={'border-neutral-3'}
                  placeHolder="Your email"
                  {...register('email', { required: 'Required' })}
                />
              </div>

              <div className="mt-4">
                <InputText
                  type="numeric"
                  additionalClassName={'text-neutral-5'}
                  borderColor={'border-neutral-3'}
                  placeHolder="Your zip code"
                  {...register('zipcode')}
                  onChange={(e) => updateZip(e.target.value)}
                />
                <Pgraph variant="p-12" className="text-neutral-5 mt-1">
                  Optional
                </Pgraph>
              </div>
              <div className="mt-5 text-center md:text-left ">
                <div className="w-full max-w-[194px] mx-auto md:mx-0">
                  <Button
                    label="Notify me"
                    primary
                    className="py-2 w-full"
                    type="submit"
                    size="medium"
                    loading={processing}
                    disabled={processing}
                  />

                  <Pgraph
                    variant="l-14"
                    className="text-center text-caution-2 mt-3"
                  >
                    {error}
                  </Pgraph>
                  <Pgraph
                    variant="l-14"
                    className="text-center text-success-3 mt-3"
                  >
                    {success}
                  </Pgraph>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}

export { PHLHero }
