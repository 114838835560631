import router, { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import {
  Pgraph,
  Headline,
  RegistryWishlist,
  RegistryChangeNameModal,
  RegistryMembershipInfo,
  Loading,
  RegistryEntryAddedConfirmation,
} from '..'
import { URLS } from '../../config'
import { IUser, IRegistry, AUTH_ACTION } from '../../interfaces'
import { RegistryRecommendedItems } from '../RegistryRecommendedItems'
import NumberFormat from 'react-number-format'
import { RegistryApi } from '../../services'
import { SignInModal } from '../SignInModal'
import {
  useAuthStore,
  useCartStore,
  useAuthDispatch,
} from '../../store'
import { ScriptBabyList } from '../ScriptBabyList'
import Link from 'next/link'

interface ICustomerRegistryProps {
  user?: IUser
  registry: IRegistry | undefined
  refreshRegistry: () => void
}

interface IBabyListProps {
  title?: string
  price: number
  redirectUrl?: string
  image?: string
}

function CustomerRegistry({
  user,
  registry,
  refreshRegistry,
}: ICustomerRegistryProps) {
  const { credentials, subscriptionActive } = useAuthStore()
  const router = useRouter()
  const [showEditRegistyName, setShowEditRegistyName] = useState<boolean>(false)
  const [showRegistryUpsell, setShowRegistryUpsell] = useState(false)
  const [displayLoader, setDisplayLoader] = useState(false)
  const [registryDueDate, setRegistryDueDate] = useState<{
    year: number
    month: number
    day: number
  } | null>(null)
  const [showSignInPop, setShowSignInPop] = useState(false)
  const [showRegistryAddedConfirmation, setShowRegistryAddedConfirmation] =
    useState(false)
  const { membershipProducts } = useCartStore()
  const [babyListParams, setBabyListParams] = useState<IBabyListProps>({
    title: '',
    image: '',
    price: 0,
    redirectUrl: '',
  })
  const authDispatch = useAuthDispatch()
  const [showBabyListFrame, setShowBabyListFrame] = useState<boolean>(false)

  useEffect(() => {
    const getRegistryDueDate = () => {
      const childBirthdays = user?.account.childBirthdays || []
      if (childBirthdays.length) {
        switch (childBirthdays.length) {
          case 0: {
            setRegistryDueDate(null)
            break
          }
          case 1: {
            if (childBirthdays[0].birthdayDate.day) {
              const date = childBirthdays[0].birthdayDate
              setRegistryDueDate(date)
            }
            break
          }
          default: {
            const sortedBirthdays = childBirthdays
              .map((item) => {
                return new Date(
                  `${item.birthdayDate.day}/${item.birthdayDate.month}/${item.birthdayDate.year}`
                )
              })
              .sort((date1, date2) => date1.getTime() - date2.getTime())
            setRegistryDueDate({
              day: sortedBirthdays[0].getDay(),
              month: sortedBirthdays[0].getMonth(),
              year: sortedBirthdays[0].getFullYear(),
            })
          }
        }
      } else {
        setRegistryDueDate(null)
      }
    }

    getRegistryDueDate()
    setShowBabyListFrame(false)

    // if (!subscriptionActive) {
    //   const getRegistryUpsellCookie = getCookie('registryUpsellCookie')
    //   if (!getRegistryUpsellCookie) {
    //     // do not show registry membership upsell popup for 90 days once displayed
    //     setCookie('registryUpsellCookie', 'true', 90)
    //     setShowRegistryUpsell(true)
    //   }
    // }
  }, [user])

  const addMembershipToRegistry = () => {
    setShowRegistryUpsell(false)
    registrySelected(1)
  }

  const openBabyListIframe = () => {
    setShowRegistryAddedConfirmation(false)
    setShowBabyListFrame(true)
  }

  const registrySelected = async (selectedValue: number) => {
    if (credentials) {
      setDisplayLoader(true)
      const productID = membershipProducts.annual?.bigCommerceProductId.toString() || ''
      const result = await RegistryApi.addRegistryEntry({
        token: credentials?.accessToken,
        registryInfo: {
          productId: productID,
          recurring: true,
          recurringPeriod: 'YEARLY',
          numPeriods: selectedValue,
          quantity: 1,
        },
      })
      if (result) {
        refreshRegistry()
        const price = membershipProducts?.annual?.price || 0
        const title = `Loop ${membershipProducts?.annual?.title}`
        const redirectUrl = `${process.env.NEXT_PUBLIC_BASE_URL}/registry/${result.registryEntryJwt}`
        const image = 'https://cdn11.bigcommerce.com/s-i7w2w515cb/products/2989/images/5434/Membership-Image__50198.1658433970.220.290.png?c=1&w=3840&q=75'
        setBabyListParams({
          title: title,
          price: price,
          redirectUrl: redirectUrl,
          image: image,
        })
        setTimeout(function () {
          setDisplayLoader(false)
          setShowRegistryAddedConfirmation(true)
        }, 500)
      } else {
        setDisplayLoader(false)
        setShowSignInPop(true)
      }
    } else {
      setShowSignInPop(true)
    }
  }

  const onGetStartedSelection = () => {
    authDispatch({
      type: AUTH_ACTION.SET_RETURN_URL,
      returnUrl: window.location.pathname,
    })
    setTimeout(function () {
      router.push(URLS.SIGNUP.INDEX)
    }, 500)
  }

  const signInComplete = () => {
    setShowSignInPop(false)
  //  setShowRegistryUpsell(true)
  }

  const editRegistryName = () => {
    setShowEditRegistyName(true)
  }

  const goToMyEditLocation = () => {
    router.push(URLS.ACCOUNT.DELIVERY_ADDRESS)
  }
  const goToUpdateAccount = () => {
    router.push(URLS.ACCOUNT.UPDATE_ACCOUNT)
  }

  return (
    <>
      <section className="bg-white w-full">
        <div className="max-w-5xl mx-auto py-4 pr-4 pl-4 lg:pl-[0px]">
          <div className="flex items-center">
            <Headline variant="recoleta-l" className="hidden md:block">{registry?.name}</Headline>
            <Headline variant="recoleta-s" className="md:hidden">{registry?.name}</Headline>
            <button
              onClick={editRegistryName}
              className="underline text-12px md:text-16px ml-[12px] border-0 bg-none"
            >
              Edit
            </button>
          </div>

          <div className="mt-2 flex items-center justify-start relative -left-1">
            <i className={`loop-icon-location text-23px`}></i>
            {user?.customer.billingAddress ? (
              <>
                <Pgraph variant="p-16">
                  {user?.customer.billingAddress.city},{' '}
                  {user?.customer.billingAddress.state}
                </Pgraph>

                <button
                  onClick={goToMyEditLocation}
                  className="underline text-12px md:text-16px ml-[12px] border-0 bg-none"
                >
                  Edit
                </button>
              </>
            ) : (
              <>
                <button
                  onClick={goToMyEditLocation}
                  className="underline text-12px md:text-16px ml-[12px] border-0 bg-none"
                >
                  Add Location
                </button>
              </>
            )}
          </div>
          <div className="mt-2 flex items-center justify-start relative">
            <img
              src="/images/calendar-icon.svg"
              alt="Due Date"
              className="ml-[2px] mr-1"
            />

            {registryDueDate ? (
              <>
                <Pgraph variant="p-16">
                  Due:{' '}
                  {registryDueDate
                    ? `${registryDueDate.month}/${registryDueDate.day}/${registryDueDate.year}`
                    : 'Unknown'}
                </Pgraph>

                <button
                  onClick={goToUpdateAccount}
                  className="underline text-12px md:text-16px ml-[12px] border-0 bg-none"
                >
                  Edit
                </button>
              </>
            ) : (
              <>
                <button
                  onClick={goToUpdateAccount}
                  className="underline text-12px md:text-16px ml-[12px] border-0 bg-none"
                >
                  Add expected due date
                </button>
              </>
            )}
          </div>

          {registry?.entries?.length || 0 > 0 ? (
            <div className="mt-3 md:mt-0 flex flex-col md:flex-row items-center justify-start relative">
              <div className="w-full md:w-4/5 md:pr-4">
                <Pgraph variant="p-16" className="text-neutral-6">
                  When friends/family gift you items, you choose if you want
                  those items and when you&apos;d like items delivered. Order
                  items as soon as you&apos;re sure you want them - the gifted
                  credits will apply at checkout, and we&apos;ll hold the items
                  until you&apos;re ready for delivery!
                </Pgraph>
              </div>
              <div className="w-full md:w-1/5 mt-4 md:mt-0">
                <div className="border border-neutral-3 rounded py-4 text-center">
                  <Pgraph variant="p-14" className="text-neutral-7">
                    Gift credits remaining
                  </Pgraph>
                  <Headline
                    variant="recoleta-l"
                    className="mt-2 text-neutral-7"
                  >
                    <NumberFormat
                      value={(user?.registryCredit || 0) / 100}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'$'}
                      decimalScale={0}
                      fixedDecimalScale={true}
                    />
                  </Headline>
                </div>
              </div>
            </div>
          ) : (
            <div className="mt-3 flex items-center justify-start relative">
              <Pgraph variant="p-16" className="md:hidden text-neutral-6">
                Use the &apos;Add to Registry&apos; button on product pages to
                start making your list.{' '}
                <Link
                    className="font-circular underline"
                    href={URLS.PRODUCTS.INDEX}
                >
                  Browse all products
                </Link>
              </Pgraph>
              <Pgraph variant="p-20" className="hidden md:block text-neutral-6">
                Use the &apos;Add to Registry&apos; button on product pages to
                start making your list.{' '}
                <Link
                  className="font-circular underline"
                  href={URLS.PRODUCTS.INDEX}
                >
                  Browse all products
                </Link>
              </Pgraph>
            </div>
          )}
        </div>
      </section>

      {registry?.entries && registry.entries.length > 0 ? (
        <RegistryWishlist
          registryEntries={registry.entries}
          user={user}
          onEntryRemoved={refreshRegistry}
        />
      ) : (
        ''
      )}

      <RegistryRecommendedItems />

      {!subscriptionActive ? <RegistryMembershipInfo /> : ''}

      {showEditRegistyName && (
        <RegistryChangeNameModal
          registry={registry}
          onClose={() => setShowEditRegistyName(false)}
          onComplete={() => {
            setShowEditRegistyName(false)
            refreshRegistry()
          }}
        />
      )}

      {/*{showRegistryUpsell && (*/}
      {/*  <RegistryMembershipUpsell*/}
      {/*    onClose={() => setShowRegistryUpsell(false)}*/}
      {/*    addToRegistry={addMembershipToRegistry}*/}
      {/*  ></RegistryMembershipUpsell>*/}
      {/*)}*/}

      {displayLoader && (
        <div className="bg-modal-overlay fixed top-0 left-0 w-full h-full md:flex justify-center z-index-100 loader-parent">
          <Loading />
        </div>
      )}

      {showSignInPop && (
        <SignInModal
          onClose={() => setShowSignInPop(false)}
          onSignInComplete={signInComplete}
          onGetStarted={onGetStartedSelection}
        />
      )}

      {showRegistryAddedConfirmation && (
        <RegistryEntryAddedConfirmation
          onClose={() => setShowRegistryAddedConfirmation(false)}
          onViewMyRegistry={() => router.push(URLS.REGISTRY)}
          onContinueBabyList={openBabyListIframe}
          isMembership={true}
        ></RegistryEntryAddedConfirmation>
      )}

      {showBabyListFrame && (
        <ScriptBabyList
          title={babyListParams.title}
          price={babyListParams.price}
          url={babyListParams.redirectUrl}
          image={babyListParams.image}
          dynamicVal={Math.random()}
        ></ScriptBabyList>
      )}
    </>
  )
}

export { CustomerRegistry }
