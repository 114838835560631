import React, { useMemo, useState } from 'react'
import NumberFormat from 'react-number-format'
import { Button, Headline, LinkTo, Pgraph } from '..'
import { CART_ACTION, IProductSummary } from '../../interfaces'
import { CartApi } from '../../services'
import {
  useAuthStore,
  useCartDispatch,
  useCartStore,
  useHooks,
} from '../../store'
import { trackEvent } from '../../config/analytics'

function RegistryMembershipMolecule() {
  const [isProcessing, setIsProcessing] = useState(false)
  const [showFullPerks, setShowFullPerks] = useState(false)
  const { membershipProducts, cart } = useCartStore()
  const { user } = useAuthStore()
  const { credentials } = useAuthStore()
  const cartDispatch = useCartDispatch()
  const { getCartData, reportBug } = useHooks()

  const { lines } = useMemo(
    () =>
      getCartData({
        cart,
      }),
    [cart, getCartData]
  )

  async function addMembershipToCart(membership: IProductSummary) {
    try {
      setIsProcessing(true)

      const currentMembership = lines.find(
        (line) =>
          line.productId === membershipProducts.annual?.bigCommerceProductId ||
          line.productId === membershipProducts.monthly?.bigCommerceProductId
      )

      const membershipId = currentMembership
        ? (currentMembership as any).id
        : undefined

      if (currentMembership && membershipId && credentials?.accessToken) {
        const deleted = await CartApi.removeCartItem(
          credentials.accessToken,
          membershipId
        )
        if (deleted) {
          const apiCart = await CartApi.addCartItem(credentials.accessToken, [
            {
              productId: membership.bigCommerceProductId,
              quantity: 1,
            },
          ])
          if (apiCart) {
            cartDispatch({
              type: CART_ACTION.SET_API_CART,
              apiCart,
            })
          }
        }
      } else {
        if (credentials?.accessToken) {
          const apiCart = await CartApi.addCartItem(credentials.accessToken, [
            {
              productId: membership.bigCommerceProductId,
              quantity: 1,
            },
          ])
          if (apiCart) {
            cartDispatch({
              type: CART_ACTION.SET_API_CART,
              apiCart,
            })
          } else {
            if (currentMembership) {
              cartDispatch({
                type: CART_ACTION.REMOVE_LINE_QUEUE,
                productId: currentMembership.productId,
              })
            }
            cartDispatch({
              type: CART_ACTION.ADD_LINE_QUEUE,
              lines: [
                {
                  imageUrl: membership.images[0].url,
                  listPrice: membership.price,
                  salePrice: membership.price,
                  name: membership.title,
                  productId: membership.bigCommerceProductId,
                  quantity: 1,
                  variantId: membership.variants.length
                    ? membership.variants[0].bigCommerceVariantId
                    : 0,
                  nonMemberPrice: membership.price,
                },
              ],
            })
          }
        } else {
          if (currentMembership) {
            cartDispatch({
              type: CART_ACTION.REMOVE_LINE_QUEUE,
              productId: currentMembership.productId,
            })
          }
          cartDispatch({
            type: CART_ACTION.ADD_LINE_QUEUE,
            lines: [
              {
                imageUrl: membership.images[0].url,
                listPrice: membership.price,
                salePrice: membership.price,
                name: membership.title,
                productId: membership.bigCommerceProductId,
                quantity: 1,
                variantId: membership.variants.length
                  ? membership.variants[0].bigCommerceVariantId
                  : 0,
                nonMemberPrice: membership.price,
              },
            ],
          })
        }
      }

      trackEvent('checkout_membership_selection', {
        loopCustomerId: user?.customer?.loopCustomerId,
        membership_type: membership,
      })

      cartDispatch({
        type: CART_ACTION.TOGGLE_CART,
        showCart: true,
      })
    } catch (error) {
      reportBug(error, membership)
    } finally {
      setIsProcessing(false)
    }
  }

  return (
    <section className="bg-white w-full">
      <div className="max-w-5xl mx-auto py-4 px-4 flex">
        <div className="w-1/2 hidden md:block">
          <img
            src="/images/registry/registry-annual-membership.jpg"
            alt="Membership for Registry"
          />
        </div>
        <div className="w-full md:w-1/2 md:my-4 md:mx-5">
          <Headline variant="recoleta-s" className="text-center">
            Membership
          </Headline>

          <Pgraph
            variant="l-14"
            className="text-neutral-6 mr-3 mt-2 text-center"
          >
            <NumberFormat
              value={membershipProducts.annual?.price || 0}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
              decimalScale={0}
              fixedDecimalScale={true}
            />
            {'   '}
            (
            <NumberFormat
              value={(membershipProducts.annual?.price || 0) / 12}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
              decimalScale={0}
              fixedDecimalScale={true}
              suffix={'/mo'}
            />
            )
          </Pgraph>

          <hr className="my-3 border-neutral-2" />

          <Pgraph variant="p-16" className="w-full text-center">
            Join today for exclusive perks
          </Pgraph>

          <ul className="pt-3 mx-2">
            <li className="text-neutral-6 flex mb-3 text-12px">
              <i className="loop-icon-checkmark text-32px mr-3"></i>
              <div>
                <b className="font-circular-medium">20% off registry coupon</b>{' '}
                - on everything left in your registry, 4 weeks before
                Baby&apos;s expected arrival
              </div>
            </li>
            <li className="text-neutral-6 flex mb-3 text-12px">
              <i className="loop-icon-checkmark text-32px mr-3"></i>
              <div>
                <b className="font-circular-medium">Start enjoying benefits</b>{' '}
                now, but your membership period won&apos;t begin until your
                child&apos;s birth month
              </div>
            </li>
            <li className="text-neutral-6 flex mb-3 text-12px">
              <i className="loop-icon-checkmark text-32px mr-3"></i>
              <div>
                <b className="font-circular-medium">
                  Unlimited priority delivery and/or pick ups
                </b>
                , so you can try items and swap whenever needed
              </div>
            </li>
            {showFullPerks ? (
              <>
                <li className="text-neutral-6 flex mb-3 text-12px">
                  <i className="loop-icon-checkmark text-32px mr-3"></i>
                  <div>
                    <b className="font-circular-medium">
                      In home set up and demonstrations
                    </b>{' '}
                    starting up to 3 weeks before due date
                  </div>
                </li>
                <li className="text-neutral-6 flex mb-3 text-12px">
                  <i className="loop-icon-checkmark text-32px mr-3"></i>
                  <div>
                    <b className="font-circular-medium">Rewards program:</b>{' '}
                    Rewards program: 10% credit back on all Loop rentals
                    quarterly
                  </div>
                </li>
              </>
            ) : (
              <></>
            )}
          </ul>

          <div className="text-center mt-3">
            {!showFullPerks ? (
              <Pgraph
                className="underline text-12px font-circular-medium border-0 hover:cursor-pointer"
                onClick={() => {
                  setShowFullPerks(true)
                }}
              >
                View More
              </Pgraph>
            ) : (
              <Pgraph
                className="underline text-12px font-circular-medium border-0 hover:cursor-pointer"
                onClick={() => {
                  setShowFullPerks(false)
                }}
              >
                View Less
              </Pgraph>
            )}
          </div>

          <div className="text-center mt-3">
            <Button
              label="Add to Bag"
              primary={true}
              className="w-auto mb-3 mx-auto"
              onClick={() =>
                addMembershipToCart(
                  membershipProducts.annual as IProductSummary
                )
              }
              loading={isProcessing}
              disabled={isProcessing}
            />
            <div className="text-center md:flex md:justify-center">
              <Pgraph variant="p-14" className="text-neutral-7 mr-2">
                Not ready to join today?
              </Pgraph>
              <Pgraph variant="p-14" className="text-neutral-7 underline">
                Add Membership to registry
              </Pgraph>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export { RegistryMembershipMolecule }
