import { useRouter } from 'next/router'
import React from 'react'
import { Headline, HeadlineHighlight, Pgraph } from '..'
import { HOW_LOOP_WORKS_STEPS, URLS } from '../../config'
import Link from 'next/link'

function HLWSteps() {
  const router = useRouter()

  const goToHLW = () => {
    router.push(URLS.HLW)
  }

  return (
    <section className="w-full">
      <div className="max-w-4xl mx-auto py-4 text-center md:pt-6">
        <Headline
          variant="recoleta-s"
          className="text-center text-poppy mb-5 md:mb-5 inline-block pr-3 pt-1 md:text-28px lg:text-32px lg:pr-4 lg:text-left lg:pt-0"
        >
          How Loop Works
          <HeadlineHighlight />
        </Headline>
        <div>
          {HOW_LOOP_WORKS_STEPS.map((step, index) => {
            return (
              <div
                key={index}
                className="group md:relative md:flex md:items-end md:min-h-[410px] md:justify-center md:mb-7 lg:min-h-[460px] "
              >
                <div className="flex flex-col w-full md:flex-row md:group-odd:justify-end md:bg-heavy-cream lg:w-[90%]">
                  <div className="w-full md:w-1/2 md:group-even:right-0 md:group-odd:left-0 md:bottom-[35px] md:absolute md:max-w-[385px] lg:max-w-[415px] lg:w-1/2">
                    <img
                      src={step.processImage}
                      alt={step.title}
                      width="100%"
                      height="100%"
                    />
                  </div>
                  <div className="order-1 bg-heavy-cream px-5 py-6 md:w-1/2 md:min-h-[170px] lg:min-h-[390px] lg:px-4 lg:flex lg:flex-col lg:w-1/2 md:group-even:items-end">
                    <div className="text-left">
                      <Headline
                        variant="recoleta-xl"
                        className="text-poppy mb-3 md:text-[55px] lg:text-[90px] lg:leading-[100px] lg:max-w-[230px]"
                      >
                        { index + 1}
                      </Headline>
                      <Pgraph
                        variant="l-16"
                        className="text-neutral-7 mb-2 lg:text-18px lg:max-w-[230px] lg:mb-3"
                      >
                        {step.title}
                      </Pgraph>
                      <Pgraph
                        variant="p-14"
                        className="text-neutral-5 lg:max-w-[230px]"
                      >
                        {step.fullText}
                      </Pgraph>

                      {step.learnMore ? (
                        <Pgraph
                          variant="l-14"
                          className="text-neutral-7 mt-3 underline"
                        >
                          <Link href={URLS.MEMBERSHIP}>
                            {' '}
                            {step.learnMore.text}
                          </Link>
                        </Pgraph>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export { HLWSteps }
