import navFile from '../public/navigation/prod-navigation.json'

const getNavigationStructure = async () => {
  try {
    if (!process.env.NEXT_PUBLIC_NAVIGATION_FILE) {
      return navFile
    }

    const response = await fetch(`${process.env.NEXT_PUBLIC_NAVIGATION_FILE}`)

    if (!response.ok) {
      throw Error('Error Fetching Navigation Config file')
    }

    return await response.json()
  } catch (error) {
    return error
  }
}

export { getNavigationStructure }
