import { IUser } from '../interfaces'
import { AnalyticsBrowser } from '@segment/analytics-next'
const analytics = AnalyticsBrowser.load({
  writeKey: process.env.NEXT_PUBLIC_SEGMENT_WRITEKEY || '',
})

export function logUser(whoAmIResult: IUser | undefined) {
  let membershipType = 'none'
  if (whoAmIResult?.memberships?.length) {
    const active = whoAmIResult?.memberships.find((x) => x.status === 'active')
    if (active) {
      membershipType = active.type
    }
  }
  analytics
    .identify(
      whoAmIResult?.customer?.loopCustomerId,
      {
        accepts_marketing: false,
        createdAt: new Date(),
        customerLifetimeValue: 0,
        default_address: {
          city: whoAmIResult?.customer?.shippingAddress?.city,
          country: whoAmIResult?.customer?.shippingAddress?.country,
          postalCode: whoAmIResult?.customer?.shippingAddress?.postalCode,
          state: whoAmIResult?.customer?.shippingAddress?.state,
          street1: whoAmIResult?.customer?.shippingAddress?.streetAddress1,
          street2: whoAmIResult?.customer?.shippingAddress?.streetAddress2,
        },
        description: '',
        email: whoAmIResult?.account?.emailAddress,
        firstName: whoAmIResult?.customer?.firstName,
        id: whoAmIResult?.customer?.loopCustomerId,
        lastName: whoAmIResult?.customer?.lastName,
        marketingOptIn: 'no_marketing',
        phone: whoAmIResult?.customer?.cellPhone,
        presentment_currency: 'USD',
        state: 'enabled',
        tags: 'active_subscriber',
        verified_email: false,
        membership: membershipType,
      },
      {}
    )
    .then((res) => {})
    .catch(console.error)
}

export function trackEvent(event_name: string, infoObj: any) {
  // notify segment of event
  analytics
    .track(event_name, infoObj)
    .then((res: any) => {})
    .catch(console.error)
}
