import React from 'react'
import { Button, Header, ImagePin, Pgraph, ReviewsIO } from '..'
import { URLS } from '../../config'
import { useRouter } from 'next/router'
import Image from 'next/image'
import { HP_LINKS, getHpUrl } from '../../config/homepage.constants'

function HomePageHero() {
  const router = useRouter()

  const goToProducts = () => {
    router.push(URLS.PRODUCTS.INDEX)
  }

  return (
    <section className="w-full bg-heavy-cream relative">
      <div className="flex flex-col content-center justify-center md:flex-row 3xl:max-w-[1800px] 3xl:ml-auto -mt-1 max:mx-auto">
        <div className="md:hidden w-full relative">
          <Image
            src="/images/homepage/homepage-mobile.jpg"
            alt="homepage"
            width={1170}
            height={1071}
          />
          <ImagePin
            title="The Figgy Play Couch"
            description="Rent for $52/mo"
            className="bottom-[20%] left-[28%]"
            destinationUrl={`${URLS.PRODUCTS.INDEX}/${getHpUrl(
              HP_LINKS.HERO.PLAY_COUCH
            )}`}
          />
          <ImagePin
            title="Baby Brezza One-Step Bottle Sterilizer & Dryer"
            description="Rent for $15/mo"
            className="top-[20%] right-[23%]"
            destinationUrl={`${URLS.PRODUCTS.INDEX}/${getHpUrl(
              HP_LINKS.HERO.BOTTLE_STERILIZER
            )}`}
            mobileVerticalDirection="bottom"
          />
        </div>
        <div className="hidden md:block md:w-[57%] lg:w-3/5 md:order-2 relative lg:h-[600px] xl:h-[700px] xl:w-2/3 overflow-hidden">
          <div className="relative h-full">
            <Image
              src="/images/homepage/homepage-tablet.jpg"
              alt="Home"
              width={1296}
              height={1251}
              className="lg:hidden"
            />
            <Image
              src="/images/homepage/homepage-desktop.jpg"
              alt="Home"
              width={3627}
              height={2049}
              className="hidden lg:block"
            />
            <ImagePin
              title="The Figgy Play Couch"
              description="Rent for $52/mo"
              className="bottom-[12%] lg:bottom-[15%] left-[10%] lg:left-[15%] z-[10]"
              destinationUrl={`${URLS.PRODUCTS.INDEX}/${getHpUrl(
                HP_LINKS.HERO.PLAY_COUCH
              )}`}
            />
            <ImagePin
              title="Baby Brezza One-Step Bottle Sterilizer & Dryer"
              description="Rent for $15/mo"
              className="top-[27%] lg:top-[30%] right-[15%] lg:right-[30%]"
              destinationUrl={`${URLS.PRODUCTS.INDEX}/${getHpUrl(
                HP_LINKS.HERO.BOTTLE_STERILIZER
              )}`}
            />
          </div>
        </div>
        <div className="md:w-[43%] lg:w-2/5 md:flex md:flex-col md:items-center md:justify-center lg:h-[540px] xl:h-[700px] xl:w-1/3">
          <div className="text-center max-w-[298px] xs:max-w-[337px] pt-[32px] pb-[32px] mx-auto md:ml-0 md:max-w-[305px] md:text-left md:pl-[35px] md:pr-[10px] md:pt-4 md:pb-0 lg:mx-auto lg:max-w-[365px]">
            <h1 className="font-recoleta font-medium text-[31px] leading-[38px] md:text-[40px] md:leading-[48px] lg:text-[50px] lg:leading-[60px] text-neutral-6">
              All The Things Your Kid Needs, &apos;Til They Don&apos;t.
            </h1>

            <div className="mt-3 md:flex md:flex-col md:mt-4">
              <Pgraph
                variant="p-14"
                className="xs:leading-19 text-neutral-7 md:text-16px md:leading-22"
              >
                Rent top-rated toys and baby gear.
              </Pgraph>

              <div className="mt-[20px] md:w-full md:text-left lg:mt-10px">
                <Button
                  label="Browse Items"
                  primary={true}
                  onClick={goToProducts}
                  size="large"
                  className="md:px-[40px] md:py-2 md:text-14px lg:px-[64px] lg:text-16px lg:my-[14px]"
                ></Button>
              </div>
              <ReviewsIO variant="hero" />
            </div>
          </div>
        </div>
      </div>
      <img
        src="/images/homepage/HTL-Wave-Top.svg"
        alt=""
        className="absolute bottom-0 w-full h-auto z-[1] xl:max-h-[196px] md:-bottom-3 lg:-bottom-4"
      />
    </section>
  )
}

export { HomePageHero }
