import { IProductFilters, IRentalItem } from '../../interfaces'
import { ByCategoryMolecule } from '../ByCategoryMolecule'

interface IBrowseByCategoryProps {
  rentedItems: IRentalItem[]
  filters: IProductFilters
}

export const BrowseByCategory = ({
  rentedItems,
  filters,
}: IBrowseByCategoryProps) => {
  return (rentedItems?.length || 0) <= 2 ? (
    <div className="max-w-7xl mx-auto justify-between">
      <div className="bg-white">
        <ByCategoryMolecule ssrFilters={filters} />
      </div>
    </div>
  ) : (
    <div></div>
  )
}
