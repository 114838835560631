import Link from 'next/link'

enum LIRF_CARD_TYPE {
  VIDEO = 'video',
  IMAGE = 'image',
}

export type ILoopingInRealLifeCardItem =
  | {
      type: LIRF_CARD_TYPE.IMAGE
      image: string
      imageAlt: string
      text: React.ReactElement
    }
  | {
      type: LIRF_CARD_TYPE.VIDEO
      video: string
      videoAlt: string
      text: React.ReactElement
    }

export const lirfItems: ILoopingInRealLifeCardItem[] = [
  {
    type: LIRF_CARD_TYPE.IMAGE,
    image: '/images/homepage/looping-in-real-life/social-image-1.jpeg',
    imageAlt:
      'Noa, 1 year, in a Stokke Tripp Trapp High Chair with Baby Set. Shop all High Chairs',
    text: (
      <>
        Noa, 1 year, in a{' '}
        <span className="cursor-pointer text-cinderella underline">
          <Link
            href={
              process.env.NEXT_PUBLIC_ENV === 'prod'
                ? '/products/stokke-tripp-trapp-high-chair-with-baby-set/363/?variant=432'
                : '/products/stokke-tripp-trapp-tray/3257/?variant=3275'
            }
          >
            Stokke Tripp Trapp High Chair with Baby Set
          </Link>
        </span>
        . Shop all{' '}
        <span className="cursor-pointer text-poppy underline">
          <Link
            href={
              process.env.NEXT_PUBLIC_ENV === 'prod'
                ? '/products/?categories=95&sortByParams=topProducts'
                : '/products/?categories=426&sortByParams=topProducts'
            }
          >
            High Chairs
          </Link>
        </span>
      </>
    ),
  },
  {
    type: LIRF_CARD_TYPE.IMAGE,
    image: '/images/homepage/looping-in-real-life/social-image-2.jpeg',
    imageAlt:
      'Naima, 5 years, with the Radio Flyer Tesla Model S For Kids. Shop all Ride Ons',
    text: (
      <>
        Naima, 5 years, with the{' '}
        <span className="cursor-pointer text-cinderella underline">
          <Link
            href={
              process.env.NEXT_PUBLIC_ENV === 'prod'
                ? '/products/radio-flyer-tesla-model-s-for-kids/481/?variant=569'
                : '/products/radio-flyer-tesla-model-s-for-kids/3317/?variant=3335'
            }
          >
            Radio Flyer Tesla Model S For Kids
          </Link>
        </span>
        . Shop all{' '}
        <span className="cursor-pointer text-poppy underline">
          <Link
            href={
              process.env.NEXT_PUBLIC_ENV === 'prod'
                ? '/products/?categories=103&sortByParams=topProducts'
                : '/products/?categories=432&sortByParams=topProducts'
            }
          >
            Ride Ons
          </Link>
        </span>
      </>
    ),
  },
  {
    type: LIRF_CARD_TYPE.IMAGE,
    image: '/images/homepage/looping-in-real-life/social-image-3.jpeg',
    imageAlt:
      "Two happy Loop members in a Radio Flyer Convertible Stroll 'N Wagon™. Shop all Strollers & Wagons",
    text: (
      <>
        Two happy Loop members in a{' '}
        <span className="cursor-pointer text-cinderella underline">
          <Link
            href={
              process.env.NEXT_PUBLIC_ENV === 'prod'
                ? '/products/radio-flyer-convertible-stroll-n-wagon%E2%84%A2/485/?variant=573'
                : '/products/radio-flyer-convertible-stroll-n-wagon%E2%84%A2/3321/?variant=3339'
            }
          >
            Radio Flyer Convertible Stroll &apos;N Wagon™
          </Link>
        </span>
        . Shop all{' '}
        <span className="cursor-pointer text-poppy underline">
          <Link
            href={
              process.env.NEXT_PUBLIC_ENV === 'prod'
                ? '/products/?categories=73&sortByParams=topProducts'
                : '/products/?categories=264&sortByParams=topProducts'
            }
          >
            Strollers & Wagons
          </Link>
        </span>
      </>
    ),
  },
  {
    type: LIRF_CARD_TYPE.VIDEO,
    video: '/images/homepage/looping-in-real-life/social-video-4.mp4',
    videoAlt:
      'Zion, 2 years, on a Lily & River Little Gymnast Balance Beam and a Woom 1 Original Balance Bike',
    text: (
      <>
        Zion, 2 years, on a{' '}
        <span className="cursor-pointer text-cinderella underline">
          <Link
            href={
              process.env.NEXT_PUBLIC_ENV === 'prod'
                ? '/products/lily-river-little-gymnast-balance-beam/268/?variant=337'
                : '/products/lily-river-little-gymnast-balance-beam/3230/?variant=3248'
            }
          >
            Lily & River Little Gymnast Balance Beam
          </Link>
        </span>{' '}
        and a{' '}
        <span className="cursor-pointer text-cinderella underline">
          <Link
            href={
              process.env.NEXT_PUBLIC_ENV === 'prod'
                ? '/products/woom-1-original-balance-bike/401/?variant=631'
                : '/products/woom-2-original-childrens-bike/3261/?variant=3279'
            }
          >
            Woom 1 Original Balance Bike
          </Link>
        </span>
      </>
    ),
  },
  {
    type: LIRF_CARD_TYPE.IMAGE,
    image: '/images/homepage/looping-in-real-life/social-image-5.jpeg',
    imageAlt:
      'Skylar, 22 months, in the Osprey POCO Child Carrier. Shop all Travel',
    text: (
      <>
        Skylar, 22 months, in the{' '}
        <span className="cursor-pointer text-cinderella underline">
          <Link
            href={
              process.env.NEXT_PUBLIC_ENV === 'prod'
                ? '/products/osprey-poco-child-carrier/337/?variant=406'
                : '/products/osprey-poco-child-carrier/3142/?variant=3140'
            }
          >
            Osprey POCO Child Carrier
          </Link>
        </span>
        . Shop all{' '}
        <span className="cursor-pointer text-poppy underline">
          <Link
            href={
              process.env.NEXT_PUBLIC_ENV
                ? '/products/?categories=265&sortByParams=topProducts'
                : '/products/?categories=40&sortByParams=topProducts'
            }
          >
            Travel
          </Link>
        </span>
      </>
    ),
  },
  {
    type: LIRF_CARD_TYPE.IMAGE,
    image: '/images/homepage/looping-in-real-life/social-image-6.jpeg',
    imageAlt:
      'A happy Loop kid plays with a Lily & River Little Surfer Balance Board. Shop all Play Gyms',
    text: (
      <>
        A happy Loop kid plays with a{' '}
        <span className="cursor-pointer text-cinderella underline">
          <Link
            href={
              process.env.NEXT_PUBLIC_ENV === 'prod'
                ? '/products/lily-river-little-surfer-balance-board/270/?variant=339'
                : '/products/new-bounce-mini-trampoline/3220/?variant=3238'
            }
          >
            Lily & River Little Surfer Balance Board
          </Link>
        </span>
        . Shop all{' '}
        <span className="cursor-pointer text-poppy underline">
          <Link
            href={
              process.env.NEXT_PUBLIC_ENV === 'prod'
                ? '/products/?categories=100&sortByParams=topProducts'
                : '/products/?categories=443&sortByParams=topProducts'
            }
          >
            Play Gyms
          </Link>
        </span>
      </>
    ),
  },
  {
    type: LIRF_CARD_TYPE.IMAGE,
    image: '/images/homepage/looping-in-real-life/social-image-7.jpeg',
    imageAlt:
      'Callahan, 1 week, in the BabyBjörn Baby Carrier Mini. Shop all Carriers',
    text: (
      <>
        Callahan, 1 week, in the{' '}
        <span className="cursor-pointer text-cinderella underline">
          <Link
            href={
              process.env.NEXT_PUBLIC_ENV === 'prod'
                ? '/products/babybj%C3%B6rn-baby-carrier-mini/201/?variant=270'
                : '/products/babybj%C3%B6rn-baby-carrier-mini/3006/?variant=3004'
            }
          >
            BabyBjörn Baby Carrier Mini
          </Link>
        </span>
        . Shop all{' '}
        <span className="cursor-pointer text-poppy underline">
          <Link
            href={
              process.env.NEXT_PUBLIC_ENV === 'prod'
                ? '/products/?categories=39&sortByParams=topProducts'
                : '/products/?categories=364&sortByParams=topProducts'
            }
          >
            Carriers
          </Link>
        </span>
      </>
    ),
  },
  {
    type: LIRF_CARD_TYPE.IMAGE,
    image: '/images/homepage/looping-in-real-life/social-image-8.jpeg',
    imageAlt:
      'Sofia (3 years) and Noa (1.5 years), both in Babyzen YOYO2 6+ Stroller. Shop all Strollers',
    text: (
      <>
        Sofia (3 years) and Noa (1.5 years), both in{' '}
        <span className="cursor-pointer text-cinderella underline">
          <Link
            href={
              process.env.NEXT_PUBLIC_ENV === 'prod'
                ? '/products/babyzen-yoyo2-6-stroller/209/?variant=278'
                : '/products/babyzen-yoyo2-6-stroller/3014/?variant=3012'
            }
          >
            Babyzen YOYO2 6+ Stroller
          </Link>
        </span>
        . Shop all{' '}
        <span className="cursor-pointer text-poppy underline">
          <Link
            href={
              process.env.NEXT_PUBLIC_ENV === 'prod'
                ? '/products/?categories=73&sortByParams=topProducts'
                : '/products/?categories=264&sortByParams=topProducts'
            }
          >
            Strollers
          </Link>
        </span>
      </>
    ),
  },
]
