import { useRouter } from 'next/router'
import {
  createContext,
  useReducer,
  ReactNode,
  Dispatch,
  useContext,
  useEffect,
  useCallback,
  useRef,
} from 'react'
import { DELIVERY_OPTIONS, LOCAL_STORAGE } from '../../config'
import {
  cartActions,
  CART_ACTION,
  CHECKOUT_STEPS,
  ICartStore,
  ICartQueuedLine,
} from '../../interfaces'
import { CartApi, ProductsApi } from '../../services'
import { useAuthStore } from '../auth'
import {
  getLocalACart,
  getLocalCart,
  getLocalBasicFilters,
  setLocalACart,
  setLocalCart,
  setLocalBasicFilters,
  getLocalFullFilters,
  removeLocalStorage,
} from '../local'
import { cartReducer } from './reducer'

export const initialCartState: ICartStore = {
  showCart: false,
  checkoutStep: CHECKOUT_STEPS.NOT_STARTED,
  cart: {
    queuedCart: [],
  },
  membershipProducts: { annual: undefined, monthly: undefined },
  coupons: [],
  showSearchModal: false,
  showRegistrySearch: false,
  registryCart: {
    lines: [],
    giftCertificates: [],
  },
  deliveryOption: DELIVERY_OPTIONS[0],
  resetCart: () => {},
  forceCartRefresh: async () => {},
  isSearchVisible: true,
}

const CartContext = createContext<ICartStore>(initialCartState)
const CartContextDispatch = createContext<Dispatch<cartActions> | undefined>(
  undefined
)

interface ICartProviderProps {
  children: ReactNode
}

const CartProvider = ({ children }: ICartProviderProps) => {
  const [cartState, dispatch] = useReducer(cartReducer, initialCartState)
  const router = useRouter()
  const { credentials, subscriptionActive, status } = useAuthStore()
  const initialized = useRef(false)

  const cart = cartState.cart

  const cartReset = useCallback(() => {
    removeLocalStorage([LOCAL_STORAGE.CART, LOCAL_STORAGE.ANONYMOUS_CART])
    dispatch({
      type: CART_ACTION.RESET_CART,
    })
  }, [])

  const forceCartRefresh = useCallback(async () => {
    try {
      if (!credentials?.accessToken) return

      const lineItems: ICartQueuedLine[] = cart.apiCart
        ? cart.apiCart?.lineItems.physicalItems
        : []
      lineItems.push(...cart.queuedCart)

      const cartReset = await CartApi.setCart(
        credentials?.accessToken,
        lineItems
      )
      if (cartReset) {
        const apiCart = await CartApi.fetchCheckout(credentials.accessToken)
        if (!apiCart?.success) throw new Error('Unable to fetch cart')
        dispatch({
          type: CART_ACTION.SET_API_CART,
          apiCart: apiCart.checkout.cart,
        })
        dispatch({
          type: CART_ACTION.SET_CREDITS,
          creditConsumption: apiCart.creditConsumption,
        })
        dispatch({
          type: CART_ACTION.CLEAR_LINE_QUEUE,
        })
        setLocalCart(apiCart.checkout.cart)
      }
    } catch (error) {
      cartReset()
    }
  }, [cart, cartReset, credentials?.accessToken])

  useEffect(() => {
    dispatch({
      type: CART_ACTION.SET_HELPER_FUNCTIONS,
      cartReset,
      forceCartRefresh,
    })
  }, [cartReset, forceCartRefresh])

  useEffect(() => {
    const getMembershipData = async () => {
      try {
        const response = await ProductsApi.fetchMetaProducts()
        if (response) {
          dispatch({
            type: CART_ACTION.STORE_MEMBERSHIPS_INFO,
            membershipProducts: response,
          })
        }
      } catch (error) {}
    }
    try {
      const apiCart = getLocalCart()
      const localCart = getLocalACart()
      if (localCart) {
        dispatch({
          type: CART_ACTION.ADD_LINE_QUEUE,
          lines: localCart,
        })
      }
      if (apiCart) {
        dispatch({
          type: CART_ACTION.SET_API_CART,
          apiCart,
        })
      }
    } catch (error) {}
    getMembershipData()
  }, [])

  useEffect(() => {
    const getFilters = async () => {
      try {
        const localBasicFilters = getLocalBasicFilters()
        const localFullFilters = getLocalFullFilters()
        if (localBasicFilters) {
          dispatch({
            type: CART_ACTION.SET_BASIC_FILTERS,
            basicFilters: localBasicFilters,
          })
        }
        if (localFullFilters) {
          dispatch({
            type: CART_ACTION.SET_FULL_FILTERS,
            fullFilters: localFullFilters,
          })
        }
        const basicFiltersReq = ProductsApi.fetchProductFilters({
          homepage: true,
          showAll: false,
        })
        const fullFiltersReq = ProductsApi.fetchProductFilters({
          showAll: false,
          homepage: false,
        })
        const [basicFilters, fullFilters] = await Promise.all([
          basicFiltersReq,
          fullFiltersReq,
        ])
        if (basicFilters) {
          dispatch({
            type: CART_ACTION.SET_BASIC_FILTERS,
            basicFilters: basicFilters.filters,
          })
          setLocalBasicFilters(basicFilters.filters)
        }
        if (fullFilters) {
          dispatch({
            type: CART_ACTION.SET_FULL_FILTERS,
            fullFilters: fullFilters.filters,
          })
          setLocalBasicFilters(fullFilters.filters)
        }
      } catch (error) {
        dispatch({
          type: CART_ACTION.SET_BASIC_FILTERS,
        })
      }
    }
    getFilters()
  }, [])

  useEffect(() => {
    const { query } = router
    if (query.showCart) {
      dispatch({
        type: CART_ACTION.TOGGLE_CART,
      })
    }
  }, [router])

  useEffect(() => {
    if (initialized.current) return

    if (credentials && status === 'authenticated') {
      initialized.current = true
      CartApi.fetchCheckout(credentials.accessToken)
        .then((checkout) => {
          dispatch({
            type: CART_ACTION.SET_API_CART,
            apiCart: checkout?.checkout.cart,
          })
          dispatch({
            type: CART_ACTION.SET_CREDITS,
            creditConsumption: checkout?.creditConsumption,
          })
          if (checkout) {
            setLocalCart(checkout.checkout.cart)
          }
        })
        .catch(forceCartRefresh)
    }
  }, [credentials, forceCartRefresh, status])

  useEffect(() => {
    if (cart.queuedCart.length < 1) return
    const addItems = async () => {
      try {
        if (status === 'authenticated' && credentials) {
          const addResult = await CartApi.addCartItem(
            credentials.accessToken,
            cart.queuedCart
          )
          if (addResult) {
            const apiCart = await CartApi.fetchCheckout(credentials.accessToken)
            if (!apiCart) throw new Error('Unable to fetch cart')
            dispatch({
              type: CART_ACTION.SET_API_CART,
              apiCart: apiCart?.checkout?.cart,
            })
            dispatch({
              type: CART_ACTION.SET_CREDITS,
              creditConsumption: apiCart?.creditConsumption,
            })
            dispatch({
              type: CART_ACTION.SET_CREDITS,
              creditConsumption: apiCart?.creditConsumption,
            })
            dispatch({
              type: CART_ACTION.CLEAR_LINE_QUEUE,
            })
            setLocalCart(apiCart?.checkout?.cart)
          }
        }
      } catch (error) {
        console.error(error)
        forceCartRefresh()
      }
    }
    addItems()
  }, [cart.queuedCart, credentials, forceCartRefresh, status])

  useEffect(() => {
    setLocalACart(cart.queuedCart)
  }, [cart.queuedCart])

  useEffect(() => {
    async function checkForSubscription() {
      if (!credentials?.accessToken || status !== 'authenticated') return

      const membershipProducts = cartState.membershipProducts
      const hasMembership = cart.apiCart?.lineItems.digitalItems.filter(
        (p) =>
          p.productId === membershipProducts.annual?.bigCommerceProductId ||
          p.productId === membershipProducts.monthly?.bigCommerceProductId
      )

      if (hasMembership && (hasMembership?.length || 0) > 1) {
        const memberships = [...hasMembership].sort(
          (a, b) => b.listPrice - a.listPrice
        )
        while (memberships.length > 1) {
          const toDelete = memberships.pop()
          if (toDelete) {
            await CartApi.removeCartItem(credentials.accessToken, toDelete.id)
          }
        }
        if (memberships[0].quantity > 1) {
          await CartApi.updateCartItem(
            credentials.accessToken,
            memberships[0].id,
            1
          )
        }
        const apiCart = await CartApi.fetchCheckout(credentials.accessToken)
        dispatch({
          type: CART_ACTION.SET_API_CART,
          apiCart: apiCart?.checkout.cart,
        })
        dispatch({
          type: CART_ACTION.SET_CREDITS,
          creditConsumption: apiCart?.creditConsumption,
        })
        return
      }

      if (subscriptionActive) {
        if (hasMembership?.length) {
          hasMembership.forEach(async (membershipItem) => {
            await CartApi.removeCartItem(
              credentials.accessToken,
              membershipItem.id
            )
          })
          const apiCart = await CartApi.fetchCheckout(credentials.accessToken)
          dispatch({
            type: CART_ACTION.SET_API_CART,
            apiCart: apiCart?.checkout.cart,
          })
          dispatch({
            type: CART_ACTION.SET_CREDITS,
            creditConsumption: apiCart?.creditConsumption,
          })
        }
      }
    }

    checkForSubscription()
  }, [
    subscriptionActive,
    credentials,
    cartState.membershipProducts,
    cart.apiCart?.lineItems.digitalItems,
    status,
  ])

  return (
    <CartContextDispatch.Provider value={dispatch}>
      <CartContext.Provider value={cartState}>{children}</CartContext.Provider>
    </CartContextDispatch.Provider>
  )
}

const useCartStore = () => {
  if (!CartContext) {
    throw new Error('useCartStore must be used within CartProvider')
  }
  const state = useContext(CartContext)
  return state
}

const useCartDispatch = () => {
  if (!CartContextDispatch) {
    throw new Error('useAuthDispatch must be used within AuthDispatchPRovider')
  }
  const dispatch = useContext(CartContextDispatch)
  if (!dispatch) {
    throw new Error('useCartDispatch dispatch not found')
  }
  return dispatch
}

export { CartProvider, useCartStore, useCartDispatch }
