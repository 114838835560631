import React from 'react'
import { Loading } from '../.'

const getColorClasses = (primary: boolean, disabled: boolean): string => {
  if (disabled) {
    return primary
      ? 'bg-neutral-3 text-neutral-5 transition-colors duration-500'
      : 'bg-neutral-3 border border-solid border-neutral-4 text-neutral-5 transition-colors duration-500'
  } else {
    return primary
      ? 'bg-poppy hover:bg-queen-2 text-white transition-colors duration-500'
      : 'bg-white border border-solid border-neutral-4 hover:border-neutral-6 text-neutral-7 transition-colors duration-500'
  }
}

const getSizeClasses = (size: IButtonProps['size']): string => {
  if (size === 'large') {
    return 'text-16px leading-22 py-14px px-64px'
  } else if (size === 'medium') {
    return 'text-14px leading-22 py-11px px-32px'
  }
  return 'text-12px leading-22 py-1 px-3 '
}
interface IButtonProps {
  /**
   * Is this the principal call to action on the page
   */
  primary?: boolean
  /**
   * Can this button be clicked?
   */
  disabled?: boolean
  /**
   * Should the button display a loading indication?
   */
  loading?: boolean
  /**
   * How large should the button be?
   */
  size?: 'small' | 'medium' | 'large'
  /**
   * Button inner text
   */
  label: string
  /**
   * Optional icon to display in button
   */
  icon?: string
  /**
   * Optional icon position - defaults to left
   */
  iconPosition?:
    | 'left-fixed'
    | 'right-fixed'
    | 'left-floating'
    | 'right-floating'
  /**
   * Optional button type parameter. Defaults to 'button'
   */
  type?: 'button' | 'submit' | 'reset'
  /**
   * Optional click handler
   */
  onClick?: () => void
  /** Optional Additional classes for styling */
  className?: string
  /** element reference */
  innerRef?: any
}

/**
 * Primary UI component for user interaction
 */
export const Button = ({
  primary = false,
  disabled = false,
  size = 'medium',
  label,
  icon,
  innerRef,
  iconPosition = 'left-fixed',
  className,
  type = 'button',
  loading,
  ...props
}: IButtonProps) => {
  const baseClasses: string =
    'rounded-r-full rounded-l-full font-circular-medium relative'
  const colorClasses: string = getColorClasses(primary, disabled)
  const sizeClasses: string = getSizeClasses(size)

  return (
    <button
      type={type}
      ref={innerRef}
      className={[baseClasses, colorClasses, sizeClasses, className].join(' ')}
      disabled={disabled}
      {...props}
    >
      {icon &&
        (iconPosition === 'left-fixed' || iconPosition === 'right-fixed') && (
          <div
            className={`absolute top-0 h-full flex items-center ${
              iconPosition === 'left-fixed' ? 'left-4' : 'right-4'
            }`}
          >
            <i className={`loop-icon-${icon} text-32px`}></i>
          </div>
        )}
      <div className="grow self-center mx-auto my-1 block">
        <div className="flex flex-row items-center justify-center">
          {icon && iconPosition === 'left-floating' && (
            <i className={`loop-icon-${icon} text-32px mr-1`}></i>
          )}
          {loading ? (
            <div className="my-[3px]">
              <Loading bgColor="bg-snow-white" />
            </div>
          ) : (
            label
          )}
          {icon && iconPosition === 'right-floating' && (
            <i className={`loop-icon-${icon} text-16px ml-1`}></i>
          )}
        </div>
      </div>
    </button>
  )
}
