import React, { useEffect, useMemo, useState } from 'react'
import {
  Button,
  Headline,
  Header,
  HeadlineHighlight,
  Pgraph,
  ProductCarouselCard,
} from '..'
import { useRouter } from 'next/router'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import { IProductSummary } from '../../interfaces'

interface ITopProductsProps {
  /** Regular for all the site and homepage includes a bigger headline */
  variant?: 'regular' | 'homepage' | '404'
  /** Optional additional class names for styling */
  className?: string
  /** Override original title */
  title?: string
  titleHighlight?: boolean
  subTitle?: string
  /** Override CTA button label */
  ctaLabel?: string
  /** Products */
  products: IProductSummary[]
  /** View products url */
  viewProductsUrl: string
}

interface IButtonGroupProps {
  next: () => void
  previous: () => void
  carouselState: {
    currentSlide: number
    totalItems: number
  }
}

const Slides_Per_Page = 2

const ButtonGroup = ({ next, previous, carouselState }: IButtonGroupProps) => {
  // use carouselState to disable button when in the last slide, for example.
  return (
    <div className="absolute -bottom-0 right-0 mr-3 hidden lg:block">
      <span onClick={() => previous()} className="mr-4 cursor-pointer">
        <i
          className={`loop-icon-arrow-long text-24px text-black transition ease-in duration-400 rotate-180 inline-block ${
            carouselState.currentSlide === 0
              ? 'cursor-not-allowed opacity-25'
              : ''
          }`}
        ></i>
      </span>
      <span onClick={() => next()} className="cursor-pointer">
        <i
          className={`loop-icon-arrow-long text-24px text-black transition ease-in duration-400 inline-block ${
            carouselState.totalItems - carouselState.currentSlide ===
            Slides_Per_Page
              ? 'cursor-not-allowed opacity-25'
              : ''
          }`}
        ></i>
      </span>
    </div>
  )
}

function TopProductsMolecule({
  variant,
  title,
  subTitle,
  titleHighlight = false,
  ctaLabel,
  products,
  viewProductsUrl,
}: ITopProductsProps) {
  const router = useRouter()

  const goToProducts = () => {
    router.push(viewProductsUrl)
  }

  return (
    <section className="w-full">
      <div className="max-w-6xl mx-auto pb-5 md:pb-6 pt-[40px] md:pt-6 overflow-hidden">
        {variant === '404' ? (
          <div className="flex flex-col px-4 md:flex-row mt-[40px]">
            <div className="text-center w-full md:w-1/2 md:text-left">
              <div className="font-recoleta font-medium text-center text-neutral-6 mb-3 bg-top-items bg-no-repeat bg-right-top bg-[length:20px_24px] inline-block pr-3 pt-1 text-28px md:text-32px lg:text-42px lg:bg-[length:30px_34px] lg:pr-4 lg:text-left lg:pt-0 lg:ml-[65px]">
                Our top items
                <HeadlineHighlight />
              </div>
            </div>
            <Pgraph
              variant="p-14"
              className="text-center text-neutral-6 w-full md:w-1/2 md:text-left lg:text-16px lg:leading-22 lg:w-2/5"
            >
              Items arrive sanitized, packaging-free and fully assembled. Enjoy
              them as long as you like, then we&apos;ll pick them up when
              you&apos;re done. It&apos;s that simple.
            </Pgraph>
          </div>
        ) : (
          <div className="text-center">
            {!titleHighlight ? (
              <h2
                className={`font-recoleta font-medium text-28px md:text-32px lg:text-42px leading-29 md:leading-[46px] lg:leading-[52px] text-center mt-4 md:mt-0 text-neutral-6 mb-3 ${
                  variant === 'homepage' ? '' : 'text-poppy'
                }`}
              >
                {title ? title : 'Browse top products'}
              </h2>
            ) : (
              <h2 className="font-recoleta font-medium text-28px md:text-32px lg:text-42px leading-29 md:leading-[46px] lg:leading-[52px] text-center mt-4 md:mt-0 text-neutral-6 mb-3">
                {title ? title : 'Browse top products'}
                <HeadlineHighlight />
              </h2>
            )}
          </div>
        )}

        {subTitle && (
          <div className="font-circular  text-center text-14px md:text-16px leading-18 md:leading-[22px]">
            {subTitle}
          </div>
        )}

        <div className="max-w-5xl mx-auto">
          <div className="mt-[18px] pl-[20px] relative pb-5">
            <Carousel
              swipeable={true}
              showDots={false}
              arrows={false}
              infinite={false}
              partialVisible={true}
              autoPlay={false}
              autoPlaySpeed={5000}
              renderButtonGroupOutside={true}
              responsive={{
                xxsmobile: {
                  breakpoint: { max: 400, min: 0 },
                  items: 1,
                  partialVisibilityGutter: 50,
                },
                xsmobile: {
                  breakpoint: { max: 500, min: 400 },
                  items: 1,
                  partialVisibilityGutter: 50,
                },
                mobile: {
                  breakpoint: { max: 640, min: 500 },
                  items: 1,
                  partialVisibilityGutter: 100,
                },
                smtablet: {
                  breakpoint: { max: 740, min: 640 },
                  items: Slides_Per_Page,
                  partialVisibilityGutter: 0,
                },
                tablet: {
                  breakpoint: { max: 1024, min: 740 },
                  items: Slides_Per_Page,
                  partialVisibilityGutter: 50,
                },
                desktop: {
                  breakpoint: { max: 9000, min: 1024 },
                  items: Slides_Per_Page,
                  partialVisibilityGutter: 160,
                },
              }}
              //@ts-ignore
              customButtonGroup={<ButtonGroup />}
            >
              {products.map((product, index) => {
                return <ProductCarouselCard key={index} product={product} />
              })}
            </Carousel>
          </div>
        </div>
        <div className="text-center">
          <Button
            primary={true}
            onClick={goToProducts}
            type="button"
            label={ctaLabel ? ctaLabel : 'Explore Top Items'}
          ></Button>
        </div>
      </div>
    </section>
  )
}

export { TopProductsMolecule }
