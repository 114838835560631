import React, { useEffect, useMemo, useState } from 'react'
import { Button, Headline, Loading, Pgraph } from '..'
import { useRouter } from 'next/router'
import { URLS } from '../../config'
import { useAuthStore } from '../../store'
import Link from 'next/link'
import { RegistryApi } from '../../services'
import Image from 'next/image'
import { ApiResultStatus, IRegistry } from '../../interfaces'

export const RegistryCurrentMolecule = () => {
  const router = useRouter()
  const { credentials } = useAuthStore()
  const [registry, setRegistry] = useState<IRegistry>()
  const [loadingRegistryItems, setLoadingRegistryItems] =
    useState<ApiResultStatus>('idle')

  useEffect(() => {
    const fetchRegistries = async () => {
      if (!credentials?.accessToken) return

      const result = await RegistryApi.fetchRegistry({
        productDetails: true,
        token: credentials.accessToken,
      })
      if (result) {
        setRegistry(result.registry)
        setLoadingRegistryItems('success')
      } else {
        setLoadingRegistryItems('success')
      }
    }

    fetchRegistries()
  }, [credentials?.accessToken])

  const handleManageRegistryClick = async () => {
    router.push(URLS.REGISTRY)
  }

  const userWithoutRegistry = useMemo(() => !registry, [registry])

  const registryImage = useMemo(() => {
    return registry && registry.entries?.length
      ? registry.entries[0].productDetails?.imageUrl
      : '/images/loop-registry-mobile.jpg'
  }, [registry])

  const registryItems = useMemo(
    () => (registry ? registry.numEntries : 0),
    [registry]
  )

  const giftedRegistryItems = useMemo(
    () => (registry ? registry.numGiftedEntries : 0),
    [registry]
  )

  if (!loadingRegistryItems) {
    return (
      <div className="my-8">
        <Loading />
      </div>
    )
  }

  if (userWithoutRegistry) {
    return (
      <div className="max-w-6xl mx-auto justify-between py-5 px-4 bg-neutral-1">
        <div>
          <Headline
            variant="recoleta-s"
            className="text-24px leading-29 md:text-24px md:leading-29 lg:text-24px lg:leading-29 text-neutral-6"
          >
            Registry
          </Headline>
          <Pgraph
            variant="p-16"
            className="mt-3 font-normal text-14px leading-18 md:text-16px md:leading-22 text-neutral-6"
          >
            You don&apos;t have a registry yet.
          </Pgraph>
        </div>
        <div className="pt-[32px]">
          <div>
            <Button
              size="small"
              label="Learn more"
              onClick={handleManageRegistryClick}
            />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="max-w-6xl mx-auto justify-between py-5 px-4 bg-neutral-1">
      <div>
        <Headline
          variant="recoleta-s"
          className="text-24px leading-29 md:text-24px md:leading-29 lg:text-24px lg:leading-29 text-neutral-6"
        >
          Registry Items
        </Headline>
        <Pgraph
          variant="p-16"
          className="mt-3 font-normal text-14px leading-18 md:text-16px md:leading-22 text-neutral-6"
        >
          Questions about Loop registry?{' '}
          <Link href={URLS.CONTACT_US} passHref>
            <span className="underline cursor-pointer">Contact us</span>
          </Link>
        </Pgraph>
      </div>
      <div className="pt-[32px]">
        <div
          className="inline-block bg-white border-2 border-neutral-2 rounded px-[24px] pt-[29px] mr-4 h-[300px] w-[252px] cursor-pointer"
          onClick={handleManageRegistryClick}
        >
          <div className="">
            <Image
              src={registryImage}
              alt="Loop.baby"
              width="960"
              height="1131"
            />
          </div>
          <Pgraph
            variant="p-16"
            className={`font-normal text-neutral-7 mt-[26px] pb-[20px] ml-[5px]`}
          >
            {`${registryItems} saved · ${giftedRegistryItems} gifted`}
          </Pgraph>
        </div>
        <div>
          <Button
            className="mt-[32px]"
            size="small"
            label="Manage Registry"
            onClick={handleManageRegistryClick}
          />
        </div>
      </div>
    </div>
  )
}
