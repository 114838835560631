import Link from 'next/link'
import { HTMLAttributeAnchorTarget, ReactNode, useState } from 'react'

export interface IFooterSectionLinkProp {
  title: string
  action: string | (() => void)
  target?: HTMLAttributeAnchorTarget
}

export interface IFooterSectionProps {
  title: string
  children?: ReactNode
  links?: IFooterSectionLinkProp[]
  expandable?: boolean // If this section can be expanded, default: 'true'.
}

export const FooterSection = ({
  title,
  children,
  links,
  expandable = true,
}: IFooterSectionProps) => {
  const [isExpanded, setIsExpanded] = useState(false)

  return (
    <div className="flex flex-col w-full md:w-1/3">
      <div className="w-full font-recoleta leading-19 flex justify-between items-center mt-48px md:mt-56px lg:mt-[64px] mb-[12px]">
        <h2 className="text-24px">{title}</h2>
        {expandable && (
          <div
            className={`text-32px cursor-pointer md:hidden`}
            onClick={() => setIsExpanded(!isExpanded)}
          >
            +
          </div>
        )}
      </div>

      {links?.map(({ title, action }) => (
        <div
          key={title}
          className={`my-[12px] font-circular text-[14px] leading-18 ${
            isExpanded ? 'block' : 'hidden md:block'
          }`}
        >
          {typeof action === 'string' ? (
            <Link href={action}>{title}</Link>
          ) : (
            <span
              role="link"
              className="cursor-pointer"
              onClick={() => action()}
            >
              {title}
            </span>
          )}
        </div>
      ))}

      {children}
    </div>
  )
}
