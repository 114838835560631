import { IAddress } from './auth.interface'
import {
  ICartApiCart,
  ICartApiPricing,
  ICartApiProduct,
} from './cart.api.interfaces'

export interface IPaymentIntent {
  setupIntent: {
    id: string
    customerId: string
    clientSecret: string
  }
}

export interface ICheckoutApi {
  checkout: {
    id: string
    cart: ICartApiCart
    billingAddress: IAddress
    consignments: [
      {
        id: string
        shippingCostIncTax: number
        shippingCostExTax: number
        handlingCostIncTax: number
        handlingCostExTax: number
        discounts: string[]
        lineItemIds: string[]
        address: {
          firstName: string
          lastName: string
          city: string
          countryCode: string
          email: string
          address1: string
          address2: string
          phone: string
          postalCode: string
          stateOrProvince: string
        }
      }
    ]
    taxes: {
      name: string
      amount: number
    }[]

    coupons: string[]
    shippingCostTotalIncTax: number
    shippingCostTotalExTax: number
    handlingCostTotalIncTax: number
    handlingCostTotalExTax: number
    taxTotal: number
    subtotalIncTax: number
    subtotalExTax: number
    grandTotal: number
    customerMessage: string
    createdTime: string
    updatedTime: string
  }
  creditConsumption: ICreditConsumption
  cartPricing: ICartApiPricing[]
  invalidItems: ICartApiProduct[]
  code: number
  success: boolean
  isAuthorized: boolean
  errors: { code: string; message: string }[]
  context: any
}

export interface ICreditConsumption {
  balance: number
  newOrderCredit: number
  registryCredit: number
  giftCertificateCredit: number
}

export enum CHECKOUT_PAYMENT_MODE {
  INITIALIZING,
  ADD_CARD,
  USE_EXISTING_CARD,
  ERROR,
}
