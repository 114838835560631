import React, { useMemo, useState } from 'react'
import { Button, Headline, HeadlineHighlight, Pgraph } from '..'
import NumberFormat from 'react-number-format'


function StrollerPerks() {

  interface IStrollerPerks {
    perk: string | JSX.Element
  }
  
const StrollerPerks: IStrollerPerks[] = [
  {
    perk: (
      <>
        <Pgraph variant="p-14" className="leading-19">
          <b className="font-circular-medium">Curated Selection & Advice.</b> Talk to our expert team and get advice from the Loop Community about what strollers might be best for you. 
        </Pgraph>
      </>
    ),
  },
  {
    perk: (
      <>
        <Pgraph variant="p-14" className="leading-19">
          <b className="font-circular-medium">Convenient.</b> Pick any three strollers to see and test in the comfort of your home for an hour.
        </Pgraph>
      </>
    ),
  },
  {
    perk: (
      <>
        <Pgraph variant="p-14" className="leading-19">
          <b className="font-circular-medium">High Quality.</b> All strollers are like-new, LoopCare certified for safety and cleanliness, and fully assembled.
        </Pgraph>
      </>
    ),
  },
  {
    perk: (
      <>
        <Pgraph variant="p-14" className="leading-19">
          <b className="font-circular-medium">Great Savings.</b> Affordable rental options and pre-Loved Loop gear to purchase at 33%-50% or more off retail prices.
        </Pgraph>
      </>
    ),
  },
  {
    perk: (
      <>
        <Pgraph variant="p-14" className="leading-19">
          <b className="font-circular-medium">100% Satisfaction.</b>  You can return any item within 30 days for any reason and we&apos;ll give you credits for future rentals or purchases.*
        </Pgraph>
      </>
    ),
  },
  {
    perk: (
      <>
        <Pgraph variant="p-14" className="leading-19">
          <b className="font-circular-medium">Try Before You Buy.</b>  Members can also rent and decide to buy at any time later. We&apos;ll credit the last month&apos;s rental towards your purchase.  
        </Pgraph>
      </>
    ),
  },
  {
    perk: (
      <>
        <Pgraph variant="p-14" className="leading-19">
          <b className="font-circular-medium">It&apos;s Free!</b>  Best of all, We&apos;ll credit the $35 test drive fee towards your purchase or rental.
        </Pgraph>
      </>
    ),
  },
]


    

return (
  <section className="bg-neutral-1 flex justify-center w-full pt-5 lg:pt-5 lg:pb-4">
    <div className="flex flex-col md:flex-row items-center mx-[20px] lg:max-w-5xl md:p-[50px] md:pt-[35px] lg:pt-4 lg:px-[0px]">
      <div className="w-full md:w-1/2 md:order-2 md:text-right">
        <img
          src="/images/stroller-showcase/stroller-perks.jpg"
          alt="Loop Membership"
          className="max-w-[50%] md:max-w-[400px] mx-auto md:ml-auto"
        />
      </div>
      <div className="mt-5 mb-6 w-full md:w-1/2 md:pr-5">
        {/* <Pgraph
          variant="l-14"
          className="xs:font-circular-medium mb-2 text-neutral-6 uppercase text-center md:text-left md:text-18px"
        >
          Limited Availability
        </Pgraph> */}
        <div className="text-center mb-4 md:text-left">
          <Headline
            variant="recoleta-s"
            className="xs:text-center mx-auto text-poppy inline-block pr-3 md:text-24px md:text-left lg:text-32px lg:pr-4 lg:text-left lg:pt-0"
          >
            All the Details & Benefits 
            <HeadlineHighlight />
          </Headline>
          {/* <Pgraph
            variant="p-14"
            className="text-neutral-5 italic text-center md:text-left"
          >
            $35 for one-hour, at-home consultation
          </Pgraph> */}
        </div>

        <Pgraph variant="p-14">
          <div className="flex mb-[16px]">
            <ul className="">
              {StrollerPerks.map((item, index) => {
                return (
                  <li
                    className="text-neutral-6 flex mb-3 text-14px"
                    key={index}
                  >
                    <i className="loop-icon-checkmark text-32px mr-3 text-poppy"></i>
                    <div>{item.perk}</div>
                  </li>
                )
              })}
              <Pgraph
                variant="p-12"
                className="text-neutral-5 italic mr-auto text-center mt-[30px] md:text-left"
              >
                *Credits will be in the amount of the purchase price less one month&apos;s rental fee.
              </Pgraph>
            </ul>
          </div>
        </Pgraph>
      </div>
    </div>
  </section>
)
}

export { StrollerPerks }
