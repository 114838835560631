import React from 'react'
import { LOOP_CARE_ITEMS } from '../../../config'
import { Pgraph } from '../../Pgrah'
import { Headline } from '../../Headline'
import Image from 'next/image'

function LoopCareMolecule() {
  return (
    <section className="w-full bg-[#F8F4E8] px-4 pt-[30px] pb-6 md:py-[70px]">
      <div className="max-w-[1440px] mx-auto md:flex md:items-center xl:px-7">
        <div className="relative m-auto md:w-1/2 lg:w-2/5">
          <div className="absolute -top-[20px] -left-[20px] z-20 md:left-auto md:-right-[20px] md:-top[30px]">
            <Image
              alt="Loop Care"
              src="/images/icons/loopcare-badge.svg"
              width="91"
              height="91"
            />
          </div>
          <video width="100%" height="326px" autoPlay loop muted playsInline>
            <source src="" type="video/webm" />
            <source src="/videos/loopcare.mp4" type="video/mp4" />
            Sorry, your browser doesn&apos;t support videos.
          </video>
        </div>
        <div className="md:w-1/2 md:pl-4 lg:pl-[70px] lg:w-3/5">
          <div className="pt-4 pb-5 md:pt-0">
            <div>
              <Headline
                variant="recoleta-m"
                className="text-neutral-6 text-center px-4 md:text-left md:px-0 lg:text-42px lg:leading-50"
              >
                LoopCare™: Safe & Clean
              </Headline>
            </div>
            <div>
              <Pgraph
                variant="p-16"
                className="text-neutral-6 text-center mt-3 md:text-left lg:text-18px lg:leading-24"
              >
                There&apos;s clean and then there&apos;s Loop-level clean. All
                products are owned and maintained by Loop—this gives us total
                control over inspection and sanitation.
              </Pgraph>
            </div>
          </div>
          <div>
            <Pgraph
              variant="l-16"
              className="text-neutral-6 uppercase mb-3 lg:mb-4"
            >
              BETWEEN EACH RENTAL ITEMS ARE:
            </Pgraph>
            <div>
              {LOOP_CARE_ITEMS.map((item) => (
                <div className="flex mb-[20px]" key={item.icon}>
                  <div>
                    <div className="bg-white rounded-full h-[30px] w-[30px] text-center leading-30 flex items-center justify-center">
                      <Image
                        src={`/images/icons/${item.icon}`}
                        alt={item.text}
                        width="28"
                        height="28"
                      />
                    </div>
                  </div>
                  <div className="pl-3">
                    <Pgraph
                      variant="p-16"
                      className="text-neutral-6 lg:text-18px lg:leading-24"
                    >
                      {item.text}
                    </Pgraph>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export { LoopCareMolecule }
