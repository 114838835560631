import Script from 'next/script'

interface IKlaviyoProps {
  email?: string
}

export const ScriptKlaviyoExecute = ({ email, ...props }: IKlaviyoProps) => {
  return (
    <>
      <Script
        id="Klaviyo"
        // strategy="worker" - Causing recording problems?
        dangerouslySetInnerHTML={{
          __html: `
                  var _learnq = _learnq || [];
                  _learnq.push(['identify', {
                    // Change the line below to dynamically print the user's email.
                    '$email' : '${email}'
                  }]);`,
        }}
      ></Script>
    </>
  )
}
