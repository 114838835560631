export const PRODUCT_LINKS = {
  babyActivityChair: {
    name: 'Nature Friends Baby Activity Chair',
    devProductId: 3036,
    prodProductId: 231,
  },
}

export const FEATURE_PRODUCTS = [
  { name: 'Doona Trike', devProductId: '3042', prodProductId : '237', devVariantId: '3040', prodVariantId: '306'},
  { name: 'Little Creator', devProductId: '3072', prodProductId : '267', devVariantId: '3070', prodVariantId: '336'},
  { name: 'Osprey POCO Child Carrier', devProductId: '3142', prodProductId : '337', devVariantId: '3140', prodVariantId: '406'},
  { name: 'Lily & River Little Climber +Rockwall', devProductId: '3211', prodProductId : '409', devVariantId: '3209', prodVariantId: '478'},
  { name: 'Loop Toy Box', devProductId: '3216', prodProductId : '453', devVariantId: '3218', prodVariantId: '532'},
  { name: 'Loop Curated Toy Boxes', devProductId: '3216', prodProductId : '453', devVariantId: '3218', prodVariantId: '532'},
  { name: 'Lily & River Little Gymnast', devProductId: '3073', prodProductId : '268', devVariantId: '3071', prodVariantId: '337'},
  { name: 'Manhattan Toy Spruce Cleaning Set', devProductId: '3126', prodProductId : '321', devVariantId: '3124', prodVariantId: '390'},
  { name: 'New Bounce Mini Trampoline', devProductId: '3220', prodProductId : '419', devVariantId: '3238', prodVariantId: '488'},
  { name: 'Stokke Tripp Trapp High Chair', devProductId: '3168', prodProductId : '363', devVariantId: '3166', prodVariantId: '432'},
  { name: 'Micro Kickboard Mini Deluxe Foldable Scooter', devProductId: '3134', prodProductId : '329', devVariantId: '3132', prodVariantId: '398'},
  { name: 'Veer Cruiser Canopy, Basket & Comfort Seat', devProductId: '3195', prodProductId : '390', devVariantId: '3193', prodVariantId: '459'},
  { name: 'Ambosstoys PRIMO Ride On Toy', devProductId: '195', prodProductId : '195', devVariantId: '264', prodVariantId: '264'}
]
