import { getRequestHeaders } from '../config'
import { fetchTimeoutRetry } from '../config/utilities'
import {
  IRegistryChangeName,
  IRegistryEntryResponse,
  IRegistryResponse,
  IRegistrySearchResponse,
} from '../interfaces'

export const fetchRegistry = async (
  params: { productDetails: boolean; token: string } = {
    productDetails: true,
    token: '',
  }
): Promise<IRegistryResponse | null> => {
  try {
    const result = await fetchTimeoutRetry(
      `${process.env.NEXT_PUBLIC_REGISTRY_URL}/registries?productDetails=${params.productDetails}`,
      {
        method: 'GET',
        mode: 'cors',
        headers: getRequestHeaders(params.token),
      }
    )
    const jsonResponse = (await result.json()) as IRegistryResponse
    if (jsonResponse.success) {
      return jsonResponse
    } else {
      return null
    }
  } catch (error) {
    return null
  }
}

export const fetchRegistryEntryByJWT = async (
  params: { JWT: string; productDetails: boolean } = {
    JWT: '',
    productDetails: true,
  }
): Promise<IRegistryEntryResponse | null> => {
  try {
    const result = await fetchTimeoutRetry(
      `${process.env.NEXT_PUBLIC_REGISTRY_URL}/registries/token/${params.JWT}?productDetails=${params.productDetails}`,
      {
        method: 'GET',
        mode: 'cors',
        headers: getRequestHeaders(),
      }
    )
    const jsonResponse = (await result.json()) as IRegistryEntryResponse
    if (jsonResponse.success) {
      return jsonResponse
    } else {
      return null
    }
  } catch (error) {
    return null
  }
}

export const fetchFullRegistryById = async (params: {
  registryId: string
  productDetails: boolean
}): Promise<IRegistryResponse | null> => {
  try {
    const result = await fetchTimeoutRetry(
      `${process.env.NEXT_PUBLIC_REGISTRY_URL}/registries/id/${params.registryId}?productDetails=${params.productDetails}`,
      {
        method: 'GET',
        mode: 'cors',
        headers: getRequestHeaders(),
      }
    )
    const jsonResponse = (await result.json()) as IRegistryResponse
    if (jsonResponse.success) {
      return jsonResponse
    } else {
      return null
    }
  } catch (error) {
    return null
  }
}

export const deleteRegistryEntry = async (
  params: { entryId: number; token: string } = {
    token: '',
    entryId: 0,
  }
): Promise<any | null> => {
  try {
    const result = await fetchTimeoutRetry(
      `${process.env.NEXT_PUBLIC_REGISTRY_URL}/registries/entries?entryId=${params.entryId}`,
      {
        method: 'DELETE',
        mode: 'cors',
        headers: getRequestHeaders(params.token),
      }
    )
    const jsonResponse = await result.json()
    if (jsonResponse.success) {
      return jsonResponse
    } else {
      return null
    }
  } catch (error) {
    return null
  }
}

export const deleteRegistry = async (
  params: { token: string } = {
    token: '',
  }
): Promise<any | null> => {
  try {
    const result = await fetchTimeoutRetry(
      `${process.env.NEXT_PUBLIC_REGISTRY_URL}/registries`,
      {
        method: 'DELETE',
        mode: 'cors',
        headers: getRequestHeaders(params.token),
      }
    )
    const jsonResponse = await result.json()
    if (jsonResponse.success) {
      return jsonResponse
    } else {
      return null
    }
  } catch (error) {
    return null
  }
}

export const updateRegistryName = async (
  params: { registryName: IRegistryChangeName; token: string } = {
    token: '',
    registryName: {
      firstName: '',
      lastName: '',
      partnerFirstName: '',
      partnerLastName: '',
    },
  }
): Promise<any | null> => {
  try {
    const result = await fetchTimeoutRetry(
      `${process.env.NEXT_PUBLIC_REGISTRY_URL}/registries`,
      {
        method: 'PUT',
        mode: 'cors',
        headers: getRequestHeaders(params.token),
        body: JSON.stringify(params.registryName),
      }
    )
    const jsonResponse = await result.json()
    if (jsonResponse.success) {
      return jsonResponse
    } else {
      return null
    }
  } catch (error) {
    return null
  }
}

export const addRegistryEntry = async (
  params: {
    registryInfo: {
      productId: string
      recurring: boolean
      recurringPeriod: string
      numPeriods: number
      quantity: number
    }
    token: string
  } = {
    token: '',
    registryInfo: {
      productId: '',
      recurring: true,
      recurringPeriod: 'MONTHLY',
      numPeriods: 1,
      quantity: 1,
    },
  }
): Promise<any | null> => {
  try {
    const result = await fetchTimeoutRetry(
      `${process.env.NEXT_PUBLIC_REGISTRY_URL}/registries/entries`,
      {
        method: 'POST',
        mode: 'cors',
        headers: getRequestHeaders(params.token),
        body: JSON.stringify(params.registryInfo),
      }
    )
    const jsonResponse = await result.json()
    if (jsonResponse.success) {
      return jsonResponse
    } else {
      return null
    }
  } catch (error) {
    return null
  }
}

export const createRegistry = async (accessToken: string) => {
  try {
    const result = await fetchTimeoutRetry(
      `${process.env.NEXT_PUBLIC_REGISTRY_URL}/registries`,
      {
        method: 'POST',
        headers: getRequestHeaders(accessToken),
        mode: 'cors',
      }
    )
    return !!result.ok
  } catch {
    return false
  }
}

interface IUpdateRegistryEntryParams {
  entryId: number
  gifted: boolean
  giftedDate: number
  gifterEmailAddress: string
}

export const updateRegistryEntry = async (
  params: IUpdateRegistryEntryParams
) => {
  try {
    const res = await fetchTimeoutRetry(
      `${process.env.NEXT_PUBLIC_REGISTRY_URL}/registries/entries`,
      {
        method: 'PUT',
        mode: 'cors',
        headers: getRequestHeaders(),
        body: JSON.stringify(params),
      }
    )
    return !!res.ok
  } catch (error) {
    return false
  }
}

export const searchRegistries = async (searchTerm: string) => {
  try {
    const url = new URL(
      process.env.NEXT_PUBLIC_REGISTRY_URL + '/registries/search'
    )
    url.searchParams.set('searchTerm', searchTerm)
    const res = await fetchTimeoutRetry(url.href, {
      method: 'GET',
      headers: getRequestHeaders(),
    })

    if (!res.ok) {
      throw new Error('Search registry error')
    }

    const json = (await res.json()) as IRegistrySearchResponse
    return json
  } catch (error) {
    return undefined
  }
}
