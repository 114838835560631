import React, { useEffect, useState } from 'react'
import { Headline } from '../Headline'
import { Button } from '../Button'
import { Pgraph } from '../Pgrah'
import { IRegistry, IRegistryChangeName } from '../../interfaces'
import { useForm } from 'react-hook-form'
import { sanitizeFields } from '../../config/utilities'
import { RegistryApi } from '../../services'
import { useAuthStore } from '../../store'
import { InputText } from '../InputText'

interface IRegistryChangeNameModalProps {
  registry: IRegistry | undefined
  onClose: () => void
  onComplete: () => void
}

function RegistryChangeNameModal({
  onClose,
  onComplete,
  registry,
}: IRegistryChangeNameModalProps) {
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState<string>()
  const [success, setSuccess] = useState<string>()
  const { credentials } = useAuthStore()

  useEffect(() => {
    const handleEsc = (event: globalThis.KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose()
      }
    }
    window.addEventListener('keydown', handleEsc)

    return () => {
      window.removeEventListener('keydown', handleEsc)
    }
  }, [onClose])

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IRegistryChangeName>({
    defaultValues: {
      firstName: registry?.firstName,
      lastName: registry?.lastName,
      partnerFirstName: registry?.partnerFirstName,
      partnerLastName: registry?.partnerLastName,
    },
  })

  const onSubmit = async (data: IRegistryChangeName) => {
    try {
      if (!credentials?.accessToken) return
      setProcessing(true)
      setError(undefined)
      setSuccess(undefined)

      sanitizeFields(data)
      const result = await RegistryApi.updateRegistryName({
        token: credentials.accessToken,
        registryName: data,
      })

      if (result) {
        setSuccess('Registry Name Updated')
        onComplete()
      } else {
        throw new Error('Error updating registry')
      }
    } catch (error) {
      console.error(error)
      setError(error instanceof Error ? error.message : 'Unknown error')
    } finally {
      setProcessing(false)
    }
  }

  return (
    <div>
      <div className="bg-modal-overlay fixed top-0 left-0 z-100 w-full h-full md:flex justify-center z-[100]">
        <div className="relative px-[27px] py-4 bg-snow-white md:p-5 md:rounded w-full md:w-1/2 lg:w-1/3 h-full md:h-fit m-[0px] md:m-auto max-w-full md:max-w-2xl ">
          <div className="cursor-pointer" onClick={onClose}>
            <i className="absolute right-0 top-0 m-4 loop-icon-times text-24px text-neutral-7"></i>
          </div>
          <Headline variant="recoleta-m" className="mb-4 text-neutral-7">
            Registry Name
          </Headline>
          <div>
            <Pgraph variant="p-14" className="mb-4">
              Make sure your name and partner&apos;s (optional) are correct.
            </Pgraph>

            <form onSubmit={handleSubmit(onSubmit)} className="w-full lg:w-2/3">
              <Pgraph variant="l-18" className="mt-0">
                Your Name
              </Pgraph>
              <div className="mt-3 w-full lg:w-9/12">
                <Pgraph variant="l-14" className="text-neutral-6 mb-[6px]">
                  First name
                </Pgraph>
                <InputText
                  type="text"
                  additionalClassName={'text-neutral-5'}
                  borderColor={'border-neutral-3'}
                  placeHolder=""
                  {...register('firstName', { required: 'Required' })}
                />
              </div>
              <div className="mt-4 w-full lg:w-9/12">
                <Pgraph variant="l-14" className="text-neutral-6 mb-[6px]">
                  Last name
                </Pgraph>
                <InputText
                  type="text"
                  additionalClassName={'text-neutral-5'}
                  borderColor={'border-neutral-3'}
                  placeHolder=""
                  {...register('lastName', { required: 'Required' })}
                />
              </div>
              <Pgraph variant="l-18" className="mt-5">
                Your Partner&apos;s name (optional)
              </Pgraph>
              <div className="mt-3 w-full lg:w-9/12">
                <Pgraph variant="l-14" className="text-neutral-6 mb-[6px]">
                  First name
                </Pgraph>
                <InputText
                  type="text"
                  additionalClassName={'text-neutral-5'}
                  borderColor={'border-neutral-3'}
                  placeHolder=""
                  {...register('partnerFirstName')}
                />
                <Pgraph variant="p-12" className="text-neutral-5 mt-1">
                  Optional
                </Pgraph>
              </div>
              <div className="mt-4 w-full lg:w-9/12">
                <Pgraph variant="l-14" className="text-neutral-6">
                  Last name
                </Pgraph>
                <InputText
                  type="text"
                  additionalClassName={'text-neutral-5'}
                  borderColor={'border-neutral-3'}
                  placeHolder=""
                  {...register('partnerLastName')}
                />
                <Pgraph variant="p-12" className="text-neutral-5 mt-1">
                  Optional
                </Pgraph>
              </div>

              <div className="text-left mt-[44px] w-full lg:w-9/12">
                <Pgraph variant="l-14" className="text-caution-2 mb-2">
                  {error}
                </Pgraph>
                <Pgraph variant="l-14" className="text-success-1 mb-2">
                  {success}
                </Pgraph>
                <Button
                  label="Save"
                  primary
                  className={'py-1 px-7'}
                  type="submit"
                  size="medium"
                  loading={processing}
                  disabled={processing}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export { RegistryChangeNameModal }
