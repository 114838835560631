import { useRouter } from 'next/router'
import React, { useState } from 'react'
import { ConfirmationModal, Pgraph, RegistryProductCard } from '..'
import { URLS } from '../../config'
import { trackEvent } from '../../config/analytics'
import { IRegistryEntry, IUser } from '../../interfaces'
import { RegistryApi } from '../../services'
import { getProductSeoName } from '../../services/products_api'
import { useAuthStore } from '../../store'

interface IRegistryRecommendedItemsProps {
  /**
   * Regisrty Entries Array
   */
  registryEntries: IRegistryEntry[]
  /**
   * Regisrty Entries Array
   */
  user?: IUser

  onEntryRemoved: () => void
}

function RegistryWishlist({
  registryEntries,
  user,
  onEntryRemoved,
  ...props
}: IRegistryRecommendedItemsProps) {
  const router = useRouter()
  const { credentials } = useAuthStore()
  const [showRemoveConfirmation, setShowRemoveConfirmation] = useState<
    false | IRegistryEntry
  >(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const openConfirmationbox = (registryEntry: IRegistryEntry) => {
    setShowRemoveConfirmation(registryEntry)
  }
  const removeEntryFromRegistry = async (registryEntry: IRegistryEntry) => {
    setIsLoading(true)
    const token = credentials?.accessToken || ''
    await RegistryApi.deleteRegistryEntry({
      entryId: registryEntry.entryId,
      token,
    })
    setIsLoading(false)

    onEntryRemoved()

    setShowRemoveConfirmation(false)
  }

  const goToPDP = (registryEntry: IRegistryEntry) => {
    trackEvent('Registry Whishlist Product Clicked', {
      loopCustomerId: user?.customer?.loopCustomerId,
      productId: registryEntry.productId,
    })
    router.push(
      `${URLS.PRODUCTS.INDEX}/${getProductSeoName(
        registryEntry.productDetails
      ).replace(/\+/g, '')}/${registryEntry.productId}`
    )
  }

  return (
    <>
      <section className="bg-white w-full">
        <div className="max-w-5xl px-4 pb-4 mx-auto">
          <div className="h-[1px] bg-neutral-3 mb-4 mt-1 md:mt-2 lg:mt-3 "></div>
          <Pgraph variant="p-20" className="mr-2">
            Wish List
          </Pgraph>

          <div className="w-full grid grid-cols-2 gap-4 md:grid-cols-3 mt-3 justify-between pb-4">
            {registryEntries.map((item, index) => (
              <RegistryProductCard
                key={item.productId}
                registryEntry={item}
                className="max-w-[260px]"
                showRemove={true}
                onRemove={() => {
                  openConfirmationbox(item)
                }}
                onClick={() => {
                  goToPDP(item)
                }}
              />
            ))}
          </div>
        </div>
      </section>

      {showRemoveConfirmation && (
        <ConfirmationModal
          title="Are you sure?"
          description="Removing an item from your registry will not effect your gift credit balance."
          confirmationText="Yes, remove item"
          cancelText="Nevermind"
          isLoading={isLoading}
          onClose={() => setShowRemoveConfirmation(false)}
          onConfirm={() => removeEntryFromRegistry(showRemoveConfirmation)}
          onCancel={() => setShowRemoveConfirmation(false)}
        />
      )}
    </>
  )
}

export { RegistryWishlist }
