import router from 'next/router'
import React, { useEffect, useState } from 'react'
import { ERRORS, URLS } from '../../config'
import {
  AUTH_ACTION,
  CART_ACTION,
  CHECKOUT_STEPS,
  ISignupForm,
} from '../../interfaces'
import { AuthApi } from '../../services'
import {
  useAuthDispatch,
  useAuthStore,
  useCartDispatch,
  useHooks,
} from '../../store'
import { Headline } from '../Headline'
import { Pgraph } from '../Pgrah'
import { trackEvent } from '../../config/analytics'
import { ISignupEndpointRequest } from '../../services/auth_api'
import { UserForm } from '../UserForm'
import { formatChildBDays } from '../../config/utilities'

export const CheckoutAccount = () => {
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState<string>('')

  const dispatch = useCartDispatch()
  const authDispatch = useAuthDispatch()
  const { user, credentials } = useAuthStore()
  const { reportBreadcrumb, reportBug } = useHooks()

  useEffect(() => {
    if (user && credentials) {
      dispatch({
        type: CART_ACTION.FORCE_CHECKOUT_STEP,
        step: CHECKOUT_STEPS.ADDRESS_3,
      })
    }
  }, [credentials, dispatch, user])

  const onSubmit = async (data: ISignupForm) => {
    try {
      reportBreadcrumb(
        'Checkout create account form sent',
        { form: data },
        'request'
      )
      setError('')
      setProcessing(true)
      const formattedChildBirthdays = formatChildBDays(data.childBirthdays)
      if (typeof formattedChildBirthdays === 'string') {
        setError(formattedChildBirthdays)
        return
      }

      const formattedData: ISignupEndpointRequest = {
        ...data,
        childBirthdays: formattedChildBirthdays,
      }

      const result = await AuthApi.signup(formattedData)
      if (result) {
        trackEvent('checkout_create_account', {
          emailAddress: data.emailAddress,
          firstName: data.firstName,
          lastName: data.lastName,
          cellPhone: data.cellPhone,
        })

        authDispatch({
          type: AUTH_ACTION.LOGIN,
          credentials: result.tokenHolder,
        })
      } else {
        throw new Error(ERRORS.alreadyHasAnAccount)
      }
    } catch (error) {
      console.error(error)
      setError(error instanceof Error ? error.message : 'Unknown error')
      reportBug(error, data)
      setProcessing(false)
    }
  }

  const onGoBack = () => {
    dispatch({
      type: CART_ACTION.TOGGLE_CART,
    })
    router.push(URLS.INDEX)
  }

  const handleSignIn = () => {
    authDispatch({
      type: AUTH_ACTION.SET_RETURN_URL,
      returnUrl: router.pathname,
    })
    router.push(URLS.LOGIN.INDEX)
  }

  return (
    <div className="flex justify-center h-full bg-snow-white mx-3">
      <div className="flex flex-col justify-center items-center w-full h-fit">
        <Headline variant="recoleta-l" className="mb-2">
          Create Account
        </Headline>
        <UserForm
          buttonText={'Next'}
          includePassword={true}
          processing={processing}
          error={error}
          showLoginOnError={false}
          onSubmitCallback={onSubmit}
        />
        <div>
          <Pgraph
            variant="l-16"
            className="underline text-center my-4 cursor-pointer"
            onClick={onGoBack}
          >
            Go back
          </Pgraph>
          <Pgraph variant="l-16" className="text-center mb-4">
            Already a member?{' '}
            <span className="underline cursor-pointer" onClick={handleSignIn}>
              Sign in
            </span>
          </Pgraph>
        </div>
      </div>
    </div>
  )
}
