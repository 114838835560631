import Link from 'next/link'
import { HTMLAttributes, useMemo, useRef, useState } from 'react'
import { Pgraph } from '../Pgrah'
import { trackEvent } from '../../config/analytics'
import { useAuthStore } from '../../store'

interface ImagePinProps {
  title: string
  description: string
  destinationUrl?: string
  //** Required for proper positioning */
  className: HTMLAttributes<HTMLDivElement>['className']
  mobileVerticalDirection?: 'top' | 'bottom'
}

export const ImagePin = ({
  title,
  description,
  className,
  destinationUrl,
  mobileVerticalDirection = 'top',
}: ImagePinProps) => {
  const [mobileOpen, setMobileOpen] = useState(false)
  const [desktopOpen, setDesktopOpen] = useState(false)
  const desktopCloseTimeout = useRef<NodeJS.Timeout>()
  const { user } = useAuthStore()

  const startDesktopClose = () => {
    desktopCloseTimeout.current = setTimeout(() => {
      setDesktopOpen(false), (desktopCloseTimeout.current = undefined)
    }, 1000)
  }

  const cancelDesktopClose = () => {
    setDesktopOpen(true)
    if (desktopCloseTimeout.current) {
      clearTimeout(desktopCloseTimeout.current)
      desktopCloseTimeout.current = undefined
    }
  }

  const trackClick = () => {
    trackEvent('clicked_shoppable_image', {
      productName: title,
      destinationUrl,
      user: user ?? 'anonymous',
    })
  }

  const classes =
    'absolute w-[18px] h-[18px] bg-snow-white rounded-full p-[2px] cursor-pointer ' +
    className

  return (
    <div className={classes}>
      <div
        className="w-full h-full border-neutral-6 group-hover:bg-neutral-6 border rounded-full"
        onClick={() => setMobileOpen(!mobileOpen)}
        onMouseOverCapture={() => cancelDesktopClose()}
        onMouseOutCapture={() => startDesktopClose()}
      ></div>
      <div
        className={`hidden ${
          desktopOpen ? 'md:block' : 'md:hidden'
        } absolute -top-[93px] -left-[100px] w-[200px] py-3 px-4 bg-snow-white rounded cursor-default shadow-zipReminder text-center`}
        onMouseOverCapture={() => cancelDesktopClose()}
        onMouseOutCapture={() => startDesktopClose()}
      >
        <Pgraph
          variant="l-14"
          className={`underline mb-2 ${destinationUrl ? 'cursor-pointer' : ''}`}
          onClick={trackClick}
        >
          <Link href={destinationUrl || '/'}>{title}</Link>
        </Pgraph>
        <Pgraph variant="p-14">{description}</Pgraph>
        <div className="relative">
          <div
            className={`absolute ${
              mobileVerticalDirection === 'top'
                ? '-bottom-[23px]'
                : '-top-[83px]'
            }  left-50%`}
          >
            <div
              className={`block rotate-45 bg-snow-white rounded w-[18px] h-[18px] ${
                mobileVerticalDirection === 'top'
                  ? 'border-b border-r border-heavy-cream'
                  : 'border-t border-l border-heavy-cream'
              }`}
            ></div>
          </div>
        </div>
      </div>

      <div
        className={`${mobileOpen ? 'block' : 'hidden'} md:hidden absolute ${
          mobileVerticalDirection === 'top' ? '-top-[93px]' : '-bottom-[101px]'
        } -left-[100px] w-[200px] py-3 px-4 bg-snow-white rounded cursor-default shadow-zipReminder text-center`}
      >
        <Pgraph
          variant="l-14"
          className={`underline mb-2 ${destinationUrl ? 'cursor-pointer' : ''}`}
          onClick={trackClick}
        >
          <Link href={destinationUrl || '/'}>{title}</Link>
        </Pgraph>
        <Pgraph variant="p-14">{description}</Pgraph>
        <div className="relative">
          <div
            className={`absolute ${
              mobileVerticalDirection === 'top'
                ? '-bottom-[23px]'
                : '-top-[83px]'
            }  left-50%`}
          >
            <div
              className={`block rotate-45 bg-snow-white rounded w-[18px] h-[18px] ${
                mobileVerticalDirection === 'top'
                  ? 'border-b border-r border-heavy-cream'
                  : 'border-t border-l border-heavy-cream'
              }`}
            ></div>
          </div>
        </div>
      </div>
    </div>
  )
}
