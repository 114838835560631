import React, { useEffect, useMemo, useState } from 'react'
import { Headline } from '../Headline'
import { Button } from '../Button'
import { Pgraph } from '../Pgrah'
import { trackEvent } from '../../config/analytics'
import { CART_ACTION, CHECKOUT_STEPS, IProductSummary } from '../../interfaces'
import {
  useAuthStore,
  useCartDispatch,
  useCartStore,
  useHooks,
} from '../../store'
import NumberFormat from 'react-number-format'
import { CartApi } from '../../services'

interface IRegistryMembershipUpsellProps {
  onClose: () => void
  addToRegistry: () => void
}

function RegistryMembershipUpsell({
  onClose,
  addToRegistry,
}: IRegistryMembershipUpsellProps) {
  const [isProcessing, setIsProcessing] = useState(false)
  const [showFullPerks, setShowFullPerks] = useState(false)
  const { membershipProducts, cart } = useCartStore()
  const { user } = useAuthStore()
  const { credentials } = useAuthStore()
  const cartDispatch = useCartDispatch()
  const { getCartData, reportBug } = useHooks()

  useEffect(() => {
    const html = document.body as HTMLElement | null
    html?.classList.add('overflow-hidden')

    return function cleanup() {
      const html = document.body as HTMLElement | null
      html?.classList.remove('overflow-hidden')
    }
  }, [])

  const { lines } = useMemo(
    () =>
      getCartData({
        cart,
      }),
    [cart, getCartData]
  )

  async function addMembershipToCart(membership: IProductSummary) {
    try {
      setIsProcessing(true)

      const currentMembership = lines.find(
        (line) =>
          line.productId === membershipProducts.annual?.bigCommerceProductId ||
          line.productId === membershipProducts.monthly?.bigCommerceProductId
      )

      const membershipId = currentMembership
        ? (currentMembership as any).id
        : undefined

      if (currentMembership && membershipId && credentials?.accessToken) {
        const deleted = await CartApi.removeCartItem(
          credentials.accessToken,
          membershipId
        )
        if (deleted) {
          const apiCart = await CartApi.addCartItem(credentials.accessToken, [
            {
              productId: membership.bigCommerceProductId,
              quantity: 1,
            },
          ])
          if (apiCart) {
            cartDispatch({
              type: CART_ACTION.SET_API_CART,
              apiCart,
            })
          }
        }
      } else {
        if (credentials?.accessToken) {
          const apiCart = await CartApi.addCartItem(credentials.accessToken, [
            {
              productId: membership.bigCommerceProductId,
              quantity: 1,
            },
          ])
          if (apiCart) {
            cartDispatch({
              type: CART_ACTION.SET_API_CART,
              apiCart,
            })
          } else {
            if (currentMembership) {
              cartDispatch({
                type: CART_ACTION.REMOVE_LINE_QUEUE,
                productId: currentMembership.productId,
              })
            }
            cartDispatch({
              type: CART_ACTION.ADD_LINE_QUEUE,
              lines: [
                {
                  imageUrl: membership.images[0].url,
                  listPrice: membership.price,
                  salePrice: membership.price,
                  name: membership.title,
                  productId: membership.bigCommerceProductId,
                  quantity: 1,
                  variantId: membership.variants.length
                    ? membership.variants[0].bigCommerceVariantId
                    : 0,
                  nonMemberPrice: membership.price,
                },
              ],
            })
          }
        } else {
          if (currentMembership) {
            cartDispatch({
              type: CART_ACTION.REMOVE_LINE_QUEUE,
              productId: currentMembership.productId,
            })
          }
          cartDispatch({
            type: CART_ACTION.ADD_LINE_QUEUE,
            lines: [
              {
                imageUrl: membership.images[0].url,
                listPrice: membership.price,
                salePrice: membership.price,
                name: membership.title,
                productId: membership.bigCommerceProductId,
                quantity: 1,
                variantId: membership.variants.length
                  ? membership.variants[0].bigCommerceVariantId
                  : 0,
                nonMemberPrice: membership.price,
              },
            ],
          })
        }
      }

      trackEvent('checkout_membership_selection', {
        loopCustomerId: user?.customer?.loopCustomerId,
        membership_type: membership,
      })

      cartDispatch({
        type: CART_ACTION.TOGGLE_CART,
        showCart: true,
      })
      cartDispatch({
        type: CART_ACTION.FORCE_CHECKOUT_STEP,
        step: CHECKOUT_STEPS.ADDRESS_3,
      })
      onClose()
    } catch (error) {
      reportBug(error, { membership })
    } finally {
      setIsProcessing(false)
      onClose()
    }
  }

  return (
    <div>
      <div className="bg-modal-overlay absolute top-0 left-0 z-100 w-full h-full justify-center z-[100] md:fixed md:overflow-y-scroll md:top-0 md:left-0 md:right-0 md:bottom-0 md:flex md:h-auto md:items-center">
        <div className="w-full h-full bg-heavy-cream overflow-auto fixed max-w-full md:relative md:m-auto md:h-auto md:max-w-xl md:rounded">
          <div>
            <div className="bg-white py-3">
              <Pgraph variant="l-16" className="text-center">
                One Last Step!
              </Pgraph>
            </div>

            <Headline
              variant="recoleta-s"
              className="mb-4 text-center text-neutral-7 w-4/5 md:w-3/5 mx-auto mt-4"
            >
              You&apos;ll need a Loop Membership to join our community
            </Headline>
            <div className="mt-3 mb-5 pb-5 bg-white mx-3 md:mx-[32px]">
              <div className="pt-2">
                <img
                  src="/images/loop-red.png"
                  alt="Loop"
                  className="mx-auto max-w-[90px]"
                />
              </div>
              <Pgraph variant="p-20" className="text-center">
                Membership
              </Pgraph>

              <Pgraph
                variant="l-14"
                className="text-neutral-6 mr-3 mt-2 text-center"
              >
                <NumberFormat
                  value={membershipProducts.annual?.price || 0}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={'$'}
                  decimalScale={0}
                  fixedDecimalScale={true}
                />
                {'   '}
                (
                <NumberFormat
                  value={(membershipProducts.annual?.price || 0) / 12}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={'$'}
                  decimalScale={0}
                  fixedDecimalScale={true}
                  suffix={'/mo'}
                />
                )
              </Pgraph>

              <hr className="my-3 w-4/5 mx-auto border-neutral-2" />

              <Pgraph variant="p-16" className="w-full text-center">
                Join today for exclusive perks
              </Pgraph>

              <ul className="pt-3 mx-2">
                <li className="text-neutral-6 flex mb-3 text-12px">
                  <i className="loop-icon-checkmark text-32px mr-3 text-caution-2 relative -top-1"></i>
                  <div>
                    <b className="font-circular-medium">
                      20% off registry coupon
                    </b>{' '}
                    - on everything left in your registry, 4 weeks before
                    Baby&apos;s expected arrival
                  </div>
                </li>
                <li className="text-neutral-6 flex mb-3 text-12px">
                  <i className="loop-icon-checkmark text-32px mr-3 text-caution-2 relative -top-1"></i>
                  <div>
                    <b className="font-circular-medium">
                      Start enjoying benefits
                    </b>{' '}
                    now, but your membership period won&apos;t begin until your
                    child&apos;s birth month
                  </div>
                </li>
                <li className="text-neutral-6 flex mb-3 text-12px">
                  <i className="loop-icon-checkmark text-32px mr-3 text-caution-2 relative -top-1"></i>
                  <div>
                    <b className="font-circular-medium">
                      Unlimited priority delivery and/or pick ups
                    </b>
                    , so you can try items and swap whenever needed
                  </div>
                </li>

                {showFullPerks ? (
                  <>
                    <li className="text-neutral-6 flex mb-3 text-12px">
                      <i className="loop-icon-checkmark text-32px mr-3 text-caution-2 relative -top-1"></i>
                      <div>
                        <b className="font-circular-medium">
                          In home set up and demonstrations
                        </b>{' '}
                        starting up to 3 weeks before due date
                      </div>
                    </li>
                    <li className="text-neutral-6 flex mb-3 text-12px">
                      <i className="loop-icon-checkmark text-32px mr-3 text-caution-2 relative -top-1"></i>
                      <div>
                        <b className="font-circular-medium">Rewards program:</b>{' '}
                        10% credit back on all Loop rentals quarterly
                      </div>
                    </li>
                  </>
                ) : (
                  <></>
                )}
              </ul>

              <div className="text-center mt-3">
                {!showFullPerks ? (
                  <Pgraph
                    className="underline text-12px font-circular-medium border-0 hover:cursor-pointer"
                    onClick={() => {
                      setShowFullPerks(true)
                    }}
                  >
                    View More
                  </Pgraph>
                ) : (
                  <Pgraph
                    className="underline text-12px font-circular-medium border-0 hover:cursor-pointer"
                    onClick={() => {
                      setShowFullPerks(false)
                    }}
                  >
                    View Less
                  </Pgraph>
                )}
              </div>

              <div className="md:flex">
                <div className="text-center mt-3 md:w-[50%] w-[100%] md:pl-[58px] md:pr-[0px] pr-[30px] pl-[30px]">
                  <Button
                    label="Add to Registry"
                    primary={false}
                    className="mb-3 mx-auto w-[100%] md:w-[185px]"
                    onClick={addToRegistry}
                    loading={isProcessing}
                    disabled={isProcessing}
                  />
                </div>
                <div className="text-center mt-3 md:w-[50%] w-[100%] md:pr-[58px] md:pl-[0px] pr-[30px] pl-[30px]">
                  <Button
                    label="Join Now"
                    primary={true}
                    className="mb-3 mx-auto w-[100%] md:w-[165px]"
                    onClick={() =>
                      addMembershipToCart(
                        membershipProducts.annual as IProductSummary
                      )
                    }
                    loading={isProcessing}
                    disabled={isProcessing}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { RegistryMembershipUpsell }
