import React, { useEffect } from 'react'

interface IVideoModalProps {
  videoSrc: string
  open: boolean
  onClose: () => void
}

function VideoModal({ open, onClose, videoSrc }: IVideoModalProps) {

  useEffect(() => {
    const handleEsc = (event: globalThis.KeyboardEvent) => {
      if (open && event.key === 'Escape') {
        onClose()
      }
    }
    window.addEventListener('keydown', handleEsc)

    return () => {
      window.removeEventListener('keydown', handleEsc)
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!open) return <></>

  return (
    <>
      <div className="fixed top-0 left-0 w-full h-screen z-30 opacity-40 bg-black flex justify-center items-center"></div>
      <div
        className="fixed top-0 left-0 w-full h-screen z-30 flex justify-center items-center"
        onClick={onClose}
      >
        <div className="w-screen h-screen flex justify-center items-center p-5 lg:p-7 z-50">
          <div className="bg-black w-full aspect-video">
            <div className="w-full h-full">
              <iframe
                className="w-full h-full"
                src="https://www.youtube.com/embed/f7yUbHKvdRs?autoplay=1"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export { VideoModal }
