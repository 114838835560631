import React from 'react'

export interface IFeaturedInBrand {
  name: string
  url: string
  image: string
  width: string
  height: string
  largeLogo: boolean
}

export interface IValueProp {
  imageSrc: string
  headline: string
  text: string
  iconSrc: string
}

export interface IReasonsToLoveLoopProp {
  imageSrc: string
  headline: string
  text: string
  iconSrc: string
  iconHeight: number
  iconWidth: number
}

export interface IRelatedArticleProp {
  imageSrc: string
  headline: string
  postId: string
}

export interface IReview {
  comment: string
  author: string | JSX.Element
}

export interface IFAQ {
  question: string
  answer: string[] | JSX.Element
}
