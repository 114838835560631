import ReasonToLoveLoopDesktop from './desktop-version'
import ReasonToLoveLoopMobile from './mobile-version'

export default function ReasonToLoveLoop() {
  return (
    <section className="w-full bg-heavy-cream py-6">
      <div className="max-w-[1440px] mx-auto">
        <div className="lg:hidden">
          <ReasonToLoveLoopMobile />
        </div>
        <div className="hidden lg:block">
          <ReasonToLoveLoopDesktop />
        </div>
      </div>
    </section>
  )
}
