import { DateTime } from 'luxon'
import Link from 'next/link'
import Carousel from 'react-multi-carousel'
import { URLS } from '../../config'
import {
  ApiResultStatus,
  IRentalItem,
  ISubscriptionInvoice,
} from '../../interfaces'
import { Button } from '../Button'
import { Headline } from '../Headline'
import { RentalCarouselCard } from '../RentalCarouselCard'
import { useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import { Loading } from '../Loading'
import { Pgraph } from '../Pgrah'

interface ICurrentRentalsProps {
  invoiceInfo?: ISubscriptionInvoice
  rentedItems?: IRentalItem[]
  status: ApiResultStatus
  refetchSubscription: () => void
}

interface IButtonGroupProps {
  next: () => void
  previous: () => void
  carouselState: {
    currentSlide: number
    totalItems: number
  }
}

const ButtonGroup = ({ next, previous, carouselState }: IButtonGroupProps) => {
  // use carouselState to disable button when in the last slide, for example.
  return (
    <div className="relative float-right -bottom-0 right-0 mr-3 hidden md:block mt-[38px]">
      <span onClick={() => previous()} className="mr-4 cursor-pointer">
        <i className="loop-icon-arrow-long text-24px text-black transition ease-in duration-400 rotate-180 inline-block"></i>
      </span>
      <span onClick={() => next()} className="cursor-pointer">
        <i className="loop-icon-arrow-long text-24px text-black transition ease-in duration-400"></i>
      </span>
    </div>
  )
}

export const CurrentRentals = ({
  invoiceInfo,
  rentedItems,
  status,
  refetchSubscription,
}: ICurrentRentalsProps) => {
  const [rentalItemDisplay, setRentalItemDisplay] = useState<number>(0)
  const router = useRouter()

  useEffect(() => {
    const configureRentalItemDisplay = () => {
      if (window.innerWidth > 1024) {
        setRentalItemDisplay(4)
      } else if (window.innerWidth > 640 && window.innerWidth < 1024) {
        setRentalItemDisplay(3)
      } else {
        setRentalItemDisplay(1)
      }
    }
    configureRentalItemDisplay()
    window.addEventListener('resize', configureRentalItemDisplay)

    return window.removeEventListener('resize', configureRentalItemDisplay)
  }, [])

  const handleReturnItemsClick = async () => {
    router.push(URLS.ACCOUNT.RETURN_ITEMS)
  }

  if (status === 'error') {
    return (
      <div className="max-w-6xl mx-auto justify-between" id="current-rentals">
        <div className="py-5 px-4 bg-neutral-1">
          <Headline
            variant="recoleta-s"
            className="text-24px leading-29 md:text-24px md:leading-29 lg:text-24px lg:leading-29 text-neutral-6"
          >
            Current Rentals
          </Headline>
          <Pgraph variant="p-14" className="my-4">
            There was an error loading your subscription information. Click{' '}
            <span
              className="cursor-pointer underline"
              onClick={refetchSubscription}
            >
              here
            </span>{' '}
            to try again.
          </Pgraph>
        </div>
      </div>
    )
  }

  if (status === 'success' && !rentedItems?.length) {
    return <></>
  }

  if (status !== 'success') {
    return (
      <div id="current-rentals" className="my-8">
        <Loading />
      </div>
    )
  }

  return (
    <div className="max-w-6xl mx-auto justify-between" id="current-rentals">
      <div className="py-5 px-4 bg-neutral-1">
        <div>
          <Headline
            variant="recoleta-s"
            className="text-24px leading-29 md:text-24px md:leading-29 lg:text-24px lg:leading-29 text-neutral-6"
          >
            Current Rentals
          </Headline>
          {invoiceInfo?.periodEnd && (
            <div className="font-circular mt-[10px] font-normal text-14px leading-18 md:text-16px md:leading-22 text-neutral-6">
              All at home items will renew on{' '}
              <span className="text-poppy">
                {DateTime.fromMillis(invoiceInfo.periodEnd * 1000).toFormat(
                  'MM/dd'
                )}
              </span>{' '}
              unless a pickup is requested.
            </div>
          )}
          <div className="mt-[10px] font-circular font-normal text-14px leading-18 md:text-16px md:leading-22 text-neutral-6">
            Questions about your rentals?{' '}
            <Link href={URLS.CONTACT_US} passHref>
              <span className="underline cursor-pointer">Contact us</span>
            </Link>
          </div>
        </div>
        <div className="pt-5">
          <div>
            <Carousel
              swipeable={true}
              showDots={false}
              arrows={false}
              infinite={false}
              partialVisible={true}
              autoPlay={false}
              autoPlaySpeed={5000}
              renderButtonGroupOutside={
                rentedItems?.length || 0 > rentalItemDisplay ? true : false
              }
              responsive={{
                mobile: {
                  breakpoint: { max: 640, min: 0 },
                  items: 1,
                  partialVisibilityGutter: 50,
                },
                tablet: {
                  breakpoint: { max: 1024, min: 640 },
                  items: 3,
                  partialVisibilityGutter: 0,
                },
                desktop: {
                  breakpoint: { max: 9000, min: 1024 },
                  items: 4,
                  partialVisibilityGutter: 25,
                },
              }}
              //@ts-ignore
              customButtonGroup={
                rentedItems?.length || 0 > rentalItemDisplay ? (
                  //@ts-ignore
                  <ButtonGroup />
                ) : (
                  ''
                )
              }
            >
              {(rentedItems || []).map((item: IRentalItem, index: number) => {
                return <RentalCarouselCard key={index} item={item} />
              })}
            </Carousel>
            <Button
              className="mt-[32px]"
              size="small"
              label="Return Items"
              onClick={handleReturnItemsClick}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
