import React from 'react'
import { IRegistryEntry } from '../../interfaces'
import { Pgraph } from '../Pgrah'
import NumberFormat from 'react-number-format'
import { recurringPeriod } from '../../config'

interface IRegistryProductCardProps {
  /**
   * Registry Entry
   */
  registryEntry: IRegistryEntry

  /**
   * Optional remove click handler
   */
  showRemove?: boolean

  /**
   * Optional click handler
   */
  onClick?: () => void

  /**
   * Optional remove Click handler
   */
  onRemove?: () => void

  /** Optional Additional classes for styling */
  className?: string
}

/**
 * Primary UI component for user interaction
 */
export const RegistryProductCard = ({
  registryEntry,
  showRemove,
  className,
  onRemove = () => {},
  onClick = () => {},
  ...props
}: IRegistryProductCardProps) => {
  const cardClasses =
    'flex flex-col hover:cursor-pointer border-neutral-2 border rounded p-2 lg:p-4 relative'

  return (
    <div
      id={registryEntry.productId}
      className={[cardClasses, className].join(' ')}
      onClick={onClick}
      {...props}
    >
      {registryEntry.gifted && (
        <div className="bg-heavy-cream rounded-[30px] py-2 px-3 flex items-center absolute left-3 top-3">
          <Pgraph variant="l-12" as="span" className="leading-14">
            Gifted
          </Pgraph>
        </div>
      )}

      <div>
        <img
          src={registryEntry.productDetails?.imageUrl}
          alt={registryEntry.productDetails?.imageDescription}
          className="cursor-pointer w-auto max-h-[210px] mx-auto"
        ></img>
      </div>

      <div className="mt-auto">
        <h3
          className={`text-14px color-neutral-6 mb-[4px] overflow-hidden text-ellipsis sm:h-[60px] md:h-[40px]`}
        >
          {registryEntry.productDetails?.title}
        </h3>
        <Pgraph variant="l-14" className="color-neutral-7">
          <NumberFormat
            value={
              registryEntry.productDetails?.price * registryEntry.numPeriods
            }
            displayType={'text'}
            thousandSeparator={true}
            prefix={'$'}
            decimalScale={0}
            suffix={
              ' / ' +
              (registryEntry.recurringPeriod === recurringPeriod.YEARLY
                ? 'year'
                : registryEntry.numPeriods +
                  (registryEntry.numPeriods > 1 ? ' months' : ' month'))
            }
            data-cy="REGISTRY_PRODUCT_CARD_PRICE"
          />
        </Pgraph>
      </div>
      {showRemove ? (
        <Pgraph
          variant="p-12"
          onClick={(e) => {
            e.stopPropagation()
            onRemove()
          }}
          className="hover:cursor-pointer text-neutral-7 underline mt-1"
        >
          Remove
        </Pgraph>
      ) : (
        ''
      )}
    </div>
  )
}
