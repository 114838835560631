import React, { useState } from 'react'
import { Button, Headline, InputText, Pgraph } from '..'
import { IEmailZipForm } from '../../interfaces'
import { useForm } from 'react-hook-form'
import { sanitizeFields } from '../../config/utilities'
import { KlaviyoService } from '../../services'
import { useHooks } from '../../store'

function PHLCollectEmail() {
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState<string>()
  const [success, setSuccess] = useState<string>()
  const { reportBug } = useHooks()

  const {
    register,
    reset,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IEmailZipForm>({
    defaultValues: {
      email: '',
      zipcode: '',
    },
  })

  const onSubmit = async (data: IEmailZipForm) => {
    try {
      setProcessing(true)
      setError(undefined)
      setSuccess(undefined)
      // wrap all forms with the following function to fix values such as whitespaces
      sanitizeFields(data)
      const result = await KlaviyoService.subscribeToAList({
        g: process.env.NEXT_PUBLIC_KLAVIYO_PHL_WAITLIST,
        email: data.email,
        $fields: '$zip',
        $zip: data.zipcode,
      })

      if (result) {
        setSuccess('Submitted')
        reset()
      } else {
        throw new Error('Error saving your data')
      }
    } catch (error) {
      console.error(error)
      reportBug(error, data)
      setError(error instanceof Error ? error.message : 'Unknown error')
    } finally {
      setProcessing(false)
    }
  }

  const updateZip = (value: any) => {
    setValue('zipcode', value)
  }

  return (
    <section className="bg-neutral-1 w-full pt-5 pb-4">
      <div className="flex flex-col content-center justify-center relative md:flex-row lg:max-w-[1074px] lg:mx-auto">
        <div className="md:w-2/5 px-4">
          <div className="max-w-[325px] mx-auto">
            <Headline
              variant="recoleta-s"
              className="text-poppy text-center mx-auto md:text-left md:text-32px md:leading-38"
            >
              If you&apos;re in Philly...
            </Headline>

            <Pgraph
              variant="p-14"
              className="text-neutral-6 text-center md:text-left mt-4 xs:leading-22"
            >
              Sign up for more info on Loop, special promotions, and more!
            </Pgraph>
          </div>
        </div>
        <div className="md:w-3/5 lg:flex lg:justify-end">
          <form onSubmit={handleSubmit(onSubmit)} className="px-5 lg:max-w-580">
            <div className="md:flex">
              <div className="mt-4 md:w-3/5 md:mr-[20px]">
                <InputText
                  type="text"
                  additionalClassName={'text-neutral-5'}
                  borderColor={'border-neutral-3'}
                  placeHolder="Your email"
                  {...register('email', { required: 'Required' })}
                />
              </div>

              <div className="mt-4 md:w-2/5">
                <InputText
                  type="numeric"
                  additionalClassName={'text-neutral-5'}
                  borderColor={'border-neutral-3'}
                  placeHolder="Your zip code"
                  {...register('zipcode')}
                  onChange={(e) => updateZip(e.target.value)}
                />
                <Pgraph variant="p-12" className="text-neutral-5 mt-1">
                  Optional
                </Pgraph>
              </div>
            </div>

            <div className="mt-5 md:mt-4 text-center md:text-right md:flex md:justify-end">
              <div className="w-full max-w-[194px] mx-auto md:mx-0">
                <Button
                  label="Notify me"
                  primary
                  className="py-2 w-full"
                  type="submit"
                  size="medium"
                  loading={processing}
                  disabled={processing}
                />

                <Pgraph
                  variant="l-14"
                  className="text-center text-caution-2 mt-3"
                >
                  {error}
                </Pgraph>
                <Pgraph
                  variant="l-14"
                  className="text-center text-success-3 mt-3"
                >
                  {success}
                </Pgraph>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  )
}

export { PHLCollectEmail }
