import { useRouter } from 'next/router'
import React from 'react'
import { Button, Headline, Pgraph } from '..'
import { URLS } from '../../config'

function RedeemInvalidCode() {
  const router = useRouter()

  return (
    <section className="w-full bg-neutral-1">
      <div className="max-w-5xl bg-leaves-1 bg-right-bottom bg-no-repeat mx-auto min-h-100 pb-7">
        <div className="mx-auto py-4 px-4 md:py-5 md:pt-7 relative z-10">
          <Headline
            variant="recoleta-s"
            className="text-center text-neutral-6 mx-auto w-4/5 mb-4 leading-29 md:text-32px md:mb-5"
          >
            Oops... something went wrong
          </Headline>
          <Pgraph
            variant="p-14"
            className="text-neutral-6 text-center w-4/5 mx-auto md:leading-19 md:text-16px md:w-3/5"
          >
            Looks like this code is either invalid or has already been used.
          </Pgraph>

          <div className="text-center mt-4">
            <Button
              primary={true}
              label="View Account"
              className="mt-4 md:w-1/3 md:mt-5"
              onClick={() => router.push(URLS.ACCOUNT.INDEX)}
            />
          </div>
        </div>

        <div className="text-center pt-5 md:relative md:text-right md:pt-0 md:-mt-[160px] md:pr-7">
          <img
            alt="Loop Leaves"
            src="/images/leaves-bg.png"
            className="max-w-[60px] inline-block md:max-w-[88px]"
          />
        </div>
      </div>
    </section>
  )
}

export { RedeemInvalidCode }
