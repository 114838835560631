import { useRouter } from 'next/router'
import {
  createContext,
  useReducer,
  ReactNode,
  Dispatch,
  useContext,
  useEffect,
  useCallback,
  useRef,
} from 'react'
import { Loading } from '../../components'
import { LOCAL_STORAGE, PROTECTED_URLS, URLS } from '../../config'
import { logUser } from '../../config/analytics'
import {
  authActions,
  AUTH_ACTION,
  IAuthStore,
  ILocationFilter,
  ISubscription,
} from '../../interfaces'
import { IGladlyUser } from '../../interfaces/gladly'
import { AuthApi, CustomersApi, SubscriptionsApi } from '../../services'
import {
  getLocalCredentials,
  removeLocalStorage,
  getLocalObject,
  setLocalCredentials,
  setLocalObject,
  setLocalSubscription,
  getLocalSubscription,
  getLocalAppVersion,
  setLocalAppVersion,
} from '../local'
import { authReducer } from './reducer'
import { useHooks } from '../hooks/index'
import { renewAuth } from '../../services/auth_api'
import { DateTime } from 'luxon'

export const initialAuthState: IAuthStore = {
  locationFilter: null,
  validLocation: false,
  promptZip: false,
  subscriptionActive: false,
  isLoading: true,
  zipModalOpen: false,
  status: 'initial',
  authLogout: () => {},
}

const AuthContext = createContext<IAuthStore>(initialAuthState)
const AuthContextDispatch = createContext<Dispatch<authActions> | undefined>(
  undefined
)

interface IAuthProviderProps {
  children: ReactNode
}

export function checkActiveMembership(subscription: ISubscription | undefined) {
  if (!subscription) return false

  return (
    subscription.membership?.status === 'active' ||
    subscription.membership?.status === 'pending' ||
    subscription.hasLegacySubscription ||
    false
  )
}

const AuthProvider = ({ children }: IAuthProviderProps) => {
  const [authState, dispatch] = useReducer(authReducer, initialAuthState)
  const router = useRouter()
  const { user, credentials, isLoading, status } = authState
  const protectedPage = PROTECTED_URLS.find((url) =>
    router.asPath.includes(url)
  )
  const triedRefresh = useRef(false)
  const { logout } = useHooks()

  const authLogout = useCallback(
    (setStatus: IAuthStore['status'] = 'guest') => {
      if (status === 'initial') return
      removeLocalStorage([
        LOCAL_STORAGE.CREDENTIALS,
        LOCAL_STORAGE.CART,
        LOCAL_STORAGE.ANONYMOUS_CART,
        LOCAL_STORAGE.WHO_AM_I,
        // LOCAL_STORAGE.ZIP_CODE, - Preserve zip?
        LOCAL_STORAGE.SUBSCRIPTION,
      ])
      dispatch({
        type: AUTH_ACTION.SET_CLEAR,
        status: setStatus,
      })
    },
    [status]
  )

  useEffect(() => {
    dispatch({
      type: AUTH_ACTION.SET_LOGOUT,
      authLogout,
    })
  }, [authLogout])

  useEffect(() => {
    async function getWhoAmI() {
      if (status === 'authenticated' || !credentials) return

      const token = credentials?.accessToken
      const tokenExp = credentials?.expiresAt
        ? DateTime.fromRFC2822(credentials?.expiresAt).diffNow('days')
        : null

      if (!triedRefresh.current && tokenExp && tokenExp.days < 7) {
        triedRefresh.current = true
        if (credentials?.refreshToken) {
          const newTokenRes = await renewAuth(credentials.refreshToken)
          if (!newTokenRes?.error && newTokenRes?.accessToken) {
            dispatch({
              type: AUTH_ACTION.SET_TOKENS,
              credentials: newTokenRes,
            })
            return
          } else {
            authLogout()
            if (protectedPage) {
              dispatch({
                type: AUTH_ACTION.SET_RETURN_URL,
                returnUrl: router.asPath,
              })
              router.push(URLS.LOGIN.INDEX)
            }
            return
          }
        }
      }

      if (token) {
        const user = await AuthApi.whoAmI({
          accessToken: token,
        })
        if (user?.success) {
          dispatch({
            type: AUTH_ACTION.SET_STATUS,
            status: 'authenticated',
          })
          dispatch({
            type: AUTH_ACTION.SET_USER,
            user,
          })
          dispatch({
            type: AUTH_ACTION.SET_IS_LOADING,
            isLoading: false,
          })
          setLocalCredentials(credentials, user)
          logUser(user)

          let location
          const existingLocationFilter = getLocalObject(LOCAL_STORAGE.ZIP_CODE)
          if (existingLocationFilter) {
            location = existingLocationFilter.location
          } else {
            location = await CustomersApi.validateLocation(
              user.customer?.shippingAddress?.postalCode
            )
          }

          if (location) {
            dispatch({
              type: AUTH_ACTION.SET_VALID_LOCATION,
              validLocation: true,
            })
            const locationFilter = {
              zipcode: user.customer?.shippingAddress?.postalCode,
              location,
            }
            dispatch({
              type: AUTH_ACTION.SET_LOCATION_FILTER,
              locationFilter,
            })

            setLocalObject(LOCAL_STORAGE.ZIP_CODE, locationFilter)
            dispatch({
              type: AUTH_ACTION.SET_PROMPT_ZIP,
              promptZip: false,
            })
          } else {
            dispatch({
              type: AUTH_ACTION.SET_VALID_LOCATION,
              validLocation: false,
            })
            dispatch({
              type: AUTH_ACTION.SET_LOCATION_FILTER,
              locationFilter: null,
            })
            removeLocalStorage([LOCAL_STORAGE.ZIP_CODE])
          }
        } else {
          if (!user || user?.errors?.length) {
            router.push(
              `${URLS.LOGIN.INDEX}?error=${encodeURIComponent(
                user?.errors[0].message || ''
              )}`
            )
          }
          authLogout('guest')
        }
      }
    }
    getWhoAmI()
  }, [credentials, authLogout, status, protectedPage, router])

  useEffect(() => {
    async function checkCredentials(accessToken: string) {
      const result = await AuthApi.checkCredentials(accessToken)
      if (!result.isValid) {
        dispatch({
          type: AUTH_ACTION.SET_RETURN_URL,
          returnUrl: router.asPath,
        })
        authLogout()
        router.push(URLS.LOGIN.INDEX)
      }
    }

    const credentials = authState.credentials

    if (protectedPage && !isLoading) {
      if (!credentials) {
        authLogout()
        dispatch({
          type: AUTH_ACTION.SET_RETURN_URL,
          returnUrl: router.asPath,
        })
        router.push(URLS.LOGIN.INDEX)
      } else {
        checkCredentials(credentials.accessToken)
      }
    } else {
      if (!credentials && isLoading) {
        dispatch({
          type: AUTH_ACTION.SET_IS_LOADING,
          isLoading: false,
        })
      }
    }
  }, [authState.credentials, isLoading, authLogout, protectedPage, router])

  useEffect(() => {
    // const releasedVersion = process.env.NEXT_PUBLIC_VERSION
    // const localVersion = getLocalAppVersion()

    // async function setLocalCredentials() {
    const credentials = getLocalCredentials()
    try {
      if (credentials) {
        const { account, whoAmI: user } = credentials
        if (!account || !user) {
          return
        }
        dispatch({
          type: AUTH_ACTION.SET_TOKENS,
          credentials: account,
        })
        dispatch({
          type: AUTH_ACTION.SET_USER,
          user,
        })
      } else {
        dispatch({
          type: AUTH_ACTION.SET_STATUS,
          status: 'guest',
        })
      }
    } catch (error) {}
    // }

    // const isLogoutNeeded = (
    //   releasedVersion: string,
    //   localVersion: string
    // ): boolean => {
    //   const releasedMajorVersion = releasedVersion.split('.')[0]
    //   const localMajorVersion = localVersion.split('.')[0]

    //   return releasedMajorVersion > localMajorVersion
    // }

    // const checkVersioning = (releasedVersion: string, localVersion: string) => {
    //   if (localVersion !== releasedVersion) {
    //     if (isLogoutNeeded(releasedVersion, localVersion)) {
    //       console.log('AuthProvider #268')
    //       authLogout()
    //       logout()
    //     }
    //     setLocalAppVersion(releasedVersion)
    //   } else {
    // setLocalCredentials()
    //   }
    // }

    // checkVersioning(releasedVersion, localVersion)

    const locationFilter = getLocalObject(LOCAL_STORAGE.ZIP_CODE)
    if (locationFilter) {
      dispatch({
        type: AUTH_ACTION.SET_LOCATION_FILTER,
        locationFilter,
      })
      dispatch({
        type: AUTH_ACTION.SET_VALID_LOCATION,
        validLocation: true,
      })
    }
  }, [])

  useEffect(() => {
    async function getSubscriptions() {
      const localSubscription = getLocalSubscription()
      if (localSubscription && localSubscription.subscriptionActive) {
        dispatch({
          type: AUTH_ACTION.SET_MEMBERSHIP,
          subscription: localSubscription.subscription,
          subscriptionActive: localSubscription.subscriptionActive,
        })
      }
      if (user && credentials?.accessToken && status === 'authenticated') {
        const membership = await SubscriptionsApi.fetchSubscriptions(
          credentials.accessToken
        )

        const subscriptionActive = checkActiveMembership(membership)
        setLocalSubscription({
          subscription: membership,
          subscriptionActive,
        })
        dispatch({
          type: AUTH_ACTION.SET_MEMBERSHIP,
          subscription: membership,
          subscriptionActive,
        })
      }
    }
    getSubscriptions()
  }, [user, credentials, status])

  useEffect(() => {
    async function setZipCode() {
      try {
        const localZip = getLocalObject(LOCAL_STORAGE.ZIP_CODE)
        if (localZip) {
          const savedZip: ILocationFilter = {
            location: localZip.location,
            zipcode: localZip.zipcode,
          }
          dispatch({
            type: AUTH_ACTION.SET_VALID_LOCATION,
            validLocation: true,
          })
          dispatch({
            type: AUTH_ACTION.SET_LOCATION_FILTER,
            locationFilter: savedZip,
          })
          return
        }
        const accountZip =
          user?.customer?.shippingAddress?.postalCode ||
          user?.customer?.billingAddress?.postalCode
        if (!accountZip) return
        const location = await CustomersApi.validateLocation(accountZip)

        if (location) {
          const locationFilter = {
            zipcode: accountZip,
            location,
          }
          dispatch({
            type: AUTH_ACTION.SET_VALID_LOCATION,
            validLocation: true,
          })
          dispatch({
            type: AUTH_ACTION.SET_LOCATION_FILTER,
            locationFilter,
          })

          setLocalObject(LOCAL_STORAGE.ZIP_CODE, locationFilter)
        } else {
          dispatch({
            type: AUTH_ACTION.SET_VALID_LOCATION,
            validLocation: false,
          })
          dispatch({
            type: AUTH_ACTION.SET_LOCATION_FILTER,
            locationFilter: null,
          })
          removeLocalStorage([LOCAL_STORAGE.ZIP_CODE])
        }
      } catch (error) {
        console.error(error)
        // dispatch({
        //   type: AUTH_ACTION.SET_PROMPT_ZIP,
        //   promptZip: true,
        // })
      }
    }
    setZipCode()
  }, [user])

  useEffect(() => {
    try {
      if (process.env.NODE_ENV !== 'production') return

      if (!window.Gladly?.setUser || !window.Gladly?.clearUser) return

      if (user?.account?.emailAddress && user?.customer?.firstName) {
        const gladlyUser: IGladlyUser = {
          email: user.account.emailAddress,
          name: user.customer.firstName + ' ' + user.customer.lastName,
        }
        window.Gladly.setUser(gladlyUser)
      } else {
        window.Gladly.clearUser()
      }

      if (window.hj && user) {
        window.hj('identify', user)
      }
    } catch (error) {
      console.error(error)
    }
  }, [user])

  return (
    <AuthContext.Provider value={authState}>
      <AuthContextDispatch.Provider value={dispatch}>
        {protectedPage && isLoading ? (
          <div className="w-full h-screen flex justify-center items-center">
            <Loading />
          </div>
        ) : (
          children
        )}
      </AuthContextDispatch.Provider>
    </AuthContext.Provider>
  )
}

const useAuthStore = () => {
  if (!AuthContext) {
    throw new Error('useAuthStore must be used within AuthProvider')
  }
  const state = useContext(AuthContext)
  return state
}

const useAuthDispatch = () => {
  if (!AuthContextDispatch) {
    throw new Error('ùseAuthDispatch must be used within AuthDispatchPRovider')
  }
  const dispatch = useContext(AuthContextDispatch)
  if (!dispatch) {
    throw new Error('useAuthDispatch dispatch not found')
  }
  return dispatch
}

export { AuthProvider, useAuthStore, useAuthDispatch }
