import { useRouter } from 'next/router'
import React, { useState } from 'react'
import {
  Button,
  Headline,
  MembershipMolecule,
  Pgraph,
  RedeemUpgradeToAnnualConfirmation,
} from '..'
import { MembershipPerks } from '../../config'

interface IRedeemUpgradeToAnnualProps {
  onUpgrade?: () => void
  remainingAmount: number
}

function RedeemUpgradeToAnnual({
  onUpgrade = () => {},
  remainingAmount,
  ...props
}: IRedeemUpgradeToAnnualProps) {
  const router = useRouter()
  const [confirmUpgrade, setConfirmUpgrade] = useState<boolean>(false)

  const onAnnualConfirmation = () => {
    onUpgrade()
  }
  const onAnnualConfirmationClose = () => {
    setConfirmUpgrade(false)
  }

  return (
    <>
      <section className="w-full bg-neutral-1">
        <div className="max-w-5xl bg-leaves-1 bg-right-bottom bg-no-repeat mx-auto min-h-100">
          <div className="mx-auto relative z-[2] py-4 px-4 md:py-5 md:pt-7">
            <Headline
              variant="recoleta-s"
              className="text-center text-neutral-6 mx-auto w-4/5 mb-4 leading-29 md:text-32px md:mb-5"
            >
              Upgrade your Membership
            </Headline>
            <Pgraph
              variant="p-14"
              className="text-neutral-5 text-center w-full mx-auto md:leading-19 md:text-16px md:w-3/5"
            >
              As you&apos;re already a member, we&apos;ll apply this gift card
              toward upgrading your account. Follow the steps below. A credit
              will be automatically added to your account to cover any monthly
              membership dues that have already been paid.
            </Pgraph>

            <div className="text-center mt-4">
              <Button
                primary={true}
                label="Upgrade now"
                className="mt-4 md:w-1/3 md:mt-5"
                onClick={() => setConfirmUpgrade(true)}
              />
            </div>
          </div>

          <div className="text-center pt-2 pb-5 md:relative md:text-right md:pt-0 md:mb-4 md:-mt-[160px] md:pr-7">
            <img
              alt="Loop Leaves"
              src="/images/leaves-bg.png"
              className="max-w-[60px] inline-block md:max-w-[88px]"
            />
          </div>
        </div>

        <MembershipMolecule
          title="Membership perks"
          perks={MembershipPerks}
          className="bg-neutral-1"
          imagePath="/images/stock/membership-join-now.jpg"
          imageClassName="md:max-width-[400px]"
        />
      </section>

      {confirmUpgrade ? (
        <RedeemUpgradeToAnnualConfirmation
          remainingAmount={remainingAmount}
          onClose={onAnnualConfirmationClose}
          onConfirm={onAnnualConfirmation}
        />
      ) : (
        ''
      )}
    </>
  )
}

export { RedeemUpgradeToAnnual }
