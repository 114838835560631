export const subscribeToAList = async (data: any) => {
  try {
    const urlData = new URLSearchParams(data)

    const result = await fetch(`${process.env.NEXT_PUBLIC_KLAVIYO_API}`, {
      method: 'POST',
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
        'cache-control': 'no-cache',
      },
      body: urlData,
    })

    if (result.status !== 200) {
      throw new Error("Couldn't subscribe")
    }
    return 'Succeded'
  } catch (error) {
    return null
  }
}
