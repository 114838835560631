import Link from 'next/link'
import { Pgraph } from '../components'
import { IFAQ } from '../interfaces'
import { URLS } from './urls.constants'

export const MEMBERSHIP_FAQS: IFAQ[] = [
  {
    question: 'How are items cleaned?',
    answer: (
      <>
        <Pgraph variant="p-14" className="text-neutral-5">
          Our{' '}
          <span className="underline text-hyperLink">
            <Link href="https://snoop-images.s3.us-west-2.amazonaws.com/Loop+Care.pdf">
              LoopCare
            </Link>
          </span>{' '}
          safety and cleaning protocol begins with a rigorous quality assurance
          inspection and documentation of each item between every rental to
          ensure it functions safely and per the manufacturers specifications.
          Items that do not meet these specifications are pulled from inventory
          and repaired or replaced as needed. Items are cleaned per the
          manufacturer&apos;s specifications between every rental. We use a
          variety of cleaning products and materials, listed below, that are
          child safe, eco-friendly and less harmful to the environment. Products
          used by Loop for cleaning include:
          <ul className="list-inside list-disc mt-3">
            <li className="underline text-hyperLink">
              <Link href="https://www.forceofnatureclean.com/">
                Force of Nature
              </Link>
            </li>
            <li>UVC Sanitizing Wand</li>
            <li className="underline text-hyperLink">
              <Link href="https://www.seventhgeneration.com/dish-liquid-free-clear">
                Seventh Generation Dish Soap
              </Link>
            </li>
            <li className="underline text-hyperLink">
              <Link href="https://www.seventhgeneration.com/disinfecting-wipes">
                Seventh Generation Disinfecting Wipes
              </Link>
            </li>
            <li>Warm Water</li>
            <li>Distilled White Vinegar</li>
            <li>Electrolux 450 G Force Washer & Dryer (Cold Water)</li>
            <li className="underline text-hyperLink">
              <Link href="https://www.eonmist.com/">eOn Sanitizing Mist</Link>
            </li>
            <li className="underline text-hyperLink">
              <Link href="https://nelliesclean.com/shop/laundry-soda/?attribute_size-loads=300+%28tin%29">
                Nellie&apos;s All Natural Unscented Powder Detergent
              </Link>
            </li>
            <li className="underline text-hyperLink">
              <Link href="https://nelliesclean.com/shop/wool-dryerballs/">
                Nellie&apos;s Eco-Friendly Unscented Dryer Balls
              </Link>
            </li>
            <li>Air Dried and Machine Medium Dried</li>
          </ul>
        </Pgraph>
      </>
    ),
  },
  {
    question: 'Do I need a membership to rent with Loop?',
    answer: (
      <>
        <Pgraph variant="p-14" className="text-neutral-5">
          No, you don&apos;t need a membership to rent with Loop! Simply find
          the products you&apos;d like to rent & checkout - we take care of the
          rest.
        </Pgraph>
        <Pgraph variant="p-14" className="mt-4 text-neutral-5">
          For those who want to rent multiple times and/or are looking for a
          discount, our membership does offer a savings of 33% or more on ALL
          item monthly rental fees, plus a savings of $15 on every delivery fee
          ($35 per delivery instead of $50 - saving you on every order!).
          Members also have insurance coverage for any minor damage. And, we
          offer other benefits of becoming a member including 10% off all of
          your travel baby gear and toy rentals with Baby Quip.
        </Pgraph>
        <Pgraph variant="p-14" className="mt-4 text-neutral-5">
          Joining as a member is our most popular way to rent, especially with
          first-time expecting parents who get the confidence and flexibility
          they need and the value they deserve.
        </Pgraph>
      </>
    ),
  },
  {
    question: 'How does membership and billing work?',
    answer: (
      <>
        <Pgraph variant="p-14" className="mb-4 text-neutral-5">
          For all customers, your billing date will remain the same each month.
          Your first month of any item rental is paid up front, and we use that
          charge as a credit upon your next recurring bill date to keep your
          billing in sync regardless of when you have items delivered.
        </Pgraph>
        <Pgraph variant="p-14" className="mb-4 text-neutral-5">
          If you join as a brand-new Loop member, your first order will include
          the membership plan as well as the first month’s deposit for all of
          your rentals. Your membership period will begin on the date for your
          first delivery, and will automatically renew one year from that date.
          The rental period for any items delivered on that date will also
          start, and be set to automatically renew on a monthly basis.
        </Pgraph>
        <Pgraph variant="p-14" className="mb-4 text-neutral-5">
          Visit our{' '}
          <span className="underline text-hyperLink">
            <Link href={URLS.MEMBERSHIP}>membership page</Link>
          </span>{' '}
          for more details.
        </Pgraph>
      </>
    ),
  },
  {
    question: 'How do I schedule a delivery?',
    answer: (
      <>
        <Pgraph variant="p-14" className="text-neutral-5">
          After you check out and pay for your Loop membership and selected
          items, we&apos;ll contact you within two business days. If you&apos;re
          currently expecting, we recommend scheduling your delivery 2 weeks
          prior to your due date. We encourage you to order well in advance if
          you&apos;re expecting, as we&apos;re happy to hold←Minor suggestion:
          change to “reserve” instead of hold items until it&apos;s closer to
          your due date.
        </Pgraph>
      </>
    ),
  },
]

export const GIFTING_FAQS: IFAQ[] = [
  {
    question: 'How do Loop gift cards work?',
    answer: [
      "Loop gift cards can be used towards any new items or recurring subscription, and don't expire!",
    ],
  },
  {
    question: 'How are Loop rental items cleaned?',
    answer: [
      'Our LoopCare safety and cleaning protocol begins with a rigorous quality assurance inspection and documentation of each item between every rental to ensure it functions safely and per the manufacturers specifications. Items that do not meet these specifications are pulled from inventory and repaired or replaced as needed.',

      "Items are cleaned per the manufacturer's specifications between every rental. We use a variety of cleaning products and materials, listed below, that are child safe, eco-friendly and less harmful to the environment.",

      "Products used by Loop for cleaning include: Force of Nature, UVC Sanitizing Wand, Seventh Generation Dish Soap, Seventh Generation Disinfecting Wipes, Warm Water, Distilled White Vinegar, Electrolux 450 G Force Washer & Dryer (Cold Water), eOn Sanitizing Mist, Nellie's All Natural Unscented Powder Detergent, Nellie's Eco-Friendly Unscented Dryer Balls, Air Dried and Machine Medium Dried",
    ],
  },
  {
    question: 'How does membership and billing work?',
    answer: (
      <>
        <Pgraph variant="p-14" className="mb-4 text-neutral-5">
          For all customers, your billing date will remain the same each month.
          Your first month of any item rental is paid up front, and we use that
          charge as a credit upon your next recurring bill date to keep your
          billing in sync regardless of when you have items delivered.
        </Pgraph>
        <Pgraph variant="p-14" className="mb-4 text-neutral-5">
          If you join as a brand-new Loop member, your first order will include
          the membership plan as well as the first month’s deposit for all of
          your rentals. Your membership period will begin on the date for your
          first delivery, and will automatically renew one year from that date.
          The rental period for any items delivered on that date will also
          start, and be set to automatically renew on a monthly basis.
        </Pgraph>
        <Pgraph variant="p-14" className="mb-4 text-neutral-5">
          Visit our{' '}
          <span className="underline text-hyperLink">
            <Link href={URLS.MEMBERSHIP}>membership page</Link>
          </span>{' '}
          for more details.
        </Pgraph>
      </>
    ),
  },
  {
    question: "I'm expecting. How do I prepare for baby's arrival?",
    answer: (
      <>
        <Pgraph variant="p-14" className="text-neutral-5">
          We are here to help during this exciting time!{' '}
          <span className="underline">
            <Link href="https://calendly.com/loop-baby/loop-consultations">
              Schedule a consult
            </Link>
          </span>{' '}
          with our expert concierge team as soon as you start thinking about
          baby gear so we can help plan what you need and schedule a stress-free
          delivery. You can reserve items for delivery well ahead of your due
          date, at no extra cost to you: Simply pay upfront for the first month
          and your second month doesn&apos;t bill until a month after your first
          Loop delivery.
        </Pgraph>
      </>
    ),
  },
  {
    question: 'How do I schedule a delivery?',
    answer: [
      "After you check out and pay for your Loop membership and selected items, we'll email you within two days to schedule your home delivery. If you're currently expecting, we recommend scheduling your delivery 2 weeks prior to your due date. We encourage you to order well in advance if you're expecting, as we're happy to hold items until it's closer to your due date.",
    ],
  },
  {
    question: 'When am I billed?',
    answer: [
      "You'll pay at check out for the first month of items (and your membership if it's your first-ever order). Then we won't bill you again until a month after we deliver the items to you, so you never have to pay for time not used!",
      " If you're an annual member, that means we also push out your membership renewal date to be a year from first delivery, and if you're a monthly member, you won't be billed again for your membership until a month after your first items are delivered.",
      "Your billing date will remain the same each month, and we'll pro-rate as needed based on new items you order.",
    ],
  },
]

export const HLW_FAQS: IFAQ[] = [
  {
    question: 'What is your safety & cleaning process?',
    answer: (
      <>
        <Pgraph variant="p-14" className="text-neutral-5">
          Our{' '}
          <span className="underline text-hyperLink">
            <Link href="https://snoop-images.s3.us-west-2.amazonaws.com/Loop+Care.pdf">
              LoopCare
            </Link>
          </span>{' '}
          safety and cleaning protocol begins with a rigorous quality assurance
          inspection and documentation of each item between every rental to
          ensure it functions safely and per the manufacturers specifications.
          Items that do not meet these specifications are pulled from inventory
          and repaired or replaced as needed. Items are cleaned per the
          manufacturer&apos;s specifications between every rental. We use a
          variety of cleaning products and materials, listed below, that are
          child safe, eco-friendly and less harmful to the environment. Products
          used by Loop for cleaning include:
          <ul className="list-inside list-disc mt-3">
            <li className="underline text-hyperLink">
              <Link href="https://www.forceofnatureclean.com/">
                Force of Nature
              </Link>
            </li>
            <li>UVC Sanitizing Wand</li>
            <li className="underline text-hyperLink">
              <Link href="https://www.seventhgeneration.com/dish-liquid-free-clear">
                Seventh Generation Dish Soap
              </Link>
            </li>
            <li className="underline text-hyperLink">
              <Link href="https://www.seventhgeneration.com/disinfecting-wipes">
                Seventh Generation Disinfecting Wipes
              </Link>
            </li>
            <li>Warm Water</li>
            <li>Distilled White Vinegar</li>
            <li>Electrolux 450 G Force Washer & Dryer (Cold Water)</li>
            <li className="underline text-hyperLink">
              <Link href="https://www.eonmist.com/">eOn Sanitizing Mist</Link>
            </li>
            <li className="underline text-hyperLink">
              <Link href="https://nelliesclean.com/shop/laundry-soda/?attribute_size-loads=300+%28tin%29">
                Nellie&apos;s All Natural Unscented Powder Detergent
              </Link>
            </li>
            <li className="underline text-hyperLink">
              <Link href="https://nelliesclean.com/shop/wool-dryerballs/">
                Nellie&apos;s Eco-Friendly Unscented Dryer Balls
              </Link>
            </li>
            <li>Air Dried and Machine Medium Dried</li>
          </ul>
        </Pgraph>
      </>
    ),
  },
  {
    question: 'How does membership and billing work?',
    answer: (
      <>
        <Pgraph variant="p-14" className="mb-4 text-neutral-5">
          For all customers, your billing date will remain the same each month.
          Your first month of any item rental is paid up front, and we use that
          charge as a credit upon your next recurring bill date to keep your
          billing in sync regardless of when you have items delivered.
        </Pgraph>
        <Pgraph variant="p-14" className="mb-4 text-neutral-5">
          If you join as a brand-new Loop member, your first order will include
          the membership plan as well as the first month’s deposit for all of
          your rentals. Your membership period will begin on the date for your
          first delivery, and will automatically renew one year from that date.
          The rental period for any items delivered on that date will also
          start, and be set to automatically renew on a monthly basis.
        </Pgraph>
        <Pgraph variant="p-14" className="mb-4 text-neutral-5">
          Visit our{' '}
          <span className="underline text-hyperLink">
            <Link href={URLS.MEMBERSHIP}>membership page</Link>
          </span>{' '}
          for more details.
        </Pgraph>
      </>
    ),
  },
  {
    question: 'Where is Loop available?',
    answer: (
      <>
        <Pgraph variant="p-14" className="text-neutral-5">
          We serve most of the San Francisco Bay Area, Tri-State Area, and
          Greater Philadelphia. To find out if Loop is available near you,
          please click the red button at the top of our website that says “Check
          delivery area” and enter your zip code.
        </Pgraph>

        <Pgraph variant="p-14" className="mt-4 text-neutral-5">
          If you have any questions about Loop’s availability in your area,
          please <Link href={URLS.CONTACT_US}>contact us</Link> or email
          team@loop.baby.
        </Pgraph>
      </>
    ),
  },
  {
    question: "I'm expecting. How do I prepare for baby's arrival?",
    answer: (
      <>
        <Pgraph variant="p-14" className="text-neutral-5">
          We are here to help during this exciting time!{' '}
          <span className="underline text-hyperLink">
            <Link href="https://calendly.com/loop-baby/loop-consultations">
              Schedule a consult
            </Link>
          </span>{' '}
          with our expert concierge team as soon as you start thinking about
          baby gear so we can help plan what you need and schedule a stress-free
          delivery. You can reserve items for delivery well ahead of your due
          date at no extra cost to you. Simply pay upfront for the first month
          and your second month doesn&apos;t bill until a month after your first
          Loop delivery.
        </Pgraph>
      </>
    ),
  },
  {
    question: 'What if I damage an item during use?',
    answer: (
      <>
        <Pgraph variant="p-14" className="mb-4 text-neutral-5">
          Normal wear and tear is completely expected and our LoopCare team
          ensures every returned item is up to standards before sharing with the
          next family.
        </Pgraph>
        <Pgraph variant="p-14" className="mb-4 text-neutral-5">
          Membership includes insurance coverage. For any damage that is larger
          and/or impacts the ability to use the product we have a “don&apos;t
          sweat the small stuff” policy for our members. We won&apos;t charge
          members for any damage that is repairable, or for any damage that
          requires replacement of an item and/or parts that retail for $25 or
          less. For higher-valued items, or if abuse is suspected, we reserve
          the right to charge up to 50% of the item&apos;s retail value.
        </Pgraph>
      </>
    ),
  },
  {
    question: 'What if I want to buy out my items?',
    answer: (
      <>
        <Pgraph variant="p-14" className="text-neutral-5">
          We offer the ability to purchase items through our{' '}
          <a href="https://loop-warehouse.mybigcommerce.com" target="_blank">
            For Keeps
          </a>{' '}
          site and occasionally offer members the ability to purchase items they
          have at home. You can <Link href={URLS.CONTACT_US}>contact us</Link>{' '}
          at team@loop.baby with any questions or to see if items you have at
          home are available for purchase.
        </Pgraph>{' '}
      </>
    ),
  },
]

export const GENERAL_FAQS: IFAQ[] = [
  {
    question: 'How are items cleaned?',
    answer: (
      <>
        <Pgraph variant="p-14" className="text-neutral-5">
          Our{' '}
          <span className="underline text-hyperLink">
            <Link href="https://snoop-images.s3.us-west-2.amazonaws.com/Loop+Care.pdf">
              LoopCare
            </Link>
          </span>{' '}
          safety and cleaning protocol begins with a rigorous quality assurance
          inspection and documentation of each item between every rental to
          ensure it functions safely and per the manufacturers specifications.
          Items that do not meet these specifications are pulled from inventory
          and repaired or replaced as needed. Items are cleaned per the
          manufacturer&apos;s specifications between every rental. We use a
          variety of cleaning products and materials, listed below, that are
          child safe, eco-friendly and less harmful to the environment. Products
          used by Loop for cleaning include:
          <ul className="list-inside list-disc mt-3">
            <li className="underline text-hyperLink">
              <Link href="https://www.forceofnatureclean.com/">
                Force of Nature
              </Link>
            </li>
            <li>UVC Sanitizing Wand</li>
            <li className="underline text-hyperLink">
              <Link href="https://www.seventhgeneration.com/dish-liquid-free-clear">
                Seventh Generation Dish Soap
              </Link>
            </li>
            <li className="underline text-hyperLink">
              <Link href="https://www.seventhgeneration.com/disinfecting-wipes">
                Seventh Generation Disinfecting Wipes
              </Link>
            </li>
            <li>Warm Water</li>
            <li>Distilled White Vinegar</li>
            <li>Electrolux 450 G Force Washer & Dryer (Cold Water)</li>
            <li className="underline text-hyperLink">
              <Link href="https://www.eonmist.com/">eOn Sanitizing Mist</Link>
            </li>
            <li className="underline text-hyperLink">
              <Link href="https://nelliesclean.com/shop/laundry-soda/?attribute_size-loads=300+%28tin%29">
                Nellie&apos;s All Natural Unscented Powder Detergent
              </Link>
            </li>
            <li className="underline text-hyperLink">
              <Link href="https://nelliesclean.com/shop/wool-dryerballs/">
                Nellie&apos;s Eco-Friendly Unscented Dryer Balls
              </Link>
            </li>
            <li>Air Dried and Machine Medium Dried</li>
          </ul>
        </Pgraph>
      </>
    ),
  },
  {
    question: 'What is your SNOO safety and cleaning process?',
    answer: (
      <>
        <Pgraph variant="p-14" className="text-neutral-5">
          All Loop SNOO&apos;s go through our rigorous{' '}
          <span className="underline text-hyperLink">
            <Link href="https://snoop-images.s3.us-west-2.amazonaws.com/Loop+Care.pdf">
              LoopCare
            </Link>
          </span>{' '}
          safety and cleaning process that meets the{' '}
          <span className="underline text-hyperLink">
            <Link href="https://files.bbystatic.com/TMFjM2lY53Oafd6WvXVFig%3D%3D/9de806ac-3e70-4c2e-8531-e5c66a7a34a7.pdf">
              manufacturer cleaning care specifications
            </Link>
          </span>
          .
          <ul className="list-inside">
            <li>
              Step 1: Upon return from each rental the SNOO is thoroughly
              examined by trained Loop technicians to ensure Safety. Each SNOO
              is tested for complete and full functionality including network &
              app connectivity, power, motion, sound, parts and software
              updates. Once the SNOO meets our quality assurance standards it
              moves to the next phase for cleaning.
            </li>
            <li>
              Step 2: Sheet and swaddles are inspected for functionality and
              safety. They are then professionally laundered.
            </li>
            <li>
              Step 3: SNOO is thoroughly vacuumed along the base and in the
              crevices to remove any debris.
            </li>
            <li>
              Step 4: SNOO is then wiped clean and disinfected using the{' '}
              <span className="underline text-hyperLink">
                <Link href="https://www.forceofnatureclean.com/">
                  Force of Nature
                </Link>
              </span>{' '}
              cleanser on the side netting, base and mattress. Side netting is
              also steam cleaned.
            </li>
            <li>
              Step 5: SNOO is then vacuumed a final time to remove any
              additional debris.
            </li>
            <li>
              Step 6: SNOO is finally sprayed with our{' '}
              <span className="underline text-hyperLink">
                <Link href="https://www.eonmist.com/">eOn Sanitizing Mist</Link>
              </span>{' '}
              and our UVC Sanitizing Wand.
            </li>
            <li>
              Step 7: SNOO is then air dried, covered and stored in our
              warehouse until its next delivery.
            </li>
            <li>
              Step 8: Prior to the next delivery each SNOO is visually inspected
              yet again and placed in our van ready for our next member
              delivery.
            </li>
          </ul>
        </Pgraph>
      </>
    ),
  },
  {
    question: 'Are Loop items used - what quality are these items in?',
    answer: (
      <>
        <Pgraph variant="p-14" className="text-neutral-5">
          Some items are brand new and others have been gently used. Every Loop
          item is in excellent shape, and is deep-cleaned, sanitized and quality
          checked before arriving at your home.
        </Pgraph>
      </>
    ),
  },
  {
    question: 'Do I need a membership to rent with Loop?',
    answer: (
      <>
        <Pgraph variant="p-14" className="text-neutral-5">
          No, you don&apos;t need a membership to rent with Loop! Simply find
          the products you&apos;d like to rent & checkout - we take care of the
          rest.
        </Pgraph>
        <Pgraph variant="p-14" className="mt-4 text-neutral-5">
          For those who want to rent multiple times and/or are looking for a
          discount, our membership does offer a savings of 33% or more on ALL
          item monthly rental fees, plus a savings of $15 on every delivery fee
          ($35 per delivery instead of $50 - saving you on every order!).
          Members also have insurance coverage for any minor damage. And, we
          offer other benefits of becoming a member including 10% off all of
          your travel baby gear and toy rentals with Baby Quip.
        </Pgraph>
        <Pgraph variant="p-14" className="mt-4 text-neutral-5">
          Joining as a member is our most popular way to rent, especially with
          first-time expecting parents who get the confidence and flexibility
          they need and the value they deserve.
        </Pgraph>
      </>
    ),
  },
  {
    question: 'What are your standards for selecting items?',
    answer: (
      <>
        <Pgraph variant="p-14" className="text-neutral-5">
          Our Golden Rule: We only carry items that we would be excited to use
          with our own families. We evaluate products on four criteria:
          <ul className="list-inside list-none">
            <li>
              Quality: We select products that are strong, durable, and
              well-designed, and effectively foster learning and development.
            </li>
            <li>
              Safety: We make sure that products meet all relevant safety
              standards, and do not offer products if we feel we cannot
              guarantee their safety, such as car seats or helmets.
            </li>
            <li>
              Sustainability: In addition to selecting durable products that can
              be safely reused, we also favor products that are made from
              natural, non-toxic materials and sustainability-focused brands
              when possible.
            </li>
            <li>
              Value: We select the best, most valuable baby products and make
              them accessible and affordable, so you can care for your family
              without compromise.
            </li>
          </ul>
        </Pgraph>
      </>
    ),
  },
  {
    question: 'How does membership and billing work?',
    answer: (
      <>
        <Pgraph variant="p-14" className="mb-4 text-neutral-5">
          For all customers, your billing date will remain the same each month.
          Your first month of any item rental is paid up front, and we use that
          charge as a credit upon your next recurring bill date to keep your
          billing in sync regardless of when you have items delivered.
        </Pgraph>
        <Pgraph variant="p-14" className="mb-4 text-neutral-5">
          If you join as a brand-new Loop member, your first order will include
          the membership plan as well as the first month’s deposit for all of
          your rentals. Your membership period will begin on the date for your
          first delivery, and will automatically renew one year from that date.
          The rental period for any items delivered on that date will also
          start, and be set to automatically renew on a monthly basis.
        </Pgraph>
        <Pgraph variant="p-14" className="mb-4 text-neutral-5">
          Visit our{' '}
          <span className="underline text-hyperLink">
            <Link href={URLS.MEMBERSHIP}>membership page</Link>
          </span>{' '}
          for more details.
        </Pgraph>
      </>
    ),
  },
  {
    question: 'How can I give Loop as a gift? How does it work?',
    answer: (
      <>
        <Pgraph variant="p-14" className="text-neutral-5">
          You can give the gift of Loop in the form of a digital gift card -{' '}
          <span className="underline text-hyperLink">
            <Link href={URLS.GIFTS}>order here</Link>
          </span>
          !
        </Pgraph>
        <Pgraph variant="p-14" className="text-neutral-5">
          *Note, the $75 product credit with a gift of a membership is only
          valid for new members.
        </Pgraph>
      </>
    ),
  },
  {
    question: 'Where is Loop available?',
    answer: (
      <>
        <Pgraph variant="p-14" className="text-neutral-5">
          We serve most of the San Francisco Bay Area, Tri-State Area, and
          Greater Philadelphia. To find out if Loop is available near you,
          please click the red button at the top of our website that says “Check
          delivery area” and enter your zip code.
        </Pgraph>

        <Pgraph variant="p-14" className="mt-4 text-neutral-5">
          If you have any questions about Loop’s availability in your area,
          please <Link href={URLS.CONTACT_US}>contact us</Link> or email
          team@loop.baby.
        </Pgraph>
      </>
    ),
  },
  {
    question: "I'm expecting. How do I prepare for baby's arrival?",
    answer: (
      <>
        <Pgraph variant="p-14" className="text-neutral-5">
          We are here to help during this exciting time!{' '}
          <span className="underline text-hyperLink">
            <Link href="https://calendly.com/loop-baby/loop-consultations">
              Schedule a consult
            </Link>
          </span>{' '}
          with our expert concierge team as soon as you start thinking about
          baby gear so we can help plan what you need and schedule a stress-free
          delivery. You can reserve items for delivery well ahead of your due
          date at no extra cost to you. Simply pay upfront for the first month
          and your second month doesn&apos;t bill until a month after your first
          Loop delivery.
        </Pgraph>
      </>
    ),
  },
  {
    question: 'How do I place Loop items on my registry?',
    answer: (
      <>
        <Pgraph variant="p-14" className="text-neutral-5">
          You can create a registry by selecting “Add to registry” on any PDP.
          We integrate with Babylist to make sharing your registry easy! View
          more details about registries{' '}
          <span className="underline text-hyperLink">
            <Link href={URLS.REGISTRY}>here</Link>
          </span>{' '}
        </Pgraph>
      </>
    ),
  },
  {
    question: "What if I don't see the item I want?",
    answer: (
      <>
        <Pgraph variant="p-14" className="text-neutral-5">
          <span className="underline text-hyperLink">
            <Link href={URLS.CONTACT_US}>Let us know!</Link>
          </span>{' '}
          We&apos;re happy to consider new additions. Some of our most popular
          items have come from member suggestions.
        </Pgraph>
      </>
    ),
  },
  {
    question: 'How do I schedule a delivery?',
    answer: (
      <>
        <Pgraph variant="p-14" className="text-neutral-5">
          After you check out and pay for your Loop membership and selected
          items, we&apos;ll contact you within two business days. If you&apos;re
          currently expecting, we recommend scheduling your delivery 2 weeks
          prior to your due date. We encourage you to order well in advance if
          you&apos;re expecting, as we&apos;re happy to hold←Minor suggestion:
          change to “reserve” instead of hold items until it&apos;s closer to
          your due date.
        </Pgraph>
      </>
    ),
  },
  {
    question: 'How do I schedule a pick up or cancel my membership?',
    answer: (
      <>
        <Pgraph variant="p-14" className="text-neutral-5">
          You can cancel your membership and/or schedule pickups through your
          account page, or by <Link href={URLS.CONTACT_US}>contacting us</Link>{' '}
          at team@loop.baby.
        </Pgraph>
        <Pgraph variant="p-14" className="text-neutral-5">
          All cancellations of memberships and/or item pickups must be done
          prior to the renewal date, in order to avoid any charges or fees. Once
          billed, renewal charges are non-refundable.
        </Pgraph>
      </>
    ),
  },
  {
    question: 'How quickly will I get my item?',
    answer: (
      <>
        <Pgraph variant="p-14" className="text-neutral-5">
          Items can typically be delivered within 5-10 business days, or at your
          desired future date.
        </Pgraph>
      </>
    ),
  },
  {
    question: 'What if I damage an item during use?',
    answer: (
      <>
        <Pgraph variant="p-14" className="mb-4 text-neutral-5">
          Normal wear and tear is completely expected and our LoopCare team
          ensures every returned item is up to standards before sharing with the
          next family.
        </Pgraph>
        <Pgraph variant="p-14" className="mb-4 text-neutral-5">
          Membership includes insurance coverage. For any damage that is larger
          and/or impacts the ability to use the product we have a “don&apos;t
          sweat the small stuff” policy for our members. We won&apos;t charge
          members for any damage that is repairable, or for any damage that
          requires replacement of an item and/or parts that retail for $25 or
          less. For higher-valued items, or if abuse is suspected, we reserve
          the right to charge up to 50% of the item&apos;s retail value.
        </Pgraph>
      </>
    ),
  },
  {
    question: 'What if I lose an item during use?',
    answer: (
      <>
        <Pgraph variant="p-14" className="mb-4 text-neutral-5">
          For our members, our “don&apos;t sweat the small stuff” policy extends
          to lost items, too. We know how small pieces can easily find
          themselves lost in the couch, the cabinet crevice only tiny fingers
          can fit in, the airport Uber, and too many places in between.
        </Pgraph>
        <Pgraph variant="p-14" className="mb-4 text-neutral-5">
          We won&apos;t charge for any nominal lost item or part that can be
          replaced for $25 or less. For higher-valued items or if abuse is
          suspected, we reserve the right to charge up to 50% of the item&apos;s
          retail value.
        </Pgraph>
      </>
    ),
  },
  {
    question: 'What if I want to buy out my items?',
    answer: (
      <>
        <Pgraph variant="p-14" className="text-neutral-5">
          We offer the ability to purchase items through our{' '}
          <a href="https://loop-warehouse.mybigcommerce.com" target="_blank">
            For Keeps
          </a>{' '}
          site and occasionally offer members the ability to purchase items they
          have at home. You can <Link href={URLS.CONTACT_US}>contact us</Link>{' '}
          at team@loop.baby with any questions or to see if items you have at
          home are available for purchase.
        </Pgraph>{' '}
      </>
    ),
  },
  {
    question: 'What if I need an item for less than 1-month?',
    answer: (
      <>
        <Pgraph variant="p-14" className="text-neutral-5">
          The minimum rental period for all items is one month. You can schedule
          a pickup before a monthly rental period is over if you’d like, however
          there will be no proration of the monthly rental fee. You can request
          a pickup from your account page or by contacting team@loop.baby.
        </Pgraph>
      </>
    ),
  },
  {
    question: 'What if I want to swap my items?',
    answer: (
      <>
        <Pgraph variant="p-14" className="text-neutral-5">
          No problem—Loop is built to be flexible. Schedule a swap by placing
          the order for your new items and marking the items you want to return
          from your account page. We&apos;ll coordinate the swap from there.
        </Pgraph>
      </>
    ),
  },
  {
    question: 'How do I get instructions for using my item?',
    answer: (
      <>
        <Pgraph variant="p-14" className="text-neutral-5">
          Please{' '}
          <span className="underline text-hyperLink">
            <Link href={URLS.CONTACT_US}>contact us</Link>
          </span>{' '}
          for any product questions or needs.
        </Pgraph>
      </>
    ),
  },
  {
    question: 'When do you retire items?',
    answer: (
      <>
        <Pgraph variant="p-14" className="text-neutral-5">
          We retire items when they no longer meet our quality standards. If an
          item is broken and cannot be fixed, we do our best to recycle it. We
          also donate items that continue to work well but no longer fit within
          our current offering.
        </Pgraph>
      </>
    ),
  },
  {
    question: 'I love Loop! How can I help spread the word?',
    answer: (
      <>
        <Pgraph variant="p-14" className="text-neutral-5">
          We&apos;re working hard to get our referrals program back up and
          running. If you have previously shared Loop love or have a referral
          code that isn&apos;t working properly, please let us know at{' '}
          <span className="underline text-hyperLink">
            <Link href="mailto:team@loop.baby">team@loop.baby</Link>
          </span>{' '}
          .
        </Pgraph>
      </>
    ),
  },
  {
    question: 'Will you take my other used baby gear?',
    answer: (
      <>
        <Pgraph variant="p-14" className="text-neutral-5">
          Possibly! We offer Loop credits for items currently in our catalog and
          in excellent condition. To learn if your item(s) are eligible, please
          fill out our Trade In, Trade Up{' '}
          <span className="underline text-hyperLink">
            <Link href="https://docs.google.com/forms/d/e/1FAIpQLScT1lRZbVO126bxDV8E1fuc56rtuLNhkBlHJV597HZh825jpw/viewform">
              form
            </Link>
          </span>{' '}
          here and one of our team members will reach out to you.
        </Pgraph>
      </>
    ),
  },
  {
    question:
      'Will I always receive the same color item that is pictured on the website?',
    answer: (
      <>
        <Pgraph variant="p-14" className="text-neutral-5">
          In most cases the item you receive will be the same color as the item
          you saw pictured on the product detail page. However, on occasion we
          need to swap items out for other similar neutral colors based on
          product availability from the manufacturer.
        </Pgraph>
      </>
    ),
  },
  {
    question: 'What happens if I need to cancel or reschedule my appointment?',
    answer: (
      <>
        <Pgraph variant="p-14" className="text-neutral-5">
          Please notify us at least 24 hours in advance of your scheduled
          appointment if you wish to cancel or reschedule your Loop delivery,
          pickup, or swap. A “no-show” or cancellation/rescheduling without at
          least 24 hour notice will result in a $20 rescheduling fee.
        </Pgraph>
        <Pgraph variant="p-14" className="text-neutral-5">
          For deliveries canceled without 24 hour notice, the order total amount
          will be refunded minus $20 for the last minute cancellation fee.
        </Pgraph>
        <Pgraph variant="p-14" className="text-neutral-5">
          If a pickup is canceled/rescheduled without at least 24 hour notice
          and is not returned within one week of the originally scheduled pickup
          date, the items scheduled for cancellation will be billed for a one
          month rental period (non-refundable). If desired, you can rent the
          item for the month billed and a new pickup date can be scheduled at
          the end of the rental period.
        </Pgraph>
      </>
    ),
  },
  {
    question: 'What happens if I change my mind about returning an item?',
    answer: (
      <>
        <Pgraph variant="p-14" className="text-neutral-5">
          If you decide at the last minute that you wish to continue renting an
          item, please notify the Customer Experience team or let our Delivery
          Experience associate know at the time of your scheduled appointment.
          The item will then be added back to your rental subscriptions.
        </Pgraph>
        <Pgraph variant="p-14" className="text-neutral-5">
          However, please note that the last minute cancellation fee of $20
          still applies for pickups canceled without notice at least 24 hours in
          advance of the scheduled appointment. The last minute cancellation fee
          does not apply to swaps, so long as the order scheduled for delivery
          is not canceled.
        </Pgraph>
      </>
    ),
  },
  {
    question:
      'What happens if I forgot to cancel a rental or my membership plan before the renewal date?',
    answer: (
      <>
        <Pgraph variant="p-14" className="text-neutral-5">
          Once a membership plan or monthly rental renewal is billed, the charge
          is non-refundable for any reason.
        </Pgraph>
        <Pgraph variant="p-14" className="text-neutral-5">
          If you wish to return a rental, please ensure that a cancellation
          request is submitted ahead of the renewal date either through the
          dashboard or by contacting team@loop.baby. The cancellation request
          may take up to 2 business days to process.
        </Pgraph>
        <Pgraph variant="p-14" className="text-neutral-5">
          A fee of $25 may be charged if the canceled item cannot be picked up
          within 7 days of the end of the last rental period.
        </Pgraph>
      </>
    ),
  },
  {
    question:
      'Why was I billed for an item I only wanted to rent for one-month?',
    answer: (
      <>
        <Pgraph variant="p-14" className="text-neutral-5">
          All Loop rentals are consolidated to one monthly billing date, to make
          it easier to remember when you&apos;ll be billed each month. When an
          item rental starts “mid-cycle” (i.e. is delivered between billing
          cycles), a prorated amount will be charged on your next monthly
          statement to cover the partial rental period between the delivery date
          and your next billing cycle. If the items delivered mid-cycle are
          canceled and returned within one month of the delivery date, the
          prorated charges will be credited back at the following billing cycle.
        </Pgraph>
        <Pgraph variant="p-14" className="text-neutral-5">
          One month of rental is always covered by the payment made when an
          order is placed, which acts as a deposit, and will show up on your
          statement as a credit applied.
        </Pgraph>
      </>
    ),
  },
  {
    question:
      "What if I want to have the item I'm renting replaced with a new one?",
    answer: (
      <>
        <Pgraph variant="p-14" className="text-neutral-5">
          Please inspect your rental items on the day they&apos;re delivered.{' '}
          <Link href={URLS.CONTACT_US}>Contact us</Link> immediately if any of
          the items delivered are damaged or not in functional condition, and
          include a photo or video of the item for reference.
        </Pgraph>
        <Pgraph variant="p-14" className="text-neutral-5">
          If an item stops functioning during rental, please{' '}
          <Link href={URLS.CONTACT_US}>contact us</Link>. Include a description
          and photo reference of the issue with your submission. We typically
          replace items free of charge if the item stops functioning due to
          normal wear-and-tear, but we may charge for a replacement if there’s
          reason to suspect the item has been misused.
        </Pgraph>
        <Pgraph variant="p-14" className="text-neutral-5">
          Renters are responsible for basic maintenance and cleaning of the item
          during the duration of the rental. If you&apos;d like to have your
          rental replaced for reasons unrelated to the functionality of the
          product, a new order must be placed for the new item regardless of the
          status or duration of the current rental.
        </Pgraph>
        <Pgraph variant="p-14" className="text-neutral-5">
          If you have any questions about replacements, please don&apos;t
          hesitate to <Link href={URLS.CONTACT_US}>contact us</Link>.
        </Pgraph>
      </>
    ),
  },
  {
    question: 'Is rush delivery available?',
    answer: (
      <Pgraph variant="p-14" className="text-neutral-5">
        We understand that sometimes you just need something when you need it!
        We evaluate rush delivery requests on a case-by-case basis. When you
        place an order, you should receive an automated text message confirming
        your order. You can respond to that text message with your rush delivery
        request, or get in touch using the{' '}
        <Link href={URLS.CONTACT_US}>contact form</Link>. The surcharge for the
        rush delivery will be determined based on the specifics of your request.
      </Pgraph>
    ),
  },
  {
    question:
      "What if the product I rented doesn't work out or my child doesn't like it? Can I have the order refunded?",
    answer: (
      <Pgraph variant="p-14" className="text-neutral-5">
        One of the benefits of rental is being able to try different products
        without the commitment of buying them. For that reason, we don&apos;t
        offer refunds for reasons other than a product being delivered in a
        broken/non-functional state, or in a condition that doesn&apos;t meet
        the standards of LoopCare.
      </Pgraph>
    ),
  },
]

export const PHL_FAQS: IFAQ[] = [
  {
    question: 'Why use Loop in Philadelphia?',
    answer: (
      <>
        <Pgraph variant="p-14" className="text-neutral-5">
          Loop is a great way to try the best gear & toys for your little ones.
          We deliver your items sanitized, packaging-free and fully assembled.
          Enjoy them as long as you like, then we&apos;ll pick them up when
          you&apos;re done. It&apos;s that simple. Our Golden Rule: we only
          carry items that we would be excited to use with our own families.
          Some items are brand new and others have been gently used. Every Loop
          item is in excellent shape, and is deep-cleaned, sanitized and quality
          checked before arriving at your home.
        </Pgraph>
      </>
    ),
  },
  {
    question: 'How much does it cost to rent with Loop in Philadelphia? ',
    answer: (
      <>
        <Pgraph variant="p-14" className="mb-4 text-neutral-5">
          Our prices vary based on the product, and we offer substantial savings
          as compared to the retail price. We encourage you to{' '}
          <Link href="https://www.loop.baby/products/">browse our site</Link>
          to get a better idea! We charge a one-time delivery fee which includes
          at home delivery, set up, and pick up when you&apos;re done.
        </Pgraph>
        <Pgraph variant="p-14" className="mb-4 text-neutral-5">
          For those who want to rent multiple times and/or are looking for a
          discount, our membership does offer a savings of 30% or more on item
          rental fees, plus unlimited deliveries/pickups (saving you $50 per
          order!) and insurance coverage for minor damage. Joining as a member
          is our most popular way to rent, especially with first-time expecting
          parents who get the confidence and flexibility they need and the value
          they deserve.
        </Pgraph>
      </>
    ),
  },
  {
    question: 'What if I damage an item during use?',
    answer: (
      <>
        <Pgraph variant="p-14" className="text-neutral-5">
          Normal wear and tear is completely expected and our LoopCare team
          ensures every returned item is up to standards before sharing with the
          next family.
        </Pgraph>
        <Pgraph variant="p-14" className="mt-4 text-neutral-5">
          Membership includes insurance coverage. For any damage that is larger
          and/or impacts the ability to use the product we have a “don&apos;t
          sweat the small stuff” policy for our members. We won&apos;t charge
          members for any damage that is repairable, or for any damage that
          requires replacement of an item and/or parts that retail for $25 or
          less. For higher-valued items, or if abuse is suspected, we reserve
          the right to charge up to 50% of the item&apos;s retail value.
        </Pgraph>
      </>
    ),
  },
  {
    question: 'Can I rent toys in Philadelphia? ',
    answer: (
      <>
        <Pgraph variant="p-14" className="text-neutral-5">
          Definitely! We have a lot of{' '}
          <Link href="https://www.loop.baby/products/?categories=45&sortByParams=topProducts&place=NYC">
            fun, safe toys
          </Link>{' '}
          to help your little ones play. We evaluate products based on four
          criteria:
        </Pgraph>
        <ul>
          <li>
            Quality: we select products that are strong, durable, well-designed,
            and effectively foster learning and development.
          </li>
          <li>
            Safety: we make sure that products meet all relevant safety
            standards, and do not offer products if we feel we cannot guarantee
            their safety, such as car seats or helmets.
          </li>
          <li>
            Sustainability: In addition to selecting durable products that can
            be safely reused, we also favor products that are made from natural,
            non-toxic materials and sustainability-focused brands when possible.
          </li>
          <li>
            Value: We select the best, most valuable baby products and make them
            accessible and affordable, so you can care for your family without
            compromise.
          </li>
        </ul>
      </>
    ),
  },
  {
    question: 'How are items cleaned?',
    answer: (
      <>
        <Pgraph variant="p-14" className="text-neutral-5">
          Our{' '}
          <span className="underline text-hyperLink">
            <Link href="https://snoop-images.s3.us-west-2.amazonaws.com/Loop+Care.pdf">
              LoopCare
            </Link>
          </span>{' '}
          safety and cleaning protocol begins with a rigorous quality assurance
          inspection and documentation of each item between every rental to
          ensure it functions safely and per the manufacturers specifications.
          Items that do not meet these specifications are pulled from inventory
          and repaired or replaced as needed. Items are cleaned per the
          manufacturer&apos;s specifications between every rental. We use a
          variety of cleaning products and materials, listed below, that are
          child safe, eco-friendly and less harmful to the environment. Products
          used by Loop for cleaning include:
          <ul className="list-inside list-disc mt-3">
            <li className="underline text-hyperLink">
              <Link href="https://www.forceofnatureclean.com/">
                Force of Nature
              </Link>
            </li>
            <li>UVC Sanitizing Wand</li>
            <li className="underline text-hyperLink">
              <Link href="https://www.seventhgeneration.com/dish-liquid-free-clear">
                Seventh Generation Dish Soap
              </Link>
            </li>
            <li className="underline text-hyperLink">
              <Link href="https://www.seventhgeneration.com/disinfecting-wipes">
                Seventh Generation Disinfecting Wipes
              </Link>
            </li>
            <li>Warm Water</li>
            <li>Distilled White Vinegar</li>
            <li>Electrolux 450 G Force Washer & Dryer (Cold Water)</li>
            <li className="underline text-hyperLink">
              <Link href="https://www.eonmist.com/">eOn Sanitizing Mist</Link>
            </li>
            <li className="underline text-hyperLink">
              <Link href="https://nelliesclean.com/shop/laundry-soda/?attribute_size-loads=300+%28tin%29">
                Nellie&apos;s All Natural Unscented Powder Detergent
              </Link>
            </li>
            <li className="underline text-hyperLink">
              <Link href="https://nelliesclean.com/shop/wool-dryerballs/">
                Nellie&apos;s Eco-Friendly Unscented Dryer Balls
              </Link>
            </li>
            <li>Air Dried and Machine Medium Dried</li>
          </ul>
        </Pgraph>
      </>
    ),
  },
  {
    question: 'What if I want to buy out my items?',
    answer: (
      <>
        <Pgraph variant="p-14" className="text-neutral-5">
          We offer the ability to purchase items through our{' '}
          <a href="https://loop-warehouse.mybigcommerce.com" target="_blank">
            For Keeps
          </a>{' '}
          site and occasionally offer members the ability to purchase items they
          have at home. You can <Link href={URLS.CONTACT_US}>contact us</Link>{' '}
          at team@loop.baby with any questions or to see if items you have at
          home are available for purchase.
        </Pgraph>{' '}
      </>
    ),
  },
]
