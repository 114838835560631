import Image from 'next/image'
import { useRouter } from 'next/router'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { URLS } from '../../config'
import {
  useAuthDispatch,
  useAuthStore,
  useCartDispatch,
  useCartStore,
  useHooks,
} from '../../store'
import Link from 'next/link'
import { AUTH_ACTION, CART_ACTION } from '../../interfaces'
import { useEffect } from 'react'
import { Pgraph } from '../Pgrah'
import { SearchModal } from '../SearchModal'
import { ZipModal } from '../ZipModal'
import { InputText, SearchRegistryModal } from '..'
import { INavigation, MenuProps } from '../../interfaces/navigation.interfaces'
import { MobileMenu } from './mobileMenu'
import { DesktopMenu } from './desktopMenu'
import { trackEvent } from '../../config/analytics'

interface NavbarProps {
  menuJson: INavigation
}

/** General Navigation Bar */
export const Navbar = ({ menuJson }: NavbarProps) => {
  const router = useRouter()
  const {
    user,
    locationFilter,
    validLocation,
    promptZip,
    zipModalOpen,
    subscription,
  } = useAuthStore()
  const authDispatch = useAuthDispatch()
  const cartDispatch = useCartDispatch()
  const { cart, showSearchModal, registryCart, showRegistrySearch } =
    useCartStore()
  const { getCartData } = useHooks()

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [showZipServiced, setShowZipServiced] = useState(false)

  const canBlur = useRef(true)

  useEffect(() => {
    window.addEventListener('resize', function () {
      if (window.innerWidth > 1024) {
        setMobileMenuOpen(false)
      }
    })
  }, [])

  const handleSearchClick = () => {
    if (!showSearchModal) {
      trackEvent('opened_search', {
        device: 'desktop',
        page: router.pathname,
      })
    }
    cartDispatch({
      type: CART_ACTION.SET_SHOW_SEARCH_MODAL,
      showSearchModal: !showSearchModal,
    })
  }

  const handleZipClick = () => {
    if (subscription?.activeItems.find((item) => item.status === 'active')) {
      router.push(`${URLS.ACCOUNT.INDEX}#current-rentals`)
      return
    }
    authDispatch({
      type: AUTH_ACTION.SET_ZIP_MODAL_OPEN,
      open: !zipModalOpen,
    })
    canBlur.current = true
  }

  const handleAccountClick = () => {
    router.push(URLS.ACCOUNT.INDEX)
  }

  const setReturnUrl = () => {
    authDispatch({
      type: AUTH_ACTION.SET_RETURN_URL,
      returnUrl: router.asPath,
    })
  }

  const handleCartClick = () => {
    cartDispatch({
      type: CART_ACTION.TOGGLE_CART,
    })
  }

  const handleMobileMenuClick = () => {
    setMobileMenuOpen(!mobileMenuOpen)
  }

  const closeZipPrompt = useCallback(() => {
    authDispatch({
      type: AUTH_ACTION.SET_PROMPT_ZIP,
      promptZip: false,
    })
  }, [authDispatch])

  const closeZipServiced = () => {
    setShowZipServiced(false)
  }

  const onCloseRegistrySearch = () => {
    cartDispatch({
      type: CART_ACTION.SET_SHOW_REGISTRY_SEARCH,
      open: !showRegistrySearch,
    })
  }

  const isRegistry = useMemo(() => {
    if (router.query.regId === user?.registryId) return false

    return (
      router.pathname === '/registry/[regId]' ||
      router.pathname.includes('guest')
    )
  }, [router.pathname, router.query.regId, user?.registryId])

  const { lines } = useMemo(
    () => (isRegistry ? getCartData({ registryCart }) : getCartData({ cart })),
    [isRegistry, getCartData, registryCart, cart]
  )

  const itemsCount = lines.length

  useEffect(() => {
    window.onscroll = () => {
      closeZipPrompt()
      window.onscroll = null
    }
  }, [closeZipPrompt])

  const deliveryAreaLabel: string = useMemo(() => {
    if (subscription?.activeItems.find((item) => item.status === 'active')) {
      return 'Schedule swap or return'
    }
    if (validLocation) {
      switch (locationFilter?.location) {
        case 'SFO': {
          return 'Bay Area'
        }
        case 'NYC': {
          return 'Tri-State Area'
        }
        case 'PHL': {
          return 'Greater Philly'
        }
      }
    }
    return 'Check delivery area'
  }, [subscription?.activeItems, validLocation, locationFilter?.location])

  const zipSuccessClose = () => {
    authDispatch({
      type: AUTH_ACTION.SET_ZIP_MODAL_OPEN,
      open: false,
    })
    setShowZipServiced(true)
    setTimeout(() => {
      setShowZipServiced(false)
    }, 1600)
  }

  const isCheckoutRoute = router.pathname.includes('checkout')

  const [showBanner, setShowBanner] = useState(true);
  const isHomePageOrMembership = router.pathname === '/' || router.pathname === '/membership';


  return (
  <div className="w-full sticky top-0 z-40 bg-white">
    <nav className="flex justify-between bg-heavy-cream h-52px md:h-[78px] z-30 px-1 md:px-3 xl:px-[3%]">
      <div className="flex flex-0 self-center items-center" data-cy="NAVBAR_HOME_LINK">
        <div className="flex items-center lg:hidden cursor-pointer mr-3" data-cy="NAVBAR_MOBILE_MENU" onClick={handleMobileMenuClick}>
          <div className="w-[20px] h-[20px] relative">
            <Image src="/images/menu.svg" alt="Mobile menu" style={{ cursor: 'pointer' }} width={100} height={100} />
          </div>
        </div>
        <div className="block h-3 md:h-18px w-60px md:w-69px relative mr-4">
          <Link href={URLS.INDEX} passHref>
            <div className="w-full h-full relative">
              <Image src="/images/loop-logo.svg" alt="Loop.baby" sizes="20vw" style={{ cursor: 'pointer ' }} priority width={100} height={100} />
            </div>
          </Link>
        </div>
      </div>

      {/* Search Bar */}
      {!isCheckoutRoute && (
        <div className="hidden md:flex ml-auto flex-1 items-center max-w-md 2xl:max-w-xl">
          <div className="items-center mr-0 relative w-[97%] lg:w-full text-center cursor-pointer" onClick={handleSearchClick}>
            <i className="loop-icon-search text-[#4A5456] text-23px lg:text-28px absolute z-10 top-[10px] left-[12px]" data-cy="NAVBAR_SEARCH_ICON"></i>
            <InputText type="text" placeHolder="What can we help you find?" className="py-[12px] pl-[52px] rounded-3xl w-[100%] text-[#4A5456] xs:placeholder:text-[13px]  placeholder:text-[#4A5456] font-circular" />
          </div>
        </div>
      )}

      {/* Right menu - sign in, cart */}
      <div className="flex items-center justify-end lg:justify-center ml-auto 2xl:ml-auto">
        <div className="flex items-center cursor-pointer mr-0 md:mr-2 justify-end">
          {!isCheckoutRoute && (
            <div className="flex items-center">
              <div className="text-snow-white font-circular-medium font-medium bg-[#E85044] rounded-full cursor-pointer py-2 px-[10px] md:py-[14px] md:px-32px text-14px" onClick={handleZipClick}>
                {deliveryAreaLabel}
              </div>
              {promptZip && (
                <div className="absolute top-[65px] z-[1] p-4 pr-5 bg-snow-white rounded w-[210px] cursor-default shadow-zipReminder">
                  <div className="relative">
                    <div className="absolute -top-[30px]">
                      <div className="block rotate-45 bg-snow-white rounded w-[18px] h-[18px] border-t border-l border-heavy-cream"></div>
                    </div>
                    <div className="absolute left-[155px] -top-3">
                      <i className="loop-icon-times text-24px cursor-pointer" onClick={closeZipPrompt}></i>
                    </div>
                  </div>
                  <Pgraph variant="p-14">Add your zip to see items in your location</Pgraph>
                </div>
              )}
              {showZipServiced && (
                <div className="absolute top-[65px] z-50 p-4 pr-5 bg-[#DBF1DB] rounded w-[210px] cursor-default shadow-zipReminder">
                  <div className="relative">
                    <div className="absolute -top-[30px]">
                      <div className="block rotate-45 bg-[#DBF1DB] rounded w-[18px] h-[18px] border-t border-l border-heavy-cream"></div>
                    </div>
                    <div className="absolute left-[155px] -top-3">
                      <i className="loop-icon-times text-24px cursor-pointer" onClick={closeZipServiced}></i>
                    </div>
                  </div>
                  <Pgraph variant="p-14">Great news, we deliver to you!</Pgraph>
                </div>
              )}
            </div>
          )}
        </div>
        {!user && (
          <Link href={URLS.LOGIN.INDEX} passHref>
            <div className="items-center cursor-pointer mr-2 flex pl-2" onClick={setReturnUrl}>
              <div className="flex items-center">
                <i className="loop-icon-account text-neutral-7 text-23px lg:text-28px relative" data-cy="NAVBAR_SIGN_IN_LINK"></i>
              </div>
            </div>
          </Link>
        )}
        {user && (
          <Link href={URLS.ACCOUNT.INDEX} passHref>
            <div className="items-center cursor-pointer mr-2 flex pl-3" onClick={handleAccountClick}>
              <div>
                <i className="loop-icon-account text-neutral-7 text-23px lg:text-28px relative top-[2px]" data-cy="NAVBAR_SIGN_IN_LINK"></i>
              </div>
            </div>
          </Link>
        )}
        <div className="flex items-center cursor-pointer" onClick={handleCartClick} data-cy="NAVBAR_CART_LINK">
          <div className="flex">
            <i className="loop-icon-cart text-neutral-7 text-23px lg:text-28px relative"></i>
            <div className="relative">
              {!!itemsCount && (
                <div className="absolute -top-[1px] text-[10px] -right-[5px] bg-poppy w-3 h-3 text-center text-snow-white rounded-full border-snow-white border border-solid">
                  {itemsCount}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      <MobileMenu mobileMenuOpen={mobileMenuOpen} onClose={() => setMobileMenuOpen(false)} menuJson={menuJson} />

      <SearchModal open={showSearchModal} onClose={handleSearchClick} />
      <SearchRegistryModal open={showRegistrySearch} onClose={onCloseRegistrySearch} />
      <ZipModal onClose={handleZipClick} onCloseSuccess={zipSuccessClose} />
    </nav>

    {/* Desktop Menu */}
    <DesktopMenu menuJson={menuJson} />
  </div>
  )
}
