export enum SOCIAL_MEDIA {
  GOOGLE = 'google-oauth2',
  FACEBOOK = 'facebook',
  APPLE = 'apple',
}

export enum PRODUCT_CUSTOM_FIELDS {
  SUMMARY = 'Summary',
  AVG_RENTAL_PERIOD = 'Default Rental Period',
  MIN_RENTAL_PERIOD = 'Minimum Rental Period',
  MAX_RENTAL_PERIOD = 'Maximum Rental Period',
  RECOMMENDED_AGE = 'Recommended Age',
  SHOPIFY_PRODUCT_ID = 'Shopify Product Id',
  AVAILABLE_IN = 'Available In',
  PDP_PRICE_SUFFIX = 'PDP Price Suffix',
  CUSTOM_SAVINGS = 'Custom Savings',
  CUSTOM_PRICE_LABEL = 'Custom Price Label',
  NON_MEMBER_PRICE = 'Non Member Price',
}

export enum LOCAL_STORAGE {
  CREDENTIALS = 'loop_act',
  ZIP_CODE = 'loop_zip_locator',
  CART = 'loop_cart',
  ANONYMOUS_CART = 'loop_a_cart',
  WHO_AM_I = 'loop_wai',
  SAVINGS = 'loop_savings',
  SCROLLYPOS = 'scrollYPos',
  PERSIST_SCROLLYPOS = 'persist_scrollYPos',
  REGISTRY_CART = 'loop_registry_cart',
  REFRESH_CART_COUNT = 'refresh_cart_count',
  DELIVERY_OPTIONS = 'loop_delivery_options',
  META_PRODUCTS = 'loop_meta_products',
  SUBSCRIPTION = 'loop_subscription',
  BASIC_FILTERS = 'loop_filters_basic',
  FULL_FILTERS = 'loop_filters_full',
  APP_VERSION = 'loop_app_version',
  AFFILIATE = 'loop_aff',
  RECENTLY_VIEWED = 'recent_products',
}

export enum CONTACT {
  CONSULTATION = 'https://calendly.com/registry-consultations/loop-consultations',
}

export enum AUTH0 {
  alreadyUsedPasswordLink = 'This URL can be used only once',
}

export enum ERRORS {
  alreadyHasAnAccount = 'Looks like you already have an account.',
}

export enum recurringPeriod {
  YEARLY = 'YEARLY',
  MONTHLY = 'MONTHLY',
}

export enum ANUAL_REDEMTION_STAGE {
  ADDRESS_PENDING = 'ADDRESS_PENDING',
  CREDIT_ADDED = 'CREDIT_ADDED',
  MEMBERSHIP_CREATED = 'MEMBERSHIP_CREATED',
  UPGRADE_TO_ANNUAL = 'UPGRADE_TO_ANNUAL',
  INVALID_CODE = 'INVALID_CODE',
}

export enum PERFORMACE_TRACKING {
  CHECKOUT_ADDRESS_STEP = 'CK_Address_Start_End',
  CHECKOUT_PAYMENT_STEP = 'CK_Payment_Start_End',
  CHECKOUT_FETCH_CHECKOUT = 'CK_Fetch_Checkout_Start_End',
  CHECKOUT_COMPLETE_STEP = 'CK_Final_Start_End',
  CART_QUANTITY_CHANGE_ITEM = 'CART_Quantity_Item_Start_End',
  CART_REMOVE_ITEM = 'CART_Remove_Item_Start_End',
  CART_FETCH_CART = 'CART_Fetch_Cart_Start_End',
  CHECKOUT_GUEST_PAYMENT_STEP = 'CK_Guest_Payment_Start_End',
  CHECKOUT_GUEST_COMPLETE_STEP = 'CK_Guest_Final_Start_End',
}
