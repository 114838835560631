// Reflects page/file structure inside the 'pages' folder

import RenderResult from 'next/dist/server/render-result'

export const URLS = {
  INDEX: '/',
  REGISTRY: '/registry',
  REGISTRY_LANDING: '/loop-registry',
  PHILLY_LANDING: '/philadelphia',
  MEMBERSHIP_LANDING: '/membership',
  HLW: '/how-loop-works',
  PRIVACY_POLICY: '/privacy-policy',
  TERMS_OF_SERVICE: '/terms-of-service',
  RENTAL_AGREEMENT: '/rental-agreement',
  FAQ: '/faq',
  CONTACT_US: '/contact-us',
  CAREERS: '/careers',
  ABOUT_US: '/about-us',
  STROLLER_SHOWCASE: '/stroller-showcase',
  PRODUCTS: {
    INDEX: '/products',
  },
  LOGIN: {
    INDEX: '/login',
    EMAIL: '/login/email',
    RESET_PASSWORD: '/login/reset-password',
  },
  SIGNUP: {
    INDEX: '/signup',
    EMAIL: '/signup/email',
  },
  ACCOUNT: {
    INDEX: '/account',
    UPDATE_ACCOUNT: '/account/update-account',
    DELIVERY_ADDRESS: '/account/delivery-address',
    CHANGE_PASSWORD: '/account/change-password',
    RETURN_ITEMS: '/account/return-items',
    MANAGE_MEMBERSHIP: '/account/manage-membership',
    UPDATE_PAYMENT: '/account/update-payment',
    REDEEM_GIFTCARD: '/account/redeem-giftcard',
  },
  MEMBERSHIP: '/membership',
  CHECKOUT: {
    INDEX: '/checkout',
    COMPLETED: '/checkout/completed',
    GUEST: '/checkout/guest',
    GUEST_COMPLETED: '/checkout/guest/completed',
    GIFT_CARD: '/checkout/giftcard',
    GIFT_CARD_COMPLETED: '/checkout/giftcard/completed',
  },
  BLOG: {
    INDEX: '/blog',
    CATEGORIES: '/blog/category',
    POSTS: '/blog/post',
  },
  GIFTS: '/gifting',
  REDEEM: '/redeem-membership/',
}

export const PROTECTED_URLS: string[] = [
  URLS.ACCOUNT.CHANGE_PASSWORD,
  URLS.ACCOUNT.DELIVERY_ADDRESS,
  URLS.ACCOUNT.INDEX,
  URLS.ACCOUNT.MANAGE_MEMBERSHIP,
  URLS.ACCOUNT.RETURN_ITEMS,
  URLS.ACCOUNT.UPDATE_ACCOUNT,
  URLS.ACCOUNT.UPDATE_PAYMENT,
  URLS.REDEEM,
]
