import { useRouter } from 'next/router'
import { Button } from '../Button'
import { Headline } from '../Headline'
import Image from 'next/image'
import { URLS } from '../../config'

export function HomePageGiftcards() {
  const router = useRouter()

  const shopNow = () => {
    router.push(URLS.GIFTS)
  }

  return (
    <div className="bg-heavy-cream w-full relative flex flex-col items-center py-7">
      <Image
        src="/images/snowflake.svg"
        alt="Snow flake"
        height={123}
        width={114}
        className="absolute hidden md:block top-[176px] left-[-10px]"
      />
      <Image
        src="/images/snowflake.svg"
        alt="Snow flake"
        height={155}
        width={148}
        className="absolute bottom-[5px] md:bottom-[95px] right-[-80px] md:right-[-20px] rotate-[-14deg]"
      />
      <Image
        src="/images/snowflake.svg"
        alt="Snow flake"
        height={48}
        width={40}
        className="absolute top-[140px] md:top-[195px] right-[10px] md:right-[100px]"
      />
      <Image
        src="/images/snowflake.svg"
        alt="Snow flake"
        height={78}
        width={70}
        className="absolute hidden md:block bottom-[10px] left-[200px] rotate-[50deg]"
      />
      <Image
        src="/images/snowflake.svg"
        alt="Snow flake"
        height={58}
        width={50}
        className="absolute top-[10px] right-[120px] md:right-[400px] rotate-[50deg]"
      />

      <div className="text-center text-32px md:text-42px font-recoleta">
        Give the Gift of Loop this Holiday!
      </div>
      <div className="flex justify-center items-center gap-6 mt-6 md:flex-row flex-col ">
        <div>
          <Image
            src="/images/gifting/giftcard-generic.png"
            alt="Gift card"
            width={369}
            height={216}
          />
        </div>
        <div className="text-center">
          <div className="text-poppy text-20px md:text-24px font-circular">
            Save 15% - 25% on Loop Gift Cards
          </div>
          <div>
            <ul>
              <li className="flex items-center my-[20px]">
                <Image
                  src="/images/heart.svg"
                  width={27}
                  height={27}
                  alt="Heart"
                  className="mr-4"
                />
                The perfect way to share your love of Loop
              </li>
              <li className="flex items-center my-[20px]">
                <Image
                  src="/images/heart.svg"
                  width={27}
                  height={27}
                  alt="Heart"
                  className="mr-4"
                />
                Good for friends or for you and your family
              </li>
              <li className="flex items-center my-[20px]">
                <Image
                  src="/images/heart.svg"
                  width={27}
                  height={27}
                  alt="Heart"
                  className="mr-4"
                />
                Can be applied to anything
              </li>
              <li className="flex items-center my-[20px]">
                <Image
                  src="/images/heart.svg"
                  width={27}
                  height={27}
                  alt="Heart"
                  className="mr-4"
                />
                Easy to buy, give and use
              </li>
              <li className="flex items-center my-[20px]">
                <Image
                  src="/images/heart.svg"
                  width={27}
                  height={27}
                  alt="Heart"
                  className="mr-4"
                />
                Parents save money & get exactly what they want!
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="mt-6">
        <Button
          label="Shop Now"
          className="w-[200px]"
          onClick={shopNow}
          primary={true}
        />
      </div>

      <div className="bg-poppy rounded-full hidden md:block w-4 h-4 top-[322px] left-[-10px] absolute" />
      <div className="bg-poppy rounded-full w-4 h-4 top-[20px] left-[44px] absolute" />
      <div className="bg-poppy rounded-full hidden md:block w-4 h-4 bottom-[23px] left-[91px] absolute" />
      <div className="bg-peter-pan hidden md:block rounded-full w-6 h-6 top-[61px] left-[110px] absolute" />
      <div className="bg-peter-pan rounded-full w-5 h-5 bottom-[134px] left-[40px] absolute" />

      <div className="bg-poppy rounded-full hidden md:block w-4 h-4 top-[5px] right-[268px] absolute" />
      <div className="bg-poppy rounded-full w-4 h-4 top-[29px] right-[36px] absolute" />
      <div className="bg-poppy rounded-full w-4 h-4 bottom-[18px] right-[100px] absolute" />
      <div className="bg-peter-pan rounded-full hidden md:block w-5 h-5 top-[108px] right-[83px] absolute" />
      <div className="bg-peter-pan rounded-full hidden md:block w-5 h-5 top-[313px] right-[-20px] absolute" />
    </div>
  )
}
