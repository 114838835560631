import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import Image from 'next/image'
import { Pgraph } from '../../Pgrah'
import { Headline } from '../../Headline'
import { REASONS_TO_LOVE_LOOP } from '../../../config'
import { useState } from 'react'

const PrevArrow = (clickHandler: () => void, hasPrev: boolean) => {
  return (
    <button
      className=" bottom-0 absolute z-[2] p-1 left-4 opacity-100"
      onClick={clickHandler}
    >
      <i
        className={`loop-icon-arrow-long cursor-pointer rotate-180 text-24px text-black transition ease-in duration-400 inline-block`}
      ></i>
    </button>
  )
}

const NextArrow = (
  clickHandler: () => void,
  hasNext: boolean,
  label: string
) => {
  return (
    <button
      className="bottom-0 absolute z-[2] p-1 right-4 opacity-100"
      onClick={clickHandler}
    >
      <i
        className={`loop-icon-arrow-long cursor-pointer text-24px text-black transition ease-in duration-400 inline-block`}
      ></i>
    </button>
  )
}

const Indicator = (
  clickHandler: (
    e: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>
  ) => void,
  isSelected: boolean
) => {
  const styles = isSelected
    ? 'inline-block cursor-pointer mx-[6px] w-2 h-2 rounded-full bg-neutral-5'
    : 'inline-block cursor-pointer mx-[6px] w-2 h-2 rounded-full border border-neutral-4'
  return <div className={styles} onClick={clickHandler}></div>
}

export default function ReasonToLoveLoopMobile() {
  const [selectedIndex, setSelectedIndex] = useState<number>(0)

  return (
    <div>
      <div className="px-4">
        {REASONS_TO_LOVE_LOOP.map((reason, index) => {
          const imageClasses = index === selectedIndex ? 'block' : 'hidden'
          return (
            <div
              className={[imageClasses].join(' ')}
              key={`image-${reason.iconSrc}`}
            >
              <Image
                alt={reason.headline}
                src={reason.imageSrc}
                width={1941}
                height={2275}
              />
            </div>
          )
        })}

        <Pgraph variant="l-14" className="text-center mt-4">
          A MILLION REASONS TO LOVE LOOP <br /> (HERE ARE FOUR)
        </Pgraph>
      </div>

      <Carousel
        autoPlay={false}
        interval={5000}
        showThumbs={false}
        showStatus={false}
        showArrows={true}
        renderArrowNext={NextArrow}
        renderArrowPrev={PrevArrow}
        renderIndicator={Indicator}
        onChange={(item) => {
          setSelectedIndex(item)
        }}
      >
        {REASONS_TO_LOVE_LOOP?.map((reason) => {
          const imageClass = `w-[${reason.iconWidth}px]`
          return (
            <div key={reason.iconSrc} className="pb-5">
              <div className="mt-1 flex items-center justify-center min-h-[100px]">
                <div className={[imageClass, 'mx-auto '].join(' ')}>
                  <Image
                    alt={reason.headline}
                    src={reason.iconSrc}
                    width={reason.iconWidth}
                    height={reason.iconHeight}
                    className={imageClass}
                  />
                </div>
              </div>

              <Headline
                variant="recoleta-s"
                className="text-center text-neutral-6 mb-3 px-3"
              >
                {reason.headline}
              </Headline>

              <Pgraph variant="p-16" className="text-neutral-6 w-[70%] mx-auto">
                {reason.text}
              </Pgraph>
            </div>
          )
        })}
      </Carousel>
    </div>
  )
}
