import { useRouter } from 'next/router'
import { IRentalItem, ISubscription } from '../../interfaces'
import { Button } from '../Button'
import { Headline } from '../Headline'
import { Loading } from '../Loading'
import { Pgraph } from '../Pgrah'
import { CONTACT } from '../../config'

interface IConsultationProps {
  rentedItems?: IRentalItem[]
}

export const Consultation = ({ rentedItems }: IConsultationProps) => {
  const router = useRouter()

  const handleScheduleConsultationClick = async () => {
    window.open(CONTACT.CONSULTATION, '_ blank')
  }

  return rentedItems?.length! <= 2 ? (
    <div className="bg-snow-white">
      <div className="max-w-7xl mx-auto justify-between">
        <div className="md:flex bg-snow-white pt-[32px] md:px-0 md:pt-0">
          <div className="m-auto md:w-1/2">
            <img src="/images/stock/consultation.jpg" alt="Product Image"></img>
          </div>
          <div className="p-4 text-center md:w-1/2 m-auto lg:px-[150px]">
            <Headline
              variant="recoleta-s"
              className="text-24px leading-29 md:text-24px md:leading-29 lg:text-32px lg:leading-38"
            >
              Schedule a free consultation
            </Headline>
            <Pgraph
              variant="p-16"
              className="mt-[12px] font-normal text-14px leading-18 md:text-14px md:leading-18 lg:text-16px lg:leading-22 text-neutral-7 font-circular"
            >
              Our experts will help you find the best baby gear for your growing
              family.
            </Pgraph>
            <Button
              className="mt-[32px]"
              primary={true}
              size="medium"
              label="Schedule Consultation"
              onClick={handleScheduleConsultationClick}
            />
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div></div>
  )
}
