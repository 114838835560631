import React, {
  Fragment,
  useEffect,
  useRef,
  useState,
  useCallback,
  ReactNode,
} from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { LOCAL_STORAGE, PERFORMACE_TRACKING, REG_EX, URLS } from '../../config'
import {
  Headline,
  InputText,
  Pgraph,
  Button,
  OrderSummary,
  Loading,
  ShareSaleTracking,
  GTMConvertionTracking,
  ScriptJustUnoConvertion,
  ORDER_SUMMARY_TYPE,
  ScriptNextDoorConvertion,
} from '../'
import {
  checkActiveMembership,
  getLocalAffiliate,
  removeLocalStorage,
  useAuthDispatch,
  useAuthStore,
  useCartDispatch,
  useCartStore,
  useHooks,
} from '../../store'
import {
  AccountsApi,
  AuthApi,
  CartApi,
  PaymentApi,
  SubscriptionsApi,
} from '../../services'
import { useRouter } from 'next/router'
import {
  AUTH_ACTION,
  CART_ACTION,
  CHECKOUT_STEPS,
  ICart,
  ICreditConsumption,
} from '../../interfaces'
import { trackEvent } from '../../config/analytics'
import { DateTime } from 'luxon'
import { ICustomerInfo } from '../../interfaces/customer.interface'
import getStripe from '../../services/stripe'

interface ChildForm {
  childBirthdays: {
    name: string
    birthdayDate: string
  }[]
}

function CheckoutCompleted() {
  const { credentials, user, subscription, status } = useAuthStore()
  const { getCartData, reportBug, reportBreadcrumb } = useHooks()
  const {
    control,
    handleSubmit,
    register,
    setValue,
    formState: { errors },
    watch,
  } = useForm<ChildForm>({
    defaultValues: {
      childBirthdays: user?.account.childBirthdays?.length
        ? [
            ...user.account.childBirthdays.map((child) => ({
              name: child.name,
              birthdayDate: child.birthdayDate.day
                ? DateTime.fromObject({
                    day: child.birthdayDate.day,
                    month: child.birthdayDate.month,
                    year: child.birthdayDate.year,
                  }).toFormat('MM/dd/yyyy')
                : '',
            })),
          ]
        : [{ birthdayDate: '', name: '' }],
    },
  })
  const { fields, append, remove } = useFieldArray({
    name: 'childBirthdays',
    control,
  })

  const dispatch = useCartDispatch()
  const authDispatch = useAuthDispatch()
  const [processing, setProcessing] = useState(true)
  const [checkedCart, setCheckedCart] = useState<ICart>()
  const [checkedCredits, setCheckedCredits] = useState<ICreditConsumption>()
  const [error, setError] = useState<string>()
  const [orderSummaryOpen, setOrderSummaryOpen] = useState(true)
  const router = useRouter()
  const checkoutSent = useRef(false)
  const [childrenUpdated, setChildrenUpdated] = useState<string>()
  const [savingChildren, setSavingChildren] = useState(false)
  const [invalidItemsCheckout, setInvalidItemsCheckout] = useState<ReactNode>()
  const [bigCOrderNumber, setBigCOrderNumber] = useState<number>(0)
  const timer = useRef<number>()

  const { deliveryOption } = useCartStore()

  useEffect(() => {
    if (status === 'initial' || status === 'authenticated' || timer.current)
      return
    timer.current = window.setTimeout(() => {
      if (checkoutSent.current) return
      setProcessing(false)
      setError("Looks like you're not logged in. Please log in and try again.")
      checkoutSent.current = false
      reportBug(new Error('Checkout Completed Credentials Timeout'), {
        user,
        credentials,
        checkoutSent: checkoutSent.current,
      })
    }, 20000)
  }, [credentials, reportBug, user, status])

  const completeOrder = useCallback(async () => {
    if (!router.isReady) return
    if (checkoutSent.current) return

    let checkoutInfo = null
    if (!user || !credentials?.accessToken) return

    checkoutSent.current = true

    if (timer.current) {
      clearTimeout(timer.current)
    }

    reportBreadcrumb('Checkout fetch started', { user }, 'navigation')
    setProcessing(true)
    setInvalidItemsCheckout(undefined)
    setError(undefined)

    const { setup_intent_client_secret, setup_intent } = router.query

    if (setup_intent && setup_intent_client_secret) {
      const stripe = await getStripe()
      const intent = await stripe?.retrieveSetupIntent(
        setup_intent_client_secret as string
      )
      if (intent?.setupIntent?.payment_method) {
        await PaymentApi.setDefaultPayment(
          credentials.accessToken,
          intent.setupIntent.payment_method as string
        )
      }
    }

    try {
      if (!deliveryOption) {
        throw new Error('No delivery options found')
      }

      const trackingPerformanceObj = {
        startTime: new Date().getTime(),
        endTime: 0,
        status: false,
      }

      const getCheckout = (checkoutInfo = await CartApi.fetchCheckout(
        credentials.accessToken
      ))

      trackingPerformanceObj.endTime = new Date().getTime()
      trackingPerformanceObj.status = !!getCheckout
      trackEvent(
        PERFORMACE_TRACKING.CHECKOUT_FETCH_CHECKOUT,
        trackingPerformanceObj
      )

      reportBreadcrumb('Checkout fetch recieved', { getCheckout }, 'request')

      if (!getCheckout) throw new Error('Fetch checkout error (api error)')

      // Product not available message block
      if (getCheckout.invalidItems && getCheckout.invalidItems.length) {
        trackEvent('PRODUCT_NOT_AVAILABLE_IN_AREA', {
          errors: getCheckout.errors,
          invalidItems: getCheckout.invalidItems,
        })

        let start = 'The '
        let situation = "isn't"
        let reference = 'it'
        if (getCheckout.invalidItems.length > 1) {
          start = ''
          situation = "aren't"
          reference = 'them'
        }

        const products = getCheckout.invalidItems
          .map((prod) => prod.name)
          .join(', ')

        const errMessage = `Uh oh! ${start}${products} ${situation} available for delivery to your address. Please remove ${reference} from your cart before placing your order. `
        const fmtErrMsg: ReactNode = (
          <>
            Uh oh! {start}
            <span className="font-circular-medium">{products}</span> {situation}{' '}
            available for delivery to your address. Please remove {reference}{' '}
            from your cart before placing your order.
          </>
        )

        setInvalidItemsCheckout(fmtErrMsg)
        checkoutSent.current = false
        setError(errMessage)
        return
      }

      if (getCheckout.errors && getCheckout.errors.length) {
        reportBreadcrumb('Errored checkout', getCheckout, 'error')
        throw new Error(
          getCheckout.errors.map((e: any) => e.message).join('; ') ||
            'Checkout fetch failed'
        )
      }

      const { membershipInCart } = getCartData({
        cart: {
          ...getCheckout.checkout.cart,
          queuedCart: [],
        },
      })

      setCheckedCart({
        apiCart: getCheckout.checkout.cart,
        queuedCart: [],
      })
      setCheckedCredits(getCheckout.creditConsumption)

      reportBreadcrumb('Checkout post started', undefined, 'state')
      const trackingPerformanceCKObj = {
        startTime: new Date().getTime(),
        endTime: 0,
        status: false,
      }

      const cartApi = await CartApi.completeCheckout(
        credentials.accessToken
        // checkoutNotes
      )

      reportBreadcrumb('Checkout response received', { cartApi }, 'request')

      trackingPerformanceCKObj.endTime = new Date().getTime()
      trackingPerformanceCKObj.status = !!getCheckout
      trackEvent(
        PERFORMACE_TRACKING.CHECKOUT_COMPLETE_STEP,
        trackingPerformanceCKObj
      )

      if (cartApi.success) {
        reportBreadcrumb('Checkout post successfull', { cartApi }, 'request')
        trackEvent('Order Completed', {
          loopCustomerId: user?.customer?.loopCustomerId,
          checkout: getCheckout.checkout,
        })
        setBigCOrderNumber(cartApi.order.id || 0)
        setError(undefined)
        dispatch({
          type: CART_ACTION.RESET_CART,
        })
        removeLocalStorage([LOCAL_STORAGE.CART])
        removeLocalStorage([LOCAL_STORAGE.DELIVERY_OPTIONS])

        if (!subscription && membershipInCart) {
          authDispatch({
            type: AUTH_ACTION.SET_MEMBERSHIP,
            subscription: {
              activeItems: [],
              errors: [],
              membership: {
                bigCommerceProductId: 0,
                interval: 'pending',
                periodEnd: 0,
                periodStart: 0,
                quantity: 0,
                status: 'pending',
                stripePriceId: '0',
                stripeProductId: '0',
                stripeSubscriptionId: '0',
                stripeSubscriptionItemId: '0',
                unitAmount: 0,
              },
              pendingItems: [],
              success: true,
              upcomingInvoices: [],
              hasLegacySubscription: false,
            },
            subscriptionActive: true,
          })
        }

        setTimeout(async () => {
          const membership = await SubscriptionsApi.fetchSubscriptions(
            credentials.accessToken
          )
          if (membership) {
            const subscriptionActive = checkActiveMembership(membership)

            authDispatch({
              type: AUTH_ACTION.SET_MEMBERSHIP,
              subscription: membership,
              subscriptionActive,
            })
          }
        }, 60000)
      } else {
        reportBreadcrumb('Checkout post failed', { cartApi }, 'error')
        if (cartApi.errors?.length) {
          const paymentError = cartApi.errors.find(
            (e: { message: string }) =>
              e.message.toLowerCase().indexOf('declined') >= 0
          )
          if (paymentError) {
            throw new Error(
              paymentError.message ||
                'Sorry, your payment was declined. Please update your card.'
            )
          } else {
            const errors = cartApi.errors
              ?.map((e: { message: string }) => e.message)
              .join('. ')
            throw new Error(errors || 'Unknown errors')
          }
        } else {
          throw new Error('Error checking out')
        }
      }
    } catch (error) {
      checkoutSent.current = false
      setError(error instanceof Error ? error.message : 'Unknow error')
      reportBug(error, { checkedCart })
      trackEvent('checkout_completion_failed', {
        loopCustomerId: user?.customer?.loopCustomerId,
        checkout: checkoutInfo?.checkout,
      })
    } finally {
      setProcessing(false)
    }
  }, [
    authDispatch,
    checkedCart,
    credentials?.accessToken,
    deliveryOption,
    dispatch,
    getCartData,
    reportBreadcrumb,
    reportBug,
    router.isReady,
    router.query,
    subscription,
    user,
  ])

  useEffect(() => {
    completeOrder()
  }, [completeOrder, user])

  const onSubmit = async (data: ChildForm) => {
    try {
      reportBreadcrumb('Checkout children form submitted', { data }, 'request')
      if (!credentials || !user) return
      setSavingChildren(true)
      const formattedChildBirthdays: ICustomerInfo['childBirthdays'] =
        data.childBirthdays.map((bd) => ({
          name: bd.name,
          birthdayDate: {
            day: bd.birthdayDate
              ? DateTime.fromFormat(
                  bd.birthdayDate,
                  'MM/dd/yyyy'
                ).day.toString()
              : '',
            month: bd.birthdayDate
              ? DateTime.fromFormat(
                  bd.birthdayDate,
                  'MM/dd/yyyy'
                ).month.toString()
              : '',
            year: bd.birthdayDate
              ? DateTime.fromFormat(
                  bd.birthdayDate,
                  'MM/dd/yyyy'
                ).year.toString()
              : '',
          },
        }))
      const childResponse = await AccountsApi.updateChildBirthdays(
        credentials.accessToken,
        user?.customer.loopCustomerId,
        formattedChildBirthdays
      )
      if (!childResponse) {
        throw new Error('Error updating children information')
      }
      setChildrenUpdated('Children birthdays saved successfully')
      const whoAmI = await AuthApi.whoAmI({
        accessToken: credentials.accessToken,
      })
      if (whoAmI) {
        authDispatch({
          type: AUTH_ACTION.SET_USER,
          user: whoAmI,
        })
      }
    } catch (error) {
      console.error(error)
      reportBug(error, { data })
      setChildrenUpdated('There was an error saving your children information')
    } finally {
      setSavingChildren(false)
    }
  }

  const childCount = watch('childBirthdays').length

  const tryCheckoutAgain = async () => {
    if (invalidItemsCheckout) {
      dispatch({
        type: CART_ACTION.TOGGLE_CART,
        showCart: true,
      })
      dispatch({
        type: CART_ACTION.FORCE_CHECKOUT_STEP,
        step: CHECKOUT_STEPS.BILLING_4,
      })
      router.push(URLS.INDEX)
    } else {
      completeOrder()
    }
  }

  const contactSupport = () => {
    router.push(URLS.CONTACT_US)
  }

  if (processing) {
    return (
      <div className="flex justify-center items-center flex-col w-full h-full min-h-fit -mt-[40%]">
        <div className="flex flex-col items-center">
          <Loading />
          <Pgraph variant="l-16" className="mt-4 text-center">
            {' '}
            We&apos;re processing your order... please wait.
            <br />
            <br />
            Do not close or refresh this tab.
          </Pgraph>
        </div>
      </div>
    )
  }

  if (error) {
    return (
      <div className="flex justify-center items-center flex-col w-full h-full">
        <div className="flex flex-col items-center -mt-[40%]">
          <Pgraph variant="l-18">Something went wrong</Pgraph>
          <Pgraph variant="p-14" className="my-4">
            There was a problem processing your order. Don&apos;t worry, you
            have not been charged.
          </Pgraph>
          <div className="flex justify-around w-full max-w-md">
            <Button
              label={invalidItemsCheckout ? 'View Cart' : 'Try Again'}
              onClick={tryCheckoutAgain}
              primary={true}
            />
            <Button
              label="Contact Support"
              onClick={contactSupport}
              primary={false}
            />
          </div>
          {error && (
            <div className="flex flex-col items-center mt-5">
              <div className="text-caution-2 font-circular leading-19 text-14px mb-4 text-center">
                Error: {invalidItemsCheckout ? invalidItemsCheckout : error}
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="flex justify-center  h-full bg-snow-white w-full">
        <div className="flex flex-col justify-center items-center w-full h-fit">
          <div className="flex flex-col justify-center items-center mx-3">
            <Headline variant="recoleta-l" className="mb-2">
              Order confirmed
            </Headline>
            <Pgraph variant="p-16" className="my-3">
              Thanks for your order! We&apos;ll be in touch within 2 days to
              schedule delivery.
            </Pgraph>

            {checkedCart && (
              <OrderSummary
                open={orderSummaryOpen}
                setOpen={setOrderSummaryOpen}
                summaryData={{
                  type: ORDER_SUMMARY_TYPE.REGULAR,
                  apiCart: checkedCart,
                  creditConsumption: checkedCredits,
                }}
              />
            )}
          </div>
          <div className="w-full bg-heavy-cream max-w-[492px]">
            <img
              src={'/images/checkout_completed.jpg'}
              width={492}
              height={347}
              alt="Checkout completed"
            />
            <div className="flex flex-col justify-center items-center mx-3 mb-4">
              <Headline variant="recoleta-m" className="my-3">
                Get new ideas
              </Headline>
              <Pgraph variant="p-14" className="my-3 text-center px-2">
                For gear &amp; toy recommendations tailored to your child&apos;s
                age &amp; stage, provide their age(s) below.
              </Pgraph>
              <form onSubmit={handleSubmit(onSubmit)}>
                {fields.map((field, index) => {
                  return (
                    <Fragment key={index}>
                      <div className="mt-4">
                        <Pgraph variant="l-14" className="text-neutral-6">
                          Child&apos;s first name
                        </Pgraph>
                        <InputText
                          type="text"
                          placeHolder="Child's first name"
                          {...register(`childBirthdays.${index}.name`)}
                          validationText={
                            errors.childBirthdays?.[index]?.name?.message
                          }
                          data-cy="SIGNUP_EMAIL_CHILD_NAME"
                          dataCyError="SINGUP_EMAIL_CHILD_NAME_ERROR"
                        />
                        <Pgraph variant="p-12" className="text-neutral-5 mt-1">
                          Optional
                        </Pgraph>
                      </div>
                      <div className="mt-4">
                        <Pgraph variant="l-14" className="text-neutral-6">
                          Child&apos;s birth date
                        </Pgraph>
                        <InputText
                          type="date"
                          placeHolder="MM/DD/YYYY"
                          {...register(`childBirthdays.${index}.birthdayDate`, {
                            validate: {
                              format: (v) =>
                                v === '' ||
                                REG_EX.date_mm_dd_yyyy.test(v) ||
                                'Use format MM/DD/YYYY',
                            },
                          })}
                          validationText={
                            errors.childBirthdays?.[index]?.birthdayDate
                              ?.message
                          }
                          data-cy="SIGNUP_EMAIL_CHILD_BDAY"
                          dataCyError="SINGUP_EMAIL_CHILD_BDAY_ERROR"
                        />
                        <Pgraph variant="p-12" className="text-neutral-5 mt-1">
                          Optional
                        </Pgraph>
                      </div>
                    </Fragment>
                  )
                })}
                <div className="flex items-center flex-col mt-4">
                  <div className="mb-4">
                    <Pgraph
                      variant="p-16"
                      className="underline mb-2 cursor-pointer"
                      onClick={() => append({ birthdayDate: '', name: '' })}
                    >
                      + Add child
                    </Pgraph>
                    {childCount > 1 && (
                      <Pgraph
                        variant="p-16"
                        className="underline cursor-pointer"
                        onClick={() => remove(childCount - 1)}
                      >
                        - Remove child
                      </Pgraph>
                    )}
                  </div>
                  <Button
                    label="Save"
                    size="large"
                    primary={true}
                    type="submit"
                    loading={savingChildren}
                    disabled={savingChildren}
                  />
                  {childrenUpdated && (
                    <Pgraph variant="p-14" className="mt-4">
                      {childrenUpdated}
                    </Pgraph>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {bigCOrderNumber > 0 ? (
        <>
          <ShareSaleTracking
            amount={checkedCart?.apiCart?.cartAmount}
            order={bigCOrderNumber}
          ></ShareSaleTracking>

          <GTMConvertionTracking
            amount={checkedCart?.apiCart?.cartAmount}
            order={bigCOrderNumber}
            lineItems={checkedCart?.apiCart?.lineItems}
          ></GTMConvertionTracking>

          <ScriptJustUnoConvertion
            amount={checkedCart?.apiCart?.cartAmount}
            order={bigCOrderNumber}
            lineItems={checkedCart?.apiCart?.lineItems}
          ></ScriptJustUnoConvertion>

          <ScriptNextDoorConvertion />
        </>
      ) : (
        ''
      )}
    </>
  )
}

export { CheckoutCompleted }
