import React, { useEffect, useState } from 'react'
import router from 'next/router'
import {
  createSocialRequest,
  LOCAL_STORAGE,
  SOCIAL_MEDIA,
  URLS,
} from '../../config'
import { logUser, trackEvent } from '../../config/analytics'
import { Headline } from '../Headline'
import { Button } from '../Button'
import { Pgraph } from '../Pgrah'
import { InputText } from '../InputText'
import { useForm } from 'react-hook-form'
import { AUTH_ACTION, ILoginForm } from '../../interfaces'
import { sanitizeFields } from '../../config/utilities'
import { AuthApi, CustomersApi } from '../../services'
import {
  removeLocalStorage,
  setLocalCredentials,
  setLocalObject,
  useAuthDispatch,
  useAuthStore,
} from '../../store'

interface ISignInModalProps {
  onClose: () => void
  onSignInComplete: () => void
  onGetStarted: () => void
}

function SignInModal({
  onClose,
  onSignInComplete,
  onGetStarted,
}: ISignInModalProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ILoginForm>({
    defaultValues: {
      email: '',
      password: '',
    },
  })

  const authDispatch = useAuthDispatch()
  const { returnUrl } = useAuthStore()
  const [emailLogin, setEmailLogin] = useState(false)
  const [loginError, setLoginError] = useState<string | undefined>(undefined)
  const [processing, setProcessing] = useState(false)

  const onSubmit = async (data: ILoginForm) => {
    try {
      setProcessing(true)
      setLoginError(undefined)
      // wrap all forms with the following function to fix values such as whitespaces
      sanitizeFields(data)
      const loginResult = await AuthApi.loginWithPassword(data)

      if (!loginResult.error) {
        authDispatch({
          type: AUTH_ACTION.LOGIN,
          credentials: loginResult,
        })
        if (returnUrl) {
          router.push(returnUrl)
          authDispatch({
            type: AUTH_ACTION.SET_RETURN_URL,
            returnUrl: undefined,
          })
        } else {
          router.push(URLS.INDEX)
        }
        onClose()
        onSignInComplete()
      } else {
        if (loginResult.errors.length) {
          const errorText = loginResult.errors[0].code
            ? loginResult.errors[0].message
            : 'Please check your email and password.'
          setLoginError(errorText)
          setProcessing(false)
          return
        } else {
          throw new Error('Unknown login error')
        }
      }
    } catch (error) {
      console.error(error)
    } finally {
      setProcessing(false)
    }
  }

  useEffect(() => {
    const handleEsc = (event: globalThis.KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose()
      }
    }
    window.addEventListener('keydown', handleEsc)

    return () => {
      window.removeEventListener('keydown', handleEsc)
    }
  }, [onClose])

  const handleEmailLoginClick = () => {
    setEmailLogin(true)
  }

  const handleGoogleLoginClick = () => {
    router.push(createSocialRequest(SOCIAL_MEDIA.GOOGLE))
  }

  const handleFacebookLoginClick = () => {
    router.push(createSocialRequest(SOCIAL_MEDIA.FACEBOOK))
  }

  const handleAppleLoginClick = () => {
    router.push(createSocialRequest(SOCIAL_MEDIA.APPLE))
  }

  return (
    <div>
      <div className="bg-modal-overlay fixed top-0 left-0 z-100 w-full h-full md:flex justify-center z-[100]">
        <div className="relative px-[27px] py-4 bg-snow-white md:p-5 md:rounded w-full md:w-1/2 lg:w-1/3 h-full md:h-fit m-[0px] md:m-auto">
          <div className="cursor-pointer" onClick={onClose}>
            <i className="absolute right-0 top-0 m-4 loop-icon-times text-24px text-neutral-7"></i>
          </div>
          <Headline variant="recoleta-m" className="mb-5 text-center">
            Log in to add to your registry
          </Headline>
          {emailLogin ? (
            <div>
              <Pgraph variant="p-14" className="mt-4 mb-[29px]">
                New to Loop?{' '}
                <Pgraph
                  variant="p-14"
                  className="inline-block font-bold cursor-pointer"
                  onClick={onGetStarted}
                  data-cy="SIGNIN_GET_STARTED"
                >
                  Get started
                </Pgraph>
              </Pgraph>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mt-4">
                  <Pgraph variant="l-14" className="text-neutral-6">
                    Email
                  </Pgraph>
                  <InputText
                    type="text"
                    placeHolder="Parents@loveloop.com"
                    {...register('email', {
                      required: 'Valid email address required',
                    })}
                    validationText={errors.email?.message}
                  />
                </div>
                <div className="mt-4">
                  <Pgraph variant="l-14" className="text-neutral-6">
                    Password
                  </Pgraph>
                  <InputText
                    type="password"
                    placeHolder="********"
                    {...register('password', {
                      minLength: {
                        value: 8,
                        message: 'Password must be 8+ characters',
                      },
                      required: 'Password must be 8+ characters',
                    })}
                    validationText={errors.password?.message}
                  />
                </div>
                <div className="mt-4 mb-4">
                  <Pgraph
                    variant="l-16"
                    className="text-neutral-6 cursor-pointer"
                    onClick={() => router.push(URLS.LOGIN.RESET_PASSWORD)}
                  >
                    Forgot password?
                  </Pgraph>
                </div>
                <Pgraph variant="l-14" className="text-center text-caution-2">
                  {loginError}
                </Pgraph>
                <Button
                  label="Login"
                  primary
                  className="w-full mb-2 mt-2"
                  type="submit"
                  size="large"
                  disabled={processing}
                  loading={processing}
                />
              </form>
            </div>
          ) : (
            <div>
              <Button
                label="Log in with email"
                primary
                className="w-full mb-2"
                onClick={handleEmailLoginClick}
                data-cy="SIGNIN_EMAIL"
              />
              <div className="relative">
                <div className="inline-block absolute z-[1] top-[11px] left-[7px] md:left-[23px]">
                  <img src="/images/social_google.svg" alt="Background" />
                </div>
                <Button
                  label="Log in with Google"
                  className="w-full mb-2"
                  onClick={handleGoogleLoginClick}
                  data-cy="SIGNIN_GOOGLE"
                />
              </div>
              <div className="relative">
                <div className="inline-block absolute z-[1] top-[11px] left-[7px] md:left-[23px]">
                  <img src="/images/social_facebook.svg" alt="Background" />
                </div>
                <Button
                  label="Log in with Facebook"
                  className="w-full mb-2"
                  onClick={handleFacebookLoginClick}
                  data-cy="SIGNIN_FACEBOOK"
                />
              </div>
              <div className="relative">
                <div className="inline-block absolute z-[1] top-[11px] left-[7px] md:left-[23px]">
                  <img src="/images/social_apple.svg" alt="Background" />
                </div>
                <Button
                  label="Log in with Apple"
                  className="w-full mb-2"
                  onClick={handleAppleLoginClick}
                  data-cy="SIGNIN_APPLE"
                />
              </div>
              <Pgraph variant="p-14" className="text-center mt-4">
                New to Loop?{' '}
                <Pgraph
                  variant="p-14"
                  className="inline-block font-bold cursor-pointer"
                  onClick={onGetStarted}
                  data-cy="SIGNIN_GET_STARTED"
                >
                  Get started
                </Pgraph>
              </Pgraph>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export { SignInModal }
