import React, { useEffect } from 'react'
import { Headline } from '../Headline'
import { Pgraph } from '../Pgrah'
import { useCartStore } from '../../store'
import NumberFormat from 'react-number-format'
import Image from 'next/image'
import { MembershipPerks, URLS } from '../../config'
import { useRouter } from 'next/router'

interface IRegistryMembershipUpsellProps {
  onClose: () => void
}

function MembershipUpsell({ onClose }: IRegistryMembershipUpsellProps) {
  const { membershipProducts } = useCartStore()
  const router = useRouter()

  useEffect(() => {
    const html = document.body as HTMLElement | null
    html?.classList.add('overflow-hidden')

    return function cleanup() {
      const html = document.body as HTMLElement | null
      html?.classList.remove('overflow-hidden')
    }
  }, [])

  const learnMoreClick = () => {
    router.push(URLS.MEMBERSHIP_LANDING)
  }

  return (
    <div>
      <div className="bg-modal-overlay absolute top-0 left-0 z-100 w-full h-full justify-center z-[100] md:fixed md:overflow-y-scroll md:top-0 md:left-0 md:right-0 md:bottom-0 md:flex md:h-auto md:items-center">
        <div className="elTarget w-full h-full bg-heavy-cream overflow-auto fixed max-w-full md:relative md:m-auto md:h-auto md:max-w-xl md:rounded">
          <i
            className="loop-icon-times text-[22px] cursor-pointer float-right relative top-[25px] right-[25px] z-index-100"
            onClick={onClose}
          />
          <div>
            <div className="md:m-[80px] bg-heavy-cream md:bg-white relative">
              <Headline
                variant="recoleta-m"
                className="text-center font-normal text-neutral-7 pt-[40px]"
              >
                Become a Loop Member
              </Headline>
              <div className="absolute">
                <Image
                  src="/images/wavingHand.svg"
                  width="113"
                  height="50"
                  alt="Waving Hand"
                />
              </div>
              <Pgraph
                variant="p-16"
                className="text-neutral-6 mr-3 mt-[20px] text-center"
              >
                <NumberFormat
                  value={(membershipProducts.annual?.price || 0) / 12}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={'Just $'}
                  decimalScale={2}
                  suffix={'/mo'}
                />
              </Pgraph>
              <Pgraph
                variant="p-12"
                className="text-neutral-6 mr-3 mt-2 text-center italic"
              >
                <NumberFormat
                  value={membershipProducts.annual?.price || 0}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={'$'}
                  suffix={' charged annually'}
                  decimalScale={0}
                  fixedDecimalScale={true}
                />
              </Pgraph>

              <hr className="my-3 w-4/5 mx-auto border-neutral-2" />
              <ul className="pt-3 mx-2 mb-[27px] pr-[40px] pl-[30px]">
                {MembershipPerks.map((perk, idx) => (
                  <li key={idx} className="text-neutral-6 flex mb-3 text-12px">
                    <i className="loop-icon-checkmark text-24px mr-3 text-poppy relative -top-1 font-bold"></i>
                    <div className="text-[14px] font-circular">{perk.perk}</div>
                  </li>
                ))}
              </ul>
              <Pgraph
                variant="p-14"
                className="text-neutral-6 mr-3 mt-2 text-center pb-[40px]"
              >
                Questions?{' '}
                <Pgraph
                  variant="p-14"
                  className="inline-block underline font-circular-medium border-0 hover:cursor-pointer"
                  onClick={learnMoreClick}
                >
                  Learn more here
                </Pgraph>
              </Pgraph>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { MembershipUpsell }
