import React, { useEffect } from 'react'
import { Pgraph } from '../Pgrah'
import { InputText } from '../InputText'
import { PASSWORD_RULES, REG_EX, URLS } from '../../config'
import { Button } from '../Button'
import { useRouter } from 'next/router'
import { useAuthDispatch } from '../../store'
import { useFieldArray, useForm } from 'react-hook-form'
import { AUTH_ACTION, ISignupForm } from '../../interfaces'
import { sanitizeFields } from '../../config/utilities'
import { BirthdayFields } from './birthdayFields'
import Link from 'next/link'

interface IUserFormProps {
  includePassword: boolean
  buttonText: string
  onSubmitCallback: (data: ISignupForm) => void
  processing: boolean
  error: string | undefined
  success?: string | undefined
  formValues?: ISignupForm
  showLoginOnError: boolean
  disabledFields?: (keyof ISignupForm)[]
}

function UserForm({
  includePassword,
  buttonText,
  onSubmitCallback,
  processing,
  error,
  success,
  formValues,
  showLoginOnError,
  disabledFields,
}: IUserFormProps) {
  const authDispatch = useAuthDispatch()
  const router = useRouter()
  const {
    register,
    handleSubmit,
    control,
    getValues,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm<ISignupForm>({
    defaultValues: {
      childBirthdays: [{ birthdayDate: '', name: '', hideSection: false }],
    },
  })
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'childBirthdays',
  })

  useEffect(() => {
    if (formValues) {
      reset(formValues)
    }
  }, [formValues, reset])

  const addEntry = () => {
    let addEntry = true
    getValues('childBirthdays').forEach(function (obj) {
      if (!obj.name && !obj.birthdayDate) {
        addEntry = false
      }
    })

    if (addEntry) {
      append({ name: '', birthdayDate: '', hideSection: false })
    }
  }

  const onSubmit = async (data: ISignupForm) => {
    if (data.childBirthdays && data.childBirthdays.length) {
      data.childBirthdays.forEach(function (field) {
        if (field.hideSection) {
          field.name = ''
        }
      })
    }
    sanitizeFields(data)
    onSubmitCallback(data)
  }

  const handleSignIn = () => {
    authDispatch({
      type: AUTH_ACTION.SET_RETURN_URL,
      returnUrl: router.pathname,
    })
    router.push(URLS.LOGIN.INDEX)
  }

  const updatePhone = (value: any) => {
    setValue('cellPhone', value)
  }

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-4">
          <Pgraph variant="l-14" className="text-neutral-6">
            First Name
          </Pgraph>
          <InputText
            type="text"
            placeHolder="First"
            {...register('firstName', {
              required: 'Required',
              validate: {
                format: (v) =>
                  v === '' ||
                  REG_EX.lettersAndSpaces.test(v) ||
                  'Only alphabets are allowed',
              },
            })}
            validationText={errors.firstName?.message}
            data-cy="SIGNUP_EMAIL_FNAME"
            dataCyError="SINGUP_EMAIL_FNAME_ERROR"
            disabled={!!disabledFields?.find((field) => field === 'firstName')}
          />
        </div>
        <div className="mt-4">
          <Pgraph variant="l-14" className="text-neutral-6">
            Last Name
          </Pgraph>
          <InputText
            type="text"
            placeHolder="Last"
            {...register('lastName', {
              required: 'Required',
              validate: {
                format: (v) =>
                  v === '' ||
                  REG_EX.lettersAndSpaces.test(v) ||
                  'Only alphabets are allowed',
              },
            })}
            validationText={errors.lastName?.message}
            data-cy="SIGNUP_EMAIL_LNAME"
            dataCyError="SINGUP_EMAIL_LNAME_ERROR"
            disabled={!!disabledFields?.find((field) => field === 'lastName')}
          />
        </div>
        <div className="mt-4">
          <Pgraph variant="l-14" className="text-neutral-6">
            Phone Number
          </Pgraph>
          <InputText
            type="numeric"
            placeHolder="Phone Number"
            {...register('cellPhone', {
              validate: {
                format: (v) =>
                  v === '' ||
                  REG_EX.onlyNumbers.test(v) ||
                  'Only numbers are allowed',
              },
            })}
            onChange={(e) => updatePhone(e.target.value)}
            disabled={!!disabledFields?.find((field) => field === 'cellPhone')}
          />
        </div>
        <div className="mt-4">
          <Pgraph variant="l-14" className="text-neutral-6">
            Email
          </Pgraph>
          <InputText
            type="text"
            placeHolder="Parents@loveloop.com"
            {...register('emailAddress', {
              required: 'Valid email address required',
            })}
            validationText={errors.emailAddress?.message}
            data-cy="SIGNUP_EMAIL_EMAIL"
            dataCyError="SINGUP_EMAIL_EMAIL_ERROR"
            disabled={
              !!disabledFields?.find((field) => field === 'emailAddress')
            }
          />
          {!!disabledFields?.find((field) => field === 'emailAddress') && (
            <div className="font-circular text-12px mt-1">
              To change your email address{' '}
              <Link href={URLS.CONTACT_US} className="underline">
                Contact Support
              </Link>
            </div>
          )}
        </div>
        {includePassword && (
          <div className="mt-4">
            <Pgraph variant="l-14" className="text-neutral-6">
              Password
            </Pgraph>
            <InputText
              type="new-password"
              placeHolder="********"
              {...register('password', PASSWORD_RULES)}
              validationText={errors.password?.message}
              data-cy="SIGNUP_EMAIL_PASSWORD"
              dataCyError="SINGUP_EMAIL_PASSWORD_ERROR"
              disabled={!!disabledFields?.find((field) => field === 'password')}
            />
          </div>
        )}

        {fields.map((field, index) => {
          return (
            <BirthdayFields
              key={index}
              formValue={field}
              index={index}
              register={register}
              onRemoveCallback={remove}
              setValue={setValue}
              errors={errors}
              watch={watch}
            />
          )
        })}
        <div className="mt-4 mb-4">
          <Pgraph
            variant="l-16"
            className="text-neutral-6 cursor-pointer"
            onClick={addEntry}
          >
            <span className="pr-2" data-cy="SIGNUP_EMAIL_ADD_CHILD">
              +
            </span>{' '}
            Add Child
          </Pgraph>
        </div>
        {error && (
          <Pgraph variant="l-14" className="text-center text-caution-2">
            {error}{' '}
            {showLoginOnError && (
              <span className="underline cursor-pointer" onClick={handleSignIn}>
                Login here
              </span>
            )}
          </Pgraph>
        )}
        {success && (
          <Pgraph
            variant="l-14"
            className="text-center text-success-2 my-[20px]"
          >
            {success}
          </Pgraph>
        )}
        <Button
          label={buttonText}
          primary
          className="w-full mb-2 mt-2"
          type="submit"
          size="large"
          data-cy="SIGNUP_EMAIL_SUBMIT"
          loading={processing}
          disabled={processing}
        />
      </form>
    </div>
  )
}

export { UserForm }
