import React, { useState, useEffect } from 'react'
import { AUTH_ACTION, IStripeCard } from '../../interfaces'
import { PaymentApi } from '../../services'
import { useAuthDispatch, useAuthStore } from '../../store'
import { Button } from '../Button'
import { Headline } from '../Headline'
import { Pgraph } from '../Pgrah'
import { AddCard } from './addCard'

interface IRemoveCardModalProps {
  card: IStripeCard
  open: boolean
  onClose: () => void
}

function RemoveCardModal({ open, onClose, card }: IRemoveCardModalProps) {
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState<string>()
  const { credentials } = useAuthStore()
  const authDispatch = useAuthDispatch()

  useEffect(() => {
    const handleEsc = (event: globalThis.KeyboardEvent) => {
      if (open && event.key === 'Escape') {
        onClose()
      }
    }
    window.addEventListener('keydown', handleEsc)

    return () => {
      window.removeEventListener('keydown', handleEsc)
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const deleteCard = async () => {
    try {
      if (!credentials?.accessToken) return

      setProcessing(true)
      setError(undefined)
      const result = await PaymentApi.deletePaymentMethod(
        credentials.accessToken,
        card.id
      )
      if (result) {
        try {
          const response = await PaymentApi.fetchPaymentInfo(
            credentials.accessToken
          )
          if (response?.success) {
            authDispatch({
              type: AUTH_ACTION.SET_PAYMENT_METHODS,
              cards: response.cards,
              defaultCard: response.defaultPaymentMethodId,
            })
          }
        } catch (error) {
          authDispatch({
            type: AUTH_ACTION.SET_PAYMENT_METHODS,
          })
        }
      } else {
        throw new Error('Error deleting card')
      }
      onClose()
    } catch (error) {
      console.error(error)
      setError(error instanceof Error ? error.message : 'Unknown error')
    } finally {
      setProcessing(false)
    }
  }

  return (
    <>
      <div className="fixed top-0 left-0 w-full h-screen z-30 opacity-40 bg-black flex justify-center items-center"></div>
      <div className="fixed top-0 left-0 w-full h-screen z-30 flex justify-center items-center">
        <div className="w-screen h-screen flex justify-center items-center md:p-5 lg:p-7 z-50">
          <div className="bg-snow-white p-5 w-full h-full md:w-auto md:h-auto">
            <div className="flex justify-center items-center flex-col h-full">
              <Headline variant="recoleta-s" className="mb-4">
                Remove Card
              </Headline>
              <Pgraph variant="p-14">
                This will remove the {card.brand.toUpperCase()} card ending in{' '}
                {card.last4}. Are you sure?
              </Pgraph>
              {error && (
                <Pgraph variant="p-14" className="text-caution-2">
                  {error}
                </Pgraph>
              )}
              <div className="flex justify-around w-full mt-4">
                <Button
                  label="Cancel"
                  primary={true}
                  size="small"
                  onClick={onClose}
                  disabled={processing}
                />
                <Button
                  label="Confirm"
                  size="small"
                  onClick={deleteCard}
                  disabled={processing}
                  loading={processing}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export { RemoveCardModal, AddCard }
