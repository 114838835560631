import Link from 'next/link'
import { useRouter } from 'next/router'
import { URLS } from '../../config'
import Image from 'next/image'
import { useAuthStore, useHooks } from '../../store'
import { Pgraph } from '../Pgrah'
import { chatWithSpecialist } from '../../config/footer.constants'
import { INavigation, MenuProps } from '../../interfaces/navigation.interfaces'
import { MobileMenuBuilder, staticLinks } from './mobileMenuBuilder'
import { useState } from 'react'

interface IMobileMenuProps {
  mobileMenuOpen: boolean
  onClose: () => void
  menuJson: INavigation
}

export const MobileMenu = ({
  mobileMenuOpen,
  onClose,
  menuJson,
}: IMobileMenuProps) => {
  const [minorCategory, setMinorCateogry] = useState<MenuProps | undefined>()
  const router = useRouter()
  const { user, credentials } = useAuthStore()
  const { logout, reportBug } = useHooks()

  const handleLogoutClick = async () => {
    try {
      if (!credentials) return
      logout()
      router.push(URLS.LOGIN.INDEX)
    } catch (error) {
      reportBug(error)
      console.error(error)
    }
  }

  const handleClose = () => {
    setTimeout(() => setMinorCateogry(undefined), 500)
    onClose()
  }

  return (
    <div className="overflow-y-hidden" onClick={(e) => e.stopPropagation()}>
      <div
        className={`lg:hidden z-50 opacity-100 px-3 pt-2 w-[300px] fixed top-0 bottom-0 left-0 ease-in-out duration-500 ${
          mobileMenuOpen ? 'translate-x-0' : '-translate-x-full'
        } md:w-[375px] bg-background-3 rounded-tr-[30px] rounded-br-[30px] text-neutral-6`}
      >
        {/* Logo and close button */}
        <div className="flex flex-row items-center">
          <i
            className="loop-icon-times text-[36px] cursor-pointer"
            onClick={handleClose}
          />
          <div className="block h-3 md:h-18px w-[53px] md:w-69px relative ml-3">
            <Link href={URLS.INDEX} passHref>
              <div className="w-full h-full relative">
                <Image
                  src="/images/loop-logo.svg"
                  alt="Loop.baby"
                  sizes="20vw"
                  style={{ cursor: 'pointer ' }}
                  priority
                  width={100}
                  height={100}
                />
              </div>
            </Link>
          </div>
        </div>

        <div className="overflow-y-scroll block h-full">
          {/* JSON menu */}
          <div className="ml-2 flex flex-col">
            <MobileMenuBuilder
              menu={menuJson.menu}
              setMinorCateogry={setMinorCateogry}
              minorCategory={minorCategory}
              closeMenu={onClose}
            />
          </div>

          {/* Middle Section */}
          <div className="ml-2 w-full max-w-[185px] h-[1px] bg-black" />
          <div className="ml-2 flex flex-col">
            {staticLinks.map((link, idx) => (
              <Link href={link.href} key={idx} passHref>
                <div className="font-recoleta text-16px leading-[20px] my-[10px] first:mt-[20px] last:mb-0 text-neutral-6 font-medium cursor-pointer">
                  {link.label}
                </div>
              </Link>
            ))}
          </div>

          {/* Bottom Section */}
          <div className="ml-2 mt-5 font-normal">
            <Pgraph variant="p-16" className="mb-2">
              <Link href={URLS.FAQ}>Support</Link>
            </Pgraph>
            <Pgraph
              variant="p-16"
              className="my-3 cursor-pointer"
              onClick={chatWithSpecialist}
            >
              <div className="flex items-center">
                <Image
                  src="/images/chat.svg"
                  alt="Chat"
                  width={22}
                  height={21}
                  className="invert"
                />
                <span className="ml-2">Chat</span>
              </div>
            </Pgraph>
            <Pgraph variant="p-16" className="mt-2 mb-[74px]">
              {!user && <Link href={URLS.LOGIN.INDEX}>Sign in</Link>}
              {user && (
                <div role="button" onClick={handleLogoutClick}>
                  Sign out
                </div>
              )}
            </Pgraph>
          </div>
        </div>
      </div>
      <div
        className={`lg:hidden z-40 flex flex-col top-0 bottom-0 right-0 left-0 bg-[rgba(18,18,18,0.2)] ease-in-out duration-500 ${
          mobileMenuOpen ? 'fixed' : 'hidden'
        }`}
        onClick={handleClose}
      ></div>
    </div>
  )
}
