import { getRequestHeaders } from '../config'
import { fetchTimeoutRetry } from '../config/utilities'
import { ICustomerInfo } from '../interfaces/customer.interface'

const fetchAccount = async (accessToken: string) => {
  try {
    const result = await fetchTimeoutRetry(
      `${process.env.NEXT_PUBLIC_ACCOUNTS_URL}/accounts`,
      {
        method: 'GET',
        mode: 'cors',
        headers: getRequestHeaders(accessToken),
      }
    )
    if (result.ok) {
      return await result.json()
    }
    return null
  } catch (error) {
    return null
  }
}

const updateChildBirthdays = async (
  accessToken: string,
  loopCustomerId: string,
  childBirthdays: ICustomerInfo['childBirthdays']
) => {
  try {
    const result = await fetchTimeoutRetry(
      `${process.env.NEXT_PUBLIC_ACCOUNTS_URL}/accounts`,
      {
        headers: getRequestHeaders(accessToken),
        method: 'PUT',
        body: JSON.stringify({
          loopCustomerId,
          childBirthdays,
        }),
      }
    )
    if (result.ok) {
      return true
    }
    return false
  } catch (error) {
    console.error(error)
    return false
  }
}

export { fetchAccount, updateChildBirthdays }
