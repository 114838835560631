import React, { useEffect, useState } from 'react'
import { CategoryCarouselCard, Headline, HeadlineHighlight, Pgraph } from '..'
import { useRouter } from 'next/router'
import { URLS } from '../../config'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import { ICategory, IProductFilters } from '../../interfaces/product.interface'
import { useCartStore } from '../../store'
interface IButtonGroupProps {
  next: () => void
  previous: () => void
  carouselState: {
    currentSlide: number
    totalItems: number
  }
}

const Slides_Per_Page = 3

const ButtonGroup = ({ next, previous, carouselState }: IButtonGroupProps) => {
  // use carouselState to disable button when in the last slide, for example.
  return (
    <div className="absolute -bottom-0 right-0 mr-3 hidden lg:block">
      <span onClick={() => previous()} className="mr-4 cursor-pointer">
        <i
          className={`loop-icon-arrow-long text-24px text-black transition ease-in duration-400 rotate-180 inline-block ${
            carouselState.currentSlide === 0
              ? 'cursor-not-allowed opacity-25'
              : ''
          }`}
        ></i>
      </span>
      <span onClick={() => next()} className="cursor-pointer">
        <i
          className={`loop-icon-arrow-long text-24px text-black transition ease-in duration-400 inline-block ${
            carouselState.totalItems - carouselState.currentSlide ===
            Slides_Per_Page
              ? 'cursor-not-allowed opacity-25'
              : ''
          }`}
        ></i>
      </span>
    </div>
  )
}

interface ICategoryMoleculeProps {
  hideTitle?: boolean
  /** Regular for all the site and homepage includes a bigger headline */
  variant?: 'regular' | 'homepage'
  /** Optional additional class names for styling */
  className?: string
  /** Optional Basic filters for SSR */
  ssrFilters: IProductFilters
}

export const ByCategoryMolecule = ({
  hideTitle,
  variant,
  className,
  ssrFilters,
  ...props
}: ICategoryMoleculeProps) => {
  return (
    <section className="w-full">
      <div
        className={`max-w-6xl mx-auto overflow-hidden lg:pb-[60px] md:pb-[40px] pb-[40px] ${
          hideTitle ? '' : 'lg:pt-[70px] md:pt-[70px] pt-[56px]'
        }`}
      >
        {variant === 'homepage' ? (
          <div className="flex flex-col px-4 md:flex-row">
            <div className="w-full md:w-1/2">
              <Headline
                variant="recoleta-s"
                className="text-poppy inline-block pr-3 pt-1 pl-1  md:text-28px lg:text-32px lg:pr-4 lg:text-left lg:pt-0"
              >
                Browse by category
                <HeadlineHighlight />
              </Headline>
            </div>
          </div>
        ) : (
          <div className={`${hideTitle ? 'hidden' : ''}`}>
            <Headline
              variant="recoleta-s"
              className="text-center text-neutral-7 relative md:text-28px lg:text-32px top-[20px]"
            >
              Browse by category
            </Headline>
          </div>
        )}

        <div className="mt-0 md:mt-5 px-[20px] relative pb-5">
          <Carousel
            swipeable={true}
            showDots={false}
            arrows={false}
            infinite={false}
            partialVisible={true}
            autoPlay={false}
            autoPlaySpeed={5000}
            renderButtonGroupOutside={true}
            responsive={{
              mobile: {
                breakpoint: { max: 640, min: 0 },
                items: 1,
                partialVisibilityGutter: 100,
              },
              tablet: {
                breakpoint: { max: 1024, min: 640 },
                items: Slides_Per_Page,
                partialVisibilityGutter: 25,
              },
              desktop: {
                breakpoint: { max: 9000, min: 1024 },
                items: Slides_Per_Page,
                partialVisibilityGutter: 70,
              },
            }}
            //@ts-ignore
            customButtonGroup={<ButtonGroup />}
          >
            {ssrFilters.categories.map((category, index) => {
              return <CategoryCarouselCard key={index} category={category} />
            })}
          </Carousel>
        </div>
      </div>
    </section>
  )
}
