import React from 'react'
import { Pgraph } from '..'
import { FEATURED_IN_BRANDS } from '../../config'
import Carousel from 'react-multi-carousel'

function FeaturedInMolecule() {
  return (
    <section className="w-full">
      <div className="relative w-full h-[60px] -z-10">
        <img
          src="/images/homepage/HTL-Wave-Bottom-Mobile.svg"
          alt=""
          className="absolute -top-4 md:-top-8 lg:-top-[200px] xl:-top-[300px] w-full h-auto"
        />
      </div>
      <div className="max-w-7xl mx-auto overflow-hidden pt-[30px] md:pt-5 z-20 md:pb-4">
        <Pgraph
          variant="l-16"
          className="text-center text-neutral-6 md:text-24px mb-2 md:mb-4"
        >
          Featured In
        </Pgraph>

        <div className="-mt-[26px]">
          <Carousel
            swipeable={true}
            showDots={false}
            arrows={false}
            infinite={true}
            partialVisible={true}
            autoPlay={true}
            autoPlaySpeed={3000}
            responsive={{
              mobile: {
                breakpoint: { max: 640, min: 0 },
                items: 2,
                partialVisibilityGutter: 25,
              },
              tablet: {
                breakpoint: { max: 1024, min: 640 },
                items: 5,
                partialVisibilityGutter: 10,
              },
              desktop: {
                breakpoint: { max: 9000, min: 1024 },
                items: 5,
                partialVisibilityGutter: 0,
              },
            }}
          >
            {FEATURED_IN_BRANDS.map((brand, index) => {
              const smallbrandStyles = `relative max-w-full inline-block min-w-[100px]`
              const largeBrandStyles = `relative max-w-full inline-block min-w-[50px]`
              const brandStyles = brand.largeLogo
                ? largeBrandStyles
                : smallbrandStyles

              return (
                <div
                  key={index}
                  className="flex items-center justify-center h-full p-5"
                >
                  <a
                    target="_blank"
                    href={brand.url}
                    className={brandStyles}
                    rel="noreferrer"
                  >
                    <img
                      src={brand.image}
                      alt={brand.name}
                      height={brand.height}
                      width={brand.width}
                    />
                  </a>
                </div>
              )
            })}
          </Carousel>
        </div>
      </div>
    </section>
  )
}

export { FeaturedInMolecule }
