import { useRouter } from 'next/router'
import React from 'react'
import NumberFormat from 'react-number-format'
import { Button, Headline, Pgraph } from '..'
import { URLS } from '../../config'
import { giftCards } from '../../interfaces'
import { useCartStore, useHooks } from '../../store'

function GiftingMembershipMolecule() {
  const { membershipProducts } = useCartStore()
  const router = useRouter()
  const { reportBug } = useHooks()

  const handleMembershipGift = () => {
    try {
      const giftCard = giftCards.find((c) => c.type === 'membership')
      if (!giftCard) throw new Error('Membership Gift card with not found')
      router.push(
        `${URLS.CHECKOUT.GIFT_CARD}/?amount=${giftCard.amount}&annual=1`
      )
    } catch (error) {
      console.error(error)
      reportBug(error)
    }
  }

  return (
    <section className="w-full">
      <div className="max-w-5xl ">
        <div className="">
          <div className="mx-auto py-4 px-4 md:py-5">
            <Headline
              variant="recoleta-s"
              className="text-poppy mx-auto w-1/2 mb-3 leading-29 md:text-28px md:leading-34 md:text-center"
            >
              Give the gift of a Loop membership
            </Headline>
            <Pgraph
              variant="p-14"
              className="text-neutral-5 text-center md:w-2/5 md:mx-auto md:leading-19"
            >
              Not sure how much to gift? Gifting Loop membership is a great
              place to start.
            </Pgraph>
          </div>

          <div className="md:flex">
            <div className="w-full">
              <img
                src={'/images/gifting/gifting-membership.jpg'}
                alt="Loop Membership"
              />
            </div>
            <div className="w-full p-4 md:pt-0 md:pl-5">
              <Headline
                variant="recoleta-s"
                className="text-neutral-6 mb-3 w-1/2 md:w-full md:text-32px"
              >
                Loop Membership
              </Headline>
              <Pgraph variant="l-16" className="text-neutral-6 font-medium">
                <NumberFormat
                  value={membershipProducts.annual?.price || 0}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={'$'}
                  decimalScale={0}
                  fixedDecimalScale={true}
                />{' '}
                <span className="text-neutral-5 font-circular">
                  <NumberFormat
                    value={(membershipProducts.annual?.price || 0) + 75}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'($'}
                    suffix={' in value)'}
                    decimalScale={0}
                    fixedDecimalScale={true}
                  />
                </span>
              </Pgraph>

              <ul className="pt-3">
                <li className="text-neutral-6 flex mb-3 text-14px">
                  <i className="loop-icon-checkmark text-28px mr-3 text-poppy"></i>
                  <div>
                    <b className="font-circular-medium font-medium">
                      $75 product credit
                    </b>{' '}
                    to go towards their first order
                  </div>
                </li>
                <li className="text-neutral-6 flex mb-3 text-14px">
                  <i className="loop-icon-checkmark text-28px mr-3 text-poppy"></i>
                  <div>
                    <b className="font-circular-medium font-medium">
                      Unlimited priority delivery and/or pick ups
                    </b>
                    , so they can try items and swap whenever needed
                  </div>
                </li>
                <li className="text-neutral-6 flex mb-3 text-14px">
                  <i className="loop-icon-checkmark text-28px mr-3 text-poppy"></i>
                  <div>
                    <b className="font-circular-medium font-medium">LoopCare</b>{' '}
                    inspection, disinfection/cleaning, and safety monitoring
                    included
                  </div>
                </li>
                <li className="text-neutral-6 flex mb-3 text-14px">
                  <i className="loop-icon-checkmark text-28px mr-3 text-poppy"></i>
                  <div>
                    <b className="font-circular-medium font-medium">
                      In home set up and demonstrations,
                    </b>{' '}
                    no need to store manuals or watch online how-tos!
                  </div>
                </li>
                <li className="text-neutral-6 flex mb-3 text-14px">
                  <i className="loop-icon-checkmark text-28px mr-3 text-poppy"></i>
                  <div>
                    <b className="font-circular-medium font-medium">
                      Rewards program
                    </b>
                    : 10% credit back on all Loop rentals quarterly
                  </div>
                </li>
                <li className="text-neutral-6 flex mb-3 text-14px">
                  <i className="loop-icon-checkmark text-28px mr-3 text-poppy"></i>
                  <div>
                    <b className="font-circular-medium font-medium">
                      Benefits start when they join
                    </b>
                    , but membership period won&apos;t begin until their first
                    delivery
                  </div>
                </li>
              </ul>

              <div className="md:text-left">
                <Button
                  primary={true}
                  label="Gift a Membership"
                  className="w-full mt-4 md:w-2/3"
                  onClick={handleMembershipGift}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export { GiftingMembershipMolecule }
