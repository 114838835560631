import React from 'react'
import { Button, Headline, LinkTo, Pgraph } from '..'
import router from 'next/router'

function RegistryGuestBanner() {
  return (
    <section className="bg-heavy-cream w-full">
      <div className="max-w-7xl mx-auto flex flex-col md:flex-row md:items-center">
        <div className="w-full md:w-auto">
          <img
            src="/images/registry/registry-guest-upsell.jpg"
            alt="Rent top-rated toys & baby gear"
            className="max-w-full md:max-w-[215px] md:ml-4 lg:ml-0"
          />
        </div>
        <div className="w-ful px-4 md:w-3/5 md:my-4 md:mx-5">
          <Headline
            variant="recoleta-s"
            className="text-center mt-4 md:text-left"
          >
            Rent top-rated toys & baby gear
          </Headline>
          <Pgraph variant="p-16" className="mt-4 text-center md:text-left">
            Loop rents and delivers baby gear & toys by the month in NYC and the
            Bay Area. Every item in our selection arrives sanitized,
            packaging-free and fully assembled.
          </Pgraph>
          <div className="my-4 text-center md:text-left">
            <Button
              label="Learn More"
              primary
              className="mb-4 md:mb-0"
              onClick={()=>{
                router.push('/how-loop-works/')
              }}
            ></Button>
          </div>
        </div>
      </div>
    </section>
  )
}

export { RegistryGuestBanner }
