import React, { ForwardedRef, KeyboardEvent, useEffect, useState } from 'react'
import Image from 'next/image'
// @ts-ignore
import { StyleSheet, TextInput, View } from 'react-native'
import { Pgraph } from '../Pgrah'

const getConfiguredClasses = (
  borderColor: string,
  validationText?: string,
  iconPosition?: string,
  disabled?: boolean
): string => {
  return (
    (validationText
      ? 'border-[#FB7450]'
      : borderColor
      ? borderColor
      : 'border-neutral-3') +
    (iconPosition === 'left' ? ' pl-[33px]' : '') +
    (disabled ? ' cursor-not-allowed disabled:opacity-30 ' : '')
  )
}

interface IInputTextProps {
  /**
   * is field required
   */
  required?: boolean
  /**
   * any validation text triggered by required action
   */
  validationText?: string
  /**
   * icon to be displayed ( as part of validation )
   */
  icon?: string
  /**
   * css class
   */
  className?: string
  /**
   * additional css class on top of base
   */
  additionalClassName?: string
  /**
   * can the input be interacted
   */
  borderColor?: string
  /**
   * can the input be interacted
   */
  disabled?: boolean
  /**
   * placeholder display
   */
  placeHolder?: string
  /**
   * define type of input
   */
  type:
    | 'text'
    | 'password'
    | 'new-password'
    | 'date'
    | 'currency'
    | 'number'
    | 'numeric'
  /**
   * Optional icon position - defaults to left
   */
  iconPosition?: 'left' | 'right'
  /**
   * Optional click handler
   */
  onClick?: () => void
  /**
   * Optional change handler
   */
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  /**
   * Optional change handler
   */
  'data-cy'?: string
  /**
   * Optional cypress test id
   */
  dataCyError?: string
  /**
   * Optional error field cypress test id
   */
}

/**
 * Primary UI component for user interaction
 */
export const InputText = React.forwardRef(function InputTextRef(
  {
    required = false,
    disabled = false,
    type = 'text',
    placeHolder = 'enter a value',
    validationText = '',
    icon = '',
    iconPosition = 'right',
    dataCyError,
    additionalClassName = '',
    borderColor = '',
    ...props
  }: IInputTextProps,
  ref: ForwardedRef<HTMLInputElement>
) {
  const baseClasses: string =
    'flex flex-row bg-snow-white border border-solid rounded w-full outline-0 focus:border-alice p-16px items-start font-circular'
  const configuredClasses: string = getConfiguredClasses(
    borderColor,
    validationText,
    iconPosition,
    disabled
  )
  const seperator = '/'

  const [isNative, setIsNative] = useState<boolean>(false)

  useEffect(() => {
    if (
      window &&
      window.navigator &&
      window.navigator.userAgent &&
      (type == 'date' || type == 'numeric') &&
      (window.navigator.userAgent.match(/iPad/i) ||
        window.navigator.userAgent.match(/iPhone/i) ||
        window.navigator.userAgent.match(/android/i))
    ) {
      setIsNative(true)
    }
  }, [type])

  const numericHandler = (event: KeyboardEvent<HTMLInputElement>) => {
    if (type === 'currency') {
      if (
        event.key === 'Backspace' ||
        event.key === 'Enter' ||
        event.key === 'Delete' ||
        !isNaN(parseInt(event.key))
      ) {
        // Backspace, delete or enter
        if (isNaN(parseInt(event.key))) return

        // Numbers
        const str = event.currentTarget.value.replace('$', '')
        event.currentTarget.value = `$${str}${event.key}`
        event.preventDefault()
      } else {
        event.preventDefault()
      }
      return
    } else if (type === 'date') {
      if (
        event.key !== '0' &&
        event.key !== '1' &&
        event.key !== '2' &&
        event.key !== '3' &&
        event.key !== '4' &&
        event.key !== '5' &&
        event.key !== '6' &&
        event.key !== '7' &&
        event.key !== '8' &&
        event.key !== '9' &&
        event.key !== 'Delete' &&
        event.key !== 'Backspace' &&
        event.key !== 'Tab'
      ) {
        event.preventDefault()
      } else {
        if (
          (event.currentTarget.value.length == 2 ||
            event.currentTarget.value.length == 5) &&
          event.key !== 'Backspace'
        ) {
          event.currentTarget.value += seperator
        }
      }
    } else if (type === 'numeric') {
      if (
        event.key !== '0' &&
        event.key !== '1' &&
        event.key !== '2' &&
        event.key !== '3' &&
        event.key !== '4' &&
        event.key !== '5' &&
        event.key !== '6' &&
        event.key !== '7' &&
        event.key !== '8' &&
        event.key !== '9' &&
        event.key !== 'Delete' &&
        event.key !== 'Backspace' &&
        event.key !== 'Tab'
      ) {
        event.preventDefault()
      }
    }
  }

  const formatCurrency = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    if (type === 'currency') {
      const strValue = e.currentTarget.value.replace('$', '').replace(',', '')
      if (strValue) {
        const fmtValue = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(parseFloat(strValue))
        e.currentTarget.value = fmtValue
      } else {
        e.currentTarget.value = strValue
      }
    }
    //@ts-ignore
    if (props.onChange) props.onChange(e)
  }

  const removeCurrencyFmt = (
    e: React.FocusEvent<HTMLInputElement, Element>
  ) => {
    if (type !== 'currency') return
    const value = e.currentTarget.value.replace(',', '').split('.')[0] || ''
    e.currentTarget.value = value
  }

  const passwordValidations = (
    <>
      <Pgraph variant="p-12" className="pb-1 pt-1">
        Password must include:
      </Pgraph>
      <ul className=" text-12px list-inside list-disc pl-2">
        <li>8 characters minimum</li>
        <li>One number</li>
        <li>One special character</li>
      </ul>
    </>
  )

  const styles = StyleSheet.create({
    textinput: {
      borderWidth: 0.5,
      backgroundColor: '#FDFDFD',
      borderColor: '#BCBCBC',
      borderRadius: '0.25rem',
      borderBlockWidth: '1px',
      borderStyle: 'solid',
      alignItems: 'flex-start',
      flexDirection: 'row',
      display: 'flex',
      paddingTop: 20,
      paddingBottom: 20,
      paddingLeft: 16,
      paddingRight: 16,
      outlineWidth: 0,
    },
  })

  return (
    <div>
      {isNative ? (
        <View>
          <TextInput
            keyboardType="numeric"
            placeholder={placeHolder}
            placeholderTextColor="#BCBCBC"
            style={styles.textinput}
            ref={ref}
            {...props}
            maxLength={type === 'date' ? 10 : 256}
            editable={!disabled}
            selectTextOnFocus={!disabled}
            onKeyPress={numericHandler}
            // onBlur={formatCurrency}
            // onFocus={removeCurrencyFmt}
          />
        </View>
      ) : (
        <div className="relative">
          <input
            type={
              type === 'date' || type === 'numeric'
                ? 'text'
                : type === 'new-password'
                ? 'password'
                : type
            }
            placeholder={placeHolder}
            className={[
              baseClasses,
              configuredClasses,
              additionalClassName,
            ].join(' ')}
            ref={ref}
            {...props}
            maxLength={type === 'date' ? 10 : 256}
            disabled={disabled}
            onKeyDown={numericHandler}
            onBlur={formatCurrency}
            onFocus={removeCurrencyFmt}
          ></input>
          {icon && (
            <div
              className={`${
                iconPosition === 'right'
                  ? 'absolute right-[8px] top-[20px]'
                  : 'absolute left-[8px] top-[20px]'
              }`}
            >
              <Image src={icon} alt={icon} fill={true} />
            </div>
          )}
          {type === 'new-password' && !validationText && (
            <div className="text-neutral-5">{passwordValidations}</div>
          )}
          {validationText && (
            <div>
              <label
                className="text-[#FB7450] text-12px font-normal font-circular"
                data-cy={dataCyError}
              >
                {type === 'new-password' ? passwordValidations : validationText}
              </label>
            </div>
          )}
        </div>
      )}
    </div>
  )
})
