import React from 'react'
import { FooterSection } from './footerSection'
import {
  companySection,
  helpSection,
  needAHandSection,
  shopSection,
  socialSection,
} from '../../config/footer.constants'

/** General Navigation Bar */
export const Footer = () => {
  return (
    <footer className="bg-neutral-6 text-snow-white ">
      <div className="flex flex-col w-full items-center">
        <div className="flex flex-col w-full max-w-[978px]">
          <div className="flex flex-wrap w-full px-48px">
            <FooterSection {...shopSection} />
            <FooterSection {...helpSection} />
            <FooterSection {...companySection} />
            <FooterSection {...socialSection} />
            <FooterSection {...needAHandSection} />
          </div>
          <div className="w-full text-center font-circular text-[14px] leading-18 pb-4 pt-5 md:text-left md:pl-48px">
            © 2023 Loop · loop.baby · Made in the USA
          </div>
        </div>
      </div>
    </footer>
  )
}
