import router from 'next/router'
import React, { Dispatch, SetStateAction, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { REG_EX, URLS } from '../../config'
import { CART_ACTION, ICheckoutGuestData } from '../../interfaces'
import { GUEST_STEP } from '../../pages/checkout/guest'
import { useAuthStore, useCartDispatch, useHooks } from '../../store'
import { Button } from '../Button'
import { Checkbox } from '../Checkbox'
import { Headline } from '../Headline'
import { InputText } from '../InputText'
import { Pgraph } from '../Pgrah'

interface ICheckoutGuestDataProps {
  setGuestData: Dispatch<SetStateAction<ICheckoutGuestData | undefined>>
  setCheckoutStep: Dispatch<SetStateAction<GUEST_STEP>>
}

export const CheckoutGuestData = ({
  setCheckoutStep,
  setGuestData,
}: ICheckoutGuestDataProps) => {
  const dispatch = useCartDispatch()
  const {
    handleSubmit,
    register,
    setValue,
    getValues,
    control,
    formState: { errors },
  } = useForm<ICheckoutGuestData>({
    defaultValues: {
      marketingOptIn: true,
    },
  })
  const { user } = useAuthStore()
  const { reportBreadcrumb } = useHooks()

  useEffect(() => {
    if (user) {
      setValue('emailAddress', user.account.emailAddress)
      setValue('firstName', user.customer.firstName)
      setValue('lastName', user.customer.lastName)
    }
  }, [setValue, user])

  const onSubmit = async (data: ICheckoutGuestData) => {
    reportBreadcrumb(
      'Guest data form submitted',
      { user, form: data },
      'request'
    )
    setGuestData(data)
    dispatch({
      type: CART_ACTION.SET_GIFTER_DATA,
      email: data.emailAddress,
      name: `${data.firstName} ${data.lastName}`,
    })
    setCheckoutStep(GUEST_STEP.PAYMENT)
  }

  const onGoBack = () => {
    dispatch({
      type: CART_ACTION.TOGGLE_CART,
    })
    router.push(URLS.INDEX)
  }

  return (
    <div className="flex justify-center h-full bg-snow-white mx-3">
      <div className="flex flex-col justify-center items-center w-full h-fit">
        <Headline variant="recoleta-l" className="mb-2">
          Your info
        </Headline>
        <form onSubmit={handleSubmit(onSubmit)} className="w-full md:w-1/2">
          <div className="mt-4">
            <Pgraph variant="l-14" className="text-neutral-6">
              First Name
            </Pgraph>
            <InputText
              type="text"
              placeHolder="First"
              {...register('firstName', {
                required: 'Required',
                validate: {
                  format: (v) =>
                    v === '' ||
                    REG_EX.lettersAndSpaces.test(v) ||
                    'Only alphabets are allowed',
                },
              })}
              validationText={errors.firstName?.message}
              data-cy="GUEST_CHECKOUT_DATA_FNAME"
              dataCyError="GUEST_CHECKOUT_ERROR_FNAME"
            />
          </div>
          <div className="mt-4">
            <Pgraph variant="l-14" className="text-neutral-6">
              Last Name
            </Pgraph>
            <InputText
              type="text"
              placeHolder="Last"
              {...register('lastName', {
                required: 'Required',
                validate: {
                  format: (v) =>
                    v === '' ||
                    REG_EX.lettersAndSpaces.test(v) ||
                    'Only alphabets are allowed',
                },
              })}
              validationText={errors.lastName?.message}
              data-cy="GUEST_CHECKOUT_DATA_LNAME"
              dataCyError="GUEST_CHECKOUT_ERROR_LNAME"
            />
          </div>
          <div className="mt-4">
            <Pgraph variant="l-14" className="text-neutral-6">
              Email
            </Pgraph>
            <InputText
              type="text"
              placeHolder="Parents@loveloop.com"
              {...register('emailAddress', {
                required: 'Required',
                pattern: REG_EX.email,
              })}
              validationText={errors.emailAddress?.message}
              data-cy="GUEST_CHECKOUT_DATA_EMAIL"
              dataCyError="GUEST_CHECKOUT_ERROR_EMAIL"
            />
          </div>
          <div className="my-4">
            <Controller
              control={control}
              name="marketingOptIn"
              render={({ field: { onChange, value } }) => (
                <Checkbox
                  checkboxText="Stay in the Loop on new products, expert tips, and more."
                  checked={value}
                  onChange={onChange}
                />
              )}
            />
          </div>
          <Button
            label="Next"
            primary
            className="w-full mb-2 mt-2"
            type="submit"
            size="large"
            data-cy="GUEST_CHECKOUT_DATA_SUBMIT"
          />
          <Pgraph
            variant="l-16"
            className="underline text-center my-4 cursor-pointer"
            onClick={onGoBack}
          >
            Go back
          </Pgraph>
        </form>
      </div>
    </div>
  )
}
