import Link from 'next/link'

interface ILinkProps {
  href?: string
  className?: string
  children: React.ReactNode
  prefetch?: boolean
}

export const LinkTo = ({
  href='',
  children,
  className = 'font-bold font-circular-medium',
  prefetch = false,
  ...props
}: ILinkProps) => {
  return (
    <span className={className}>
      <Link href={href} prefetch={prefetch} {...props}>
        {children}
      </Link>
    </span>
  )
}
