import { IReasonsToLoveLoopProp } from '../interfaces'

const REASONS_TO_LOVE_LOOP: IReasonsToLoveLoopProp[] = [
  {
    headline: "It's Easy to Loop",
    text: "Your picks are hand-delivered, fully assembled, and ready to go. Then, when you're ready to swap or change things up, we come and take them away.",
    imageSrc: '/images/reasons-to-love-loop/its-easy-to-loop.jpg',
    iconSrc: '/images/icons/icon-pdp-one.svg',
    iconHeight: 90,
    iconWidth: 90,
  },
  {
    headline: 'We Clean Like We Mean It',
    text: "Life with kids can get messy—we get it. That's why we created LoopCare™, a standard of cleanliness that ensures the highest level of safety and sanitation.",
    imageSrc: '/images/reasons-to-love-loop/we-clean-like-we-mean-it.jpg',
    iconSrc: '/images/icons/icon-pdp-two.svg',
    iconHeight: 55,
    iconWidth: 55,
  },
  {
    headline: 'Seasoned Parent Approved',
    text: "We curate so you don't have to. These products are worth their weight in gold. They make life easier and give you that second-parent confidence (even if this is your first rodeo).",
    imageSrc: '/images/reasons-to-love-loop/seasoned-parent-approved.jpg',
    iconSrc: '/images/icons/icon-pdp-three.svg',
    iconHeight: 122,
    iconWidth: 122,
  },
  {
    headline: 'Sharing Is Caring (For The Planet)',
    text: 'By circulating products, we extend their life and reduce waste for future generations. Fun fact: products in our inventory are enjoyed 5x longer than single use products. More fun, fewer landfills.',
    imageSrc: '/images/reasons-to-love-loop/sharing-is-caring.jpg',
    iconSrc: '/images/icons/icon-pdp-four.svg',
    iconHeight: 85,
    iconWidth: 92,
  },
]

export { REASONS_TO_LOVE_LOOP }
