import router from 'next/router'
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { REG_EX } from '../../config'
import { CART_ACTION, ICartGiftCertificate } from '../../interfaces'
import { GIFT_STEP } from '../../pages/checkout/giftcard'
import {
  useAuthStore,
  useCartDispatch,
  useCartStore,
  useHooks,
} from '../../store'
import { Button } from '../Button'
import { Checkbox } from '../Checkbox'
import { Headline } from '../Headline'
import { InputText } from '../InputText'
import { InputTextArea } from '../InputTextArea'
import { Pgraph } from '../Pgrah'

interface ICheckoutGuestDataProps {
  setGuestData: Dispatch<SetStateAction<ICartGiftCertificate | undefined>>
  setCheckoutStep: Dispatch<SetStateAction<GIFT_STEP>>
  userData?: ICartGiftCertificate
}

export const CheckoutGiftData = ({
  setCheckoutStep,
  setGuestData,
  userData,
}: ICheckoutGuestDataProps) => {
  const dispatch = useCartDispatch()
  const {
    handleSubmit,
    register,
    setValue,
    control,
    watch,
    formState: { errors },
  } = useForm<ICartGiftCertificate>({
    defaultValues: {
      serviceAreaConfirmed: false,
      printAtHome: false,
    },
  })
  const { user } = useAuthStore()
  const {
    registryCart: { giftCertificates },
  } = useCartStore()
  const [serviceAreaConfirmed, setServiceAreaConfirmed] = useState(true)
  const { reportBreadcrumb } = useHooks()

  const charCount = watch('message')?.length || 0

  useEffect(() => {
    reportBreadcrumb(
      'Gift checkout started',
      { user, userData, query: router.query },
      'navigation'
    )
    if (user) {
      setValue('sender.email', user.account.emailAddress)
      setValue('sender.firstName', user.customer.firstName)
      setValue('sender.lastName', user.customer.lastName)
      setValue('name', user.customer.firstName + ' ' + user.customer.lastName)
    }
    if (userData) {
      setValue('message', userData.message)
      setValue('printAtHome', userData.printAtHome)
      setValue('serviceAreaConfirmed', userData.serviceAreaConfirmed)
      setValue('recipient.name', userData.recipient.name)
      setValue('recipient.email', userData.recipient.email)
    }
    setValue('quantity', 1)
    setValue('amount', parseInt(router.query.amount as string))
  }, [reportBreadcrumb, setValue, user, userData])

  const onSubmit = async (giftCertificate: ICartGiftCertificate) => {
    reportBreadcrumb('Gift data form submitted', { giftCertificate }, 'request')
    if (!watch('serviceAreaConfirmed')) {
      setServiceAreaConfirmed(false)
      return
    }

    setServiceAreaConfirmed(true)
    giftCertificate.sender.name =
      giftCertificate.sender.firstName + ' ' + giftCertificate.sender.lastName
    setGuestData(giftCertificate)

    if (giftCertificates.length) {
      dispatch({
        type: CART_ACTION.REMOVE_GIFT_CERTIFICATE,
        index: 0,
      })
    }

    giftCertificate.validForAnnualMembership = !!router.query.annual

    dispatch({
      type: CART_ACTION.ADD_GIFT_CERTIFICATE,
      giftCertificate,
    })
    setCheckoutStep(GIFT_STEP.PAYMENT)
  }

  const canGoNext = !watch('serviceAreaConfirmed')

  const onGoBack = () => {
    router.back()
  }

  return (
    <div className="flex justify-center h-full bg-snow-white mx-3">
      <div className="flex flex-col justify-center items-center w-full h-fit">
        <Headline variant="recoleta-l" className="mb-2">
          Your info
        </Headline>
        <form onSubmit={handleSubmit(onSubmit)} className="w-full md:w-1/2">
          <div className="mt-4">
            <Pgraph variant="l-14" className="text-neutral-6">
              Your first name
            </Pgraph>
            <InputText
              type="text"
              placeHolder="First"
              {...register('sender.firstName', {
                required: 'Required',
                validate: {
                  format: (v) =>
                    v === '' ||
                    REG_EX.lettersAndSpaces.test(v.replaceAll(' ', '')) ||
                    'Only alphabets are allowed',
                },
              })}
              validationText={errors.sender?.firstName?.message}
              data-cy="GIFTING_SENDER_FNAME"
            />
          </div>
          <div className="mt-4">
            <Pgraph variant="l-14" className="text-neutral-6">
              Your last name
            </Pgraph>
            <InputText
              type="text"
              placeHolder="Last"
              {...register('sender.lastName', {
                required: 'Required',
                validate: {
                  format: (v) =>
                    v === '' ||
                    REG_EX.lettersAndSpaces.test(v.replaceAll(' ', '')) ||
                    'Only alphabets are allowed',
                },
              })}
              validationText={errors.sender?.lastName?.message}
              data-cy="GIFTING_SENDER_LNAME"
            />
          </div>
          <div className="mt-4">
            <Pgraph variant="l-14" className="text-neutral-6">
              Your email address
            </Pgraph>
            <InputText
              type="text"
              placeHolder="Parents@loveloop.com"
              {...register('sender.email', {
                required: 'Required',
                pattern: REG_EX.email,
              })}
              validationText={errors.sender?.email?.message}
              data-cy="GIFTING_SENDER_EMAIL"
            />
          </div>
          <div className="my-4">
            <Controller
              control={control}
              name="serviceAreaConfirmed"
              render={({ field: { onChange, value } }) => (
                <Pgraph variant="l-16" className="text-neutral-5">
                  <Checkbox
                    checkboxText="I confirm that my recipient lives within our service areas (required)"
                    checked={value}
                    onChange={onChange}
                    addTextClassName={'leading-22'}
                    data-cy="GIFTING_CONFIRM_SERVICE_AREA"
                  />
                </Pgraph>
              )}
            />
          </div>
          <div className="my-4">
            <Controller
              control={control}
              name="printAtHome"
              render={({ field: { onChange, value } }) => (
                <Pgraph variant="p-16" className="text-neutral-5">
                  <Checkbox
                    checkboxText="I'd prefer to print gift card at home instead of delivering electronically (optional)"
                    checked={value}
                    onChange={onChange}
                    data-cy="GIFTING_PRINT_AT_HOME"
                  />
                </Pgraph>
              )}
            />
          </div>
          {!watch('printAtHome') && (
            <>
              <div className="mt-4">
                <Pgraph variant="l-14" className="text-neutral-6">
                  Recipient&apos;s name
                </Pgraph>
                <InputText
                  type="text"
                  placeHolder="Their name"
                  {...register('recipient.name', {
                    required: 'Required',
                    validate: {
                      format: (v) =>
                        v === '' ||
                        REG_EX.lettersAndSpaces.test(v.replaceAll(' ', '')) ||
                        'Only alphabets are allowed',
                    },
                  })}
                  validationText={errors.recipient?.name?.message}
                  data-cy="GIFTING_RECIPIENT_NAME"
                />
              </div>
              <div className="mt-4">
                <Pgraph variant="l-14" className="text-neutral-6">
                  Recipient&apos;s email address
                </Pgraph>
                <InputText
                  type="text"
                  placeHolder="Their email address"
                  {...register('recipient.email', {
                    required: 'Required',
                    validate: (v) =>
                      REG_EX.email.test(v) || 'Invalid email address',
                  })}
                  validationText={errors.sender?.email?.message}
                  data-cy="GIFTING_RECIPIENT_EMAIL"
                />
              </div>
            </>
          )}
          <div className="border-y border-y-neutral-2 mb-5">
            <Pgraph variant="p-14" className="my-4">
              Add a gift message
            </Pgraph>
            <InputTextArea
              placeHolder=""
              {...register('message', {
                maxLength: {
                  value: 255,
                  message: 'Maximum length is 255 characters',
                },
              })}
              data-cy="GIFTING_MESSAGE"
            />
            {errors.message?.message && (
              <Pgraph variant="p-14" className="my-1 text-caution-2">
                {errors.message?.message}
              </Pgraph>
            )}
            <Pgraph
              variant="p-12"
              className={`${
                charCount > 255 ? 'text-caution-2' : 'text-neutral-5'
              } text-right -5 mt-1 mb-4`}
            >
              {charCount}/255 characters
            </Pgraph>
          </div>
          {!serviceAreaConfirmed && (
            <Pgraph variant="p-14" className="text-caution-2 text-center">
              Please confirm that the recipient lives in a service area covered
              by Loop.
            </Pgraph>
          )}
          <Button
            label="Next"
            primary
            className="w-full mb-2 mt-2"
            type="submit"
            size="large"
            disabled={canGoNext}
            data-cy="GIFTING_DATA_SUBMIT"
          />
          <Pgraph
            variant="l-16"
            className="underline text-center my-4 cursor-pointer"
            onClick={onGoBack}
          >
            Go back
          </Pgraph>
        </form>
      </div>
    </div>
  )
}
