import React from 'react'
import { Headline, Pgraph } from '..'
import Image from 'next/image'

function HLWQuote() {
  return (
    <section className="w-full bg-heavy-cream">
      <div className="max-w-5xl mx-auto py-4 px-4 md:mb-0 md:pb-0">
        <div className="flex flex-col md:flex-row md:items-center">
          <div className="w-full text-center order-2 md:order-0">
            <div className="w-full mx-auto max-w-[180px] md:max-w-[185px] lg:max-w-[280px]">
              <img
                src="/images/hlw/hlw-ilustration2.png"
                alt="HLW Ilustration"
                className="max-w-[247px]"
              />
            </div>
          </div>
          <div className=" pt-4 pb-5 px-5 text-center md:order-2">
            <div className="relative">
              <div className="-mt-2 absolute">
                <Image
                  src="/images/double-quotes.svg"
                  width={31}
                  height={27}
                  alt="Double quotes"
                />
              </div>
              <Headline
                variant="recoleta-s"
                className="text-neutral-6 mb-3 md:text-24px lg:text-28px lg:leading-34 md:mb-4 max-w-2xl"
              >
                Easy to order. Product in great condition. Love that I can give
                it back when my little one has moved on!&quot;
              </Headline>
              <Pgraph
                variant="l-14"
                className="text-center text-poppy lg:text-16px lg:leading-22"
              >
                Shaila | San Francisco, CA
              </Pgraph>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export { HLWQuote }
