import React, { ForwardedRef } from 'react'
import Image from 'next/image'

const getConfiguredClasses = (
  validationText?: string,
  iconPosition?: string
): string => {
  return (
    (validationText ? 'border-caution-2' : 'border-neutral-3') +
    (iconPosition === 'left' ? ' pl-[33px]' : '')
  )
}

interface ISelectInputProps {
  /**
   * is field required
   */
  required?: boolean
  /**
   * any validation text triggered by required action
   */
  validationText?: string
  /**
   * icon to be displayed ( as part of validation )
   */
  icon?: string
  /**
   * css class
   */
  className?: string
  /**
   * can the input be interacted
   */
  disabled?: boolean
  /**
   * placeholder display
   */
  placeHolder?: string
  /**
   * Optional icon position - defaults to left
   */
  iconPosition?: 'left' | 'right'
  /**
   * iconSize (e.g. "20", "15", etc.)
   */
  iconSize?: number
  /**
   * Optional click handler
   */
  onClick?: () => void
  /**
   * Optional change handler
   */
  options: ISelectInputOptions[]
  noDefaultSelected?: boolean
}

export interface ISelectInputOptions {
  value: string
  label: string
}

/**
 * Primary UI component for user interaction
 */
export const SelectInput = React.forwardRef(function SelectInputRef(
  {
    required = false,
    disabled = false,
    placeHolder = 'enter a value',
    validationText = '',
    icon = '',
    iconPosition = 'right',
    options,
    noDefaultSelected = false,
    iconSize = 14,
    ...props
  }: ISelectInputProps,
  ref: ForwardedRef<HTMLSelectElement>
) {
  const baseClasses: string =
    'font-circular bg-snow-white border border-solid rounded w-full outline-0 focus:border-alice p-16px appearance-none pr-50px bg-no-repeat bg-dropdown bg-right-1rem-center hover:cursor-pointer'
  const configuredClasses: string = getConfiguredClasses(
    validationText,
    iconPosition
  )

  return (
    <div className="relative">
      <select
        placeholder={placeHolder}
        className={[baseClasses, configuredClasses].join(' ')}
        ref={ref}
        defaultValue={noDefaultSelected ? '' : undefined}
        {...props}
        style={{
          backgroundImage: 'url("/images/chevron-down.svg")',
        }}
      >
        {noDefaultSelected && (
          <option disabled value="">
            {placeHolder}
          </option>
        )}
        {options.map((o) => (
          <option value={o.value} key={o.value}>
            {o.label}
          </option>
        ))}
      </select>
      {icon && (
        <div
          className={`cursor-pointer ${
            iconPosition === 'right'
              ? 'absolute right-[8px] top-[20px]'
              : 'absolute left-[8px] top-[20px]'
          }`}
        >
          <Image
            src={icon}
            alt={icon}
            width={iconSize}
            height={iconSize}
          ></Image>
        </div>
      )}
      {validationText && (
        <div>
          <label className="text-caution-2 text-12px font-normal font-circular">
            {validationText}
          </label>
        </div>
      )}
    </div>
  )
})
