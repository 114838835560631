import { Elements } from '@stripe/react-stripe-js'
import Link from 'next/link'
import { useRouter } from 'next/router'
import {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  Button,
  Headline,
  Loading,
  OrderSummary,
  ORDER_SUMMARY_TYPE,
  Pgraph,
} from '..'
import {
  LOCAL_STORAGE,
  PERFORMACE_TRACKING,
  URLS,
  getRequestHeaders,
} from '../../config'
import {
  AUTH_ACTION,
  CART_ACTION,
  CHECKOUT_PAYMENT_MODE,
  CHECKOUT_STEPS,
  ICheckoutApi,
  IStripeCard,
} from '../../interfaces'
import { CartApi, PaymentApi } from '../../services'
import {
  getLocalAffiliate,
  removeLocalStorage,
  useAuthDispatch,
  useAuthStore,
  useCartDispatch,
  useCartStore,
  useHooks,
} from '../../store'
import PaymentForm from './form'
import { trackEvent } from '../../config/analytics'
import { GiftcardForm } from './giftcardForm'
import getStripe from '../../services/stripe'
import { DateTime } from 'luxon'

const stripePromise = getStripe()

export const CheckoutPayment = () => {
  const [activeMode, setActiveMode] = useState<CHECKOUT_PAYMENT_MODE>(
    CHECKOUT_PAYMENT_MODE.INITIALIZING
  )
  const initializationStarted = useRef(false)
  const [card, setCard] = useState<IStripeCard>()
  const stripeToken = useRef<string | null>(null)
  const [processing, setProcessing] = useState(false)
  const [selectingCard, setSelectingCard] = useState(false)
  const [orderSummaryOpen, setOrderSummaryOpen] = useState(true)
  const [fetchedCheckout, setFetchedCheckout] = useState<
    ICheckoutApi | null | undefined
  >(undefined)
  const [errorPopup, setErrorPopup] = useState<ReactNode>()
  const cartDispatch = useCartDispatch()
  const authDispatch = useAuthDispatch()
  const { cart, creditConsumption } = useCartStore()
  const { credentials, user, paymentMethods } = useAuthStore()
  const router = useRouter()
  const { reportBug, reportBreadcrumb } = useHooks()

  const getCheckout = useCallback((accessToken: string) => {
    return CartApi.fetchCheckout(accessToken)
  }, [])

  const getPayIntent = useCallback((accessToken: string) => {
    return PaymentApi.createPayIntent(accessToken)
  }, [])

  const getPaymentMethods = useCallback((accessToken: string) => {
    return PaymentApi.fetchPaymentInfo(accessToken)
  }, [])

  const initialize = useCallback(async () => {
    const accessToken = credentials?.accessToken
    if (initializationStarted.current || !accessToken) return

    initializationStarted.current = true

    // Affiliate tracking
    const affiliate = getLocalAffiliate()
    if (affiliate) {
      const date = DateTime.fromISO(affiliate.createdAt)

      if (date.diffNow().days < 30) {
        reportBreadcrumb('Affiliate tracking', { affiliate })
        fetch(`${process.env.NEXT_PUBLIC_TRANSACTIONAL_URL}`, {
          method: 'POST',
          headers: getRequestHeaders(credentials.accessToken),
          body: JSON.stringify({ transactionId: affiliate.transactionId }),
        })
          .then(() => removeLocalStorage([LOCAL_STORAGE.AFFILIATE]))
          .catch(() =>
            reportBreadcrumb('Affiliate fetch failed', { affiliate }, 'error')
          )
      }
    }

    const paymentMethodsPromise = getPaymentMethods(accessToken)
    const checkoutPromise = getCheckout(accessToken)
    const payIntentPromise = getPayIntent(accessToken)
    const [paymentMethods, checkout, payIntent] = await Promise.all([
      paymentMethodsPromise,
      checkoutPromise,
      payIntentPromise,
    ])

    setFetchedCheckout(checkout)

    if (!paymentMethods || !checkout || !checkout?.success || !payIntent) {
      // Verify checkout
      if (checkout?.invalidItems && checkout.invalidItems.length) {
        trackEvent('PRODUCT_NOT_AVAILABLE_IN_AREA', {
          errors: checkout.errors,
          invalidItems: checkout.invalidItems,
        })
        let start = 'The '
        let situation = "isn't"
        let reference = 'it'
        if (checkout.invalidItems.length > 1) {
          start = ''
          situation = "aren't"
          reference = 'them'
        }

        const products = checkout.invalidItems
          .map((prod) => prod.name)
          .join(', ')

        const fmtErrMsg: ReactNode = (
          <>
            Uh oh! {start}
            <span className="font-circular-medium">{products}</span> {situation}{' '}
            available for delivery to your address. Please remove {reference}{' '}
            from your cart before placing your order.
          </>
        )

        setErrorPopup(fmtErrMsg)
        // setTimeout(() => {
        //   setErrorPopup(undefined)
        // }, 3000)
      }

      setActiveMode(CHECKOUT_PAYMENT_MODE.ERROR)
      reportBug(new Error('Checkout initialization error'), {
        paymentMethods,
        checkout,
        payIntent,
      })
      return
    }

    // Set stripe token
    stripeToken.current = payIntent.setupIntent.clientSecret

    // Set card
    const card = paymentMethods.defaultPaymentMethodId
      ? paymentMethods.cards.find(
          (c) => c.id === paymentMethods.defaultPaymentMethodId
        )
      : paymentMethods.cards[0]
    if (card) {
      setCard(card)
      setActiveMode(CHECKOUT_PAYMENT_MODE.USE_EXISTING_CARD)
    } else {
      setActiveMode(CHECKOUT_PAYMENT_MODE.ADD_CARD)
    }
  }, [
    credentials?.accessToken,
    getCheckout,
    getPayIntent,
    getPaymentMethods,
    reportBreadcrumb,
    reportBug,
  ])

  useEffect(() => {
    initialize()
  }, [initialize])

  const retryInitialization = () => {
    initializationStarted.current = false
    setActiveMode(CHECKOUT_PAYMENT_MODE.INITIALIZING)
    setCard(undefined)
    stripeToken.current = null
    initialize()
  }

  const selectCard = async (card: IStripeCard) => {
    try {
      if (card.id === paymentMethods?.defaultCard) {
        setActiveMode(CHECKOUT_PAYMENT_MODE.USE_EXISTING_CARD)
        return
      }

      if (!credentials?.accessToken) return
      setProcessing(true)
      const trackingPerformanceObj = {
        startTime: new Date().getTime(),
        newCard: false,
        endTime: 0,
        status: false,
      }

      const saveCardRes = await PaymentApi.setDefaultPayment(
        credentials.accessToken,
        card.id
      )
      trackingPerformanceObj.endTime = new Date().getTime()
      trackingPerformanceObj.status = !!saveCardRes

      trackEvent(
        PERFORMACE_TRACKING.CHECKOUT_PAYMENT_STEP,
        trackingPerformanceObj
      )

      if (!saveCardRes) throw new Error('Error saving card')

      reportBreadcrumb('Default card changed', { card })
      const updCardsRes = await PaymentApi.fetchPaymentInfo(
        credentials.accessToken
      )
      if (updCardsRes?.success) {
        authDispatch({
          type: AUTH_ACTION.SET_PAYMENT_METHODS,
          cards: updCardsRes.cards,
          defaultCard: updCardsRes.defaultPaymentMethodId,
        })
      }
      setActiveMode(CHECKOUT_PAYMENT_MODE.USE_EXISTING_CARD)
    } catch (error) {
      console.error(error)
      reportBug(error, { card })
    } finally {
      setProcessing(false)
    }
  }

  const placeOrder = () => {
    reportBreadcrumb('Checkout order placed', { user, cart })
    trackEvent('Checkout Completed', {
      loopCustomerId: user?.customer?.loopCustomerId,
      cart: cart,
    })
    router.push(URLS.CHECKOUT.COMPLETED)
  }

  const onGoBack = () => {
    cartDispatch({
      type: CART_ACTION.FORCE_CHECKOUT_STEP,
      step: CHECKOUT_STEPS.ADDRESS_3,
    })
  }

  const invalidItems = useMemo(() => {
    return fetchedCheckout?.invalidItems || []
  }, [fetchedCheckout?.invalidItems])

  const cantCheckout = useMemo(() => {
    // Loading resources
    if (processing) return true

    // Guest checkout
    if (fetchedCheckout === undefined) return false

    // Fetch checkout API loading error (network)
    if (fetchedCheckout === null) return true

    // Any other errors
    return (
      !fetchedCheckout.success ||
      (fetchedCheckout.errors && fetchedCheckout.errors.length > 0) ||
      (fetchedCheckout.invalidItems && fetchedCheckout.invalidItems.length > 0)
    )
  }, [fetchedCheckout, processing])

  return (
    <div className="flex flex-col items-center mx-3 relative">
      {errorPopup && (
        <div className="absolute z-20 -top-5 bg-caution-3 shadow-zipReminder w-[240px]">
          <div className="absolute right-2 top-1 z-30 cursor-pointer">
            <i
              className="loop-icon-times text-14px"
              onClick={() => setErrorPopup(undefined)}
            />
          </div>
          <div className="w-full py-3 px-4 text-center font-circular text-14px leading-18">
            {errorPopup}
          </div>
        </div>
      )}
      <Headline variant="recoleta-l" className="mb-2">
        Payment method
      </Headline>
      <div className="w-full md:w-2/3 mx-auto">
        <OrderSummary
          open={orderSummaryOpen}
          setOpen={setOrderSummaryOpen}
          showCouponForm={true}
          summaryData={{
            type: ORDER_SUMMARY_TYPE.REGULAR,
            apiCart: cart,
            creditConsumption,
          }}
          invalidItems={invalidItems}
        />
      </div>

      {activeMode === CHECKOUT_PAYMENT_MODE.ERROR && (
        <div className="my-4 w-full flex flex-col items-center justify-center">
          <Pgraph variant="p-16">
            {errorPopup
              ? errorPopup
              : 'There was an error initializing the checkout. Please try again'}
          </Pgraph>
          <div className="mt-4">
            <Button
              label="Try again"
              primary={true}
              onClick={retryInitialization}
            />
          </div>
        </div>
      )}

      {activeMode === CHECKOUT_PAYMENT_MODE.INITIALIZING && (
        <div className="my-6">
          <Loading />
        </div>
      )}
      {activeMode === CHECKOUT_PAYMENT_MODE.USE_EXISTING_CARD && (
        <div className="flex flex-col w-full max-w-md">
          <Pgraph variant="p-14">Selected payment method:</Pgraph>
          {!selectingCard && (
            <div className="flex justify-between items-center">
              <Pgraph variant="l-14">
                {card?.brand.toUpperCase()} ending in {card?.last4}
              </Pgraph>
              <Button
                label="Change"
                size="small"
                primary={false}
                onClick={() => setSelectingCard(true)}
              />
            </div>
          )}
          {selectingCard && (
            <div className="flex flex-col">
              {paymentMethods?.cards?.map((c) => (
                <div
                  className="flex justify-between items-center my-3"
                  key={c.id}
                >
                  <Pgraph variant="l-14">
                    {c?.brand.toUpperCase()} ending in {c.last4}
                    {c.id === paymentMethods.defaultCard && ' - DEFAULT'}
                  </Pgraph>
                  <Button
                    label="Select"
                    size="small"
                    primary={true}
                    onClick={() => {
                      selectCard(c)
                      setSelectingCard(false)
                    }}
                    loading={processing}
                    disabled={processing}
                    className="min-w-[130px]"
                  />
                </div>
              ))}
              <Button
                label="Add new"
                size="small"
                primary={true}
                className="w-[130px] my-3 self-end"
                onClick={() => setActiveMode(CHECKOUT_PAYMENT_MODE.ADD_CARD)}
                disabled={processing}
              />
            </div>
          )}
          {!selectingCard && (
            <>
              {/* {fetchedCheckout !== undefined && (
                <PaymentFormErrors fetchedCheckout={fetchedCheckout} />
              )} */}
              <GiftcardForm />
              <Button
                className="w-full mt-6"
                label="Place Order"
                disabled={cantCheckout}
                primary={true}
                onClick={placeOrder}
              />
            </>
          )}
        </div>
      )}
      {activeMode === CHECKOUT_PAYMENT_MODE.ADD_CARD && !stripeToken.current && (
        <div className="my-4">
          <Loading />
        </div>
      )}
      {activeMode === CHECKOUT_PAYMENT_MODE.ADD_CARD && stripeToken.current && (
        <div className="w-full">
          <Elements
            stripe={stripePromise}
            options={{
              clientSecret: stripeToken.current,
              appearance: {
                theme: 'flat',
                variables: {
                  borderRadius: '0.25rem',
                  colorDanger: '#9F0000',
                  colorBackground: '#fdfdfd',
                  fontFamily: 'Circular Medium, Helvetica, Arial, sans-serif',
                  fontSizeBase: '14px',
                  fontWeightNormal: '500',
                  fontLineHeight: '17px',
                },
                rules: {
                  '.Input': {
                    border: '1px solid black',
                  },
                },
              },
            }}
          >
            <PaymentForm />
          </Elements>
        </div>
      )}
      <Pgraph
        variant="l-16"
        className="z-10 underline text-center my-4 cursor-pointer"
        onClick={onGoBack}
      >
        Go back
      </Pgraph>
      <Pgraph variant="p-12" className="text-center mb-4 mt-4 max-w-md">
        By confirming your order, you agree to our{' '}
        <span className="underline cursor-pointer">
          <Link href={URLS.TERMS_OF_SERVICE} passHref>
            terms of service
          </Link>
        </span>{' '}
        and{' '}
        <span className="underline cursor-pointer">
          <Link href={URLS.PRIVACY_POLICY} passHref>
            privacy policy
          </Link>
        </span>
        . Your subscription will automatically renew until you cancel.
      </Pgraph>
    </div>
  )
}
