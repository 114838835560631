import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js'
import React, { FormEvent, useMemo, useState } from 'react'
import { LOCAL_STORAGE, PERFORMACE_TRACKING, URLS } from '../../config'
import {
  setLocalObject,
  useAuthStore,
  useCartStore,
  useHooks,
} from '../../store'
import { Button } from '../Button'
import { Pgraph } from '../Pgrah'
import { GiftcardForm } from './giftcardForm'
import { trackEvent } from '../../config/analytics'
import { ICheckoutApi } from '../../interfaces'
import { useRouter } from 'next/router'

interface IPaymentFormProps {
  isAnonymousCheckout?: boolean
  isGiftCardCheckout?: boolean
  fetchedCheckout?: ICheckoutApi | null
}

function PaymentForm({
  isAnonymousCheckout = false,
  isGiftCardCheckout = false,
  fetchedCheckout = undefined,
}: IPaymentFormProps) {
  const stripe = useStripe()
  const elements = useElements()
  const [errorMessage, setErrorMessage] = useState<string>()
  const [processing, setProcessing] = useState(false)
  const { credentials, user } = useAuthStore()
  const { registryCart, cart } = useCartStore()
  const { reportBug, reportBreadcrumb } = useHooks()
  const router = useRouter()

  const stripeSubmitAnonymous = async () => {
    try {
      reportBreadcrumb('Anonymous checkout started', { registryCart })
      setProcessing(true)
      setLocalObject(LOCAL_STORAGE.REGISTRY_CART, registryCart)
      if (!stripe || !elements) return

      const trackingPerformanceObj = {
        startTime: new Date().getTime(),
        newCard: true,
        endTime: '',
        status: '',
      }
      trackEvent(
        PERFORMACE_TRACKING.CHECKOUT_GUEST_PAYMENT_STEP,
        trackingPerformanceObj
      )
      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${process.env.NEXT_PUBLIC_BASE_URL}${
            isGiftCardCheckout
              ? URLS.CHECKOUT.GIFT_CARD_COMPLETED
              : URLS.CHECKOUT.GUEST_COMPLETED
          }`, // This whould redirect to the api endpoint with the access token as then redirect back to the website
        },
      })
      // If the setup is successful nothing below here gets executed

      if (error) {
        setErrorMessage(error.message)
        return
      }
    } catch (error) {
      console.error(error)
      reportBug(error)
    } finally {
      setProcessing(false)
    }
  }

  const stripeSubmitLogged = async () => {
    try {
      reportBreadcrumb('Member checkout started', { cart, user })
      setProcessing(true)
      if (!stripe || !elements || !credentials?.accessToken) return

      const trackingPerformanceObj = {
        startTime: new Date().getTime(),
        newCard: true,
        endTime: '',
        status: '',
      }
      trackEvent(
        PERFORMACE_TRACKING.CHECKOUT_PAYMENT_STEP,
        trackingPerformanceObj
      )

      const returnUrl = `${process.env.NEXT_PUBLIC_BASE_URL}${URLS.CHECKOUT.COMPLETED}`

      const { error } = await stripe.confirmSetup({
        elements,
        confirmParams: {
          return_url: returnUrl, // This whould be replace to the api endpoint with the access token as then redirect back to the website
        },
        redirect: 'if_required',
      })
      // If the setup is successful nothing below here gets executed

      if (error) {
        setErrorMessage(error.message)
        return
      }

      router.push(returnUrl)
    } catch (error) {
      console.error(error)
      reportBug(error)
    } finally {
      setProcessing(false)
    }
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (isAnonymousCheckout || isGiftCardCheckout) {
      stripeSubmitAnonymous()
    } else {
      stripeSubmitLogged()
    }
  }

  const cantCheckout = useMemo(() => {
    // Loading resources
    if (!stripe || processing) return true

    // Guest checkout
    if (fetchedCheckout === undefined) return false

    // Fetch checkout API loading error (network)
    if (fetchedCheckout === null) return true

    // Any other errors
    return (
      !fetchedCheckout.success ||
      (fetchedCheckout.errors && fetchedCheckout.errors.length > 0) ||
      (fetchedCheckout.invalidItems && fetchedCheckout.invalidItems.length > 0)
    )
  }, [fetchedCheckout, processing, stripe])

  return (
    <div className="flex justify-center h-full bg-snow-white mx-3">
      <div className="flex flex-col justify-center items-center w-full h-fit">
        <PaymentElement />
        <form onSubmit={handleSubmit} className="w-full md:w-1/2">
          {errorMessage && (
            <Pgraph
              variant="l-14"
              className="my-3 text-center text-caution-2"
              data-cy="PAYMENT_FORM_ERROR"
            >
              {errorMessage}
            </Pgraph>
          )}
          {!isAnonymousCheckout && !isGiftCardCheckout && <GiftcardForm />}
          <Button
            label="Place Order"
            primary
            className="w-full mb-2 mt-4"
            type="submit"
            size="large"
            disabled={cantCheckout}
            data-cy="PAYMENT_FORM_SUBMIT"
          />
        </form>
      </div>
    </div>
  )
}

export default PaymentForm
