import { getRequestHeaders } from '../config'
import { fetchTimeoutRetry } from '../config/utilities'
import { ISubscription } from '../interfaces'

export interface IReturnItemsRequest {
  notes?: string
  productsToReturn?: string[]
  cancelMembership: boolean
  productVariantsToReturn?: IProductsToReturn[]
}

export interface IProductsToReturn {
  productId?: string
  variantId?: string
  quantity: number
}

const fetchSubscriptions = async (accessToken: string) => {
  try {
    const result = await fetchTimeoutRetry(
      `${process.env.NEXT_PUBLIC_SUBSCRIPTIONS_URL}/v2/subscriptions`,
      {
        method: 'GET',
        mode: 'cors',
        headers: getRequestHeaders(accessToken),
      }
    )
    if (result.ok) {
      return (await result.json()) as ISubscription
    }
    return undefined
  } catch (error) {
    return undefined
  }
}

const returnItems = async (data: any, accessToken?: string) => {
  try {
    const result = await fetchTimeoutRetry(
      `${process.env.NEXT_PUBLIC_SUBSCRIPTIONS_URL}/return-items`,
      {
        method: 'POST',
        mode: 'cors',
        headers: getRequestHeaders(accessToken),
        body: JSON.stringify(data),
      }
    )

    if (!result.ok || result.status !== 200)
      throw new Error(
        'There was an error returning your items, please try again'
      )

    const jsonResponse = await result.json()
    return jsonResponse
  } catch (error: any) {
    console.error(error)
    return null
  }
}

const cancelMembership = async (data: any, accessToken?: string) => {
  try {
    const result = await fetchTimeoutRetry(
      `${process.env.NEXT_PUBLIC_SUBSCRIPTIONS_URL}/return-items`,
      {
        method: 'POST',
        mode: 'cors',
        headers: getRequestHeaders(accessToken),
        body: JSON.stringify(data),
      }
    )

    if (!result.ok || result.status !== 200)
      throw new Error(
        'There was an error cancelling your membership, please try again'
      )

    const jsonResponse = await result.json()
    return jsonResponse
  } catch (error: any) {
    console.error(error)
    return null
  }
}

export { fetchSubscriptions, returnItems, cancelMembership }
