import React, { useEffect, useMemo, useState } from 'react'
import { Button, Headline, ProductCarouselCard } from '..'
import { PRODUCT_CONFIG, URLS } from '../../config'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import { CartApi, ProductsApi } from '../../services'
import { CART_ACTION, CHECKOUT_STEPS, IProductSummary } from '../../interfaces'
import {
  useAuthStore,
  useCartDispatch,
  useCartStore,
  useHooks,
} from '../../store'
import { trackEvent } from '../../config/analytics'
import { useRouter } from 'next/router'

interface ITopProductsProps {
  /** Optional additional class names for styling */
  className?: string
  /** Override original title */
  title?: string
  /** Override CTA button label */
  ctaLabel?: string
  /** Display newborn items (0-3 months)? */
  showNewbornItems?: boolean
}

interface IButtonGroupProps {
  next: () => void
  previous: () => void
  carouselState: {
    currentSlide: number
    totalItems: number
  }
}

const Slides_Per_Page = 2

const ButtonGroup = ({ next, previous, carouselState }: IButtonGroupProps) => {
  // use carouselState to disable button when in the last slide, for example.
  return (
    <div className="absolute -bottom-0 right-0 mr-3 hidden lg:block">
      <span onClick={() => previous()} className="mr-4 cursor-pointer">
        <i
          className={`loop-icon-arrow-long text-24px text-black transition ease-in duration-400 rotate-180 inline-block ${
            carouselState.currentSlide === 0
              ? 'cursor-not-allowed opacity-25'
              : ''
          }`}
        ></i>
      </span>
      <span onClick={() => next()} className="cursor-pointer">
        <i
          className={`loop-icon-arrow-long text-24px text-black transition ease-in duration-400 inline-block ${
            carouselState.totalItems - carouselState.currentSlide ===
            Slides_Per_Page
              ? 'cursor-not-allowed opacity-25'
              : ''
          }`}
        ></i>
      </span>
    </div>
  )
}

function MembershipTopProductsMolecule({
  className,
  title,
  showNewbornItems,
}: ITopProductsProps) {
  const [isProcessing, setIsProcessing] = useState(false)
  const { membershipProducts, cart } = useCartStore()
  const { user } = useAuthStore()
  const { credentials, subscriptionActive } = useAuthStore()
  const cartDispatch = useCartDispatch()
  const { getCartData, reportBug } = useHooks()
  const router = useRouter()

  const { lines } = useMemo(
    () =>
      getCartData({
        cart,
      }),
    [cart, getCartData]
  )

  const [topProducts, setTopProducts] = useState<IProductSummary[]>([])
  const { basicFilters: filters } = useCartStore()

  const newbornCategory = useMemo(() => filters?.ages[0].id || 0, [filters])

  async function addMembershipToCart(membership: IProductSummary) {
    try {
      setIsProcessing(true)

      const currentMembership = lines.find(
        (line) =>
          line.productId === membershipProducts.annual?.bigCommerceProductId
      )

      const membershipId = currentMembership
        ? (currentMembership as any).id
        : undefined

      if (currentMembership && membershipId && credentials?.accessToken) {
        const deleted = await CartApi.removeCartItem(
          credentials.accessToken,
          membershipId
        )
        if (deleted) {
          const apiCart = await CartApi.addCartItem(credentials.accessToken, [
            {
              productId: membership.bigCommerceProductId,
              quantity: 1,
            },
          ])
          if (apiCart) {
            cartDispatch({
              type: CART_ACTION.SET_API_CART,
              apiCart,
            })
          }
        }
      } else {
        if (credentials?.accessToken) {
          const apiCart = await CartApi.addCartItem(credentials.accessToken, [
            {
              productId: membership.bigCommerceProductId,
              quantity: 1,
            },
          ])
          if (apiCart) {
            cartDispatch({
              type: CART_ACTION.SET_API_CART,
              apiCart,
            })
          } else {
            if (currentMembership) {
              cartDispatch({
                type: CART_ACTION.REMOVE_LINE_QUEUE,
                productId: currentMembership.productId,
              })
            }
            cartDispatch({
              type: CART_ACTION.ADD_LINE_QUEUE,
              lines: [
                {
                  imageUrl: membership.images[0].url,
                  listPrice: membership.price,
                  salePrice: membership.price,
                  name: membership.title,
                  productId: membership.bigCommerceProductId,
                  quantity: 1,
                  variantId: membership.variants.length
                    ? membership.variants[0].bigCommerceVariantId
                    : 0,
                  nonMemberPrice: membership.price,
                },
              ],
            })
          }
        } else {
          if (currentMembership) {
            cartDispatch({
              type: CART_ACTION.REMOVE_LINE_QUEUE,
              productId: currentMembership.productId,
            })
          }
          cartDispatch({
            type: CART_ACTION.ADD_LINE_QUEUE,
            lines: [
              {
                imageUrl: membership.images[0].url,
                listPrice: membership.price,
                salePrice: membership.price,
                name: membership.title,
                productId: membership.bigCommerceProductId,
                quantity: 1,
                variantId: membership.variants.length
                  ? membership.variants[0].bigCommerceVariantId
                  : 0,
                nonMemberPrice: membership.price,
              },
            ],
          })
        }
      }

      trackEvent('add_membership_LP_bottom', {
        loopCustomerId: user?.customer?.loopCustomerId,
        membership_type: membership,
      })

      cartDispatch({
        type: CART_ACTION.TOGGLE_CART,
        showCart: true,
      })
      cartDispatch({
        type: CART_ACTION.FORCE_CHECKOUT_STEP,
        step: CHECKOUT_STEPS.ACCOUNT_2,
      })
    } catch (error) {
      reportBug(error, { membership })
    } finally {
      setIsProcessing(false)
    }
  }

  useEffect(() => {
    if (!filters) return

    const getProductsData = async (catId: number) => {
      try {
        switch (true) {
          case showNewbornItems: {
            const response = await ProductsApi.fetchProducts({
              pageParam: 1,
              itemsPerPage: 10,
              filterOption: {
                ages: [newbornCategory],
              },
            })

            if (response?.success) {
              setTopProducts(
                response.products.slice(
                  0,
                  parseInt(
                    process.env.NEXT_PUBLIC_NO_OF_PRODUCTS_IN_CAROUSEL || '9'
                  )
                )
              )
            }
            break
          }
          default: {
            const response = await ProductsApi.fetchProducts({
              pageParam: 1,
              itemsPerPage: 10,
              filterOption: {
                categories: [catId],
              },
            })

            if (response?.success) {
              setTopProducts(
                response.products.slice(
                  0,
                  parseInt(
                    process.env.NEXT_PUBLIC_NO_OF_PRODUCTS_IN_CAROUSEL || '9'
                  )
                )
              )
            }
            break
          }
        }
      } catch (error) {
        reportBug(error)
      }
    }

    getProductsData(filters.topProductsCategoryId)
  }, [filters, newbornCategory, showNewbornItems, reportBug])

  return (
    <section className="w-full bg-heavy-cream">
      <div className="max-w-6xl mx-auto pb-[0px] md:pb-6 pt-[40px] md:pt-6 overflow-hidden">
        <div className="text-center">
          <Headline
            variant="circular-xs"
            className="xs:text-16px xs:font-circular-medium uppercase text-neutral-6 mb-3"
          >
            Top Loops
          </Headline>
        </div>
        <div className="text-center">
          <Headline
            variant="recoleta-s"
            className={`text-center mb-4 md:text-28px lg:text-32px text-poppy
            }`}
          >
            {title ? title : 'Member favorites'}
          </Headline>
        </div>

        <div className="max-w-5xl mx-auto">
          <div className="mt-[20px] md:mt-5 px-[20px] relative pb-5">
            {topProducts ? (
              <Carousel
                swipeable={true}
                showDots={false}
                arrows={false}
                infinite={false}
                partialVisible={true}
                autoPlay={false}
                autoPlaySpeed={5000}
                renderButtonGroupOutside={true}
                responsive={{
                  xxsmobile: {
                    breakpoint: { max: 400, min: 0 },
                    items: 1,
                    partialVisibilityGutter: 50,
                  },
                  xsmobile: {
                    breakpoint: { max: 500, min: 400 },
                    items: 1,
                    partialVisibilityGutter: 50,
                  },
                  mobile: {
                    breakpoint: { max: 640, min: 500 },
                    items: 1,
                    partialVisibilityGutter: 100,
                  },
                  smtablet: {
                    breakpoint: { max: 740, min: 640 },
                    items: Slides_Per_Page,
                    partialVisibilityGutter: 0,
                  },
                  tablet: {
                    breakpoint: { max: 1024, min: 740 },
                    items: Slides_Per_Page,
                    partialVisibilityGutter: 50,
                  },
                  desktop: {
                    breakpoint: { max: 9000, min: 1024 },
                    items: Slides_Per_Page,
                    partialVisibilityGutter: 140,
                  },
                }}
                //@ts-ignore
                customButtonGroup={<ButtonGroup />}
              >
                {topProducts.map((product, index) => {
                  return (
                    <ProductCarouselCard
                      key={index}
                      product={product}
                      componentBackground="bg-heavy-cream"
                    />
                  )
                })}
              </Carousel>
            ) : (
              <div></div>
            )}
          </div>
        </div>
        <div className="text-center relative -top-[20px] md:top-[0px]">
          {!subscriptionActive ? (
            <Button
              primary={true}
              onClick={() =>
                addMembershipToCart(
                  membershipProducts.annual as IProductSummary
                )
              }
              type="button"
              label={`Join today for $${
                membershipProducts.annual?.price || 0
              } / ${PRODUCT_CONFIG.membershipPeriod.year}`}
            ></Button>
          ) : (
            <Button
              primary
              label="View my membership"
              className="w-[222px] mb-[16px]"
              onClick={() => {
                router.push(URLS.ACCOUNT.INDEX)
              }}
            />
          )}
        </div>
      </div>
    </section>
  )
}

export { MembershipTopProductsMolecule }
