import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button, Headline, InputText, Pgraph, SelectInput } from '..'
import { PERFORMACE_TRACKING, REG_EX, URLS, US_STATES } from '../../config'
import { AUTH_ACTION, CART_ACTION, IAddress, ICart } from '../../interfaces'
import { CustomersApi } from '../../services'
import {
  useAuthDispatch,
  useAuthStore,
  useCartDispatch,
  useCartStore,
  useHooks,
} from '../../store'
import { trackEvent } from '../../config/analytics'

interface IDeliveryForm extends Pick<ICart, 'deliveryAddress'> {
  cellPhone: string
}

export const CheckoutAddress = () => {
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState<string>()
  const {
    cart: { deliveryAddress },
  } = useCartStore()
  const { credentials, user } = useAuthStore()
  const {
    register,
    handleSubmit,
    setValue,
    setError: setFormError,
    formState: { errors },
  } = useForm<IDeliveryForm>({
    defaultValues: {
      deliveryAddress: {
        firstName: deliveryAddress?.firstName || '',
        lastName: deliveryAddress?.lastName || '',
        address1: deliveryAddress?.address1 || '',
        address2: deliveryAddress?.address2 || '',
        city: deliveryAddress?.city || '',
        state: deliveryAddress?.state || US_STATES[0].value,
        zip: deliveryAddress?.zip || '',
      },
      cellPhone: user?.customer.cellPhone || '',
    },
  })
  const dispatch = useCartDispatch()
  const router = useRouter()
  const authDispatch = useAuthDispatch()
  const { reportBug, reportBreadcrumb, logout } = useHooks()

  useEffect(() => {
    if (user?.customer?.shippingAddress) {
      setValue('deliveryAddress.firstName', user.customer.firstName)
      setValue('deliveryAddress.lastName', user.customer.lastName)
      setValue(
        'deliveryAddress.address1',
        user.customer.shippingAddress.streetAddress1
      )
      setValue(
        'deliveryAddress.address2',
        user.customer.shippingAddress.streetAddress2
      )
      setValue('deliveryAddress.state', user.customer.shippingAddress.state)
      setValue('deliveryAddress.city', user.customer.shippingAddress.city)
      setValue('deliveryAddress.zip', user.customer.shippingAddress.postalCode)
    }
    setValue('cellPhone', user?.customer.cellPhone || '')
  }, [user, setValue])

  const onSubmit = async ({ deliveryAddress, cellPhone }: IDeliveryForm) => {
    try {
      reportBreadcrumb(
        'Checkout address form sent',
        { deliveryAddress, cellPhone },
        'request'
      )
      setProcessing(true)
      setError(undefined)
      if (!credentials?.accessToken) return

      const address: IAddress = {
        city: deliveryAddress?.city || '',
        country: 'US',
        postalCode: deliveryAddress?.zip || '',
        state: deliveryAddress?.state || 'CA',
        streetAddress1: deliveryAddress?.address1 || '',
        streetAddress2: deliveryAddress?.address2,
      }

      const validZip = await CustomersApi.validateLocation(
        deliveryAddress?.zip || ''
      )

      if (!validZip) {
        setFormError('deliveryAddress.zip', {
          message:
            "Uh oh! The entered zip doesn't seem to be in our delivery area. Please double check that it was entered correctly.",
        })
        return
      }

      const trackingPerformanceObj = {
        startTime: new Date().getTime(),
        endTime: 0,
        status: false,
      }
      console.log(cellPhone)
      if (!!cellPhone) {
        console.log('There is phone number')

        const updCellPhonePromise = CustomersApi.updatePartialCustomerInfo(
          { cellPhone },
          credentials.accessToken
        )

        const updAddressPromise = CustomersApi.updateAddresses({
          accessToken: credentials?.accessToken,
          shippingAddress: address,
          billingAddress: address,
        })

        const [updCellPhone, updAddress] = await Promise.all([
          updCellPhonePromise,
          updAddressPromise,
        ])
        const updCellPhoneReponse: any = updCellPhone

        if (updCellPhoneReponse.code !== 200) {
          if (updCellPhoneReponse.cause === 403) {
            console.log('There is phone number---- 403')
            logout()
            router.push(URLS.LOGIN.INDEX)
          } else {
            console.log('There is phone number---- ERROR !== 403')
            throw new Error('Error updating cell phone number')
          }
        }

        console.log('There is phone number == updAddress')
        if (updAddress) {
          console.log('There is phone number == updAddress POSITIVE')
          trackEvent('checkout_delivery_address', {
            loopCustomerId: user?.customer?.loopCustomerId,
            streetAddress1: deliveryAddress?.address1,
            streetAddress2: deliveryAddress?.address2,
            city: deliveryAddress?.city,
            state: deliveryAddress?.state,
            postalCode: deliveryAddress?.zip,
          })

          authDispatch({
            type: AUTH_ACTION.SET_TOKENS,
            credentials,
          })
          authDispatch({
            type: AUTH_ACTION.SET_PAYMENT_METHODS,
            cards: undefined,
            defaultCard: undefined,
          })
        } else {
          console.log('There is phone number == updAddress NEGATIVE')
          throw new Error('Please check the address you entered')
        }

        /* Performance Tracking */
        trackingPerformanceObj.endTime = new Date().getTime()
        trackingPerformanceObj.status = !!(
          validZip &&
          updAddress &&
          updCellPhoneReponse.code === 200
        )

        trackEvent(
          PERFORMACE_TRACKING.CHECKOUT_ADDRESS_STEP,
          trackingPerformanceObj
        )
        /* END Performance Tracking */
      } else {
        const updAddressPromise = CustomersApi.updateAddresses({
          accessToken: credentials?.accessToken,
          shippingAddress: address,
          billingAddress: address,
        })

        console.log('There is NOOOOOOO phone number')
        const [updAddress] = await Promise.all([updAddressPromise])

        console.log('There is NOOOOOOO phone number update address')
        if (updAddress) {
          console.log(
            'There is NOOOOOOO phone number update address == POSITIVE'
          )
          trackEvent('checkout_delivery_address', {
            loopCustomerId: user?.customer?.loopCustomerId,
            streetAddress1: deliveryAddress?.address1,
            streetAddress2: deliveryAddress?.address2,
            city: deliveryAddress?.city,
            state: deliveryAddress?.state,
            postalCode: deliveryAddress?.zip,
          })

          authDispatch({
            type: AUTH_ACTION.SET_TOKENS,
            credentials,
          })
          authDispatch({
            type: AUTH_ACTION.SET_PAYMENT_METHODS,
            cards: undefined,
            defaultCard: undefined,
          })
        } else {
          console.log(
            'There is NOOOOOOO phone number update address == NEGATIVE'
          )
          throw new Error('Please check the address you entered')
        }

        /* Performance Tracking */
        trackingPerformanceObj.endTime = new Date().getTime()
        trackingPerformanceObj.status = !!(validZip && updAddress)

        trackEvent(
          PERFORMACE_TRACKING.CHECKOUT_ADDRESS_STEP,
          trackingPerformanceObj
        )
        /* END Performance Tracking */
      }

      dispatch({
        type: CART_ACTION.SET_DELIVERY_ADDRESS,
        deliveryAddress,
      })
    } catch (error) {
      console.error(error)
      reportBug(error, { deliveryAddress, cellPhone })
      setError(error instanceof Error ? error.message : 'Unknown error')
    } finally {
      setProcessing(false)
    }
  }

  const onGoBack = () => {
    dispatch({
      type: CART_ACTION.TOGGLE_CART,
    })
    router.push(URLS.INDEX)
  }

  const updateZip = (value: any) => {
    setValue('deliveryAddress.zip', value)
  }

  const updatePhone = (value: any) => {
    setValue('cellPhone', value)
  }

  return (
    <div className="flex justify-center  h-full bg-snow-white mx-3">
      <div className="flex flex-col justify-center items-center w-full h-fit">
        <Headline variant="recoleta-l" className="mb-2">
          Delivery Address
        </Headline>
        <form onSubmit={handleSubmit(onSubmit)} className="w-full md:w-1/2">
          <div className="mt-4">
            <Pgraph variant="l-14" className="text-neutral-6">
              First Name
            </Pgraph>
            <InputText
              type="text"
              placeHolder="First"
              {...register('deliveryAddress.firstName', {
                required: 'Required',
                validate: {
                  format: (v) =>
                    v === '' ||
                    REG_EX.lettersAndSpaces.test(v) ||
                    'Only alphabets are allowed',
                },
              })}
              validationText={errors.deliveryAddress?.firstName?.message}
            />
          </div>
          <div className="mt-4">
            <Pgraph variant="l-14" className="text-neutral-6">
              Last Name
            </Pgraph>
            <InputText
              type="text"
              placeHolder="Last"
              {...register('deliveryAddress.lastName', {
                required: 'Required',
                validate: {
                  format: (v) =>
                    v === '' ||
                    REG_EX.lettersAndSpaces.test(v) ||
                    'Only alphabets are allowed',
                },
              })}
              validationText={errors.deliveryAddress?.lastName?.message}
            />
          </div>
          <div className="mt-4">
            <Pgraph variant="l-14" className="text-neutral-6">
              Address
            </Pgraph>
            <InputText
              type="text"
              placeHolder="Address"
              {...register('deliveryAddress.address1', {
                required: 'Valid address required',
              })}
              validationText={errors.deliveryAddress?.address1?.message}
            />
          </div>
          <div className="mt-4">
            <Pgraph variant="l-14" className="text-neutral-6">
              Apartment, suite, etc.
            </Pgraph>
            <InputText
              type="text"
              placeHolder="Address line 2"
              {...register('deliveryAddress.address2', {
                required: false,
              })}
              validationText={errors.deliveryAddress?.address2?.message}
            />
          </div>
          <div className="mt-4">
            <Pgraph variant="l-14" className="text-neutral-6">
              City
            </Pgraph>
            <InputText
              type="text"
              placeHolder="City"
              {...register('deliveryAddress.city', {
                required: 'Enter a city',
              })}
              validationText={errors.deliveryAddress?.city?.message}
            />
          </div>
          <div className="mt-4">
            <Pgraph variant="l-14" className="text-neutral-6">
              State
            </Pgraph>
            <SelectInput
              {...register('deliveryAddress.state', {
                required: 'Select a state',
              })}
              validationText={errors.deliveryAddress?.state?.message}
              options={US_STATES}
            />
          </div>
          <div className="mt-4 mb-4">
            <Pgraph variant="l-14" className="text-neutral-6">
              ZIP
            </Pgraph>
            <InputText
              type="numeric"
              placeHolder="ZIP"
              {...register('deliveryAddress.zip', {
                required: 'Enter your ZIP code',
                validate: (v) => REG_EX.us_zip.test(v) || 'Invalid ZIP code',
              })}
              validationText={errors.deliveryAddress?.zip?.message}
              onChange={(e) => updateZip(e.target.value)}
            />
          </div>
          <div className="mt-4 mb-4">
            <Pgraph variant="l-14" className="text-neutral-6">
              Phone number
            </Pgraph>
            <InputText
              type="numeric"
              placeHolder="Phone number"
              {...register('cellPhone', {
                validate: (v) => REG_EX.phone.test(v) || 'Invalid phone number',
              })}
              validationText={errors.cellPhone?.message}
              onChange={(e) => updatePhone(e.target.value)}
            />
          </div>
          <Pgraph variant="l-14" className="text-center text-caution-2">
            {error}
          </Pgraph>
          <Button
            label="Next"
            primary
            className="w-full mb-2 mt-2"
            type="submit"
            size="large"
            disabled={processing}
            loading={processing}
          />
          <Pgraph
            variant="l-16"
            className="underline text-center my-4 cursor-pointer"
            onClick={onGoBack}
          >
            Go back
          </Pgraph>
        </form>

        {
          <Pgraph
            variant="p-14"
            className="w-full md:w-1/2 text-neutral-5 text-center mb-5"
          >
            By submitting my phone number I consent to receive delivery-related
            messages from Loop via SMS at the number provided. Message & Data
            rates may apply.
          </Pgraph>
        }
      </div>
    </div>
  )
}
