import Script from 'next/script'
import React from 'react'
import { IProduct } from '../../../interfaces'

interface IKlaviyoPDPProps {
  product: IProduct
  productUrl: string
}

export const KlaviyoPDPTracking = ({
  product,
  productUrl,
}: IKlaviyoPDPProps) => {
  return (
    <Script
      id="KlaviyoPDP"
      // strategy="worker" - Causing recording problems?
      dangerouslySetInnerHTML={{
        __html: `
          var _learnq = _learnq || []; 
          var item = {
            Name: "${product.title}", 
            ProductID: "${product.bigCommerceProductId}",
            ImageURL: "${
              product.images.length > 0 ? product.images[0]?.url : ''
            }", 
            URL: "${productUrl}",
            Brand: "${product.brand?.name}", 
            Price: "${product.price}",
            CompareAtPrice: "${product.retailPrice}"
           };
         
         _learnq.push(['track', 'Viewed Product', item]); 
         
         _learnq.push(['trackViewedItem', {
            Title: item.Name, 
            ItemId: item.ProductID,
            ImageUrl: item.ImageURL, 
            Url: item.URL,
         
            Metadata: { 
              Brand: item.Brand,
              Price: item.Price, 
              CompareAtPrice: item.CompareAtPrice
          } 
          }]);
          `,
      }}
    ></Script>
  )
}
