import Script from 'next/script'

export const ScriptHubspot = () => {
  return (
    <>
      {/* <!-- Start of HubSpot Embed Code --> */}

      <Script
        id="hs-script-loader"
        // strategy="worker" - Causing recording problems?
        async
        defer
        src={`//js.hs-scripts.com/${process.env.NEXT_PUBLIC_HUBSPOT_ID}.js`}
      ></Script>
      {/* <!--  End of HubSpot Embed Code --> */}
    </>
  )
}
