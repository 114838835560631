import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  useAuthDispatch,
  useAuthStore,
  useCartDispatch,
  useCartStore,
  useHooks,
} from '../../store'
import { Button, InputText, Loading, MembershipUpsell } from '../'
import {
  AUTH_ACTION,
  CART_ACTION,
  CHECKOUT_STEPS,
  ICartApiProduct,
  ICartQueuedLine,
  IProductSummary,
} from '../../interfaces'
import { Headline } from '../Headline'
import { Pgraph } from '../Pgrah'
import { useRouter } from 'next/router'
import { PERFORMACE_TRACKING, PRODUCT_CONFIG, URLS } from '../../config'
import CartLine from './cartLine'
import { useForm } from 'react-hook-form'
import { CartApi } from '../../services'
import { trackEvent } from '../../config/analytics'
import NumberFormat from 'react-number-format'
import { ServiceFeeModule } from '../ServicFeeModule'
import Link from 'next/link'

interface ICouponForm {
  couponCode: string
}

export const Cart = () => {
  const {
    showCart,
    cart,
    checkoutStep,
    membershipProducts,
    registryCart,
    deliveryOption,
    creditConsumption,
    forceCartRefresh,
    resetCart,
  } = useCartStore()
  const [hasCoupon, setHasCoupon] = useState(false)
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ICouponForm>()
  const dispatch = useCartDispatch()
  const authDispatch = useAuthDispatch()
  const { user, credentials, subscriptionActive } = useAuthStore()
  const router = useRouter()
  const [applyingCoupon, setApplyingCoupon] = useState(false)
  const [couponMsg, setCouponMsg] = useState<string>()
  const [isProcessing, setIsProcessing] = useState(false)
  const [showUpsell, setShowUpsell] = useState(false)
  const { getCartData, reportBug, reportBreadcrumb } = useHooks()
  const cartDispatch = useCartDispatch()

  const closeCart = useCallback(() => {
    dispatch({
      type: CART_ACTION.TOGGLE_CART,
      showCart: false,
    })
  }, [dispatch])

  const handleLoginClick = () => {
    authDispatch({
      type: AUTH_ACTION.SET_RETURN_URL,
      returnUrl: router.asPath,
    })
    closeCart()
    router.push(URLS.LOGIN.INDEX)
  }

  const handleBrowseNewItemsClick = () => {
    authDispatch({
      type: AUTH_ACTION.SET_RETURN_URL,
      returnUrl: router.asPath,
    })
    closeCart()
    router.push(
      `${URLS.PRODUCTS.INDEX}?sortByParams=${PRODUCT_CONFIG.sortOptions[1].key}`
    )
  }

  const isRegistry = useMemo(() => {
    if (router.query.regId === user?.registryId) return false

    return (
      router.pathname === '/registry/[regId]' ||
      router.pathname.includes('guest')
    )
  }, [router.pathname, router.query.regId, user?.registryId])

  const updateLineQuantity = async (
    line: Partial<ICartApiProduct>,
    newQuantity: string
  ) => {
    if (line.id && credentials?.accessToken) {
      try {
        setIsProcessing(true)

        const trackingPerformanceObj = {
          startTime: new Date().getTime(),
          endTime: 0,
          status: false,
        }

        const updated = await CartApi.updateCartItem(
          credentials.accessToken,
          line.id,
          parseInt(newQuantity)
        )

        trackingPerformanceObj.endTime = new Date().getTime()
        trackingPerformanceObj.status = !!updated

        trackEvent(
          PERFORMACE_TRACKING.CART_QUANTITY_CHANGE_ITEM,
          trackingPerformanceObj
        )

        if (updated) {
          const apiCart = await CartApi.fetchCheckout(credentials.accessToken)
          dispatch({
            type: CART_ACTION.SET_API_CART,
            apiCart: apiCart?.checkout.cart,
          })
          dispatch({
            type: CART_ACTION.SET_CREDITS,
            creditConsumption: apiCart?.creditConsumption,
          })
        } else {
          throw new Error('Unable to update quantity')
        }
      } catch (error) {
        reportBug(error, { line, newQuantity })
        forceCartRefresh()
      } finally {
        setIsProcessing(false)
        return
      }
    }

    if (!line.id && line.productId) {
      dispatch({
        type: CART_ACTION.UPDATE_LINE_QUEUE,
        productId: line.productId,
        newQuantity: parseInt(newQuantity),
      })
    }
  }

  const removeLine = async (line: Partial<ICartApiProduct>) => {
    try {
      if (isRegistry) {
        if (!line.productId) {
          dispatch({
            type: CART_ACTION.REGISTRY_CART_REMOVE_GIFT,
          })
        } else {
          dispatch({
            type: CART_ACTION.REGISTRY_CART_REMOVE_ITEM,
            productId: line.productId,
          })
        }
        return
      }
      if (line.id && credentials?.accessToken) {
        setIsProcessing(true)

        const trackingPerformanceObj = {
          startTime: new Date().getTime(),
          endTime: 0,
          status: false,
        }

        const deleted = await CartApi.removeCartItem(
          credentials.accessToken,
          line.id
        )

        trackingPerformanceObj.endTime = new Date().getTime()
        trackingPerformanceObj.status = !!deleted

        trackEvent(PERFORMACE_TRACKING.CART_REMOVE_ITEM, trackingPerformanceObj)

        if (deleted) {
          if (lines.length > 1) {
            const apiCart = await CartApi.fetchCheckout(credentials.accessToken)
            dispatch({
              type: CART_ACTION.SET_API_CART,
              apiCart: apiCart?.checkout.cart,
            })
            dispatch({
              type: CART_ACTION.SET_CREDITS,
              creditConsumption: apiCart?.creditConsumption,
            })
          } else {
            resetCart()
          }
        } else {
          throw new Error('Delete item failed')
        }
      } else {
        if (line.productId) {
          dispatch({
            type: CART_ACTION.REMOVE_LINE_QUEUE,
            productId: line.productId,
          })
        }
      }
    } catch (error) {
      console.error(error)
      reportBug(error, { line })
      await forceCartRefresh()
    } finally {
      setIsProcessing(false)
    }
  }

  const {
    lines,
    subTotal,
    delivery,
    total: cartTotal,
    subscriptionSavings,
    credits,
    membershipInCart,
    coupons,
    upsellAmount,
  } = useMemo(
    () =>
      getCartData({
        registryCart: isRegistry ? registryCart : undefined,
        cart: isRegistry ? undefined : cart,
        creditConsumption: isRegistry ? undefined : creditConsumption,
      }),
    [cart, getCartData, isRegistry, registryCart, creditConsumption]
  )

  const handleCheckout = () => {
    reportBreadcrumb('Checkout button clicked', { cart, user }, 'navigation')
    if (checkoutStep > CHECKOUT_STEPS.MEMBERSHIP_1) {
      router.push(URLS.CHECKOUT.INDEX)
    } else {
      if (
        checkoutStep > CHECKOUT_STEPS.MEMBERSHIP_1 ||
        subscriptionActive ||
        (membershipInCart && user)
      ) {
        dispatch({
          type: CART_ACTION.FORCE_CHECKOUT_STEP,
          step: CHECKOUT_STEPS.ADDRESS_3,
        })
        router.push(URLS.CHECKOUT.INDEX)
        // } else if (membershipInCart && !user) {
        //   closeCart()
        //   dispatch({
        //     type: CART_ACTION.FORCE_CHECKOUT_STEP,
        //     step: CHECKOUT_STEPS.ACCOUNT_2,
        //   })
        //   router.push(URLS.CHECKOUT.INDEX)
      } else {
        dispatch({
          type: CART_ACTION.FORCE_CHECKOUT_STEP,
          step: CHECKOUT_STEPS.ACCOUNT_2,
        })
        router.push(`${URLS.CHECKOUT.INDEX}?c=1`)
      }
    }
    trackEvent('Checkout Started', {
      loopCustomerId: user?.customer?.loopCustomerId,
      cart: cart,
    })
    closeCart()
  }

  const applyCoupon = async ({ couponCode }: ICouponForm) => {
    try {
      if (!credentials?.accessToken) {
        setCouponMsg('You must be logged in to apply a coupon.')
        return
      }
      if (couponCode === user?.customer.referralCode) {
        setCouponMsg('You cannot apply your own referral code.')
        return
      }

      setApplyingCoupon(true)
      setCouponMsg(undefined)
      const apiCart = await CartApi.addCoupon(
        credentials.accessToken,
        couponCode
      )
      if (apiCart) {
        if (typeof apiCart === 'string') {
          throw apiCart
        }
        setCouponMsg(`Coupon ${couponCode} applied successfully.`)
        dispatch({
          type: CART_ACTION.SET_API_CART,
          apiCart,
        })
      } else {
        throw new Error('Invalid coupon')
      }
    } catch (error) {
      setCouponMsg(typeof error === 'string' ? error : 'Error applying coupon')
    } finally {
      setApplyingCoupon(false)
    }
  }

  const removeCoupon = async (couponCode: string) => {
    try {
      if (!credentials?.accessToken) {
        return
      }
      setApplyingCoupon(true)
      const apiCart = await CartApi.removeCoupon(
        credentials.accessToken,
        couponCode
      )
      if (apiCart) {
        dispatch({
          type: CART_ACTION.SET_API_CART,
          apiCart,
        })
      } else {
        throw new Error('Invalid coupon')
      }
    } catch (error) {
      console.error(error)
      reportBug(error, { couponCode })
    } finally {
      setApplyingCoupon(false)
    }
  }

  useEffect(() => {
    const handleEsc = (event: globalThis.KeyboardEvent) => {
      if (showCart && event.key === 'Escape') {
        closeCart()
      }
    }

    window.addEventListener('keydown', handleEsc)

    return () => {
      window.removeEventListener('keydown', handleEsc)
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleRegistryCheckout = () => {
    dispatch({
      type: CART_ACTION.TOGGLE_CART,
      showCart: false,
    })
    router.push(URLS.CHECKOUT.GUEST)
  }

  const addMembershipInfo = () => {
    addMembershipToCart(membershipProducts.annual as IProductSummary)
  }

  async function addMembershipToCart(membership: IProductSummary) {
    try {
      setIsProcessing(true)
      const currentMembership = lines.find(
        (line) =>
          line.productId === membershipProducts.annual?.bigCommerceProductId ||
          line.productId === membershipProducts.monthly?.bigCommerceProductId
      )

      const membershipId = currentMembership
        ? (currentMembership as any).id
        : undefined

      if (currentMembership && membershipId && credentials?.accessToken) {
        const deleted = await CartApi.removeCartItem(
          credentials.accessToken,
          membershipId
        )
        if (deleted) {
          const apiCart = await CartApi.addCartItem(credentials.accessToken, [
            {
              productId: membership.bigCommerceProductId,
              quantity: 1,
            },
          ])
          if (apiCart) {
            cartDispatch({
              type: CART_ACTION.SET_API_CART,
              apiCart,
            })
          }
        }
      } else {
        if (credentials?.accessToken) {
          const apiCart = await CartApi.addCartItem(credentials.accessToken, [
            {
              productId: membership.bigCommerceProductId,
              quantity: 1,
            },
          ])
          if (apiCart) {
            cartDispatch({
              type: CART_ACTION.SET_API_CART,
              apiCart,
            })
          } else {
            if (currentMembership) {
              cartDispatch({
                type: CART_ACTION.REMOVE_LINE_QUEUE,
                productId: currentMembership.productId,
              })
            }
            cartDispatch({
              type: CART_ACTION.ADD_LINE_QUEUE,
              lines: [
                {
                  imageUrl: membership.images[0].url,
                  listPrice: membership.price,
                  salePrice: membership.price,
                  name: membership.title,
                  productId: membership.bigCommerceProductId,
                  quantity: 1,
                  variantId: membership.variants.length
                    ? membership.variants[0].bigCommerceVariantId
                    : 0,
                  nonMemberPrice: membership.price,
                },
              ],
            })
          }
        } else {
          if (currentMembership) {
            cartDispatch({
              type: CART_ACTION.REMOVE_LINE_QUEUE,
              productId: currentMembership.productId,
            })
          }
          cartDispatch({
            type: CART_ACTION.ADD_LINE_QUEUE,
            lines: [
              {
                imageUrl: membership.images[0].url,
                listPrice: membership.price,
                salePrice: membership.price,
                name: membership.title,
                productId: membership.bigCommerceProductId,
                quantity: 1,
                variantId: membership.variants.length
                  ? membership.variants[0].bigCommerceVariantId
                  : 0,
                nonMemberPrice: membership.price,
              },
            ],
          })
        }
      }

      trackEvent('add_membership_cart', {
        loopCustomerId: user?.customer?.loopCustomerId,
        membership_type: membership,
      })

      cartDispatch({
        type: CART_ACTION.TOGGLE_CART,
        showCart: true,
      })
    } catch (error) {
      reportBug(error)
    } finally {
      setIsProcessing(false)
    }
  }

  if (!lines.length) {
    return (
      <>
        <div
          className={`z-40 ${
            showCart ? 'fixed' : 'hidden'
          } top-0 left-0 w-full h-full overflow-x-hidden bg-black opacity-30`}
          onClick={closeCart}
        ></div>
        <div
          className={`z-50 fixed overflow-y-auto flex flex-col items-center top-0 h-full right-0 w-full lg:w-[700px] px-3 bg-snow-white opacity-100 p-3 ease-in-out duration-500 ${
            showCart ? 'translate-x-0' : 'translate-x-full'
          }`}
        >
          <div className="flex w-full flex-row-reverse">
            <i
              className="loop-icon-times text-24px cursor-pointer relative right-[10px] top-[10px]"
              onClick={closeCart}
            />
          </div>
          <div className="lg:px-8 text-center">
            <Headline variant="recoleta-l" className="mt-7 mb-2">
              Your bag is empty
            </Headline>
            {!user ? (
              <>
                <Pgraph variant="p-16" className="mb-5">
                  If you have an account, log in to see what you previously
                  added.
                </Pgraph>
                <div className="w-full flex justify-center">
                  <Button
                    primary={true}
                    label="Log in"
                    className="w-full md:max-w-xs"
                    onClick={handleLoginClick}
                  />
                </div>
                <Pgraph variant="p-14" className="mt-4">
                  or{' '}
                  <Pgraph
                    variant="p-14"
                    as="span"
                    className="cursor-pointer underline"
                    onClick={handleBrowseNewItemsClick}
                  >
                    Browse New Items
                  </Pgraph>
                </Pgraph>
              </>
            ) : (
              <div className="w-full flex justify-center mt-5">
                <Button
                  primary={true}
                  label="Browse New Items"
                  className="w-full md:max-w-xs"
                  onClick={handleBrowseNewItemsClick}
                />
              </div>
            )}
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <div
        className={`z-40 ${
          showCart ? 'fixed' : 'hidden'
        } top-0 left-0 w-full h-full overflow-x-hidden bg-black opacity-30`}
        onClick={closeCart}
      ></div>
      <div
        className={`z-50 fixed overflow-y-auto flex flex-col top-0 h-full right-0 w-full lg:w-[700px] bg-snow-white opacity-100 ease-in-out duration-500 ${
          showCart ? 'translate-x-0' : 'translate-x-full'
        }`}
      >
        <div className="flex flex-row-reverse">
          <i
            className="loop-icon-times text-24px cursor-pointer relative right-[10px] top-[10px]"
            onClick={closeCart}
          />
        </div>
        <div className="mt-5 md:mt-[56px] text-neutral-7 px-4 md:px-[96px] text-center">
          <Headline variant="recoleta-m">Complete your order</Headline>
        </div>
        {!isRegistry && (
          <div className="px-4 md:px-[96px] text-center">
            <Pgraph
              variant="p-16"
              className="mt-3 mb-0 text-neutral-6 text-center italic"
            >
              We&apos;ll be in touch within 2 business days to schedule delivery{' '}
            </Pgraph>
          </div>
        )}
        {!isRegistry && !membershipInCart && !subscriptionActive && (
          <div className="w-full mt-4 md:mt-[30px] bg-heavy-cream px-0 md:px-[86px]">
            <div className="ml-[27px] md:ml-[16px] mr-[27px] md:mr-0 text-center">
              <div className="mb-2 relative mt-4">
                <Headline variant="recoleta-xs" className="text-neutral-7">
                  Become a member and get <span className="text-success-3 inline-block">FREE</span>{' '}
                  delivery and save{' '}
                  <span className="text-success-3 inline-block">
                    {' '}
                    {isNaN(subscriptionSavings)
                      ? '...'
                      : `$${subscriptionSavings}`}
                  </span>.
                  <br />
                  Plus save{' '}
                  <span className="text-success-3 inline-block">
                    33% or more
                  </span>{' '}
                  on all your future orders!
                </Headline>
                
              </div>
              <div>
                {/* <div className="mb-[12px]">
                  <Pgraph
                    variant="p-14"
                    className="font-normal text-neutral-5 italic"
                  >
                    With a $200/yr Membership you can rent as much as you need
                    when you need it
                  </Pgraph>
                </div> */}
                <div className="text-14px underline mb-3 mt text-success-3">
                  <Pgraph
                    variant="l-14"
                    onClick={addMembershipInfo}
                    className="underline border-0 hover:cursor-pointer text-center "
                  >
                    Join and Save Now
                  </Pgraph>
                </div>
              </div>
            </div>
          </div>
        )}

        {upsellAmount > 0 && (
          <div className="w-full mt-4 md:mt-[30px] bg-heavy-cream px-0 md:px-[86px]">
            <div className="ml-[27px] md:ml-[16px] mr-[27px] md:mr-0 text-center">
              <div className="mb-2 relative mt-4">
                <Headline variant="recoleta-xs" className="text-neutral-7">
                  Get <span className="text-success-3 inline-block">FREE</span>{' '}
                  delivery if you spend{' '}
                  <span className="text-success-3 inline-block">
                    <NumberFormat
                      value={upsellAmount}
                      prefix="$"
                      displayType="text"
                    />
                  </span>{' '}
                  more
                  <br /> and get your basket up to $100.
                </Headline>
              </div>
              <div>
                <div className="text-14px underline mb-3 mt text-success-3">
                  <Link href={URLS.PRODUCTS.INDEX}>
                    <Pgraph
                      variant="l-14"
                      className="underline border-0 text-center "
                      onClick={closeCart}
                    >
                      Add More to Bag
                    </Pgraph>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="flex justify-center mb-4  px-[96px]">
          {isRegistry && (
            <Pgraph variant="p-14" className="mt-5 text-neutral-5">
              Your recipient will be notified of your gift via email and will be
              able to schedule delivery of item(s) when they are needed
            </Pgraph>
          )}
        </div>
        {isProcessing && (
          <div className="mt-5 mb-4">
            <Loading />
          </div>
        )}
        <div className="px-3 md:px-[96px]">
          {!isProcessing &&
            lines.map((line, idx) => (
              <CartLine
                key={idx}
                line={line}
                updateLineQuantity={updateLineQuantity}
                removeLine={removeLine}
                topBorder={idx === 0}
                isRegistry={isRegistry}
                deliveryOption={deliveryOption}
              />
            ))}

          {user && (
            <div className="border-b-[1px] border-solid border-neutral-2 pb-4 md:pb-[30px]">
              {!isRegistry && (
                <div className="items-center mt-[32px] relative">
                  <Pgraph variant="p-14" className="inline-block">
                    Promo code
                  </Pgraph>
                  <div className="hidden md:inline-block">
                    <i
                      className={`${
                        hasCoupon
                          ? 'loop-icon-minus top-[2px]'
                          : 'loop-icon-plus top-[5px]'
                      } text-24px cursor-pointer relative left-[26px] right-[0px]`}
                      onClick={() => setHasCoupon(!hasCoupon)}
                    />
                  </div>
                  <div className="inline-block md:hidden">
                    <i
                      className={`${
                        hasCoupon
                          ? 'loop-icon-minus top-[2px]'
                          : 'loop-icon-plus top-[5px]'
                      } text-24px cursor-pointer absolute right-0`}
                      onClick={() => setHasCoupon(!hasCoupon)}
                    />
                  </div>
                  <Pgraph variant="p-14" className="text-neutral-7 mt-[20px]">
                  <span onClick={addMembershipInfo} className="underline border-0 hover:cursor-pointer text-success-3 font-bold"
                  >
                    Join today
                  </span> and use code <span className="text-success-3 inline-block">NEWMEMBER200</span>{' '}
                  to get <span className="text-success-3 inline-block">$100</span> in Loop credit {' '}
                  and another{' '}
                  <span className="text-success-3 inline-block">
                  $100
                  </span>{' '}
                  after you spend your first $500!
                </Pgraph>
                </div>
              )}
              {hasCoupon && !isRegistry && (
                <div>
                  <form onSubmit={handleSubmit(applyCoupon)}>
                    <div className="flex flex-col justify-between mt-[32px]">
                      <div className="w-[100%] md:max-w-xs mb-[10px] md:[mb-[36px]">
                        <InputText
                          {...register('couponCode', {
                            minLength: 1,
                          })}
                          type="text"
                          placeHolder=""
                          validationText={errors.couponCode?.message}
                        />
                        {couponMsg && (
                          <Pgraph variant="p-14" className="mt-4">
                            {couponMsg}
                          </Pgraph>
                        )}
                      </div>
                      <div className="max-w-xs mt-3">
                        <Button
                          label="Apply"
                          primary={true}
                          type="submit"
                          loading={applyingCoupon}
                          disabled={applyingCoupon}
                        />
                      </div>
                    </div>
                  </form>
                </div>
              )}
            </div>
          )}
          <div className="flex justify-between items-center mb-4 mt-5 text-neutral-6">
            <Pgraph variant="p-14">Subtotal</Pgraph>
            <Pgraph variant="p-14">
              <NumberFormat
                value={subTotal}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'$'}
                decimalScale={2}
                fixedDecimalScale={true}
              />
            </Pgraph>
          </div>
          {!isRegistry && (
            <ServiceFeeModule delivery={delivery}></ServiceFeeModule>
          )}
          {coupons.map((coupon, idx) => (
            <div key={idx} className="flex justify-between mb-4 items-center">
              {applyingCoupon ? (
                <Loading />
              ) : (
                <Pgraph variant="p-14">
                  Promo Code: {coupon.code} -{' '}
                  <Pgraph
                    as="span"
                    variant="p-14"
                    className="underline cursor-pointer"
                    onClick={() => removeCoupon(coupon.code)}
                  >
                    Remove
                  </Pgraph>
                </Pgraph>
              )}
              <Pgraph variant="p-14">
                -${coupon.discountedAmount.toFixed(2)}
              </Pgraph>
            </div>
          ))}
          {!isRegistry && credits > 0 && (
            <div className="flex justify-between mb-4 items-center text-neutral-6">
              <Pgraph variant="p-14">Credit applied</Pgraph>
              <Pgraph variant="p-14">
                <NumberFormat
                  value={credits}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={'-$'}
                  decimalScale={2}
                  fixedDecimalScale={true}
                ></NumberFormat>
              </Pgraph>
            </div>
          )}
          <div className="flex justify-between items-center mb-4">
            <Pgraph variant="l-14">Total</Pgraph>
            <Pgraph variant="l-14">
              <NumberFormat
                value={cartTotal}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'$'}
                decimalScale={2}
                fixedDecimalScale={true}
              />
            </Pgraph>
          </div>
          {!isRegistry && (
            <div className="flex justify-center mb-4">
              <Button
                label="Checkout"
                primary={true}
                onClick={handleCheckout}
                className="w-[300px]"
              />
            </div>
          )}
          {isRegistry && (
            <div className="flex justify-center mb-4">
              <Button
                label="Checkout"
                primary={true}
                onClick={handleRegistryCheckout}
                className="w-[300px]"
                data-cy="CART_REGISTRY_CHECKOUT_BUTTON"
              />
            </div>
          )}
          {!isRegistry && !user && (
            <div className="flex justify-center pb-5">
              <Pgraph variant="p-14">Already a member?&nbsp;</Pgraph>
              <Pgraph
                variant="p-14"
                className="underline cursor-pointer"
                onClick={handleLoginClick}
              >
                Log in
              </Pgraph>
            </div>
          )}
        </div>
      </div>
      {showUpsell && <MembershipUpsell onClose={() => setShowUpsell(false)} />}
    </>
  )
}
