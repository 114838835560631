import React, { useEffect, useState } from 'react'
import { Button, Headline, Pgraph, ProductCarouselCard } from '..'
import { useRouter } from 'next/router'
import { URLS } from '../../config'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import { ProductsApi } from '../../services'
import {IProduct, IProductSummary} from '../../interfaces'
import { IProductFiltersResponse } from '../../interfaces/product.interface'
import { useCartStore } from '../../store'

interface IButtonGroupProps {
  next: () => void
  previous: () => void
  carouselState: {
    currentSlide: number
    totalItems: number
  }
}

interface IYouMayAlsoLikeProps {
  category?: IProduct
}

const ButtonGroup = ({ next, previous, carouselState }: IButtonGroupProps) => {
  // use carouselState to disable button when in the last slide, for example.
  return (
    <div className="absolute -bottom-0 right-0 mr-3 hidden lg:block">
      <span onClick={() => previous()} className="mr-4 cursor-pointer">
        <i className="loop-icon-arrow-long text-24px text-black transition ease-in duration-400 rotate-180 inline-block"></i>
      </span>
      <span onClick={() => next()} className="cursor-pointer">
        <i className="loop-icon-arrow-long text-24px text-black transition ease-in duration-400"></i>
      </span>
    </div>
  )
}

function YouMayAlsoLikeMolecule({category}: IYouMayAlsoLikeProps) {
  const [results, setResults] = useState<IProductSummary[]>([])
  const { basicFilters: filters } = useCartStore()

  const router = useRouter()

  const goToProducts = () => {
    router.push(URLS.PRODUCTS.INDEX + ( category ? `?categories=${category.categories[0].id}`  : ''))
  }

  useEffect(() => {
    if (!filters) return

    const getResults = async (catId: number) => {
      try {
        const response = await ProductsApi.fetchProducts({
          pageParam: 1,
          itemsPerPage: 10,
          filterOption: {
            categories: category ? [category.categories[0].id] : [catId],
          },
        })

        if (response?.success) {
          const filteredResults = response.products.filter(x=> x.bigCommerceProductId !== category?.bigCommerceProductId);
          setResults(filteredResults)
        }
      } catch (error) {
      }
    }
    getResults(filters.topProductsCategoryId)
  }, [filters])

  return (
    <section className="w-full">
      <div className="max-w-6xl mx-auto py-6 overflow-hidden">
        <div className="">
          <Headline
            variant="recoleta-s"
            className="text-center text-neutral-7 md:text-28px lg:text-32px"
          >
            You may also like
          </Headline>
        </div>

        <div className="max-w-5xl mx-auto">
          <div className="mt-5 px-[20px] relative pb-5">
            {results ? (
              <Carousel
                swipeable={true}
                showDots={false}
                arrows={false}
                infinite={true}
                partialVisible={true}
                autoPlay={false}
                autoPlaySpeed={5000}
                renderButtonGroupOutside={true}
                responsive={{
                  xxsmobile: {
                    breakpoint: { max: 400, min: 0 },
                    items: 1,
                    partialVisibilityGutter: 50,
                  },
                  xsmobile: {
                    breakpoint: { max: 500, min: 400 },
                    items: 1,
                    partialVisibilityGutter: 50,
                  },
                  mobile: {
                    breakpoint: { max: 640, min: 500 },
                    items: 1,
                    partialVisibilityGutter: 100,
                  },
                  smtablet: {
                    breakpoint: { max: 740, min: 640 },
                    items: 2,
                    partialVisibilityGutter: 0,
                  },
                  tablet: {
                    breakpoint: { max: 1024, min: 740 },
                    items: 2,
                    partialVisibilityGutter: 50,
                  },
                  desktop: {
                    breakpoint: { max: 9000, min: 1024 },
                    items: 2,
                    partialVisibilityGutter: 140,
                  },
                }}
                //@ts-ignore
                customButtonGroup={<ButtonGroup />}
              >
                {results.map((product, index) => {
                  return <ProductCarouselCard key={index} product={product} />
                })}
              </Carousel>
            ) : (
              <div></div>
            )}
          </div>
        </div>

        <div className="text-center">
          <Button
            primary={true}
            onClick={goToProducts}
            type="button"
            label={category ? `Browse ${category.categories[0].name}` : 'Browse Top Rentals'}
          ></Button>
        </div>
      </div>
    </section>
  )
}

export { YouMayAlsoLikeMolecule }
