import * as prismic from '@prismicio/client'
import {
  BlogEntryPageDocument,
  BlogPostCategoryDocument,
  BlogPostDocument,
} from '../.slicemachine/prismicio'
import * as prismicNext from '@prismicio/next'

export const PRISMIC_URL = 'https://loop-baby.cdn.prismic.io/api/v2'
export const PRISMIC_REPO = 'loop-baby'
const accessToken = process.env.PRISMIC_ACCESS_TOKEN

const routes: prismicNext.CreateClientConfig['routes'] = [
  {
    type: 'blog_entry_page',
    path: '/blog',
  },
  {
    type: 'blog_post',
    path: '/blog/post/:uid',
  },
]

function createClient(config: prismicNext.CreateClientConfig = {}) {
  try {
    const client = prismic.createClient(PRISMIC_REPO, {
      accessToken,
      routes,
      ...config,
    })

    prismicNext.enableAutoPreviews({
      client,
      previewData: config.previewData,
      req: config.req,
    })

    return client
  } catch (error) {
    console.error(error)
    throw error
  }
}

async function fetchAllBlogPosts() {
  const client = createClient()

  const page = await client.getAllByType('blog_post')

  return page
}

async function fetchBlogPostByUid(slug: string) {
  const client = createClient()
  const post = await client.getByUID('blog_post', slug)

  return post
}

async function fetchCategories() {
  const client = createClient()

  const categories = await client.getAllByType('blog_post_category')

  return categories
}

async function fetchBlogPostCategories(post: BlogPostDocument) {
  const client = createClient()

  //@ts-ignore
  const categoriesIds = post.data.categories.map((c) => c.category.id)

  const categories = await client.getByIDs<BlogPostCategoryDocument>(
    categoriesIds
  )

  return categories
}

async function fetchBlogMainPage() {
  const client = createClient()

  const mainPage = await client.getSingle<
    BlogEntryPageDocument & {
      data: {
        main_post: {
          uid: string
          slug: string
          isBroken: boolean
        }
      }
    }
  >('blog_entry_page')

  return mainPage
}

export {
  createClient,
  fetchAllBlogPosts,
  fetchBlogPostByUid,
  fetchCategories,
  fetchBlogPostCategories,
  fetchBlogMainPage,
}
