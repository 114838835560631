import React from 'react'
import { Button, Headline, Pgraph } from '..'
import { useRouter } from 'next/router'
import { URLS } from '../../config'

function PromotionalBox() {
  const router = useRouter()

  const goToHLW = () => {
    router.push(`${URLS.PRODUCTS.INDEX}?categories=58`) // Toybox category id = 58 on PROD
  }

  return (
    <section className="w-full bg-heavy-cream py-6">
      <div className="max-w-7xl mx-auto">
        <div className="flex flex-col items-center md:flex-row lg:min-h-[500px]">
          <div className="px-5 flex flex-col items-center md:w-1/2 lg:w-2/5 lg:justify-start">
            <Headline
              variant="recoleta-s"
              className="text-poppy text-center mb-4 px-5 md:text-28px md:text-left lg:text-32px lg:self-start"
            >
              Rent toys by your child&apos;s age &amp; stage
            </Headline>

            <Pgraph
              variant="p-16"
              className="text-center text-Kona-00 md:text-left md:px-5"
            >
              Loop rental toy boxes make it easy to rotate and refresh toys as
              your child grows. Our toy boxes can include items from: Plan Toys,
              Grimms, Wee Gallery, Banner Toys, Francis Lincon and more.
            </Pgraph>

            <div className="text-center my-5 md:text-left md:w-full md:px-5">
              <Button
                size="medium"
                type="button"
                label="Explore Toy Boxes"
                primary={true}
                onClick={goToHLW}
              ></Button>
            </div>
          </div>
          <div className="w-full md:w-1/2 lg:w-3/5 relative lg:self-start">
            <div className="md:hidden relative w-full">
              <img
                src="/images/homepage/toybox-promo-mobile.png"
                alt="ToyBox Promo"
                height={'100%'}
                width={'100%'}
              />
            </div>

            <div className="hidden md:block relative w-full lg:absolute lg:w-[110%] lg:right-0">
              <img
                src="/images/homepage/toybox-promo.png"
                alt="ToyBox Promo"
                height={'80%'}
                width={'100%'}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export { PromotionalBox }
