import React, { useState } from 'react'
import { Headline } from '../Headline'
import { ReviewsIO } from '../ReviewsIO'
import { Pgraph } from '../Pgrah'
import { PRODUCT_LINKS } from '../../config/productLinks.constants'
import { getProductSeoName } from '../../services/products_api'
import Link from 'next/link'

function PHLCommunitySaying() {
  const getProductURL = (): string => {
    return `${process.env.NEXT_PUBLIC_BASE_URL}/products/${getProductSeoName({
      title: PRODUCT_LINKS.babyActivityChair.name,
    })}/${
      process.env.NEXT_PUBLIC_ENV !== 'prod'
        ? PRODUCT_LINKS.babyActivityChair.devProductId
        : PRODUCT_LINKS.babyActivityChair.prodProductId
    }`
  }
  return (
    <section className="flex justify-center items-center flex-col bg-neutral-1 w-full">
      {/* Mobile */}
      <div className="md:hidden">
        <Headline
          variant="recoleta-m"
          className="text-neutral-7 mx-auto w-[75%] mb-[100px] text-center md:hidden"
        >
          What our community is saying
        </Headline>
        <div className="px-4 bg-heavy-cream relative min-h-[330px] w-full">
          <div className="absolute -top-7 w-[calc(100%-48px)]">
            <img
              src="/images/phl/phl-community.jpg"
              alt="Philadelphia Community"
              className="max-h-[345px] mx-auto"
            />
            <Pgraph
              variant="p-14"
              className="text-neutral-5 mt-2  md:ml-4 mb-4 max-w-[350px] mx-auto"
            >
              Having fun in the{' '}
              <Link
                href={getProductURL()}
                className="text-neutral-6 underline"
              >
                Nature Friends Baby Activity Chair
              </Link>
            </Pgraph>
          </div>
        </div>
        <div className="w-full lg:max-w-6xl bg-heavy-cream flex flex-col absolue md:hidden">
          <div className="mx-4 relative -top-6">
            <div className="absolute flex w-full justify-end">
              <div className="relative top-[47px] -mr-4">
                <img
                  src="/images/homepage/social-proof-hand.png"
                  alt="Look at these reviews!"
                  width={75}
                  height={20}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-center w-full relative -top-5">
            <div className="flex justify-center">
              <ReviewsIO variant="social" />
            </div>
            <div>
              <div className="flex">
                <div className="flex ml-5 -mt-2 absolute">
                  <div>
                    <img
                      src="/images/double-quotes.svg"
                      width={31}
                      height={27}
                      alt="Double quotes"
                    />
                  </div>
                </div>
                <div className="flex flex-col justify-center">
                  <div className="font-recoleta text-16px leading-22 text-neutral-5 mx-5 text-center">
                    I have been renting from Loop for a few months and I only
                    wish I found it sooner. I&apos;m able to rent the exact
                    items I would have bought, use them for a couple months, and
                    then they take them away...”
                  </div>
                  <Pgraph
                    variant="l-14"
                    className="text-center mt-4 mb-[30px] md:mb-6"
                  >
                    Alexa B. <span className="font-circular">mom of 2</span>
                  </Pgraph>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Tablet and desktop */}
      <div className="hidden md:flex flex-row bg-heavy-cream w-full lg:max-w-[1050px]">
        <div className="w-1/2 pb-3">
          <div className="-mt-5 mb-4 lg:ml-4 lg:mb-3">
            <img
              src="/images/phl/phl-community.jpg"
              alt="Philadelphia Community"
              className="max-w-[100%]"
            />
          </div>
          <Pgraph variant="p-14" className="text-neutral-5 ml-4 mb-4">
            Having fun in the{' '}
            <Link href={getProductURL()} className="text-neutral-6 underline">
              Nature Friends Baby Activity Chair
            </Link>
          </Pgraph>
        </div>
        <div className="w-1/2 flex flex-col md:px-3 lg:px-4 md:pt-4 lg:pt-5 relative">
          <Headline
            variant="recoleta-m"
            className="text-center mt-4 mx-4 lg:hidden"
          >
            What our <br />
            community is saying
          </Headline>
          <Headline
            variant="recoleta-m"
            className="text-center mt-5 mb-3 mx-4 hidden lg:block"
          >
            What our community is saying
          </Headline>
          <div className="">
            <div className="absolute mt-[30px] right-0">
              <img
                src="/images/homepage/social-proof-hand.png"
                alt="Look at these reviews!"
                width={126}
                height={33}
              />
            </div>
          </div>
          <div className="flex justify-center flex-col">
            <div className="text-center">
              <ReviewsIO variant="social" />
            </div>
            <div>
              <div className="flex">
                <div className="flex ml-5 -mt-2 absolute">
                  <div>
                    <img
                      src="/images/double-quotes.svg"
                      width={31}
                      height={27}
                      alt="Double quotes"
                    />
                  </div>
                </div>
                <div className="flex flex-col justify-center">
                  <div className="font-recoleta text-16px leading-22 text-neutral-5 mx-5 text-center md:text-20px md:leading-29">
                    I have been renting from Loop for a few months and I only
                    wish I found it sooner. I&apos;m able to rent the exact
                    items I would have bought, use them for a couple months, and
                    then they take them away...”
                  </div>
                  <Pgraph
                    variant="l-14"
                    className="text-center mt-4 mb-[30px] md:mb-6 lg:text-18px lg:leading-22"
                  >
                    Alexa B. <span className="font-circular">mom of 2</span>
                  </Pgraph>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export { PHLCommunitySaying }
