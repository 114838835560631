import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import {
  Button,
  Headline,
  InputText,
  MembershipMolecule,
  Pgraph,
  SelectInput,
  ValuePropMolecule,
} from '..'
import { REDEEM_VALUE_PROPS, US_STATES } from '../../config'
import { AUTH_ACTION, IAddress } from '../../interfaces'
import { useAuthDispatch, useAuthStore } from '../../store'
import { sanitizeFields } from '../../config/utilities'
import { CustomersApi } from '../../services'
import { MembershipPerks } from '../../config/members-perks.constants'

interface IRedeemAddressNeededProps {
  onAddressSaved?: () => void
}

function RedeemAddressNeeded({
  onAddressSaved = () => {},
  ...props
}: IRedeemAddressNeededProps) {
  const [processing, setProcessing] = useState<boolean>(false)
  const { credentials } = useAuthStore()
  const authDispatch = useAuthDispatch()
  const [error, setError] = useState<string>()
  const [sucess, setSuccess] = useState<string>()

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IAddress>({
    defaultValues: {
      streetAddress1: '',
      streetAddress2: '',
      streetAddress3: '',
      city: '',
      state: 'CA',
      postalCode: '',
      country: 'US',
    },
  })

  const onSubmit = async (data: IAddress) => {
    try {
      if (!credentials?.accessToken) return
      setProcessing(true)
      setError(undefined)
      setSuccess(undefined)
      // wrap all forms with the following function to fix values such as whitespaces
      sanitizeFields(data)
      const result = await CustomersApi.updateAddresses({
        accessToken: credentials.accessToken,
        billingAddress: data,
        shippingAddress: data,
      })

      if (result) {
        authDispatch({
          type: AUTH_ACTION.SET_TOKENS,
          credentials,
        })
        setSuccess('Address updated')
        onAddressSaved()
        // NOT setProcessing(false) because Redeem is still pending and button should remain disable
      } else {
        throw new Error('Error updating address')
        setProcessing(false)
      }
    } catch (error) {
      console.error(error)
      setError(error instanceof Error ? error.message : 'Unknown error')
      setProcessing(false)
    }
  }

  const updateZip = (value: any) => {
    setValue('postalCode', value)
  }

  return (
    <section className="w-full bg-neutral-1">
      <div className="max-w-5xl mx-auto">
        <div className="">
          <div className="mx-auto py-4 px-4 md:py-5">
            <div className="max-w-3xl mx-auto">
              <Headline
                variant="recoleta-s"
                className="text-poppy mt-3 w-4/5 text-center mx-auto mb-3 leading-29 md:text-28px md:w-3/3 lg:text-32px lg:leading-38 lg:mb-4"
              >
                Welcome to the Loop family
              </Headline>
              <Pgraph
                variant="p-14"
                className="text-neutral-5 w-4/5 mx-auto mt-3 text-center md:text-16px md:mx-auto md:w-3/5 md:leading-19 lg:w-3/5 lg:mb-4"
              >
                You&apos;ve been gifted a Membership to Loop. To Activate we
                just need a few more pieces of information from you. Your
                membership won&apos;t start until the delivery of your first
                item.
              </Pgraph>

              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="md:grid md:grid-cols-2 md:gap-4">
                  <div className="mt-4">
                    <Pgraph variant="l-14" className="text-neutral-6 mb-[6px]">
                      Address
                    </Pgraph>
                    <InputText
                      type="text"
                      additionalClassName={'text-neutral-5'}
                      borderColor={'border-neutral-3'}
                      placeHolder="Address"
                      {...register('streetAddress1', { required: 'Required' })}
                      validationText={errors.streetAddress1?.message}
                    />
                  </div>
                  <div className="mt-4">
                    <Pgraph variant="l-14" className="text-neutral-6 mb-[6px]">
                      Apartment, suite, etc.
                    </Pgraph>
                    <InputText
                      type="text"
                      additionalClassName={'text-neutral-5'}
                      borderColor={'border-neutral-3'}
                      placeHolder=" Apartment, suite, etc."
                      {...register('streetAddress2')}
                    />
                  </div>
                </div>

                <div className="md:grid md:grid-cols-2 md:gap-4">
                  <div className="mt-4">
                    <Pgraph variant="l-14" className="text-neutral-6 mb-[6px]">
                      City
                    </Pgraph>
                    <InputText
                      type="text"
                      additionalClassName={'text-neutral-5'}
                      borderColor={'border-neutral-3'}
                      placeHolder="City"
                      {...register('city', { required: 'Required' })}
                      validationText={errors.city?.message}
                    />
                  </div>
                  <div className="md:grid md:grid-cols-2 md:gap-4">
                    <div className="mt-4">
                      <Pgraph
                        variant="l-14"
                        className="text-neutral-6 mb-[6px]"
                      >
                        State
                      </Pgraph>
                      <SelectInput
                        {...register('state', {
                          required: 'Select a state',
                        })}
                        validationText={errors.state?.message}
                        options={US_STATES}
                      />
                    </div>

                    <div className="mt-4">
                      <Pgraph
                        variant="l-14"
                        className="text-neutral-6 mb-[6px]"
                      >
                        Zip
                      </Pgraph>
                      <InputText
                        type="numeric"
                        additionalClassName={'text-neutral-5'}
                        borderColor={'border-neutral-3'}
                        placeHolder="Zip"
                        {...register('postalCode', { required: 'Required' })}
                        validationText={errors.postalCode?.message}
                        onChange={(e) => updateZip(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div className="text-center mt-[44px]">
                  <Pgraph variant="l-14" className="text-center text-caution-2">
                    {error}
                  </Pgraph>
                  <Button
                    label="Join now"
                    primary
                    className={'py-[5px] px-[75px] w-[275px] mx-auto'}
                    type="submit"
                    size="medium"
                    loading={processing}
                    disabled={processing}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>

        <ValuePropMolecule
          className="bg-heavy-cream"
          propsList={REDEEM_VALUE_PROPS}
        />
      </div>
      <div>
        <MembershipMolecule
          title="Membership perks"
          perks={MembershipPerks}
          className="bg-neutral-1"
          imagePath="/images/stock/membership-join-now.jpg"
          imageClassName="md:max-width-[400px]"
        />
      </div>
    </section>
  )
}

export { RedeemAddressNeeded }
