import React, { ForwardedRef } from 'react'
import Image from 'next/image'

const getConfiguredClasses = (
  borderColor: string,
  validationText?: string,
  iconPosition?: string,
  disabled?: boolean
): string => {
  return (
    (validationText
      ? 'border-caution-2'
      : borderColor
      ? borderColor
      : 'border-neutral-3') +
    (iconPosition === 'left' ? ' pl-[33px]' : '') +
    (disabled ? ' cursor-not-allowed disabled:opacity-30 ' : '')
  )
}

interface IInputTextAreaProps {
  /**
   * is field required
   */
  required?: boolean
  /**
   * any validation text triggered by required action
   */
  validationText?: string
  /**
   * icon to be displayed ( as part of validation )
   */
  icon?: string
  /**
   * css class
   */
  className?: string
  /**
   * additional css class on top of base
   */
  additionalClassName?: string
  /**
   * can the input be interacted
   */
  borderColor?: string
  /**
   * can the input be interacted
   */
  disabled?: boolean
  /**
   * placeholder display
   */
  placeHolder?: string
  /**
   * Optional icon position - defaults to left
   */
  iconPosition?: 'left' | 'right'
  /**
   * Optional click handler
   */
  onClick?: () => void
  /**
   * Optional change handler
   */
  'data-cy'?: string
  /**
   * Optional cypress test id
   */
  dataCyError?: string
  /**
   * Optional error field cypress test id
   */
}

/**
 * Primary UI component for user interaction
 */
export const InputTextArea = React.forwardRef(function InputTextAreaRef(
  {
    required = false,
    disabled = false,
    placeHolder = 'enter a value',
    validationText = '',
    icon = '',
    iconPosition = 'right',
    dataCyError,
    additionalClassName = '',
    borderColor = '',
    ...props
  }: IInputTextAreaProps,
  ref: ForwardedRef<HTMLTextAreaElement>
) {
  const baseClasses: string =
    'font-circular flex flex-row bg-snow-white border border-solid rounded w-full outline-0 focus:border-alice p-16px items-start'
  const configuredClasses: string = getConfiguredClasses(
    borderColor,
    validationText,
    iconPosition,
    disabled
  )

  return (
    <div className="relative">
      <textarea
        rows={5}
        placeholder={placeHolder}
        className={[baseClasses, configuredClasses, additionalClassName].join(
          ' '
        )}
        ref={ref}
        {...props}
        disabled={disabled}
      ></textarea>
    </div>
  )
})
