import { JSXMapSerializer } from '@prismicio/react'
import Link from 'next/link'
import { Headline, Pgraph } from '../components'

export const prismicRichTextComponents: JSXMapSerializer = {
  hyperlink: ({ children, node }) => (
    <a href={node.data.url || '/'} className="underline">
      {children}
    </a>
  ),
  strong: ({ children }) => <strong>{children}</strong>,
  paragraph: ({ children, node }) => (
    <Pgraph
      variant="p-16"
      className={`my-4 ${
        //@ts-ignore
        node.direction === 'rtl' ? 'text-right' : 'text-left'
      }`}
    >
      {children}
    </Pgraph>
  ),
  heading1: ({ children }) => (
    <Headline variant="recoleta-m" className="my-4">
      {children}
    </Headline>
  ),
  heading2: ({ children }) => (
    <Headline variant="recoleta-s" className="my-4">
      {children}
    </Headline>
  ),
  heading3: ({ children }) => (
    <Headline variant="recoleta-xs" className="my-4">
      {children}
    </Headline>
  ),
  heading4: ({ children }) => (
    <Headline variant="recoleta-xs" className="my-4">
      {children}
    </Headline>
  ),
  heading5: ({ children }) => (
    <Headline variant="recoleta-xs" className="my-4">
      {children}
    </Headline>
  ),
  heading6: ({ children }) => (
    <Headline variant="recoleta-xs" className="my-4">
      {children}
    </Headline>
  ),
  list: ({ children }) => (
    <ul className="list-inside list-disc ml-4">{children}</ul>
  ),
  oList: ({ children }) => (
    <ol className="list-inside list-decimal ml-4">{children}</ol>
  ),
}
