import React, { ForwardedRef } from 'react'

interface ICheckboxProps {
  /**
   * Checked
   */
  checked?: boolean
  /**
   * is field required
   */
  required?: boolean
  /**
   * Checkbox text
   */
  checkboxText?: string
  /**
   * css class
   */
  className?: string
  /**
   * additional css class to be applied on checkbox
   */
  addClassName?: string
  /**
   * additional css class to be applied on text
   */
  addTextClassName?: string
  /**
   * can the input be interacted
   */
  disabled?: boolean

  /**
   * Optional click handler
   */
  onClick?: () => void
  /**
   * Optional change handler
   */
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

/**
 * Primary UI component for user interaction
 */
export const Checkbox = React.forwardRef(function CheckboxForwardRef(
  {
    checkboxText = '',
    addClassName = '',
    addTextClassName = '',
    onChange,
    ...props
  }: ICheckboxProps,
  ref: ForwardedRef<HTMLInputElement>
) {
  const checkboxClasses: string =
    'border border-neutral-4 absolute top-0 left-0 h-[16px] w-[16px] rounded-sm transition-all duration-[400ms] peer-checked:bg-black peer-checked:border-black peer-checked:after:block peer-checked:after:relative peer-checked:after:left-[4px] peer-checked:after:top-[1px] peer-checked:after:w-[6px] peer-checked:after:h-[10px] peer-checked:after:border peer-checked:after:border-white peer-checked:after:border-t-0 peer-checked:after:border-r-[3px] peer-checked:after:border-b-[3px] peer-checked:after:border-l-0  peer-checked:after:rotate-45'
  return (
    <label
      className={['font-circular relative cursor-pointer pl-[20px]', addTextClassName].join(
        ' '
      )}
    >
      {checkboxText}
      <input
        type="checkbox"
        className="absolute opacity-0 peer"
        ref={ref}
        onChange={onChange}
        {...props}
      />
      <span className={[checkboxClasses, addClassName].join(' ')}></span>
    </label>
  )
})
