import { useRouter } from 'next/router'
import {
  BlogPostCategoryDocument,
  BlogPostDocument,
} from '../../.slicemachine/prismicio'

interface IPrismicBlogCardProps {
  post: BlogPostDocument
  category: BlogPostCategoryDocument
}

const PrismicBlogCard = ({ category, post }: IPrismicBlogCardProps) => {
  const router = useRouter()

  const gotoPost = () => {
    router.push(`/blog/post/${post.uid}`)
  }

  return (
    <div className="w-full">
      <img
        src={post.data.header_image.url || ''}
        alt={post.data.header_image.alt || 'Looplife'}
        className="w-full cursor-pointer"
        onClick={gotoPost}
      />
      <div className="text-14px leading-18 mt-3">
        {category.data.name?.toUpperCase()}
      </div>
      <div
        className="my-3 font-recoleta text-24px cursor-pointer"
        onClick={gotoPost}
      >
        {post.data.title}
      </div>
      <div className="text-16px">{post.data.summary}</div>
      <div
        className="underline cursor-pointer my-3 text-16px"
        onClick={gotoPost}
      >
        Read now
      </div>
    </div>
  )
}

export { PrismicBlogCard }
