import React, { useMemo } from 'react'
import NumberFormat from 'react-number-format'
import {
  QUANTITY_OPTIONS,
  PRODUCT_CONFIG,
  DELIVERY_OPTIONS,
} from '../../config'
import { Dropdown } from '../Dropdown'
import { Pgraph } from '../Pgrah'
import Image from 'next/image'
import {
  ICartApiProduct,
  ICartQueuedLine,
  IRegistryCartLine,
} from '../../interfaces'
import { useAuthStore, useCartStore, useHooks } from '../../store'
import { trackEvent } from '../../config/analytics'

interface ICartLineProps {
  line: ICartQueuedLine | ICartApiProduct | IRegistryCartLine
  updateLineQuantity: (
    line: Partial<ICartApiProduct>,
    newQuantity: string
  ) => void
  removeLine: (line: Partial<ICartApiProduct>) => void
  topBorder: boolean
  isRegistry: boolean
  deliveryOption?: typeof DELIVERY_OPTIONS[number]
}

export const CartLine = ({
  line,
  updateLineQuantity,
  removeLine,
  topBorder,
  isRegistry,
}: ICartLineProps) => {
  const {
    membershipProducts: { annual, monthly },
    cart,
  } = useCartStore()
  const { subscriptionActive } = useAuthStore()
  const { getCartData } = useHooks()

  const membershipInCart = useMemo(() => {
    if (isRegistry) return false
    const { membershipInCart } = getCartData({ cart })
    return membershipInCart
  }, [cart, getCartData, isRegistry])

  const isMember = useMemo(() => {
    return subscriptionActive || membershipInCart
  }, [membershipInCart, subscriptionActive])

  const isMembership = !![annual, monthly].find(
    (p) => p?.bigCommerceProductId === line.productId
  )
  const isAnnualMembership = annual?.bigCommerceProductId === line.productId

  const onRemoveLine = (line: Partial<ICartApiProduct>) => {
    trackEvent('Product Removed', line)
    removeLine(line)
  }

  const onRemoveMembership = (line: Partial<ICartApiProduct>) => {
    trackEvent('Membership Removed', line)
    removeLine(line)
  }

  const price = useMemo(() => {
    return line.salePrice
  }, [line])

  return (
    <div
      className={`items-center my-2 pb-4 pb border-b-[1px] border-solid border-neutral-2 ${
        topBorder ? 'border-t-[1px] pt-3' : ''
      }`}
    >
      <div className={`hidden md:block ${isMembership ? 'pt-[16px]' : ''}`}>
        <div className="md:flex">
          <div className="w-[96px] h-[96px] relative min-w-[96px] text-center">
            <Image src={line.imageUrl} alt={line.name} fill={true} />
          </div>
          <div className="w-[50%] ml-[20px]">
            <Pgraph variant="l-12" className="mb-[8px]">
              {line.name}
            </Pgraph>
            {isMembership && (
              <Pgraph variant="p-12" className="italic">
                $16.67/mo charged annually. Your membership savings are
                automatically reflected in this cart and order total.
              </Pgraph>
            )}
            <div className="hidden md:block mt-[18px] -ml-[17px]">
              {!isRegistry && (
                <Dropdown
                  value={line.quantity.toString()}
                  onChange={(e) => updateLineQuantity(line, e.target.value)}
                  options={QUANTITY_OPTIONS}
                  variant="no-border"
                  className={isMembership ? 'hidden' : ''}
                />
              )}
            </div>
          </div>
          <div className="w-[50%] text-right">
            <div className="flex flex-col">
              <Pgraph
                variant={'p-12'}
                className={`${
                  isMember ? 'line-through text-neutral-4' : ''
                } mb-[8px] ${isMembership || isRegistry ? 'hidden' : ''}`}
              >
                Non-member{' '}
                <NumberFormat
                  value={line.nonMemberPrice}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={'$'}
                  decimalScale={0}
                  fixedDecimalScale={true}
                  suffix={
                    isRegistry
                      ? undefined
                      : ' / ' +
                        (isAnnualMembership
                          ? PRODUCT_CONFIG.membershipPeriod.year
                          : PRODUCT_CONFIG.rentingPeriod)
                  }
                />
              </Pgraph>
              <Pgraph
                variant="p-12"
                className={`${!isMember ? 'text-neutral-4' : ''}`}
              >
                {isMembership || isRegistry ? '' : 'Member '}
                <NumberFormat
                  value={price}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={'$'}
                  decimalScale={0}
                  fixedDecimalScale={true}
                  suffix={
                    isRegistry
                      ? undefined
                      : ' / ' +
                        (isAnnualMembership
                          ? PRODUCT_CONFIG.membershipPeriod.year
                          : PRODUCT_CONFIG.rentingPeriod)
                  }
                />
              </Pgraph>
            </div>
            <div
              className={`hidden md:block mt-[18px] ${
                isMembership ? 'pt-[16px]' : ''
              }`}
            >
              <Pgraph
                variant="p-12"
                className={'underline cursor-pointer'}
                onClick={() => {
                  isMembership ? onRemoveMembership(line) : onRemoveLine(line)
                }}
              >
                {isMembership ? 'Remove' : 'Remove'}
              </Pgraph>
            </div>
          </div>
        </div>
      </div>
      <div className={`md:hidden ${isMembership ? 'pt-[16px]' : ''}`}>
        <div className="flex">
          <div className="w-[50%]">
            <Pgraph variant="l-12" className="mb-[8px]">
              {line.name}
            </Pgraph>
            <div className="w-[96px] h-[96px] relative min-w-[96px] text-center mt-[12px]">
              <Image src={line.imageUrl} alt={line.name} fill={true} />
            </div>
          </div>
          <div className="w-[50%] text-right">
            <Pgraph
              variant="p-12"
              className={`${
                isMember ? 'line-through text-neutral-4' : ''
              } mb-[8px] ${isMembership || isRegistry ? 'hidden' : ''}`}
            >
              Non-member{' '}
              <NumberFormat
                value={line.nonMemberPrice}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'$'}
                decimalScale={0}
                fixedDecimalScale={true}
                suffix={
                  isRegistry
                    ? undefined
                    : ' / ' +
                      (isAnnualMembership
                        ? PRODUCT_CONFIG.membershipPeriod.year
                        : PRODUCT_CONFIG.rentingPeriod)
                }
              />
            </Pgraph>
            <Pgraph
              variant="p-12"
              className={`${!isMember ? 'text-neutral-4' : ''}`}
            >
              {isMembership ? '' : 'Member '}
              <NumberFormat
                value={price}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'$'}
                decimalScale={0}
                fixedDecimalScale={true}
                suffix={
                  isRegistry
                    ? undefined
                    : ' / ' +
                      (isAnnualMembership
                        ? PRODUCT_CONFIG.membershipPeriod.year
                        : PRODUCT_CONFIG.rentingPeriod)
                }
              />
            </Pgraph>
            {/*<Pgraph variant="p-12" className="mt-[16px]">*/}
            {/*  {isMembership*/}
            {/*    ? `Your membership savings are automatically reflected in this cart.`*/}
            {/*    : `Delivery timing : ${deliveryOption?.value}`}*/}
            {/*</Pgraph>*/}
            <div className="mt-[18px] relative">
              {!isRegistry && (
                <div className="absolute right-[60px] -top-[3px]">
                  <Dropdown
                    value={line.quantity.toString()}
                    onChange={(e) => updateLineQuantity(line, e.target.value)}
                    options={QUANTITY_OPTIONS}
                    variant="no-border"
                    className={isMembership ? 'hidden' : ''}
                  />
                </div>
              )}
              {isMembership && (
                <Pgraph
                  variant="p-12"
                  className="italic mb-[4px] text-neutral-6"
                >
                  $16.67/mo charged annually. Your membership savings are
                  automatically reflected in this cart and order total.
                </Pgraph>
              )}
              <Pgraph
                variant="p-12"
                className="underline cursor-pointer absolute right-[0px]"
                onClick={() => {
                  isMembership ? onRemoveMembership(line) : onRemoveLine(line)
                }}
              >
                {isMembership ? 'Remove' : 'Remove'}
              </Pgraph>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CartLine
