import {
  ILoginForm,
  IResetPasswordForm,
  IUser,
  ISignupEndPointResponse,
} from '../interfaces'
import { fetchAccount } from './accounts_api'
import { getRequestHeaders } from '../config'
import { CONTACT_TYPE } from '../config/contact-us.constants'
import { fetchTimeoutRetry, sanitizeFields } from '../config/utilities'
import { DateTime } from 'luxon'

export interface ISignupEndpointRequest {
  firstName: string
  lastName: string
  password: string
  emailAddress: string
  cellPhone?: string
  childBirthdays: {
    name: string
    birthdayDate: {
      year: string
      month: string
      day: string
    }
  }[]
}

export interface IAddressEndpointRequest {
  address1: string
  address2: string
  city: string
  state: string
  zip: string
}

export interface IChangePasswordEndpointRequest {
  oldPassword: string
  newPassword: string
  newPasswordCheck: string
}

export interface ISupportEndpointRequest {
  details: string
}

interface ISignupResponse {
  account: IUser['account']
  success: boolean
  tokenHolder: {
    accessToken: string
    idToken: string
    tokenType: 'Bearer'
    expiresIn: number
    expiresAt: string
    scope: string
  }
}
type IPasswordLoginResponse =
  | {
      error: false
      accessToken: string
      idToken: string
      tokenType: string
      expiresIn: number
      scope: string
      expiresAt: string
      refreshToken: string
    }
  | {
      error: true
      errors: {
        code: string
        message: string
      }[]
    }

interface IMessageResponse {
  message: string
}

interface ICheckAuthResponse {
  account: any
  isValid: boolean
}
interface ILogoutProps {
  accessToken: string
}

export interface IContactUsRequest {
  type: CONTACT_TYPE
  typeDetails?: string
  isBodyHTML: boolean
  body: string
  sender: {
    firstName: string
    lastName: string
    email: string
    phone?: string
    state?: string
  }
}

const loginWithPassword = async ({
  email,
  password,
}: ILoginForm): Promise<IPasswordLoginResponse> => {
  try {
    const result = await fetchTimeoutRetry(
      `${process.env.NEXT_PUBLIC_AUTH_URL}/login`,
      {
        method: 'POST',
        mode: 'cors',
        headers: getRequestHeaders(),
        body: JSON.stringify({
          email,
          password,
        }),
      }
    )

    if (!result.ok || result.status !== 200) {
      const errorJson = await result.json()
      if (errorJson.errors && errorJson.errors.length) {
        return {
          error: true,
          errors: errorJson.errors,
        }
      } else {
        throw new Error("Couldn't parse error response")
      }
    }

    const jsonResponse = await result.json()

    return {
      error: false,
      ...jsonResponse,
    }
  } catch (error: any) {
    console.error(error)
    return {
      error: true,
      errors: [
        {
          code: 'UNKNOWN',
          message: error instanceof Error ? error.message : 'Unknown error',
        },
      ],
    }
  }
}

const changePassword = async (
  oldPassword: string,
  newPassword: string,
  accessToken: string
) => {
  try {
    const result = await fetchTimeoutRetry(
      `${process.env.NEXT_PUBLIC_AUTH_URL}/change-password`,
      {
        method: 'POST',
        mode: 'cors',
        headers: getRequestHeaders(accessToken),
        body: JSON.stringify({
          oldPassword,
          newPassword,
        }),
      }
    )

    if (!result.ok || result.status !== 200)
      throw new Error('Please check your email and password')

    const jsonResponse = await result.json()
    return jsonResponse as IMessageResponse
  } catch (error: any) {
    console.error(error)
    return null
  }
}

const resetPassword = async ({ email }: IResetPasswordForm) => {
  try {
    const result = await fetchTimeoutRetry(
      `${process.env.NEXT_PUBLIC_AUTH_URL}/reset-password`,
      {
        method: 'POST',
        mode: 'cors',
        headers: getRequestHeaders(),
        body: JSON.stringify({
          email,
        }),
      }
    )

    if (!result.ok || result.status !== 200)
      throw new Error('Please check your email')

    const jsonResponse = await result.json()

    return jsonResponse as IMessageResponse
  } catch (error: any) {
    console.error(error)
    return null
  }
}

const checkCredentials = async (
  accessToken: string
): Promise<ICheckAuthResponse> => {
  const account = await fetchAccount(accessToken)
  if (account) {
    return {
      account,
      isValid: true,
    }
  } else {
    return {
      account: null,
      isValid: false,
    }
  }
}

const logout = async ({ accessToken }: ILogoutProps) => {
  try {
    const result = await fetchTimeoutRetry(
      `${process.env.NEXT_PUBLIC_AUTH0_URL}/v2/logout?federated&client_id=${process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID}`,
      {
        method: 'GET',
        mode: 'no-cors',
        headers: getRequestHeaders(accessToken),
      }
    )

    if (!result.ok || result.status !== 200)
      throw new Error('Please check your email')

    return true
  } catch (error) {
    console.error(error)
    return false
  }
}

const whoAmI = async ({ accessToken }: { accessToken: string }) => {
  try {
    const result = await fetchTimeoutRetry(
      `${process.env.NEXT_PUBLIC_AUTH_URL}/whoami`,
      {
        method: 'GET',
        mode: 'cors',
        headers: getRequestHeaders(accessToken),
      }
    )

    const jsonResponse = await result.json()

    return jsonResponse as IUser
  } catch {
    return null
  }
}

const signup = async (
  signupData: ISignupEndpointRequest,
  accessToken: string | undefined = undefined
) => {
  try {
    let tokenHolder: ISignupEndPointResponse = {}

    for (const child of signupData.childBirthdays) {
      if (
        !child.name &&
        !child.birthdayDate.day &&
        !child.birthdayDate.month &&
        !child.birthdayDate.year
      ) {
        continue
      }

      try {
        const bDate = DateTime.fromObject({
          day: parseInt(child.birthdayDate.day),
          month: parseInt(child.birthdayDate.month),
          year: parseInt(child.birthdayDate.year),
        })
        if (!bDate.isValid) {
          throw new Error(`Birth date for ${child.name || 'child'} is invalid.`)
        }
      } catch (error) {
        throw new Error(`Birth date for ${child.name || 'child'} is invalid.`)
      }
    }

    if (!accessToken) {
      const accountResult = await fetchTimeoutRetry('/api/signup', {
        method: 'POST',
        headers: getRequestHeaders(),
        body: JSON.stringify(signupData),
      })
      const resJson = (await accountResult.json()) as ISignupEndPointResponse
      if (resJson.error) {
        throw new Error(resJson.error)
      } else {
        tokenHolder = resJson
        accessToken = resJson.accessToken
      }
    }

    if (!accessToken) throw new Error("Couldn't generate account")

    const result = await fetchTimeoutRetry(
      `${process.env.NEXT_PUBLIC_AUTH_URL}/signup`,
      {
        method: 'POST',
        mode: 'cors',
        headers: getRequestHeaders(accessToken),
        body: JSON.stringify(signupData),
      }
    )

    const jsonResponse = await result.json()
    if (!result.ok || result.status !== 200)
      throw new Error(
        jsonResponse?.errors?.length
          ? jsonResponse?.errors[0].message
          : jsonResponse
          ? JSON.stringify(
              typeof jsonResponse === 'object'
                ? jsonResponse
                : 'Unknown body value'
            )
          : "Couldn't sign up"
      )

    return {
      ...jsonResponse,
      tokenHolder,
    } as ISignupResponse
  } catch (error: any) {
    console.error(error)
    throw new Error(error)
  }
}

const getUserInfo = async ({ accessToken }: { accessToken: string }) => {
  try {
    const result = await fetchTimeoutRetry(
      `${process.env.NEXT_PUBLIC_AUTH0_URL}/userinfo`,
      {
        method: 'GET',
        mode: 'cors',
        headers: getRequestHeaders(accessToken),
      }
    )

    if (!result.ok || result.status !== 200)
      throw new Error('Unable to get user info')

    const jsonResponse = await result.json()

    return jsonResponse as IUser
  } catch {
    return null
  }
}

const contactUs = async (data: IContactUsRequest) => {
  try {
    // wrap all forms with the following function to fix values such as whitespaces
    sanitizeFields(data)
    const result = await fetchTimeoutRetry(
      `${process.env.NEXT_PUBLIC_AUTH_URL}/contact-us`,
      {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify(data),
      }
    )

    if (!result.ok || result.status !== 200)
      throw new Error('There was an error contacting us, please try again')

    const jsonResponse = await result.json()
    return jsonResponse as IMessageResponse
  } catch (error: any) {
    console.error(error)
    return null
  }
}

const renewAuth = async (refreshToken: string) => {
  try {
    const response = await fetch(
      `${process.env.NEXT_PUBLIC_AUTH_URL}/renew-auth`,
      {
        method: 'POST',
        headers: getRequestHeaders(),
        body: JSON.stringify({ refreshToken }),
      }
    )
    if (!response.ok) {
      throw new Error('Invalid refresh token')
    }
    const json = (await response.json()) as IPasswordLoginResponse
    return json
  } catch (error) {
    return null
  }
}

export {
  loginWithPassword,
  changePassword,
  resetPassword,
  logout,
  whoAmI,
  checkCredentials,
  signup,
  getUserInfo,
  contactUs,
  renewAuth,
}
