import React, { ReactNode, useEffect, useState } from 'react'
import { Button } from '..'
import { useRouter } from 'next/router'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import Image from 'next/image'

interface IButtonGroupProps {
  next: () => void
  previous: () => void
  carouselState: {
    currentSlide: number
    totalItems: number
  }
}

const Slides_Per_Page = 2

const ButtonGroup = ({ next, previous, carouselState }: IButtonGroupProps) => {
  // use carouselState to disable button when in the last slide, for example.
  return (
    <div className="absolute -bottom-0 right-0 mr-3 hidden lg:block">
      <span onClick={() => previous()} className="mr-4 cursor-pointer">
        <i
          className={`loop-icon-arrow-long text-24px text-black transition ease-in duration-400 rotate-180 inline-block ${
            carouselState.currentSlide === 0
              ? 'cursor-not-allowed opacity-25'
              : ''
          }`}
        ></i>
      </span>
      <span onClick={() => next()} className="cursor-pointer">
        <i
          className={`loop-icon-arrow-long text-24px text-black transition ease-in duration-400 inline-block ${
            carouselState.totalItems - carouselState.currentSlide ===
            Slides_Per_Page
              ? 'cursor-not-allowed opacity-25'
              : ''
          }`}
        ></i>
      </span>
    </div>
  )
}

interface HomePageFeatureCarrousellItem {
  ctaLabel: string
  ctaUrl: string
  body: ReactNode
  bgColor: string
}

const items: HomePageFeatureCarrousellItem[] = [

  {
    ctaLabel: 'Learn More',
    ctaUrl: 'https://www.loop.baby/blog/post/sf-warehouse-sale/',
    bgColor: 'bg-butter',
    body: (
      <div id="SS" className="absolute inset-0">
        <div id="SS-image-container" className="w-full h-full md:block">
        <Image id="SS-image"
          src={'/images/promo-card-WH-desktop.png'}
          fill
          style={{
            objectFit: 'cover',
            objectPosition: 'center',
          }}
          alt="Loop Referral Program"
        />
        </div>
        <div className="w-full h-full md:hidden">
        <Image
          src={'/images/promo-card-WH-Mobile.png'}
          fill
          style={{
            objectFit: 'cover',
            objectPosition: 'center',
          }}
          alt=""
        />
        </div>
      </div>
    ),
  },
  // {
  //   ctaLabel: 'Shop Now',
  //   ctaUrl: 'https://loop-warehouse.mybigcommerce.com/',
  //   bgColor: 'bg-fairy-tale',
  //   body: (
  //     <div id="SS" className="absolute inset-0">
  //       <div id="SS-image-container" className="w-full h-full md:block">
  //       <Image id="SS-image"
  //         src={'/images/promo-card-july-4-desk.png'}
  //         fill
  //         style={{
  //           objectFit: 'cover',
  //           objectPosition: 'center',
  //         }}
  //         alt="Loop Referral Program"
  //       />
  //       </div>
  //       <div className="w-full h-full md:hidden">
  //       <Image
  //         src={'/images/promo-card-july-4-desk.png'}
  //         fill
  //         style={{
  //           objectFit: 'cover',
  //           objectPosition: 'center',
  //         }}
  //         alt="Loop Referral Program"
  //       />
  //       </div>
  //     </div>
  //   ),
  // },
  // {
  //   ctaLabel: 'Shop Now',
  //   ctaUrl: 'https://loop-warehouse.mybigcommerce.com/',
  //   bgColor: 'bg-mango',
  //   body: (
  //     <div id="SS" className="absolute inset-0">
  //       <div id="SS-image-container" className="w-full h-full md:block">
  //       <Image id="SS-image"
  //         src={'/images/PromoCard7_ForKeeps-desk.png'}
  //         fill
  //         style={{
  //           objectFit: 'cover',
  //           objectPosition: 'center',
  //         }}
  //         alt="Loop Referral Program"
  //       />
  //       </div>
  //       <div className="w-full h-full md:hidden">
  //       <Image
  //         src={'/images/PromoCard7_ForKeeps-mob.png'}
  //         fill
  //         style={{
  //           objectFit: 'cover',
  //           objectPosition: 'center',
  //         }}
  //         alt="Loop Referral Program"
  //       />
  //       </div>
  //     </div>
  //   ),
  // },
  {
    ctaLabel: 'Explore Items',
    ctaUrl: '/products/?categories=115&sortByParams=topProducts',
    bgColor: 'bg-queen-2',
    body: (
      <div id="SS" className="absolute inset-0">
        <div id="SS-image-container" className="w-full h-full md:block">
        <Image id="SS-image"
          src={'/images/summer-special-desktop.png'}
          fill
          style={{
            objectFit: 'cover',
            objectPosition: 'center',
          }}
          alt="Loop Referral Program"
        />
        </div>
        <div className="w-full h-full md:hidden">
        <Image
          src={'/images/summer-special-mobile.png'}
          fill
          style={{
            objectFit: 'cover',
            objectPosition: 'center',
          }}
          alt="Loop Referral Program"
        />
        </div>
      </div>
    ),
  },
  // {
  //   ctaLabel: 'Join Now',
  //   ctaUrl: '/membership',
  //   bgColor: 'bg-mango',
  //   body: (
  //     <div id="SS" className="absolute inset-0">
  //       <div id="SS-image-container" className="w-full h-full md:block">
  //       <Image id="SS-image"
  //         src={'/images/new-member-promo-d.png'}
  //         fill
  //         style={{
  //           objectFit: 'cover',
  //           objectPosition: 'center',
  //         }}
  //         alt="Loop Referral Program"
  //       />
  //       </div>
  //       <div className="w-full h-full md:hidden">
  //       <Image
  //         src={'/images/new-member-promo-m.png'}
  //         fill
  //         style={{
  //           objectFit: 'cover',
  //           objectPosition: 'center',
  //         }}
  //         alt="Loop Referral Program"
  //       />
  //       </div>
  //     </div>
  //   ),
  // },
  {
    ctaLabel: 'Shop Now',
    ctaUrl: 'https://loop-warehouse.mybigcommerce.com/',
    bgColor: 'bg-giving-tree',
    body: (
      <div className="absolute inset-0">
        <div className="w-full h-full md:block">
        <Image
          src={'/images/promo-card-FK-d.png'}
          fill
          style={{
            objectFit: 'cover',
            objectPosition: 'center',
          }}
          alt="Loop Referral Program"
        />
        </div>
        <div className="w-full h-full md:hidden">
        <Image
          src={'/images/promo-card-FK-m.png'}
          fill
          style={{
            objectFit: 'cover',
            objectPosition: 'center',
          }}
          alt="Loop Referral Program"
        />
        </div>
      </div>
    ),
  },
  {
    ctaLabel: 'Shop Now',
    ctaUrl: '/gifting',
    bgColor: 'bg-butter',
    body: (
      <div className="absolute inset-0">
        <div className="w-full h-full md:block">
          <Image
            src={'/images/promo-card-gift.png'}
            fill
            style={{
              objectFit: 'cover',
              objectPosition: 'center',
            }}
            alt="Loop Referral Program"
          />
        </div>
        <div className="w-full h-full md:hidden">
          <Image
            src={'/images/promo-card-gift-m.png'}
            fill
            style={{
              objectFit: 'cover',
              objectPosition: 'center',
            }}
            alt="Loop Referral Program"
          />
        </div>
      </div>
    ),
  },
  {
    ctaLabel: 'Learn More',
    ctaUrl: 'https://www.loop.baby/blog/post/loop_membe_perk_save_10_on_travel/',
    bgColor: 'bg-fairy-tale',
    body: (
      <div className="absolute inset-0">
        <div className="w-full h-full md:block">
        <Image
          src={'/images/promo-tile-BQ.png'}
          fill
          style={{
            objectFit: 'cover',
            objectPosition: 'center',
          }}
          alt="Loop Referral Program"
        />
        </div>
        <div className="w-full h-full md:hidden">
        <Image
          src={'/images/promo-tile-BQ-mobile.png'}
          fill
          style={{
            objectFit: 'cover',
            objectPosition: 'center',
          }}
          alt="Loop Referral Program"
        />
        </div>
      </div>
    ),
  },
  {
    ctaLabel: 'Learn More',
    ctaUrl: '/blog/post/introducing-new-loop-referral-program/',
    bgColor: 'bg-queen-2',
    body: (
      <div className="absolute inset-0">
        <div className="w-full h-full md:block">
        <Image
          src={'/images/promo-card-referral-d.png'}
          fill
          style={{
            objectFit: 'cover',
            objectPosition: 'center',
          }}
          alt="Loop Referral Program"
        />
        </div>
        <div className="w-full h-full md:hidden">
        <Image
          src={'/images/promo-card-loop-referral-mob.png'}
          fill
          style={{
            objectFit: 'cover',
            objectPosition: 'center',
          }}
          alt="Loop Referral Program"
        />
        </div>
      </div>
    ),
  },
  {
    ctaLabel: 'Learn More',
    ctaUrl: '/stroller-showcase',
    bgColor: 'bg-queen-2',
    body: (
      <div id="SS" className="absolute inset-0">
        <div id="SS-image-container" className="w-full h-full md:block">
        <Image id="SS-image"
          src={'/images/promo-card-strollshow-d.png'}
          fill
          style={{
            objectFit: 'cover',
            objectPosition: 'center',
          }}
          alt="Loop Referral Program"
        />
        </div>
        <div className="w-full h-full md:hidden">
        <Image
          src={'/images/promo-card-strollshow-m.png'}
          fill
          style={{
            objectFit: 'cover',
            objectPosition: 'center',
          }}
          alt="Loop Referral Program"
        />
        </div>
      </div>
    ),
  },
  {
    ctaLabel: 'Schedule Here',
    ctaUrl: 'https://loop-warehouse.mybigcommerce.com/snoo-showcase/',
    bgColor: 'bg-butter',
    body: (
      <div id="SS" className="absolute inset-0">
        <div id="SS-image-container" className="w-full h-full md:block">
        <Image id="SS-image"
          src={'/images/snoo-showcase-desktop.png'}
          fill
          style={{
            objectFit: 'cover',
            objectPosition: 'center',
          }}
          alt="Loop Referral Program"
        />
        </div>
        <div className="w-full h-full md:hidden">
        <Image
          src={'/images/snoo-showcase-mobile.png'}
          fill
          style={{
            objectFit: 'cover',
            objectPosition: 'center',
          }}
          alt="Loop Referral Program"
        />
        </div>
      </div>
    ),
  }
  // {
  //   ctaLabel: 'Shop Now',
  //   ctaUrl: '/gifting',
  //   bgColor: 'bg-butter',
  //   body: (
  //     <div id="SS" className="absolute inset-0">
  //       <div id="SS-image-container" className="w-full h-full md:block">
  //       <Image id="SS-image"
  //         src={'/images/mday-gift-desk.png'}
  //         fill
  //         style={{
  //           objectFit: 'cover',
  //           objectPosition: 'center',
  //         }}
  //         alt="Loop Referral Program"
  //       />
  //       </div>
  //       <div className="w-full h-full md:hidden">
  //       <Image
  //         src={'/images/mday-gift-mob.png'}
  //         fill
  //         style={{
  //           objectFit: 'cover',
  //           objectPosition: 'center',
  //         }}
  //         alt="Loop Referral Program"
  //       />
  //       </div>
  //     </div>
  //   ),
  // },
  // {
  //   ctaLabel: 'Learn More',
  //   ctaUrl: '/gifting',
  //   bgColor: 'bg-giving-tree',
  //   body: (
  //     <div className="absolute inset-0">
  //       <div className="w-full h-full md:block">
  //         <Image
  //           src={'/images/promo-card-customer-photos.png'}
  //           fill
  //           style={{
  //             objectFit: 'cover',
  //             objectPosition: 'center',
  //           }}
  //           alt="Loop Referral Program"
  //         />
  //       </div>
  //       <div className="w-full h-full md:hidden">
  //         <Image
  //           src={'/images/promo-card-customer-photos-mobile.png'}
  //           fill
  //           style={{
  //             objectFit: 'cover',
  //             objectPosition: 'center',
  //           }}
  //           alt="Loop Referral Program"
  //         />
  //       </div>
  //     </div>
  //   ),
  // },
    // {
  //   ctaLabel: 'Learn More',
  //   ctaUrl: '/products/snoo--4-month-rental-special/413/?variant=482',
  //   bgColor: 'bg-giving-tree',
  //   body: (
  //     <div className="absolute inset-0">
  //       <div className="w-full h-full md:block">
  //       <Image
  //         src={'/images/promo-card-SNOO.png'}
  //         fill
  //         style={{
  //           objectFit: 'cover',
  //           objectPosition: 'center',
  //         }}
  //         alt="Loop Referral Program"
  //       />
  //       </div>
  //       <div className="w-full h-full md:hidden">
  //       <Image
  //         src={'/images/promo-card-SNOO-mobile.png'}
  //         fill
  //         style={{
  //           objectFit: 'cover',
  //           objectPosition: 'center',
  //         }}
  //         alt="Loop Referral Program"
  //       />
  //       </div>
  //     </div>
      
  //   ),
  // },
]

function HomePageFeatureCarrousell() {
  const [fullWidth, setFullWidth] = useState(true)
  const router = useRouter()

  const handleCtaClick = (url: string) => {
    router.push(url)
  }

  useEffect(() => {
    const watchWidth = () => {
      if (window.innerWidth >= 1500) {
        setFullWidth(true)
      } else {
        setFullWidth(false)
      }
    }

    window.addEventListener('resize', watchWidth)

    return () => {
      window.removeEventListener('resize', watchWidth)
    }
  }, [])

  return (
    <section className="w-full bg-heavy-cream" >
      <div className="mx-auto py-6 md:pt-[72px] overflow-hidden relative">
        <div className="font-recoleta font-medium text-28px md:text-32px lg:text-42px leading-29 md:leading-[46px] lg:leading-[52px] text-center text-neutral-6">
        New Exclusive Member Perks and Deals
        </div>

        <div className="text-center text-16px md:text-18px font-circular my-3">New ways to enjoy Loop and participate in our growing community</div>

        <div className="mx-auto max-w-[1500px]">
          <div className="md:mt-5 pl-[20px] relative pb-5">
            {items ? (
              <Carousel
                swipeable={true}
                showDots={false}
                arrows={false}
                infinite={false}
                partialVisible={fullWidth ? false : true}
                autoPlay={false}
                renderButtonGroupOutside={true}
                responsive={{
                  xxsmobile: {
                    breakpoint: { max: 400, min: 0 },
                    items: 1,
                    partialVisibilityGutter: -50,
                  },
                  xsmobile: {
                    breakpoint: { max: 500, min: 400 },
                    items: 1,
                    partialVisibilityGutter: 75,
                  },
                  mobile: {
                    breakpoint: { max: 640, min: 500 },
                    items: 1,
                    partialVisibilityGutter: 100,
                  },
                  smtablet: {
                    breakpoint: { max: 740, min: 640 },
                    items: Slides_Per_Page,
                    partialVisibilityGutter: 0,
                  },
                  tablet: {
                    breakpoint: { max: 900, min: 740 },
                    items: Slides_Per_Page,
                    partialVisibilityGutter: -120,
                  },
                  smdesktop: {
                    breakpoint: { max: 1150, min: 900 },
                    items: Slides_Per_Page,
                    partialVisibilityGutter: -50,
                  },
                  mddesktop: {
                    breakpoint: { max: 1400, min: 1150 },
                    items: Slides_Per_Page,
                    partialVisibilityGutter: 100,
                  },
                  desktop: {
                    breakpoint: { max: 9000, min: 1400 },
                    items: 3,
                    partialVisibilityGutter: 0,
                  },
                }}
                //@ts-ignore
                customButtonGroup={<ButtonGroup />}
              >
                {items.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className={`w-[300px] md:w-[450px] h-[340px] md:h-[413px] ${item.bgColor} relative`}
                    >
                      {item.body}
                      <div className="flex w-full bottom-2  md:bottom-0 absolute justify-center pb-[2px] md:pb-3">
                        <Button
                          onClick={() => handleCtaClick(item.ctaUrl)}
                          label={item.ctaLabel}
                          primary
                          // size="small"
                          className="block md:hidden"
                        />
                        <Button
                          onClick={() => handleCtaClick(item.ctaUrl)}
                          label={item.ctaLabel}
                          primary
                          className="hidden md:block"
                        />
                      </div>
                    </div>
                  )
                })}
              </Carousel>
            ) : (
              <div></div>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export { HomePageFeatureCarrousell }
