import { GetStaticProps, NextPage } from 'next'
import { useState } from 'react'
import {
  CheckoutGuestData,
  CheckoutGuestPayment,
  PageContainer,
  Pgraph,
} from '../../../components'
import { ICheckoutGuestData } from '../../../interfaces'
import { MainPageNavigationProps } from '../../../interfaces/navigation.interfaces'
import { WebConfService } from '../../../services'

export enum GUEST_STEP {
  CUSTOMER_DATA = 1,
  PAYMENT = 2,
  COMPLETED = 3,
}

export const getStaticProps: GetStaticProps<
  MainPageNavigationProps
> = async () => {
  const navigationJson = await WebConfService.getNavigationStructure()
  if (!navigationJson) throw new Error('Error fetching Navigation Structure')

  return {
    props: {
      navigation: navigationJson,
    },
    revalidate: 300,
  }
}

const GuestChecout: NextPage<MainPageNavigationProps> = ({ navigation }) => {
  const [guestStep, setGuestStep] = useState(GUEST_STEP.CUSTOMER_DATA)
  const [guestData, setGuestData] = useState<ICheckoutGuestData>()

  return (
    <PageContainer
      navigation={navigation}
      seoAttributes={{
        title: 'Checkout',
      }}
    >
      <div className="flex relative self-center h-full bg-snow-white w-full max-w-2xl mt-4">
        <div className="flex relative flex-col w-full">
          {guestStep < 3 && (
            <div className="flex relative flex-col items-center my-4">
              <Pgraph variant="p-12">Step {guestStep} of 3</Pgraph>
            </div>
          )}
          {guestStep === GUEST_STEP.CUSTOMER_DATA && (
            <CheckoutGuestData
              setCheckoutStep={setGuestStep}
              setGuestData={setGuestData}
            />
          )}
          {guestStep === GUEST_STEP.PAYMENT && (
            <CheckoutGuestPayment
              setCheckoutStep={setGuestStep}
              userData={guestData}
            />
          )}
        </div>
      </div>
    </PageContainer>
  )
}

export default GuestChecout
