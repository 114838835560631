enum CONTACT_TYPE {
  CONTACT_US = 'CONTACT_US',
  CANCEL_MEMBERSHIP = 'CANCEL_MEMBERSHIP',
  RETURN_ITEMS = 'RETURN_ITEMS',
}

const CONTACT_US_REASONS = [
  {
    value: 'Delivery/Pickup Question',
    label: 'I have a delivery or pickup question',
  },
  {
    value: 'Sell Used Products',
    label: "I'd like to sell used baby gear",
  },
  { value: 'Item Question', label: 'I have an item question' },
  {
    value: 'Billing/Membership Question',
    label: 'I have a billing or membership question',
  },
  {
    value: 'Registry Question',
    label: 'I have a registry question',
  },
  {
    value: 'Partnering with Loop',
    label: "I'm interested in partnering with Loop",
  },
  {
    value: 'Product Request',
    label: "I have a product request",
  },
  { value: 'Other', label: 'Other' },
]

export { CONTACT_TYPE, CONTACT_US_REASONS }
