import React from 'react'
import { Headline } from '..'

function HLWIntro() {
  return (
    <section className="w-full">
      <div className="max-w-5xl mx-auto py-4 px-4">
        <div className="border-b border-neutral-3 flex flex-col md:flex-row md:items-center md:pb-4">
          <div className=" pt-4 pb-5 px-5 text-center md:w-3/5">
            <Headline
              variant="recoleta-s"
              className="text-center text-poppy mb-[12px] md:mb-5 bg-top-items bg-no-repeat bg-right-top bg-[length:20px_24px] inline-block pr-3 pt-1 md:text-28px lg:text-32px lg:bg-[length:30px_34px] lg:pr-4 lg:text-left lg:pt-0"
            >
              How Loop Works
            </Headline>
          </div>
        </div>
      </div>
    </section>
  )
}

export { HLWIntro }
