import React from 'react'
import Image from 'next/image'
import { Pgraph } from '../Pgrah'
import { Headline } from '../Headline'

function DeliveryMolecule() {
  return (
    <section className="w-full">
      <div className="md:flex border-b-[1px] border-b-neutral-3 md:pb-[64px]">
        <div className="m-auto md:w-1/2 md:hidden">
          <img src="/images/stock/delivery.jpg" alt="Product Image"></img>
        </div>
        <div className="pt-5 pb-[66px] pr-7 md:w-1/2 m-auto p-4">
          <div>
            <Pgraph
              variant="p-14"
              className="mb-3 text-14px leading-17 md:text-20px md:leading-24 text-neutral-7"
            >
              Delivery
            </Pgraph>
          </div>
          <div>
            <Headline
              variant="recoleta-s"
              className="mb-3 text-24px leading-29 md:text-32px md:leading-38 font-normal text-poppy"
            >
              Reserve on your time
            </Headline>
          </div>
          <div>
            <Pgraph
              variant="p-14"
              className="text-14px leading-18 md:text-16px md:leading-22 font-normal text-neutral-5"
            >
              Once you join Loop and check out your items, we’ll email you
              within one business day to arrange delivery. If you’re currently
              expecting, we recommend scheduling at least 2 weeks prior to your
              due date.
            </Pgraph>
          </div>
        </div>
        <div className="m-auto md:w-1/2 hidden md:block">
          <img
            src="/images/stock/delivery.jpg"
            alt="Delivery"
            width="100%"
            height="100%"
          ></img>
        </div>
      </div>
    </section>
  )
}

export { DeliveryMolecule }
