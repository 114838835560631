import React, { useEffect, useState } from 'react'
import { Headline } from '../Headline'
import { Button } from '../Button'
import { Pgraph } from '../Pgrah'
import { LinkTo } from '../LinkTo'

interface IRegistryAddedProps {
  onClose: () => void
  onContinueBabyList: () => void
  onViewMyRegistry: () => void
  isLoading?: boolean
  isMembership: boolean
}

function RegistryEntryAddedConfirmation({
  onClose,
  onContinueBabyList,
  onViewMyRegistry,
  isLoading = false,
  isMembership = false,
}: IRegistryAddedProps) {
  useEffect(() => {
    const handleEsc = (event: globalThis.KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose()
      }
    }
    window.addEventListener('keydown', handleEsc)

    return () => {
      window.removeEventListener('keydown', handleEsc)
    }
  }, [onClose])
  return (
    <div>
      <div className="bg-modal-overlay fixed flex items-center top-0 left-0 z-100 w-full h-full md:flex justify-center z-[100]">
        <div className="flex items-center relative px-[27px] py-6 bg-snow-white md:p-5 md:rounded w-full h-full md:w-1/2 lg:w-1/3 md:h-fit m-[0px] md:m-auto lg:p-6 max-w-full md:max-w-2xl ">
          <div className="max-w-sm mx-auto">
            <div className="cursor-pointer" onClick={onClose}>
              <i className="absolute right-0 top-0 m-4 loop-icon-times text-24px text-neutral-7"></i>
            </div>
            <Headline
              variant="recoleta-m"
              className="mb-4 text-center text-neutral-7"
            >
              Your item has been added to your registry
            </Headline>
            <div>
              <div className="text-center mb-4">
                {isMembership ? (
                  <Button
                    label="Add membership to babylist"
                    primary={true}
                    onClick={onContinueBabyList}
                    disabled={isLoading}
                    loading={isLoading}
                  ></Button>
                ) : (
                  <Button
                    label="View my registry"
                    primary={true}
                    onClick={onViewMyRegistry}
                    disabled={isLoading}
                    loading={isLoading}
                  ></Button>
                )}
              </div>

              {!isMembership && (
                <div className="text-center">
                  <Pgraph variant="p-14" className="inline-block">
                    Or{' '}
                    <Pgraph
                      variant="p-14"
                      onClick={onContinueBabyList}
                      as="span"
                      className="hover:cursor-pointer underline xs:font-circular-medium"
                    >
                      add this item to my Babylist registry
                    </Pgraph>
                  </Pgraph>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { RegistryEntryAddedConfirmation }
