import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { CART_ACTION } from '../../interfaces'
import { CartApi } from '../../services'
import { useAuthStore, useCartDispatch } from '../../store'
import { Button } from '../Button'
import { InputText } from '../InputText'
import { Pgraph } from '../Pgrah'

interface ICouponForm {
  couponCode: string
}

function CouponForm() {
  const [couponMsg, setCouponMsg] = useState<string>()
  const [applyingCoupon, setApplyingCoupon] = useState(false)
  const [expanded, setExpanded] = useState(false)
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ICouponForm>()

  const { credentials, user } = useAuthStore()
  const dispatch = useCartDispatch()

  const applyCoupon = async ({ couponCode }: ICouponForm) => {
    try {
      if (!credentials?.accessToken) return
      if (couponCode === user?.customer.referralCode) {
        setCouponMsg('You cannot apply your own referral code.')
        return
      }

      setApplyingCoupon(true)
      setCouponMsg(undefined)
      const apiCart = await CartApi.addCoupon(
        credentials.accessToken,
        couponCode
      )
      if (apiCart) {
        if (typeof apiCart === 'string') {
          throw apiCart
        }
        setCouponMsg(`Coupon ${couponCode} applied successfully.`)
        dispatch({
          type: CART_ACTION.SET_API_CART,
          apiCart,
        })
      } else {
        throw new Error('Invalid coupon')
      }
    } catch (error) {
      setCouponMsg(typeof error === 'string' ? error : 'Error applying coupon')
    } finally {
      setApplyingCoupon(false)
    }
  }

  return (
    <>
      <div className="flex justify-between items-center mt-4 mb-5 w-full">
        <Pgraph variant="p-14">Promo code</Pgraph>
        <div>
          <i
            className={`${
              expanded ? 'loop-icon-minus' : 'loop-icon-plus'
            } text-24px cursor-pointer`}
            onClick={() => setExpanded(!expanded)}
          />
        </div>
      </div>
      {expanded && (
        <form onSubmit={handleSubmit(applyCoupon)} className="mb-6">
          <div className="flex flex-row justify-between">
            <InputText
              {...register('couponCode', {
                minLength: 1,
              })}
              type="text"
              placeHolder=""
              validationText={errors.couponCode?.message}
            />
            <Button
              label="Apply"
              primary={true}
              type="submit"
              loading={applyingCoupon}
              disabled={applyingCoupon}
              className="ml-4"
            />
          </div>
          {couponMsg && (
            <Pgraph variant="p-14" className="mt-4">
              {couponMsg}
            </Pgraph>
          )}
        </form>
      )}
    </>
  )
}

export { CouponForm }
