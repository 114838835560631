import React, { useEffect } from 'react'
import { Headline } from '../Headline'
import { Pgraph } from '../Pgrah'
import Image from 'next/image'
import { MembershipPerks, URLS } from '../../config'
import { useRouter } from 'next/router'
import NumberFormat from 'react-number-format'
import { Button } from '../Button'
import { IProductSummary } from '../../interfaces'

function RegistryMembershipInfo() {
  const router = useRouter()

  return (
    <section className="bg-white w-full">
      <div className="max-w-5xl mx-auto pb-4 px-4 md:flex pt-[40px] md:pt-[100px] relative">
        <div className="w-full md:hidden mb-[40px] md:mb-[0px]">
          <img
            src="/images/baby_playing.jpg"
            alt="Loop registry"
            className="w-full"
          />
        </div>
        <div className="w-full md:w-1/2 md:my-4 md:mx-5">
          <div className="hidden md:block absolute top-[0px] left-[30%]">
            <Image
              src="/images/handillustration.svg"
              alt="image"
              width="261"
              height="166"
            ></Image>
          </div>
          <Pgraph variant="p-14" className="mb-[20px] text-center md:text-left">
            JOIN TODAY
          </Pgraph>
          <Headline
            variant="recoleta-m"
            className="w-1/2 md:w-full text-poppy relative md:max-w-[390px] text-left m-auto mb-[16px] md:mb-[32px] md:ml-[0px] "
          >
            Loop Members get even more
            <div className="absolute -top-[10px] right-[0px]">
              <Image
                src="/images/flourish.svg"
                alt="image"
                width="28"
                height="28"
              ></Image>
            </div>
          </Headline>
          <ul className="mr-2 mb-[50px] pr-[40px]">
            {MembershipPerks.map((perk, idx) => (
              <li key={idx} className="text-neutral-6 flex mb-3 text-12px">
                <i className="loop-icon-checkmark text-24px mr-3 text-poppy relative -top-1 font-bold"></i>
                <div className="text-[14px] font-circular">{perk.perk}</div>
              </li>
            ))}
          </ul>
          <div className="text-center md:text-left">
            <Button
              size="medium"
              primary={true}
              className="px-[60px]"
              label="Learn more"
              onClick={() => router.push(URLS.MEMBERSHIP_LANDING)}
            />
          </div>
        </div>
        <div className="w-1/2 hidden md:block">
          <img src="/images/baby_playing.jpg" alt="Membership for Registry" />
        </div>
      </div>
    </section>
  )
}

export { RegistryMembershipInfo }
