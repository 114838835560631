import React, {
  useRef,
  KeyboardEvent,
  useEffect,
  useState,
  useMemo,
} from 'react'
import { useForm } from 'react-hook-form'
import { CART_ACTION, IProductSummary } from '../../interfaces'
import { ProductsApi } from '../../services'
import { Pgraph } from '../Pgrah'
import Image from 'next/image'
import { Button } from '../Button'
import { getProductSeoName } from '../../services/products_api'
import { useRouter } from 'next/router'
import {
  useAuthStore,
  useCartDispatch,
  useCartStore,
  useHooks,
} from '../../store'
import { trackEvent } from '../../config/analytics'
import { GIFT_CARD_IMG_PATH, URLS } from '../../config'

interface ISearchModalProps {
  open: boolean
  onClose: () => void
}

interface ISearchForm {
  searchTerm: string
}

function SearchModal({ onClose, open }: ISearchModalProps) {
  const {
    register,
    formState: { errors },
    trigger,
    getValues,
    setValue,
    setFocus,
  } = useForm<ISearchForm>()
  const {
    searchResults,
    basicFilters: filters,
    searchTopProducts,
  } = useCartStore()
  const dispatch = useCartDispatch()
  const router = useRouter()
  const { user } = useAuthStore()
  const searchTimeout = useRef<NodeJS.Timeout | undefined>(undefined)
  const topProductsFetch = useRef(false)
  const { reportBug } = useHooks()
  const { locationFilter } = useAuthStore()

  useEffect(() => {
    if (topProductsFetch.current || searchTopProducts || !filters) return

    topProductsFetch.current = true
    const getTopProductsData = async (catId: number) => {
      try {
        const response = await ProductsApi.fetchProducts({
          itemsPerPage: 10,
          filterOption: {
            categories: [catId],
          },
        })
        if (response?.success) {
          dispatch({
            type: CART_ACTION.SET_SEARCH_TOP_PRODUCTS,
            topProducts: response.products,
          })
        } else {
          topProductsFetch.current = false
        }
      } catch (error) {
        reportBug(error)
      } finally {
        topProductsFetch.current = false
      }
    }

    getTopProductsData(filters.topProductsCategoryId)
  }, [filters, topProductsFetch, reportBug, searchTopProducts, dispatch])

  useEffect(() => {
    if (open) {
      setFocus('searchTerm')
    }
  }, [open, setFocus])

  const onSubmit = async ({ searchTerm }: ISearchForm) => {
    if (!searchTimeout.current) {
      dispatch({
        type: CART_ACTION.SET_SEARCH_RESULTS,
      })
      searchTimeout.current = setTimeout(async () => {
        trackEvent(
          localStorage.getItem('mobileView')
            ? 'search-mobile-catalog-opened'
            : 'search-nav-opened',
          {
            loopCustomerId: user?.customer?.loopCustomerId,
            url: window?.location?.href,
            searchTerm: searchTerm,
          }
        )

        const place = locationFilter?.location

        try {
          const result = await ProductsApi.fetchProducts({
            filterOption: {
              searchTerm,
              place,
            },
          })
          if (result) {
            const searchResults = result.products
            if (
              'gifts'.includes(searchTerm) ||
              'cards'.includes(searchTerm) ||
              'coupons'.includes(searchTerm) ||
              'presents'.includes(searchTerm)
            ) {
              searchResults.unshift({
                bigCommerceProductId: 0,
                customFields: [],
                price: 0,
                retailPrice: 0,
                title: 'Loop gift card',
                options: [],
                variants: [],
                images: [
                  {
                    description: 'Loop gift card',
                    url: GIFT_CARD_IMG_PATH,
                  },
                ],
              })
            }
            dispatch({
              type: CART_ACTION.SET_SEARCH_RESULTS,
              searchResults,
            })
          }
        } catch (error) {
          console.error(error)
          reportBug(error, { searchTerm })
        }
      }, 200)
      return
    } else {
      window.clearTimeout(searchTimeout.current)
      searchTimeout.current = undefined
      onSubmit({ searchTerm })
    }
  }

  const handleChange = () => {
    trigger()
    const searchTerm = getValues('searchTerm')
    if (searchTerm.length > 2) {
      onSubmit({ searchTerm })
    } else {
    }
  }

  const goToProduct = (product: IProductSummary) => {
    if (product.title === 'Loop gift card') {
      router.push(URLS.GIFTS)
      clearSearch()
      onClose()
      return
    }
    const seoName = getProductSeoName(product)
    const url = `/products/${seoName}/${
      product.bigCommerceProductId
    }/?variant=${
      product.variants.length ? product.variants[0].bigCommerceVariantId : 0
    }`
    router.push(url)
    clearSearch()
    onClose()
  }

  const onShowAllMatches = () => {
    const searchTerm = getValues('searchTerm')
    router.push(`/products?searchTerm=${encodeURIComponent(searchTerm)}`)
    dispatch({
      type: CART_ACTION.SET_SEARCH_RESULTS,
      searchTerm,
      searchResults,
    })
    clearSearch()
    onClose()
  }

  const clearSearch = () => {
    setValue('searchTerm', '')
    dispatch({
      type: CART_ACTION.SET_SEARCH_RESULTS,
      searchResults: undefined,
      searchTerm: undefined,
    })
  }

  const checkEnterSubmit = (e: KeyboardEvent<HTMLInputElement>) => {
    if (errors.searchTerm) return
    if (e.code === 'Enter') {
      onShowAllMatches()
    }
  }

  const displayProducts = useMemo<IProductSummary[]>(() => {
    if (searchResults && searchResults.length > 0) {
      return searchResults.slice(0, 5)
    }
    if (
      searchResults &&
      searchResults.length < 1 &&
      searchTopProducts?.length
    ) {
      return searchTopProducts.slice(0, 5)
    }
    return []
  }, [searchResults, searchTopProducts])

  useEffect(() => {
    const handleEsc = (event: globalThis.KeyboardEvent) => {
      if (open && event.key === 'Escape') {
        clearSearch()
        onClose()
      }
    }
    window.addEventListener('keydown', handleEsc)

    return () => {
      window.removeEventListener('keydown', handleEsc)
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getVariantText = (product: IProductSummary) => {
    if (
      product.variants &&
      product.variants.length &&
      product.variants[0].options &&
      product.variants[0].options.length
    ) {
      return ` ${product.variants[0].options[0].value}`
    } else {
      return ''
    }
  }

  const getDisplayImage = (product: IProductSummary) => {
    if (
      product.variants &&
      product.variants.length &&
      product.variants[0].options &&
      product.variants[0].options.length
    ) {
      return product.variants[0].image
    } else {
      return product?.images?.length
        ? product?.images[0].url
        : 'https://cdn11.bigcommerce.com/s-i7w2w515cb/products/373/images/379/Product_available__05547.1651879285.386.513.jpg?c=1'
    }
  }

  const getDisplayDescription = (product: IProductSummary) => {
    if (
      product.variants &&
      product.variants.length &&
      product.variants[0].options &&
      product.variants[0].options.length
    ) {
      return product.variants[0].options[0].value
    } else {
      return product?.images?.length
        ? product?.images[0].description
        : 'Product Image'
    }
  }

  return open ? (
    <div className="bg-snow-white fixed top-0 left-0 z-10 w-full h-full flex justify-center">
      <div
        className="cursor-pointer"
        onClick={() => {
          clearSearch()
          onClose()
        }}
      >
        <i className=" absolute right-0 top-0 m-4 loop-icon-times text-24px text-neutral-7"></i>
      </div>
      <div className="flex flex-col mt-7 w-full items-center">
        <div className="w-full max-w-[500px]">
          <form onSubmit={(e) => e.preventDefault()}>
            <div className="w-full flex flex-row">
              <input
                className="border-b-2 border-b-queen-2 text-24px md:text-28px w-full ml-4 focus:outline-none placeholder:text-neutral-7 placeholder:text-24px font-circular"
                type="text"
                {...register('searchTerm', {
                  onChange: handleChange,
                  minLength: {
                    value: 3,
                    message: 'Please enter at least 3 characters.',
                  },
                })}
                onKeyUp={checkEnterSubmit}
                placeholder="Search for items or brands"
              ></input>
              <button
                type="button"
                className="mr-4 pt-2 p-2 border-b-queen-2 border-b-2"
              >
                <i className="loop-icon-search-bold text-28px md:text-32px"></i>
              </button>
            </div>
          </form>
        </div>

        {errors.searchTerm && (
          <Pgraph variant="p-16" className="text-caution-2 mt-2">
            {errors.searchTerm.message}
          </Pgraph>
        )}

        {searchResults && searchResults.length > 0 && (
          <div className="w-full max-w-[500px]">
            <Pgraph variant="l-14" className="self-start pl-4 mt-32px mb-3">
              Results
            </Pgraph>
          </div>
        )}

        {searchResults && !searchResults.length && (
          <div className="max-w-[500px] px-4 mt-5">
            <Pgraph variant="p-16">
              No results for{' '}
              <Pgraph variant="l-16" className="font-bolder" as="span">
                &ldquo;{getValues('searchTerm')}&rdquo;
              </Pgraph>
              . Check the spelling or use a more general word.
            </Pgraph>
            <Pgraph variant="l-16" className="mt-5 mb-3">
              Top items
            </Pgraph>
          </div>
        )}

        {displayProducts.map((p) => (
          <div
            key={p.bigCommerceProductId}
            className="w-full px-4 max-w-[500px] cursor-pointer"
            onClick={() => goToProduct(p)}
          >
            <div className="w-full flex flex-row border-b-neutral-2 border-b p-2 items-center">
              <Image
                src={`${getDisplayImage(p)}`}
                alt={`${getDisplayDescription(p)}`}
                width={60}
                height={60}
              />
              <Pgraph variant="p-14" className="ml-3">
                {p.title}
                {`${getVariantText(p)}`}
              </Pgraph>
            </div>
          </div>
        ))}

        {searchResults && searchResults.length > 5 && (
          <div className="w-full mx-4 max-w-[500px] mt-5 flex justify-center">
            <Button
              label="Show all matches"
              primary
              onClick={onShowAllMatches}
            />
          </div>
        )}
      </div>
    </div>
  ) : (
    <></>
  )
}

export { SearchModal }
