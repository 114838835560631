import React, { useEffect, useState } from 'react'
import { LinkTo, Pgraph } from '..'
import { AUTH_ACTION, IProductSummary } from '../../interfaces'
import { ProductsApi } from '../../services'
import { useCartStore } from '../../store'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import { RegistryProductCarouselCard } from '../RegistryProductCarouselCard/index'
import Link from 'next/link'
import { URLS } from '../../config'

interface IButtonGroupProps {
  next: () => void
  previous: () => void
  carouselState: {
    currentSlide: number
    totalItems: number
  }
}

function RegistryRecommendedItems() {
  const [recommendedProducts, setRecommendedProducts] = useState<
    IProductSummary[]
  >([])
  const { basicFilters: filters } = useCartStore()
  const [slidesPerPage, setSlidesPerPage] = useState<number>(1)

  const ButtonGroup = ({
    next,
    previous,
    carouselState,
  }: IButtonGroupProps) => {
    // use carouselState to disable button when in the last slide, for example.
    return (
      <div className="absolute -bottom-0 right-0 mr-3 hidden lg:block">
        <span onClick={() => previous()} className="mr-4 cursor-pointer">
          <i
            className={`loop-icon-arrow-long text-24px text-black transition ease-in duration-400 rotate-180 inline-block ${
              carouselState.currentSlide === 0
                ? 'cursor-not-allowed opacity-25'
                : ''
            }`}
          ></i>
        </span>
        <span onClick={() => next()} className="cursor-pointer">
          <i
            className={`loop-icon-arrow-long text-24px text-black transition ease-in duration-400 inline-block ${
              carouselState.totalItems - carouselState.currentSlide ===
              slidesPerPage
                ? 'cursor-not-allowed opacity-25'
                : ''
            }`}
          ></i>
        </span>
      </div>
    )
  }

  useEffect(() => {
    if (window.innerWidth > 0 && window.innerWidth <= 400) {
      setSlidesPerPage(1)
    } else if (window.innerWidth > 400 && window.innerWidth <= 500) {
      setSlidesPerPage(1)
    } else if (window.innerWidth > 500 && window.innerWidth <= 640) {
      setSlidesPerPage(1)
    } else if (window.innerWidth > 640 && window.innerWidth <= 740) {
      setSlidesPerPage(2)
    } else if (window.innerWidth > 740 && window.innerWidth <= 1200) {
      setSlidesPerPage(3)
    } else {
      setSlidesPerPage(4)
    }
    window.addEventListener('resize', function () {
      if (window.innerWidth > 0 && window.innerWidth <= 400) {
        setSlidesPerPage(1)
      } else if (window.innerWidth > 400 && window.innerWidth <= 500) {
        setSlidesPerPage(1)
      } else if (window.innerWidth > 500 && window.innerWidth <= 640) {
        setSlidesPerPage(1)
      } else if (window.innerWidth > 640 && window.innerWidth <= 740) {
        setSlidesPerPage(2)
      } else if (window.innerWidth > 740 && window.innerWidth <= 1200) {
        setSlidesPerPage(3)
      } else {
        setSlidesPerPage(4)
      }
    })
  }, [])

  useEffect(() => {
    if (!filters) return

    const getTopProductsData = async (catId: number) => {
      try {
        const response = await ProductsApi.fetchProducts({
          pageParam: 1,
          itemsPerPage: 10,
          filterOption: {
            ages: [catId],
          },
        })

        if (response?.success) {
          setRecommendedProducts(response.products)
        }
      } catch (error) {}
    }

    getTopProductsData(filters.ages[0].id)
  }, [filters])

  return (
    <section className="bg-heavy-cream w-full p-4">
      <div className="max-w-5xl mx-auto mt-1 md:mt-2 lg:mt-3">
        <div className="md:flex">
          <Pgraph variant="p-16" className="md:hidden text-neutral-6">
            Our baby gear experts can help you create the perfect registry,
            schedule a{' '}
            <Link className="font-circular underline" href={URLS.CONTACT_US}>
              free consultation here
            </Link>
          </Pgraph>
          <Pgraph variant="p-20" className="hidden md:block text-neutral-6">
            Our baby gear experts can help you create the perfect registry,
            schedule a{' '}
            <Link className="font-circular underline" href={URLS.CONTACT_US}>
              free consultation here
            </Link>
          </Pgraph>
        </div>
      </div>

      <div>
        <div className="max-w-5xl mx-auto">
          <div className="mt-3 md:mt-5 relative pb-5">
            {recommendedProducts ? (
              <Carousel
                swipeable={true}
                showDots={false}
                arrows={false}
                infinite={false}
                partialVisible={true}
                autoPlay={false}
                autoPlaySpeed={5000}
                renderButtonGroupOutside={true}
                responsive={{
                  xxsmobile: {
                    breakpoint: { max: 400, min: 0 },
                    items: 1,
                    partialVisibilityGutter: 110,
                  },
                  xsmobile: {
                    breakpoint: { max: 500, min: 400 },
                    items: 1,
                    partialVisibilityGutter: 50,
                  },
                  mobile: {
                    breakpoint: { max: 640, min: 500 },
                    items: 1,
                    partialVisibilityGutter: 100,
                  },
                  smtablet: {
                    breakpoint: { max: 740, min: 640 },
                    items: 2,
                    partialVisibilityGutter: 0,
                  },
                  tablet: {
                    breakpoint: { max: 1024, min: 740 },
                    items: 3,
                    partialVisibilityGutter: 50,
                  },
                  desktop: {
                    breakpoint: { max: 1200, min: 1024 },
                    items: 3,
                    partialVisibilityGutter: 60,
                  },
                  desktopxl: {
                    breakpoint: { max: 9000, min: 1200 },
                    items: 4,
                    partialVisibilityGutter: 20,
                  },
                }}
                //@ts-ignore
                customButtonGroup={<ButtonGroup />}
              >
                {recommendedProducts.map((product, index) => {
                  return (
                    <RegistryProductCarouselCard
                      key={index}
                      product={product}
                    />
                  )
                })}
              </Carousel>
            ) : (
              <div></div>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export { RegistryRecommendedItems }
