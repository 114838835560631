import { Button, InputTextArea, Pgraph } from '../../components'
import { useForm } from 'react-hook-form'
import React, { Fragment, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { useAuthDispatch, useAuthStore, useHooks } from '../../store'
import { IContactUsForm } from '../../interfaces/forms.interface'
import { IContactUsRequest } from '../../services/auth_api'
import { CONTACT_TYPE } from '../../config/contact-us.constants'
import { AuthApi } from '../../services'
import { sanitizeFields } from '../../config/utilities'

interface IContactProps {
  callback: any
}

export const Contact = ({ callback, ...props }: IContactProps) => {
  const [processing, setProcessing] = useState(false)
  const [success, setSuccess] = useState<string>()
  const [error, setError] = useState<string>()
  const { reportBug } = useHooks()

  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<IContactUsForm>({
    defaultValues: {
      details: '',
    },
  })

  const { user } = useAuthStore()
  const onContact = async (data: IContactUsForm) => {
    try {
      setProcessing(true)
      setError(undefined)
      setSuccess(undefined)
      // wrap all forms with the following function to fix values such as whitespaces
      sanitizeFields(data)
      const prepareBody = (data: IContactUsForm): string => {
        const body: string = `
                    Name: ${user?.customer?.firstName} ${user?.customer?.lastName}
                    Email: ${user?.account?.emailAddress}
                    Phone: ${user?.customer?.cellPhone}
                    State: ${user?.customer?.shippingAddress?.state}
                    
                    Message: ${data.details}
                    `
        return body
      }

      const formattedData: IContactUsRequest = {
        isBodyHTML: false,
        body: prepareBody(data),
        type: CONTACT_TYPE.CONTACT_US,
        sender: {
          email: user?.account?.emailAddress || '',
          firstName: user?.customer?.firstName || '',
          lastName: user?.customer?.lastName || '',
          phone: user?.customer?.cellPhone || '',
          state: user?.customer?.shippingAddress?.state || '',
        },
      }
      const result = await AuthApi.contactUs(formattedData)
      if (result) {
        setProcessing(false)
        callback(true)
      } else {
        throw new Error('Unable to contact us')
        callback(false)
      }
    } catch (error) {
      console.error(error)
      callback(false)
      reportBug(error, data)
      setError(error instanceof Error ? error.message : 'Unknown error')
    } finally {
      setProcessing(false)
    }
  }

  useEffect(() => {}, [user])

  return (
    <form onSubmit={handleSubmit(onContact)}>
      <div>
        <Pgraph
          variant="p-16"
          className="text-14px leading-17 font-medium text-neutral-6 mb-[6px] mt-[24px]"
        >
          Send a message to support
        </Pgraph>
        <InputTextArea
          additionalClassName={'text-neutral-5'}
          borderColor={'border-neutral-3'}
          placeHolder="How can we help you ?"
          {...register('details')}
        />
        <Button
          label="Send"
          primary
          className={'py-[5px] px-[75px] mt-[24px]'}
          type="submit"
          size="medium"
          loading={processing}
          disabled={processing}
        />
      </div>
    </form>
  )
}
