import React, { useEffect, useState } from 'react'
import { CART_ACTION, IProductSummary, IUser } from '../../interfaces'
import { Pgraph } from '../Pgrah'
import Image from 'next/image'
import NumberFormat from 'react-number-format'
import {
  LOCAL_STORAGE,
  PRODUCT_CONFIG,
  PRODUCT_CUSTOM_FIELDS,
  URLS,
} from '../../config'
import { useRouter } from 'next/router'
import { getProductSeoName } from '../../services/products_api'
import { useAuthStore } from '../../store'
import { trackEvent } from '../../config/analytics'
import { setLocalObject } from '../../store/local'
import Link from 'next/link'

interface IProductCardProps {
  /**
   * Product Cart Type
   */
  product: IProductSummary

  /**
   * Product Cart Type
   */
  type?: 'default' | 'large'

  /**
   * Optional click handler
   */
  onClick?: () => void

  /** Optional Additional classes for styling */
  className?: string

  /** Scroll position Y */
  scrollYPos?: number
}

/**
 * Primary UI component for user interaction
 */
export const ProductCard = ({
  product,
  className,
  scrollYPos,
  ...props
}: IProductCardProps) => {
  const router = useRouter()
  const [selectedEllipseOption, setEllipseOption] = useState<string>('')
  const { user } = useAuthStore()

  useEffect(() => {
    setEllipseOption(
      window.innerWidth > 640 ? 'ellipse-box-2' : 'ellipse-box-3'
    )
    window.addEventListener('resize', function () {
      setEllipseOption(
        window.innerWidth > 640 ? 'ellipse-box-2' : 'ellipse-box-3'
      )
    })
  }, [])

  const cardClasses =
    'flex flex-col justify-between border-neutral-2 border rounded p-2 lg:p-4'

  const customPriceLabel = product?.customFields.find(
    (f) => f.name === PRODUCT_CUSTOM_FIELDS.CUSTOM_PRICE_LABEL
  )?.value

  const getVariantText = () => {
    if (
      product.variants &&
      product.variants.length &&
      product.variants[0].options &&
      product.variants[0].options.length
    ) {
      return ` ${product.variants[0].options[0].value}`
    } else {
      return ''
    }
  }

  const getDisplayImage = () => {
    if (
      product.variants &&
      product.variants.length &&
      product.variants[0].options &&
      product.variants[0].options.length
    ) {
      return product.variants[0].image
    } else {
      return product?.images?.length
        ? product?.images[0].url
        : 'https://cdn11.bigcommerce.com/s-i7w2w515cb/products/373/images/379/Product_available__05547.1651879285.386.513.jpg?c=1'
    }
  }

  const getDisplayDescription = () => {
    if (
      product.variants &&
      product.variants.length &&
      product.variants[0].options &&
      product.variants[0].options.length
    ) {
      return product.variants[0].options[0].value
    } else {
      return product?.images?.length
        ? product?.images[0].description
        : 'Product Image'
    }
  }

  useEffect(() => {
    window.onbeforeunload = () => {
      setLocalObject(LOCAL_STORAGE.SCROLLYPOS, scrollYPos)
    }
  }, [scrollYPos])

  return (
    <Link
      href={`${URLS.PRODUCTS.INDEX}/${getProductSeoName(product).replace(
        /\+/g,
        ''
      )}/${product.bigCommerceProductId}?variant=${
        product.variants.length ? product.variants[0].bigCommerceVariantId : 0
      }`}
      passHref
      className={[cardClasses, className].join(' ')}
      id={product.bigCommerceProductId.toString()}
    >
      <div>
        <img
          src={`${getDisplayImage()}`}
          alt={`${getDisplayDescription()}`}
          className="cursor-pointer w-auto max-h-[210px] mx-auto"
        ></img>
      </div>

      <div>
        <h3
          className={`text-14px font-circular color-neutral-6 mb-[4px] overflow-hidden text-ellipsis sm:h-[60px] md:h-[40px] ${selectedEllipseOption}`}
        >
          {product.title}
          {`${getVariantText()}`}
        </h3>
        <Pgraph variant="l-14" className="color-neutral-7">
          {!!customPriceLabel ? (
            customPriceLabel
          ) : (
            <NumberFormat
              value={product?.price}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
              decimalScale={0}
              suffix={' / ' + PRODUCT_CONFIG.rentingPeriod}
            />
          )}
        </Pgraph>
      </div>
    </Link>
  )
}
