import React from 'react'

interface ILoadingProps {
  /**
   * Background color in tailwind style (e.g. "bg-poppy")
   */
  bgColor?: string
}

function Loading({ bgColor = 'bg-poppy' }: ILoadingProps) {
  return (
    <div className="flex flex-col w-full h-full justify-center items-center">
      <div className="flex flex-row">
        <div
          className={`w-2 h-2 rounded-full ${bgColor} m-1 animate-pulse`}
          style={{ animationDelay: '0.0s' }}
        ></div>
        <div
          className={`w-2 h-2 rounded-full ${bgColor} m-1 animate-pulse`}
          style={{ animationDelay: '0.2s' }}
        ></div>
        <div
          className={`w-2 h-2 rounded-full ${bgColor} m-1 animate-pulse`}
          style={{ animationDelay: '0.4s' }}
        ></div>
        <div
          className={`w-2 h-2 rounded-full ${bgColor} m-1 animate-pulse`}
          style={{ animationDelay: '0.6s' }}
        ></div>
        <div
          className={`w-2 h-2 rounded-full ${bgColor} m-1 animate-pulse`}
          style={{ animationDelay: '0.8s' }}
        ></div>
      </div>
    </div>
  )
}

export { Loading }
