import { IFooterSectionProps } from '../components/Footer/footerSection'
import { URLS } from './urls.constants'

export const chatWithSpecialist = () => {
  if (!window.Gladly.show) return
  window.Gladly.show()
}

const shopSection: IFooterSectionProps = {
  title: 'Rent',
  links: [
    {
      title: 'How Loop Works',
      action: URLS.HLW,
    },
    {
      title: 'Membership',
      action: URLS.MEMBERSHIP,
    },
    {
      title: 'Browse Items',
      action: URLS.PRODUCTS.INDEX,
    },
    {
      title: 'Registry',
      action: URLS.REGISTRY_LANDING,
    },
    {
      title: 'Gift Loop',
      action: URLS.GIFTS,
    },
  ],
}

const helpSection: IFooterSectionProps = {
  title: 'Help',
  links: [
    {
      title: 'FAQ',
      action: URLS.FAQ,
    },
    {
      title: 'Contact',
      action: URLS.CONTACT_US,
    },
    {
      title: 'Terms of Service',
      action: URLS.TERMS_OF_SERVICE,
    },
    {
      title: 'Privacy Policy',
      action: URLS.PRIVACY_POLICY,
    },
    {
      title: 'Rental Agreement',
      action: URLS.RENTAL_AGREEMENT,
    },
  ],
}

const companySection: IFooterSectionProps = {
  title: 'Company',
  links: [
    {
      title: 'About Us  ',
      action: URLS.ABOUT_US,
    },
    {
      title: 'Careers',
      action: URLS.CAREERS,
    },
    {
      title: 'Blog',
      action: URLS.BLOG.INDEX,
    },
  ],
}

const socialSection: IFooterSectionProps = {
  title: 'Social',
  expandable: false,
  children: (
    <div className="flex flex-row">
      <div className="my-[12px] mr-[12px]">
        <a
          target="_blank"
          href="https://www.facebook.com/loopbabygear/"
          rel="noreferrer"
        >
          <img
            src="/images/facebook.svg"
            alt="Loop Facebook"
            width="100%"
            height="100%"
          ></img>
        </a>
      </div>
      <div className="my-[12px] ml-[12px]">
        <a
          target="_blank"
          href="https://www.instagram.com/loop.rentals/"
          rel="noreferrer"
        >
          <img
            src="/images/instagram.svg"
            alt="Loop Instagram"
            width="100%"
            height="100%"
          ></img>
        </a>
      </div>
    </div>
  ),
}

const needAHandSection: IFooterSectionProps = {
  title: 'Need a hand?',
  expandable: false,
  children: (
    <div
      className="flex flex-row my-[12px] cursor-pointer items-center"
      onClick={chatWithSpecialist}
    >
      <img
        className="mr-2"
        src="/images/chat.svg"
        alt="Need a hand?"
        width={36}
        height={36}
      ></img>

      <div
        className="flex font-circular text-[14px] leading-18 break-words max-w-[170px]"
        onClick={chatWithSpecialist}
      >
        We&apos;re here to help - chat now
      </div>
    </div>
  ),
}

export {
  shopSection,
  helpSection,
  companySection,
  socialSection,
  needAHandSection,
}
