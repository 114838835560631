import { useRouter } from 'next/router'
import { trackEvent } from '../../config/analytics'
import { CART_ACTION } from '../../interfaces'
import { useCartDispatch, useCartStore } from '../../store'
import { useCallback, useEffect, useState } from 'react'

export const SearchBarMobile = () => {
  const [yOffset, setYOffset] = useState(0)
  const dispatch = useCartDispatch()
  const router = useRouter()
  const { isSearchVisible } = useCartStore()

  const handleClick = () => {
    trackEvent('opened_search', {
      device: 'mobile',
      page: router.pathname,
    })

    dispatch({
      type: CART_ACTION.SET_SHOW_SEARCH_MODAL,
      showSearchModal: true,
    })
  }

  useEffect(() => {
    if (window.innerWidth > 1280) {
      localStorage.removeItem('mobileView')
    } else {
      localStorage.setItem('mobileView', 'true')
    }
    window.addEventListener('resize', function () {
      if (window.innerWidth > 1280) {
        localStorage.removeItem('mobileView')
      } else {
        localStorage.setItem('mobileView', 'true')
      }
    })
  }, [])

  const handleScroll = useCallback(
    (e: Event) => {
      const window = e.currentTarget as Window
      if (yOffset > window?.scrollY || window.scrollY < 85) {
        // scrolling up
        dispatch({
          type: CART_ACTION.SET_SEARCH_VISIBLE,
          isSearchVisible: true,
        })
      } else {
        // scrolling down
        dispatch({
          type: CART_ACTION.SET_SEARCH_VISIBLE,
          isSearchVisible: false,
        })
      }
      setYOffset(window.scrollY)
    },
    [dispatch, yOffset]
  )

  useEffect(() => {
    setYOffset(window.scrollY)
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll])

  return (
    <div
      className={`md:hidden bg-heavy-cream px-4 py-3 w-full z-10 sticky top-[52px] duration-300 ${
        isSearchVisible ? 'translate-y-0' : '-translate-y-full'
      }`}
    >
      <div className="bg-heavy-cream w-full max-w-md mx-auto">
        <div
          className="w-full flex flex-row border bg-snow-white border-neutral-4 rounded-2xl overflow-hidden"
          onClick={handleClick}
        >
          <button className="pt-[12px] p-2" onClick={handleClick}>
            <i className="loop-icon-search text-24px text-neutral-7 ml-2"></i>
          </button>
          <input
            className="text-13px w-full focus:outline-none placeholder:text-[#4A5456] placeholder:text-[13px]"
            type="text"
            placeholder="What can we help you find?"
            readOnly={true}
            onChange={() => handleClick()}
          ></input>
        </div>
      </div>
    </div>
  )
}
