import { Elements } from '@stripe/react-stripe-js'
import Link from 'next/link'
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import { Headline, Loading, OrderSummary, ORDER_SUMMARY_TYPE, Pgraph } from '..'
import { URLS } from '../../config'
import { CART_ACTION, ICheckoutGuestData } from '../../interfaces'
import { PaymentApi } from '../../services'
import { useCartDispatch, useCartStore } from '../../store'
import PaymentForm from '../CheckoutPayment/form'
import { GUEST_STEP } from '../../pages/checkout/guest'
import getStripe from '../../services/stripe'

interface ICheckoutGuestPaymentProps {
  setCheckoutStep: Dispatch<SetStateAction<GUEST_STEP>>
  userData?: ICheckoutGuestData
}

const stripePromise = getStripe()

export const CheckoutGuestPayment = ({
  setCheckoutStep,
  userData,
}: ICheckoutGuestPaymentProps) => {
  const [orderSummaryOpen, setOrderSummaryOpen] = useState(true)
  const cartDispatch = useCartDispatch()
  const { registryCart } = useCartStore()
  const [stripeToken, setStripeToken] = useState<string>()
  const gettingToken = useRef(false)

  useEffect(() => {
    // @diego - Get stripe token
    if (!gettingToken.current && !stripeToken) {
      gettingToken.current = true
      if (userData) {
        const { emailAddress, firstName, lastName } = userData
        PaymentApi.initiateGuestCheckout({
          emailAddress,
          firstName,
          lastName,
          lineItems: registryCart.lines,
          ...registryCart,
        })
          .then((response) => {
            if (response) {
              setStripeToken(response.stripePaymentIntentSecret)
              cartDispatch({
                type: CART_ACTION.REGISTRY_CART_TOKENS,
                stripePaymentIntentId: response.stripePaymentIntentId,
                stripePaymentIntentSecret: response.stripePaymentIntentSecret,
                cardDiscounts: response.cardDiscounts,
              })
            }
          })
          .finally(() => {
            gettingToken.current = false
          })
      }
    }
  }, [cartDispatch, gettingToken, registryCart, stripeToken, userData])

  const onGoBack = () => {
    setCheckoutStep(GUEST_STEP.CUSTOMER_DATA)
  }

  return (
    <div className="flex flex-col items-center mx-3">
      <Headline variant="recoleta-l" className="mb-2">
        Payment method
      </Headline>
      <div className="w-full md:w-2/3 mx-auto">
        <OrderSummary
          open={orderSummaryOpen}
          setOpen={setOrderSummaryOpen}
          showCouponForm={false}
          summaryData={{
            type: ORDER_SUMMARY_TYPE.GUEST,
            registryCart,
          }}
        />
      </div>
      {!stripeToken && (
        <div className="my-4">
          <Loading />
        </div>
      )}
      {stripeToken && (
        <div className="w-full">
          <Elements
            stripe={stripePromise}
            options={{
              clientSecret: stripeToken,
              appearance: {
                theme: 'flat',
                variables: {
                  borderRadius: '0.25rem',
                  colorDanger: '#9F0000',
                  colorBackground: '#fdfdfd',
                  fontFamily: 'Circular Medium, Helvetica, Arial, sans-serif',
                  fontSizeBase: '14px',
                  fontWeightNormal: '500',
                  fontLineHeight: '17px',
                },
                rules: {
                  '.Input': {
                    border: '1px solid black',
                  },
                },
              },
            }}
          >
            <PaymentForm isAnonymousCheckout={true} />
          </Elements>
        </div>
      )}
      <Pgraph
        variant="l-16"
        className="underline text-center my-4 cursor-pointer"
        onClick={onGoBack}
      >
        Go back
      </Pgraph>
      <Pgraph variant="p-12" className="text-center mb-4 mt-4 max-w-md">
        By confirming your order, you agree to our{' '}
        <span className="underline cursor-pointer">
          <Link href={URLS.TERMS_OF_SERVICE} passHref>
            terms of service
          </Link>
        </span>{' '}
        and{' '}
        <span className="underline cursor-pointer">
          <Link href={URLS.PRIVACY_POLICY} passHref>
            privacy policy
          </Link>
        </span>
        .
      </Pgraph>
    </div>
  )
}
