import { AuthApi } from '.'
import { getRequestHeaders } from '../config'
import { fetchTimeoutRetry } from '../config/utilities'
import { IAddress } from '../interfaces'
import {
  ICustomerInfo,
  IValidateLocationResponse,
} from '../interfaces/customer.interface'

export const validateLocation = async (
  zipcode: string
): Promise<string | null> => {
  try {
    const result = await fetchTimeoutRetry(
      `${process.env.NEXT_PUBLIC_CUSTOMERS_URL}/customers/location/` + zipcode,
      {
        method: 'GET',
        mode: 'cors',
        headers: getRequestHeaders(),
      }
    )
    const jsonResponse = (await result.json()) as IValidateLocationResponse
    if (jsonResponse.success) {
      return jsonResponse.location
    } else {
      return null
    }
  } catch (error) {
    return null
  }
}

interface IUpdateAddressRequest {
  accessToken: string
  billingAddress?: IAddress
  shippingAddress?: IAddress
}

export const updateAddresses = async ({
  accessToken,
  billingAddress,
  shippingAddress,
}: IUpdateAddressRequest): Promise<boolean> => {
  try {
    const result = await fetchTimeoutRetry(
      `${process.env.NEXT_PUBLIC_CUSTOMERS_URL}/customers/address`,
      {
        method: 'PUT',
        headers: getRequestHeaders(accessToken),
        body: JSON.stringify({ shippingAddress, billingAddress }),
      }
    )

    if (result.status !== 200) {
      throw new Error("Couldn't update addresses")
    }

    return true
  } catch (error) {
    return false
  }
}

export const updateCustomerInfo = async (
  customerInfo: ICustomerInfo,
  accessToken: string = ''
) => {
  try {
    const result = await fetchTimeoutRetry(
      `${process.env.NEXT_PUBLIC_CUSTOMERS_URL}/customers`,
      {
        method: 'PUT',
        headers: getRequestHeaders(accessToken),
        body: JSON.stringify(customerInfo),
      }
    )

    if (result.status !== 200) {
      throw new Error("Couldn't update customer info")
    }

    const whoAmI = await AuthApi.whoAmI({ accessToken })
    return whoAmI
  } catch (error) {
    return null
  }
}

export const updatePartialCustomerInfo = async (
  info: Partial<ICustomerInfo>,
  accessToken: string
) => {
  try {
    const result = await fetchTimeoutRetry(
      `${process.env.NEXT_PUBLIC_CUSTOMERS_URL}/customers/info`,
      {
        method: 'PUT',
        headers: getRequestHeaders(accessToken),
        body: JSON.stringify(info),
      }
    )

    if (result.status !== 200) {
      const error: Error | any = new Error("Couldn't update customer info")
      error.cause = result.status

      throw error
    }

    const whoAmI = await AuthApi.whoAmI({ accessToken })
    return whoAmI
  } catch (error) {
    return error
  }
}
