import { Pgraph } from '../Pgrah'
import { INavigation, MenuProps } from '../../interfaces/navigation.interfaces'
import { useRouter } from 'next/router'
import Image from 'next/image'
import { Headline } from '../Headline'
import { useState } from 'react'

interface IDesktopMenuProps {
  menuJson: INavigation
}

export const DesktopMenu = ({ menuJson }: IDesktopMenuProps) => {
  const router = useRouter()
  const [openedIndex, setOpenedIndex] = useState(-1)

  const onMenuClick = (menuItem: MenuProps) => {
    const query = menuItem.filters || undefined
    router.push({ pathname: menuItem.link, query })
    setOpenedIndex(-1)
  }

  const onMouseEnter = (index: number) => {
    setOpenedIndex(index)
  }
  const onMouseLeave = () => {
    setOpenedIndex(-1)
  }

  const menu = [...menuJson.menu].map((item, index) => {
    return {
      ...item,
      classes: `group flex items-center justify-center desktop-menu-item ${
        openedIndex == index ? 'active' : 'not-active'
      }`,
    }
  })
  return (
    <div className="bg-white hidden lg:block">
      <div className="max-w-5xl w-full mx-auto h-[52px] flex justify-between items-stretch relative">
        <div className="text-center grid gap-0 grid-flow-col w-full">
          {menu.map((menuItem, index) => (
            <div
              key={`desk-menu-${index}`}
              className={menuItem.classes}
              onMouseEnter={onMouseEnter.bind(this, index)}
              onMouseLeave={onMouseLeave}
            >
              <Headline
                variant="recoleta-xs"
                className={`text-neutral-6 inline-block relative xs:text-[13px] cursor-pointer group-hover:font-semibold `}
                onClick={() => onMenuClick(menuItem)}
              >
                {menuItem.label}
              </Headline>
              {menuItem.items && menuItem.items.length > 0 && (
                <section className="submenu absolute z-10 top-[52px] left-0 bg-snow-white w-full rounded-b-2xl shadow-navigation max-w-[1200px] px-[4%] xl:w-[125%] xl:-left-[9%] ">
                  <div className="w-full m-auto mt-5 mb-7 grid grid-cols-5 grid-flow-col gap-4">
                    {menuItem?.items?.map((subMenuItem, j) =>
                      !subMenuItem.hideFromDesktop ? (
                        <div className="menu-col" key={`submenuN-${j}`}>
                          <Pgraph
                            variant="p-14"
                            className={`xs:font-circular-medium text-neutral-6 inline-block align-top uppercase text-left w-full`}
                          >
                            {subMenuItem.label && (
                              <span onClick={() => onMenuClick(subMenuItem)}>
                                {subMenuItem.label}
                              </span>
                            )}

                            {subMenuItem.items && (
                              <div className="mt-3">
                                {subMenuItem.items.map((y, k) => (
                                  <div
                                    key={`submenu-${Math.random() * 100}-${k}`}
                                    onClick={() => onMenuClick(y)}
                                    className="hover:cursor-pointer"
                                  >
                                    {y.img ? (
                                      <>
                                        <Image
                                          src={y.img}
                                          alt={y.label || ''}
                                          height="750"
                                          width="600"
                                          priority
                                        />
                                        <Pgraph
                                          variant="p-12"
                                          className={`xs:text-[13px] xs:leading-19 text-neutral-6 mt-[12px] xs:font-circular-medium capitalize cursor-pointer max-w-[200px]`}
                                          key={k}
                                        >
                                          {y.label}
                                        </Pgraph>
                                      </>
                                    ) : (
                                      <Pgraph
                                        variant="p-14"
                                        className={`text-neutral-6 mt-[12px] font-normal capitalize cursor-pointer ${
                                          y.type === 'seperator'
                                            ? ' border-b-[1px] border-solid max-w-[100px]'
                                            : ' max-w-[200px]'
                                        }`}
                                        key={k}
                                      >
                                        {y.label}
                                      </Pgraph>
                                    )}
                                  </div>
                                ))}
                              </div>
                            )}
                          </Pgraph>
                        </div>
                      ) : (
                        ''
                      )
                    )}
                  </div>
                </section>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
