import { useRouter } from 'next/router'
import React from 'react'
import { Button, Headline, TopProductsMolecule } from '..'
import { URLS } from '../../config/urls.constants'
import { IProductFilters, IProductSummary } from '../../interfaces'

interface RedeemMembershipProps {
  filters: IProductFilters
  topProducts: IProductSummary[]
}

function RedeemMembershipCreated({
  filters,
  topProducts,
}: RedeemMembershipProps) {
  const router = useRouter()

  return (
    <section className="w-full bg-snow-white">
      <div className="max-w-5xl mx-auto">
        <div className="">
          <div className="mx-auto py-4 px-4 md:py-5">
            <Headline
              variant="recoleta-s"
              className="text-neutral-7 mt-3 w-4/5 text-center mx-auto mb-3 leading-29 md:text-28px md:w-2/5 lg:text-32px lg:leading-38"
            >
              Congrats - you&apos;re now a Member
            </Headline>

            <div className="text-center mt-4">
              <Button
                primary={true}
                label="Start shopping"
                className="w-full mt-4 md:w-1/3"
                onClick={() => {
                  router.push(URLS.PRODUCTS.INDEX)
                }}
              />
            </div>
          </div>
        </div>
        <TopProductsMolecule
          title="Member favorites"
          titleHighlight={true}
          products={topProducts}
          viewProductsUrl={`${URLS.PRODUCTS.INDEX}?categories=${filters.topProductsCategoryId}`}
        />

        {/*
        <ValuePropMolecule
          className="bg-heavy-cream"
          propsList={REDEEM_VALUE_PROPS}
        />
        <MembershipMolecule
          title="Membership perks"
          perks={MembershipPerks}
          className="bg-neutral-1"
          imagePath="/images/stock/membership-join-now.jpg"
          imageClassName="md:max-width-[400px]"
        />
        */}
      </div>
    </section>
  )
}

export { RedeemMembershipCreated }
