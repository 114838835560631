import React from 'react'
import { Button, Headline, Pgraph } from '..'
import Image from 'next/image'

 function StrollerHLW() {
  return (
    <section className='bg-heavy-cream md:my-5 md:mx-5 lg:mx-6 pb-3'>
      <div className='flex flex-col text-center justify-center mx-auto py-40px max-w-[800px]'>
        <Headline
          className='text-poppy mb-6 max-w-[600px] mx-auto'
          variant='recoleta-l'
        >
          If you fall in love with a stroller at your showcase, you have two options:
          </Headline>
        <div className='flex justify-around'>
          <div className='flex flex-col relative'>
            <div className='relative flex justify-center mt-0'>
              <Image
                alt="stroller photo"
                src="/images/stroller-showcase/stroller-hlw-1.png"
                width={250}
                height={250}
                className='w-[150px] xs:w-[170px] md:w-[250px] md:block'
              />
            </div>
            <div className='absolute w-full bottom-[60px] xs:bottom-[40px] md:top-[210px] md:grow-1 justify-center items-center flex'>
              <Image
                alt="stroller photo"
                src="/images/hlw-1.svg"
                width={75}
                height={75}
                className=''
              />

            </div>
            <Pgraph
              variant='p-18'
              className='mt-50px'
            >
              <b>Purchase a stroller</b>
            </Pgraph>
            {/* <Pgraph
              variant='p-16'
              className='mt-20px text-left'
            >
              Your $35 consultation fee will be applied to your purchase <br/><br/>30-Day Rental Policy: After using the stroller for a bit, you realize you would rather rent it, that&apos;s okay! We&apos;ll give you store credit minus the $35 consultation fee and the price of one month&apos;s rental.
            </Pgraph> */}
          </div>
          <div className='flex flex-col relative'>
            <div className='relative flex justify-center mt-o'>
              <Image
                alt="stroller photo"
                src="/images/stroller-showcase/stroller-hlw-2.png"
                width={250}
                height={250}
                className='w-[150px] xs:w-[170px] md:w-[250px] md:block'
              />
              {/* <Image
                alt="stroller photo"
                src="/images/stroller-showcase/stroller-hlw-2.png"
                width={175}
                height={175}
                className='md:hidden block'
              /> */}
              
            </div>
            <div className='absolute w-full bottom-[15px] md:grow-1 justify-center items-center flex'>
              <Image
                alt="stroller photo"
                src="/images/hlw-2.svg"
                width={120}
                height={100}
                className=''
              />
            </div>
            <Pgraph
              variant='p-18'
              className='mt-50px'
            >
              <b>Rent a stroller</b>
            </Pgraph>
            {/* <Pgraph
              variant='p-16'
              className='mt-20px text-left'
            >
              Your $35 consultation fee will be applied to your first month&apos;s rental<br/><br/>30-Day Purchase Policy: Loving your stroller and want to buy it? Great! We will apply your $35 consultation fee and the first month&apos;s rental toward your purchase.
            </Pgraph> */}
        </div>
        </div>
        <Pgraph
          variant='p-12'
          className='max-w-[500px] text-center mx-auto mt-6'
        >
          <b>Still deciding?</b> That&apos;s okay! The $35 consultation fee is a non-refundable, one-time payment to cover the cost of the stroller showcase service, purchase or rental is not required.
        </Pgraph>
      </div>
    </section>
  )
}

export {StrollerHLW}