import { useRouter } from 'next/router'
import React from 'react'
import { URLS } from '../../../config'
import { Button } from '../../Button'
import { Headline } from '../../Headline'
import { Pgraph } from '../../Pgrah'

interface IContactMoleculeProps {
  /**
   * css class
   */
  className?: string
}

function ContactMolecule({ className, ...props }: IContactMoleculeProps) {
  const router = useRouter()

  return (
    <section className="w-full bg-alice">
      <div className="max-w-6xl mx-auto pt-6 pb-[72px]">
        <div className="align-center px-4 text-center max-w-[700px] mx-auto">
          <Headline
            variant="recoleta-m"
            className="text-neutral-6 md:text-40px lg:text-52px"
          >
            Need A Hand?
          </Headline>
          <Pgraph variant="p-16" className="text-neutral-7 mt-3">
            Choosing the right bouncer or figuring out which toy to try next can
            sometimes feel impossible. Our customer experience team is here to
            help save you from decision fatigue.
          </Pgraph>

          <Button
            primary
            className="mt-4 text-center w-8 md:max-w-[200px] min-w-[150px]"
            label="Reach Out"
            onClick={() => router.push(URLS.CONTACT_US)}
          ></Button>
        </div>
      </div>
    </section>
  )
}

export { ContactMolecule }
