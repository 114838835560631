import React, { useState } from 'react'
import { Headline } from '../../Headline'
import { ProductCarouselCard } from '../../ProductCarouselCard'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import { IProductSummary } from '../../../interfaces'

const Slides_Per_Page = 2

interface IButtonGroupProps {
  next: () => void
  previous: () => void
  carouselState: {
    currentSlide: number
    totalItems: number
  }
}

interface IPopularItemsByAgeProps {
  /** Optional additional class names for styling */
  className?: string
  /** Products */
  ageRangeInfo: { [key: string]: AgeRangeInfo }

  defaultCarousel: string
}

const ButtonGroup = ({ next, previous, carouselState }: IButtonGroupProps) => {
  // use carouselState to disable button when in the last slide, for example.
  return (
    <div className="text-right pr-4 2xl:pr-8">
      <span onClick={() => previous()} className="mr-4 cursor-pointer">
        <i
          className={`loop-icon-arrow-long text-24px text-black transition ease-in duration-400 rotate-180 inline-block ${
            carouselState.currentSlide === 0
              ? 'cursor-not-allowed opacity-25'
              : ''
          }`}
        ></i>
      </span>
      <span onClick={() => next()} className="cursor-pointer">
        <i
          className={`loop-icon-arrow-long text-24px text-black transition ease-in duration-400 inline-block ${
            carouselState.totalItems - carouselState.currentSlide ===
            Slides_Per_Page
              ? 'cursor-not-allowed opacity-25'
              : ''
          }`}
        ></i>
      </span>
    </div>
  )
}

export interface AgeRangeInfo {
  displayName: string
  devFilter: { ages: number[]; categories: number[] }
  prodFilter: { ages: number[]; categories: number[] }
  products: IProductSummary[]
}

function PopularItemsByAge({
  ageRangeInfo,
  defaultCarousel,
}: IPopularItemsByAgeProps) {
  Object.keys(ageRangeInfo).map((range) => {
    ageRangeInfo[range].products.length > 9
      ? (ageRangeInfo[range].products.length = 9)
      : null
  })

  const [selectedAge, setSelectedAge] = useState<string>(
    defaultCarousel || 'baby'
  )

  const onAgeRangeChange = function (key: string, ageRange: AgeRangeInfo) {
    setSelectedAge(key)
  }

  return (
    <section className="w-full bg-white py-5">
      <div className="max-w-[1440px] mx-auto">
        <div className="lg:flex lg:mb-4">
          <Headline
            variant="recoleta-m"
            className="text-neutral-6 text-center lg:text-left lg:w-1/3 xl:w-1/4 lg:pl-4"
          >
            Popular Items By Age
          </Headline>

          <div className="lg:max-w-[400px] lg:w-1/3 xl:w-2/3 lg:pl-2 xl:pl-4 lg:content-center lg:grid lg:grid-cols-1">
            <ul className="grid grid-cols-3 text-neutral-4 font-circular-medium text-20px mt-4 lg:mt-0 lg:align-middle mb-4 lg:mb-0">
              {Object.keys(ageRangeInfo).map((range) => {
                const selectedClasses =
                  selectedAge === range ? 'text-poppy underline' : ''
                return (
                  <li
                    key={range}
                    onClick={() => {
                      onAgeRangeChange(range, ageRangeInfo[range])
                    }}
                    className={[
                      ' hover:cursor-pointer text-center ease-out',
                      selectedClasses,
                    ].join(' ')}
                  >
                    {ageRangeInfo[range].displayName}
                  </li>
                )
              })}
            </ul>
          </div>
        </div>

        <div className="pl-[20px] relative min-h-[445px]">
          {Object.keys(ageRangeInfo).map((range) => {
            const carouselClasses = selectedAge === range ? 'block' : 'hidden'
            return (
              <div
                key={range}
                className={['ease-out  relative top-0', carouselClasses].join(
                  ' '
                )}
              >
                <Carousel
                  swipeable={true}
                  showDots={false}
                  arrows={false}
                  infinite={false}
                  partialVisible={true}
                  autoPlay={false}
                  autoPlaySpeed={5000}
                  renderButtonGroupOutside={true}
                  responsive={{
                    xxsmobile: {
                      breakpoint: { max: 400, min: 0 },
                      items: 1,
                      partialVisibilityGutter: 50,
                    },
                    xsmobile: {
                      breakpoint: { max: 500, min: 400 },
                      items: 1,
                      partialVisibilityGutter: 50,
                    },
                    mobile: {
                      breakpoint: { max: 640, min: 500 },
                      items: 1,
                      partialVisibilityGutter: 100,
                    },
                    smtablet: {
                      breakpoint: { max: 740, min: 640 },
                      items: 2,
                      partialVisibilityGutter: 0,
                    },
                    tablet: {
                      breakpoint: { max: 1024, min: 740 },
                      items: 3,
                      partialVisibilityGutter: 0,
                    },
                    desktop: {
                      breakpoint: { max: 1440, min: 1024 },
                      items: 3,
                      partialVisibilityGutter: 0,
                    },
                    xldesktop: {
                      breakpoint: { max: 9000, min: 1440 },
                      items: 3,
                      partialVisibilityGutter: 30,
                    },
                  }}
                  //@ts-ignore
                  customButtonGroup={<ButtonGroup />}
                >
                  {ageRangeInfo[range].products.map((product, index) => {
                    return (
                      <ProductCarouselCard
                        key={range + '' + product.bigCommerceProductId}
                        product={product}
                      />
                    )
                  })}
                </Carousel>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export { PopularItemsByAge }
