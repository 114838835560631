import { getRequestHeaders } from '../config'
import { fetchTimeoutRetry } from '../config/utilities'
import { IGiftcardApiResponse } from '../interfaces'

export const applyGiftCard = async (accessToken: string, code: string) => {
  try {
    const res = await fetchTimeoutRetry(
      `${process.env.NEXT_PUBLIC_GIFTCARD_URL}/accounts/gift-certificates`,
      {
        method: 'POST',
        headers: getRequestHeaders(accessToken),
        body: JSON.stringify({ code }),
      }
    )

    const json = (await res.json()) as IGiftcardApiResponse
    return json
  } catch (error) {
    console.error(error)
    return undefined
  }
}

export const validateGiftCard = async (accessToken: string, code: string) => {
  try {
    const res = await fetchTimeoutRetry(
      `${process.env.NEXT_PUBLIC_GIFTCARD_URL}/gift-certificates/${code}/validate`,
      {
        method: 'GET',
        headers: getRequestHeaders(accessToken),
      }
    )

    const json = (await res.json()) as any
    return json
  } catch (error) {
    console.error(error)
    return undefined
  }
}

export const redeemAnnualMembership = async (
  accessToken: string,
  code: string
) => {
  try {
    const res = await fetchTimeoutRetry(
      `${process.env.NEXT_PUBLIC_GIFTCARD_URL}/gift-certificates/${code}/redeem-annual`,
      {
        method: 'POST',
        headers: getRequestHeaders(accessToken),
        body: JSON.stringify({ code }),
      }
    )

    const json = (await res.json()) as any
    return json
  } catch (error) {
    console.error(error)
    return undefined
  }
}
