import Script from 'next/script'
import { ICartApiProduct } from '../../interfaces'

interface IJustUnoConvertionProps {
  amount?: number
  order?: number
  lineItems?: {
    physicalItems: ICartApiProduct[]
    digitalItems: ICartApiProduct[]
    customItems: ICartApiProduct[]
    giftCertificates: ICartApiProduct[]
  }
}

export const ScriptJustUnoConvertion = ({
  amount = 0,
  order = 0,
  lineItems = {
    physicalItems: [],
    digitalItems: [],
    customItems: [],
    giftCertificates: [],
  },
}: IJustUnoConvertionProps) => {
  const products = [
    ...lineItems.digitalItems.map((item) => {
      return {
        id: item.productId,
        name: item.name,
        price: item.listPrice,
        quantity: item.quantity,
      }
    }),
    ...lineItems.physicalItems.map((item) => {
      return {
        id: item.productId,
        name: item.name,
        price: item.listPrice,
        quantity: item.quantity,
      }
    }),
    ...lineItems.customItems.map((item) => {
      return {
        id: item.productId,
        name: item.name,
        price: item.listPrice,
        quantity: item.quantity,
      }
    }),
    ...lineItems.giftCertificates.map((item) => {
      return {
        id: item.productId,
        name: item.name,
        price: item.listPrice,
        quantity: item.quantity,
      }
    }),
  ]

  let itemsScript = ''

  products.forEach((item) => {
    itemsScript += `
    juapp(
        'orderItem',
        '${item.id}',
        {name:'${item.name}',quantity:${item.quantity},price:${item.price}}
      );
      `
  })

  return (
    <>
      <Script
        id="JustUnoScriptConvertion"
        // strategy="worker" - Causing recording problems?
        data-cfasync="false"
        dangerouslySetInnerHTML={{
          __html: `
        window.juapp=window.juapp||function(){(window.juapp.q=window.juapp.q||[]).push(arguments)}

        juapp(
            'order',
            '${order}',
            {total:${amount},currency:'USD'}
            );
        
            ${itemsScript}
        `,
        }}
      ></Script>
    </>
  )
}
