import Script from 'next/script'
import React from 'react'
import { ICartApiProduct } from '../../interfaces'
interface IGTMConvertionProps {
  amount?: number
  order?: number
  lineItems?: {
    physicalItems: ICartApiProduct[]
    digitalItems: ICartApiProduct[]
    customItems: ICartApiProduct[]
    giftCertificates: ICartApiProduct[]
  }
}

export const GTMConvertionTracking = ({
  amount = 0,
  order = 0,
  lineItems = {
    physicalItems: [],
    digitalItems: [],
    customItems: [],
    giftCertificates: [],
  },
}: IGTMConvertionProps) => {
  const products = [
    ...lineItems.digitalItems.map((item) => {
      return {
        sku: item.sku,
        name: item.name,
        price: item.listPrice,
        quantity: item.quantity,
      }
    }),
    ...lineItems.physicalItems.map((item) => {
      return {
        sku: item.sku,
        name: item.name,
        price: item.listPrice,
        quantity: item.quantity,
      }
    }),
    ...lineItems.customItems.map((item) => {
      return {
        sku: item.sku,
        name: item.name,
        price: item.listPrice,
        quantity: item.quantity,
      }
    }),
    ...lineItems.giftCertificates.map((item) => {
      return {
        sku: item.sku,
        name: item.name,
        price: item.listPrice,
        quantity: item.quantity,
      }
    }),
  ]
  const trackingObj = {
    transactionId: order,
    transactionAffiliation: 'Loop Baby',
    event: 'purchase',
    transactionTotal: amount,
    transactionTax: 0,
    transactionShipping: 0,
    transactionProducts: products,
  }

  return (
    <Script
      id="GTMConvertionTracking"
      // strategy="worker" - Causing recording problems?
      dangerouslySetInnerHTML={{
        __html: `
          window.dataLayer = window.dataLayer || [];
          dataLayer.push(${JSON.stringify(trackingObj)});
          `,
      }}
    ></Script>
  )
}
