import React from 'react'
import { Headline, Pgraph } from '..'

interface IIncludedInRentalsProps {
  background?: string
  iconsBg?: string
}

export const IncludedInRentals = ({
  background = 'bg-neutral-1',
  iconsBg = 'bg-alice',
}: IIncludedInRentalsProps) => {
  return (
    <section className={['w-full', background].join(' ')}>
      <div className="max-w-5xl mx-auto pb-6 pt-5 px-4 md:pt-6 md:pb-7">
        <Headline
          variant="recoleta-s"
          className="text-neutral-6 mb-[35px] px-4 text-center"
        >
          Included with all Loop rentals
        </Headline>
        <div className="flex flex-col md:flex-row">
          <div className="grid grid-cols-2 md:w-1/2">
            <div className="text-center px-3">
              <div
                className={[
                  'rounded-full h-[104px] w-[104px] flex justify-center items-center mx-auto',
                  iconsBg,
                ].join(' ')}
              >
                <img
                  src="/images/icons/icon-delivery.svg"
                  alt="Deliveries and pick-ups that fit your schedule"
                  className="max-w-[104px] mx-auto"
                />
              </div>
              <Pgraph
                variant="p-14"
                className="text-neutral-6 mt-2 max-w-[140px] mx-auto xs:leading-18 md:text-16px md:leading-22"
              >
                Deliveries and pick-ups that fit your schedule
              </Pgraph>
            </div>
            <div className="text-center px-3">
              <div
                className={[
                  'rounded-full h-[104px] w-[104px] flex justify-center items-center mx-auto',
                  iconsBg,
                ].join(' ')}
              >
                <img
                  src="/images/icons/icon-cleaning.svg"
                  alt="Items thoroughly cleaned, inspected and sanitized"
                  className="max-w-[104px] mx-auto"
                />
              </div>
              <Pgraph
                variant="p-14"
                className="text-neutral-6 mt-2 max-w-[140px] mx-auto xs:leading-18 md:text-16px md:leading-22"
              >
                Items thoroughly cleaned, inspected and sanitized
              </Pgraph>
            </div>
          </div>
          <div className="grid grid-cols-2 mt-4 md:mt-0 md:w-1/2">
            <div className="text-center px-3">
              <div
                className={[
                  'rounded-full h-[104px] w-[104px] flex justify-center items-center mx-auto',
                  iconsBg,
                ].join(' ')}
              >
                <img
                  src="/images/icons/icon-packaging.svg"
                  alt="No packaging waste"
                  className="max-w-[104px] mx-auto"
                />
              </div>

              <Pgraph
                variant="p-14"
                className="text-neutral-6 mt-2 max-w-[140px] mx-auto xs:leading-18 md:text-16px md:leading-22"
              >
                No packaging waste
              </Pgraph>
            </div>
            <div className="text-center px-3">
              <div
                className={[
                  'rounded-full h-[104px] w-[104px] flex justify-center items-center mx-auto',
                  iconsBg,
                ].join(' ')}
              >
                <img
                  src="/images/icons/icon-tools.svg"
                  alt="Items are set-up in home"
                  className="max-w-[104px] mx-auto"
                />
              </div>

              <Pgraph
                variant="p-14"
                className="text-neutral-6 mt-2 max-w-[140px] mx-auto xs:leading-18 md:text-16px md:leading-22"
              >
                Items are set-up in home
              </Pgraph>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
