import React from 'react'
import { Headline, Pgraph } from '..'

function HLWHero() {
  return (
    <section className="bg-neutral-1 w-full">
      <div className="flex flex-col content-center justify-center relative md:flex-row lg:max-w-[1074px] lg:mx-auto">
        <div className="md:w-1/2 md:order-2">
          <img
            src="/images/hlw/hlw-hero-mobile.jpg"
            alt="How Loop Works"
            className="md:hidden"
          />
          <img
            src="/images/hlw/hlw-hero-desktop.jpg"
            alt="How Loop Works"
            width="100%"
            className="hidden md:block w-full"
          />
        </div>
        <div className="md:w-1/2">
          <div className="text-center mx-auto px-5 lg:pt-7 lg:max-w-[450px]">
            <img
              src="/images/hlw/hlw-ilustration.png"
              alt="HLW Ilustration"
              className="max-w-[105px] my-4 hidden md:block lg:max-w-[190px] lg:mb-6"
            />
            <Pgraph
              variant="l-12"
              className="mx-auto text-center md:text-left text-neutral-6 mt-4"
            >
              WHY BUY WHEN YOU CAN RENT?
            </Pgraph>

            <Headline
              variant="recoleta-xs"
              className="text-poppy text-center mx-auto md:text-left md:text-32px md:leading-38 mt-3"
            >
              When you Loop, you&apos;re renting today&apos;s best gear and toys
              for your child.
            </Headline>

            <Pgraph
              variant="p-16"
              className="text-neutral-6 text-center md:text-left mt-3 leading-22"
            >
              We&apos;ll deliver your items exactly when you want, you can enjoy
              them as long as you like, and then we&apos;ll pick them back up
              when you&apos;re ready.
            </Pgraph>

            <img
              src="/images/hlw/hlw-ilustration.png"
              alt="HLW Ilustration"
              className="max-w-[105px] mx-auto my-4 md:hidden"
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export { HLWHero }
