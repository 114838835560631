import Bugsnag, { BreadcrumbType } from '@bugsnag/js'
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react'
import { GIFT_CARD_IMG_PATH, URLS } from '../../config'
import {
  ICartQueuedLine,
  ICoupon,
  IGetCartLinesParams,
  IGetCartLinesResult,
  IHooksState,
} from '../../interfaces'
import { useAuthDispatch, useAuthStore } from '../auth'
import { useCartDispatch, useCartStore } from '../cart'
import { useRouter } from 'next/router'

interface IHooksProviderProps {
  children: ReactNode
}

const minimumCartAmountForFreeShipping = 100

const initialState: IHooksState = {
  logout: () => {},
  getCartData: (params: IGetCartLinesParams) => ({
    credits: 0,
    giftCards: 0,
    lines: [],
    delivery: 0,
    subTotal: 0,
    total: 0,
    discounts: 0,
    coupons: [],
    subscriptionSavings: 0,
    upsellAmount: 0,
    subscriptionPercentSavings: '0',
    membershipInCart: false,
  }),
  reportBug: (error, additionalData) => {},
  reportBreadcrumb: (eventName, metaData, type) => {},
}

const hooksContext = createContext<IHooksState>(initialState)

export const HooksProvider = ({ children }: IHooksProviderProps) => {
  const [hooks, setHooks] = useState<IHooksState>(initialState)

  const router = useRouter()

  const authDispatch = useAuthDispatch()
  const cartDispatch = useCartDispatch()

  const authStore = useAuthStore()
  const { subscriptionActive } = authStore
  const cartStore = useCartStore()
  const { membershipProducts } = cartStore

  useEffect(() => {
    const logout = () => {
      router.push(URLS.INDEX)
      setTimeout(() => {
        authStore.authLogout()
        cartStore.resetCart()
      }, 2000)
    }

    const getCartData = ({
      cart,
      creditConsumption,
      registryCart,
    }: IGetCartLinesParams): IGetCartLinesResult => {
      if (cart && registryCart)
        throw new Error(
          "Hooks provider error: Can't use cart and regisry cart at the same time."
        )

      const giftCardsRaw = creditConsumption
        ? (creditConsumption.giftCertificateCredit || 0) / 100
        : 0

      const lines: ICartQueuedLine[] = registryCart
        ? [
            ...registryCart.lines.map((line) => ({
              ...line,
              salePrice: line.salePrice * line.rentalLength,
            })),
            ...registryCart.giftCertificates.map((gift) => ({
              name: gift.validForAnnualMembership
                ? 'Loop membership gift card'
                : 'Loop gift card',
              quantity: gift.quantity,
              salePrice: gift.amount,
              imageUrl: GIFT_CARD_IMG_PATH,
              productId: 0,
              variantId: 99900 + gift.amount,
              listPrice: 99900 + gift.amount,
              nonMemberPrice: gift.amount,
            })),
          ]
        : cart
        ? [
            ...(cart.apiCart?.lineItems.customItems || []),
            ...(cart.apiCart?.lineItems.digitalItems || []).filter(
              (p) => !p.name.includes('Delivery')
            ),
            ...(cart.apiCart?.lineItems.giftCertificates || []),
            ...(cart.apiCart?.lineItems.physicalItems || []),
            ...(cart.queuedCart || []),
          ]
        : []

      const deliveryFee = cart?.apiCart?.lineItems?.digitalItems?.find((p) =>
        p.name.includes('Delivery')
      )?.listPrice

      const membershipInCart = !!lines.find(
        (line) =>
          line.productId === membershipProducts.annual?.bigCommerceProductId
      )

      const itemsTotal = !!registryCart
        ? lines.reduce((acc, it) => (acc = acc + it.salePrice * it.quantity), 0)
        : lines.reduce(
            (acc, it) =>
              (acc =
                acc +
                (membershipInCart || subscriptionActive
                  ? it.salePrice
                  : it.nonMemberPrice ?? it.salePrice) * // ?? Fallback for checkout
                  it.quantity),
            0
          ) || 0

      const discounts =
        cart?.apiCart?.coupons?.reduce(
          (acc, it) => (acc = acc + it.discountedAmount),
          0
        ) ||
        registryCart?.cardDiscounts?.reduce(
          (acc, it) => (acc = acc + it.discountAmount),
          0
        ) ||
        0
      const subTotal = itemsTotal // -taxes??

      const newOrderCredit = (creditConsumption?.newOrderCredit || 0) / 100
      const stripeBalance = (creditConsumption?.balance || 0) / 100
      const giftCertificate =
        (creditConsumption?.giftCertificateCredit || 0) / 100
      const registryCredit = (creditConsumption?.registryCredit || 0) / 100
      const totalCredits: number =
        newOrderCredit + stripeBalance + giftCertificate + registryCredit
      const credits = totalCredits > subTotal ? subTotal : totalCredits
      const giftCards = giftCardsRaw > subTotal ? subTotal : giftCardsRaw

      const delivery = registryCart
        ? 0
        : deliveryFee ??
          (cart?.apiCart?.lineItems?.digitalItems?.length === 0 ||
          membershipInCart
            ? 0
            : membershipProducts.deliveryFee?.nonMemberPrice || 0)

      const coupons: ICoupon[] = cart?.apiCart?.coupons ?? []

      const total = subTotal - credits + delivery - discounts
      const subscriptionSavings = subscriptionActive
        ? 0
        : lines.reduce(
            (acc, it) =>
              (acc = acc + it.nonMemberPrice - it.salePrice) * it.quantity,
            0
          ) + 50

      const subscriptionPercentSavings = (
        (subscriptionSavings / total) *
        100
      ).toFixed(0)

      const upsellAmount =
        membershipInCart || !subscriptionActive
          ? 0
          : minimumCartAmountForFreeShipping - itemsTotal

      return {
        giftCards,
        credits,
        lines,
        discounts,
        delivery,
        subTotal,
        total,
        coupons,
        subscriptionSavings,
        subscriptionPercentSavings,
        membershipInCart,

        upsellAmount,
      }
    }

    const reportBug = (error: unknown, additionalData?: object) => {
      return //early return to avoid bugsnag reports since service will be disable after 6/12/2023

      /*if (process.env.NODE_ENV === 'development') return

      const errorType: Error =
        error instanceof Error ? error : new Error('Unknown Error')

      Bugsnag.notify(errorType, (ev) => {
        if (authStore?.user) {
          ev.setUser(
            authStore?.user?.account?.loopCustomerId,
            authStore?.user?.account?.emailAddress,
            authStore?.user?.customer?.firstName +
              ' ' +
              authStore?.user?.customer?.lastName
          )
        }
        ev.addMetadata(
          'Auth Store',
          authStore ? authStore : { authStore: 'No data provided' }
        )
        ev.addMetadata(
          'Cart Store',
          cartStore ? cartStore : { cartStore: 'No data provided' }
        )
        if (additionalData) {
          ev.addMetadata('Additional Data', additionalData)
        }
      })*/
    }

    const reportBreadcrumb = (
      eventName: string,
      metaData?: object,
      type?: BreadcrumbType
    ) => {
      return //early return to avoid bugsnag reports since service will be disable after 6/12/2023

      if (process.env.NODE_ENV === 'development') return

      Bugsnag.leaveBreadcrumb(eventName, metaData, type)
    }

    setHooks({
      logout,
      getCartData,
      reportBug,
      reportBreadcrumb,
    })
  }, [
    authDispatch,
    authStore,
    cartDispatch,
    cartStore,
    membershipProducts,
    router,
    subscriptionActive,
  ])

  return <hooksContext.Provider value={hooks}>{children}</hooksContext.Provider>
}

export const useHooks = () => {
  const hooks = useContext(hooksContext)

  if (!hooks) throw new Error('useHooks must be used within a HooksProvider')

  return hooks
}
