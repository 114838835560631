import React, { useEffect, useState } from 'react'
import NumberFormat from 'react-number-format'
import { Button } from '../Button'
import { Headline } from '../Headline'
import { Pgraph } from '../Pgrah/index'

interface IRedeemUpgradeToAnnualConfirmation {
  remainingAmount: number
  onClose: () => void
  onConfirm: () => void
}

function RedeemUpgradeToAnnualConfirmation({
  remainingAmount,
  onClose,
  onConfirm,
}: IRedeemUpgradeToAnnualConfirmation) {
  const [processing, setProcessing] = useState<boolean>(false)

  useEffect(() => {
    const handleEsc = (event: globalThis.KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose()
      }
    }
    window.addEventListener('keydown', handleEsc)

    return () => {
      window.removeEventListener('keydown', handleEsc)
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div className="bg-snow-white fixed top-0 left-0 z-50 w-full h-full flex justify-center">
        <div
          className="cursor-pointer"
          onClick={() => {
            onClose()
          }}
        >
          <i className=" absolute right-0 top-0 m-4 loop-icon-times text-24px text-neutral-7"></i>
        </div>
        <div className="flex flex-col mt-5 mb-5 w-full items-center">
          <div className="w-full max-w-[456px] px-4">
            <Headline variant="recoleta-s" className="text-center mb-4">
              Upgrade your Membership
            </Headline>

            <hr className="border-neutral-2" />

            <div className="flex py-4">
              <div className="pr-3 w-2/6">
                <div className="border border-neutral-2 py-2">
                  <img
                    src="/images/loop-red.png"
                    alt="Loop Membership"
                    className="max-w-[100%]"
                  />
                </div>
              </div>
              <div className="pr-3 pt-2 w-3/6">
                <Pgraph variant="p-14" className="text-neutral-6">
                  Loop Membership
                </Pgraph>
              </div>
              <div className="w-1/6">
                <Pgraph
                  variant="p-14"
                  className="text-neutral-6 text-right pt-2"
                >
                  $0.00
                </Pgraph>
              </div>
            </div>
            <hr className="border-neutral-2" />

            <div>
              <div className="grid grid-cols-2 py-2 mt-4">
                <div>
                  <Pgraph variant="p-14" className="text-neutral-6">
                    Subtotal
                  </Pgraph>
                </div>
                <div className="text-right">
                  <Pgraph variant="p-14" className="text-neutral-6">
                    $0.00
                  </Pgraph>
                </div>
              </div>
              <div className="flex  py-2">
                <div className="w-3/6 md:w-3/5">
                  <Pgraph variant="p-14" className="text-neutral-6 pr-3">
                    Monthly membership credit applied
                  </Pgraph>
                </div>
                <div className="text-right w-3/6 md:w-2/5">
                  <Pgraph variant="p-14" className="text-neutral-6">
                    <NumberFormat
                      value={remainingAmount / 100}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'-$'}
                      decimalScale={2}
                      fixedDecimalScale={true}
                    />
                  </Pgraph>
                </div>
              </div>
              <div className="grid grid-cols-2 py-2">
                <div>
                  <Pgraph variant="l-14" className="text-neutral-6">
                    Total
                  </Pgraph>
                </div>
                <div className="text-right">
                  <Pgraph variant="l-14" className="text-neutral-6">
                    $0.00
                  </Pgraph>
                </div>
              </div>
            </div>

            <div className="mt-4 p-2 bg-heavy-cream border border-dashed border-alice">
              <Pgraph variant="p-14" className="text-neutral-5 text-center">
                + $75 credit added to your account
              </Pgraph>
            </div>

            <div className="text-center mt-5">
              <Button
                label="Upgrade now"
                primary={true}
                type="button"
                onClick={() => {
                  onConfirm()
                  setProcessing(true)
                }}
                disabled={processing}
                loading={processing}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export { RedeemUpgradeToAnnualConfirmation }
