import Head from 'next/head'
import React, { useEffect, useState } from 'react'
import { Navbar } from '../Navbar'
import { NoScriptGTM } from '../NoScriptTagManager'
import { PromoBanner } from '../PromoBanner'
import { ScriptHotjar } from '../ScriptHotjar'
import { ScriptJustUno } from '../ScriptJustUno'
import { ScriptKlaviyoInitiate } from '../ScriptKlaviyoInitiate'
import { ScriptKlaviyoExecute } from '../ScriptKlaviyoExecute'
import { ScriptGTM } from '../ScriptTagManager/index'
import { ScriptGoogleOptimize } from '../ScriptGoogleOptimize/index'
import { useAuthStore } from '../../store'
import { useRouter } from 'next/router'
import { ScriptGladly } from '../ScriptGladly'
import { Version } from '../Version'
import { ScriptNextDoor } from '../ScriptNextDoor/index'
import { INavigation } from '../../interfaces/navigation.interfaces'
import { SearchBarMobile } from '../SearchBarMobile'
import { PromoBannerDocument } from '../../.slicemachine/prismicio'

interface IPageContainerProps {
  navigation: INavigation
  head?: React.ReactNode
  children: React.ReactNode
  seoAttributes: {
    /**
     * Title will be appended with " | Loop"
     */
    title: string
    /**
     * If no description is provided, 'A simpler way to shop for your baby: rent curated baby gear by the month.' will be used.
     */
    description?: string
    /**
     * Defaults to 'baby gear rental,toy rental,registry,newborn',
     */
    keywords?: string
    /**
     * Defaults to '/images/loop-logo.svg'
     */
    image?: string
    /**
     * Defaults to '/favicon.ico'
     */
    favIcon?: string
    /**
     * Canonical URL
     */
    canonicalUrl?: string
  }
  promoBanner?: PromoBannerDocument
}

export const PageContainer = ({
  navigation,
  head,
  children,
  seoAttributes: {
    title,
    description = 'A simpler way to shop for your baby: rent curated baby gear by the month.',
    image = `${process.env.NEXT_PUBLIC_BASE_URL}/images/loop-social-image.jpg`,
    keywords = 'baby gear rental,toy rental,registry,newborn',
    favIcon = '/favicon.ico',
    canonicalUrl,
  },
  promoBanner,
}: IPageContainerProps) => {
  const [userEmail, setUserEmail] = useState<string | undefined>()
  const { user } = useAuthStore()
  const router = useRouter()

  useEffect(() => {
    setUserEmail(user?.account?.emailAddress)
  }, [user?.account?.emailAddress])

  const fullUrl = canonicalUrl
    ? `${process.env.NEXT_PUBLIC_BASE_URL}${canonicalUrl}`
    : `${process.env.NEXT_PUBLIC_BASE_URL}${
        router.asPath === '/' ? '' : router.asPath.split('?')[0]
      }`

  return (
    <div className="min-h-screen">
      <ScriptGTM />
      <Head>
        <title>{title + ' | Loop'}</title>
        <meta name="description" content={description} />
        <meta name="tags" content={keywords} />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, user-scalable=0"
        />
        <meta property="og:title" content={title + ' | Loop'} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />
        <link rel="icon" href={favIcon} />
        <link rel="canonical" href={fullUrl} />
        {head}
      </Head>
      <ScriptHotjar />
      <ScriptKlaviyoInitiate />
      {userEmail && <ScriptKlaviyoExecute email={userEmail} />}
      <ScriptGoogleOptimize />
      <ScriptNextDoor />
      {!!!process.env.NEXT_PUBLIC_IS_TEST_RUN && <ScriptJustUno />}
      <PromoBanner promoBanner={promoBanner} />
      <Navbar menuJson={navigation} />
      <main className="flex flex-col h-full">
        <NoScriptGTM />
        <SearchBarMobile />
        {children}
      </main>
      <ScriptGladly />

      <Version />
    </div>
  )
}
