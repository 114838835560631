import Image from 'next/image'
import { ILoopingInRealLifeCardItem } from '../../config'

interface IRealLifeCardProps {
  realLifeItem: ILoopingInRealLifeCardItem
}

export const RealLifeCard = ({ realLifeItem }: IRealLifeCardProps) => {
  return (
    <div className="mx-2 flex flex-col">
      <div className="relative flex h-full">
        {realLifeItem.type === 'image' && (
          <Image
            src={realLifeItem.image}
            alt={realLifeItem.imageAlt}
            width={729}
            height={1092}
            className='max-w-full'
          />
        )}
        {realLifeItem.type === 'video' && (
          <div className="overflow-hidden relative w-full flex">
            <video
              className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[100%] min-w-[290px] h-auto flex"
              autoPlay
              loop
              muted
              playsInline
              aria-label={realLifeItem.videoAlt}
            >
              <source src={realLifeItem.video} type="video/mp4" />
              Sorry, your browser doesn&apos;t support videos.
            </video>
          </div>
        )}
      </div>
      <div className="w-full font-circular text-[13px] leading-16 mt-auto min-h-[55px] pt-2">
        {realLifeItem.text}
      </div>
    </div>
  )
}
