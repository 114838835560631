import React, { ReactNode } from 'react'

interface IPgraphProps {
  /** Style variant according to Paragraphs and Labels in Alice Design System */
  variant?:
    | 'p-28'
    | 'p-24'
    | 'p-20'
    | 'p-18'
    | 'p-16'
    | 'p-14'
    | 'p-12'
    | 'l-20'
    | 'l-18'
    | 'l-16'
    | 'l-14'
    | 'l-12'
  /** Text or components to render with this Typography */
  children: ReactNode
  /** Option additional styling classes */
  className?: string
  /** Optional onClick handler */
  onClick?: (e: React.MouseEvent) => void
  /** Optional HTML render element (defaults to div) */
  /** Optional onMouseEnter handler */
  onMouseEnter?: (e: React.MouseEvent) => void
  /** Optional onMouseLeave handler */
  onMouseLeave?: (e: React.MouseEvent) => void
  as?: 'div' | 'span' | 'i'
}

export const Pgraph = ({
  variant,
  children,
  className,
  onClick,
  as = 'div',
  ...props
}: IPgraphProps) => {
  let classes = 'font-circular text-20px leading-24' // Default = p-20

  switch (variant) {
    case 'p-28': {
      classes = 'font-circular text-28px leading-34'
      break
    }
    case 'p-24': {
      classes = 'font-circular text-24px leading-28'
      break
    }
    case 'p-18': {
      classes = 'font-circular text-18px leading-22'
      break
    }
    case 'p-16': {
      classes = 'font-circular text-16px leading-22'
      break
    }
    case 'p-14': {
      classes = 'font-circular text-14px leading-18'
      break
    }
    case 'p-12': {
      classes = 'font-circular text-12px leading-16'
      break
    }

    case 'l-20': {
      classes = 'font-circular-medium text-20px leading-29'
      break
    }
    case 'l-18': {
      classes = 'font-circular-medium text-18px leading-22'
      break
    }
    case 'l-16': {
      classes = 'font-circular-medium text-16px leading-19'
      break
    }
    case 'l-14': {
      classes = 'font-circular-medium text-14px leading-17'
      break
    }
    case 'l-12': {
      classes = 'font-circular-medium text-12px leading-14'
      break
    }
  }

  if (className) {
    classes += ` ${className}`
  }

  switch (as) {
    case 'span':
      return (
        <span className={classes} onClick={onClick} {...props}>
          {children}
        </span>
      )
    case 'i':
      return (
        <i className={classes} onClick={onClick} {...props}>
          {children}
        </i>
      )
      break
    default:
      return (
        <div className={classes} onClick={onClick} {...props}>
          {children}
        </div>
      )
      break
  }
}
