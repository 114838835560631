import Script from 'next/script'

export const ScriptKlaviyoInitiate = () => {
  return (
    <>
      <Script
        async
        // strategy="worker" - Causing recording problems?
        src={`//static.klaviyo.com/onsite/js/klaviyo.js?company_id=${process.env.NEXT_PUBLIC_KLAVIYO_ID}`}
      ></Script>
    </>
  )
}
