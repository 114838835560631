import React from 'react'
import { Button, ProductCarouselCard } from '..'
import { useRouter } from 'next/router'
import { URLS } from '../../config'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import { IProductSummary } from '../../interfaces'
import Image from 'next/image'

interface IButtonGroupProps {
  next: () => void
  previous: () => void
  carouselState: {
    currentSlide: number
    totalItems: number
  }
}

const Slides_Per_Page = 2

const ButtonGroup = ({ next, previous, carouselState }: IButtonGroupProps) => {
  // use carouselState to disable button when in the last slide, for example.
  return (
    <div className="absolute -bottom-0 right-0 mr-3 hidden lg:block">
      <span onClick={() => previous()} className="mr-4 cursor-pointer">
        <i
          className={`loop-icon-arrow-long text-24px text-black transition ease-in duration-400 rotate-180 inline-block ${
            carouselState.currentSlide === 0
              ? 'cursor-not-allowed opacity-25'
              : ''
          }`}
        ></i>
      </span>
      <span onClick={() => next()} className="cursor-pointer">
        <i
          className={`loop-icon-arrow-long text-24px text-black transition ease-in duration-400 inline-block ${
            carouselState.totalItems - carouselState.currentSlide ===
            Slides_Per_Page
              ? 'cursor-not-allowed opacity-25'
              : ''
          }`}
        ></i>
      </span>
    </div>
  )
}

interface NewArrivalsMoleculeProps {
  newArrivalsCategoryId: number
  hideTitle?: boolean
  newArrivals: IProductSummary[]
}

function NewArrivalsMolecule({
  newArrivalsCategoryId,
  newArrivals,
  hideTitle = false,
}: NewArrivalsMoleculeProps) {
  const router = useRouter()

  const goToProducts = () => {
    router.push(`${URLS.PRODUCTS.INDEX}?categories=${newArrivalsCategoryId}`)
  }

  return (
    <section className="w-full">
      <div className="max-w-6xl mx-auto py-6 md:pt-[72px] overflow-hidden relative">
        {!hideTitle && (
          <div className="font-recoleta font-medium text-28px md:text-32px lg:text-42px leading-29 md:leading-[46px] lg:leading-[52px] text-center text-neutral-6">
            Our new arrivals
          </div>
        )}

        <div className="max-w-5xl mx-auto">
          <div className="md:mt-5 pl-[20px] relative pb-5">
            {newArrivals ? (
              <Carousel
                swipeable={true}
                showDots={false}
                arrows={false}
                infinite={false}
                partialVisible={true}
                autoPlay={false}
                autoPlaySpeed={5000}
                renderButtonGroupOutside={true}
                responsive={{
                  xxsmobile: {
                    breakpoint: { max: 400, min: 0 },
                    items: 1,
                    partialVisibilityGutter: 50,
                  },
                  xsmobile: {
                    breakpoint: { max: 500, min: 400 },
                    items: 1,
                    partialVisibilityGutter: 50,
                  },
                  mobile: {
                    breakpoint: { max: 640, min: 500 },
                    items: 1,
                    partialVisibilityGutter: 100,
                  },
                  smtablet: {
                    breakpoint: { max: 740, min: 640 },
                    items: Slides_Per_Page,
                    partialVisibilityGutter: 0,
                  },
                  tablet: {
                    breakpoint: { max: 1024, min: 740 },
                    items: Slides_Per_Page,
                    partialVisibilityGutter: 50,
                  },
                  desktop: {
                    breakpoint: { max: 9000, min: 1024 },
                    items: Slides_Per_Page,
                    partialVisibilityGutter: 160,
                  },
                }}
                //@ts-ignore
                customButtonGroup={<ButtonGroup />}
              >
                {newArrivals.map((product, index) => {
                  return <ProductCarouselCard key={index} product={product} />
                })}
              </Carousel>
            ) : (
              <div></div>
            )}
          </div>
        </div>

        <div className="text-center relative -top-[20px] md:top-[0px]">
          <Button
            primary={true}
            onClick={goToProducts}
            type="button"
            label="Browse New Arrivals"
          ></Button>
        </div>
        <div className="absolute top-2 left-2 w-[41px] h-[47px] md:top-[30px] md:left-[52px] md:w-[59px] md:h-[68px] lg:top-[40px] lg:left-[70px] lg:w-[88px] lg:h-[102px]">
          <Image
            src="/images/yellow-branch.svg"
            alt="Yellow branch"
            width={88}
            height={102}
          />
        </div>
      </div>
    </section>
  )
}

export { NewArrivalsMolecule }
