import { IValueProp } from '../interfaces'

const LOOP_VALUE_PROPS: IValueProp[] = [
  {
    headline: 'More Play Dates, Less Storage Wars',
    text: 'Reclaim some extra shelf space (and mental space) when you circulate items as  your kid outgrows them.',
    imageSrc: '/images/homepage/more-play.jpeg',
    iconSrc: '/images/green-circle.svg',
  },
  {
    headline: 'More Peekaboo, Less Packaging',
    text: 'Extending the life of these items reduces waste and environmental impact. Because we’d rather see quality products in more homes and fewer landfills.',
    imageSrc: '/images/homepage/more-peekaboo.jpeg',
    iconSrc: '/images/blue-triangle.svg',
  },
  {
    headline: 'More Snoozing, Less Stressing',
    text: 'Our customer experience team obsesses over the quality of each item based on strict safety and sanitation standards.',
    imageSrc: '/images/homepage/more-sleep.jpeg',
    iconSrc: '/images/pink-pill.svg',
  },
]

const REDEEM_VALUE_PROPS: IValueProp[] = [
  {
    headline: 'Sign Up',
    text: 'Confirm where you want your Loop items to be delivered. We service the NYC and SF Bay areas',
    imageSrc: '/images/value-props/find-items.jpg',
    iconSrc: '/images/value-props/1.svg',
  },
  {
    headline: 'Find Items',
    text: 'Shop our collection of top-rated baby gear. A $75 credit will be automatically added to your account',
    imageSrc: '/images/value-props/order-favorites.jpg',
    iconSrc: '/images/value-props/2.svg',
  },
  {
    headline: 'Set Delivery',
    text: 'Schedule delivery based on your schedule and relax as our team drops off your full-assembled items',
    imageSrc: '/images/value-props/set-delivery.jpg',
    iconSrc: '/images/zerowaste.svg',
  },
]

export { LOOP_VALUE_PROPS, REDEEM_VALUE_PROPS }
