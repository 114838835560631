import { GetStaticProps, NextPage } from 'next'
import { useRouter } from 'next/router'
import { useEffect, useMemo, useState } from 'react'
import {
  CheckoutGiftData,
  CheckoutGiftPayment,
  PageContainer,
  Pgraph,
} from '../../../components'
import { URLS } from '../../../config'
import { giftCards, ICartGiftCertificate } from '../../../interfaces'
import { MainPageNavigationProps } from '../../../interfaces/navigation.interfaces'
import { WebConfService } from '../../../services'

export enum GIFT_STEP {
  LANDING = 1,
  CUSTOMER_DATA = 2,
  PAYMENT = 3,
  COMPLETED = 4,
}

export const getStaticProps: GetStaticProps<
  MainPageNavigationProps
> = async () => {
  const navigationJson = await WebConfService.getNavigationStructure()
  if (!navigationJson) throw new Error('Error fetching Navigation Structure')

  return {
    props: {
      navigation: navigationJson,
    },
    revalidate: 300,
  }
}

const GuestChecout: NextPage<MainPageNavigationProps> = ({ navigation }) => {
  const [guestStep, setGuestStep] = useState(GIFT_STEP.CUSTOMER_DATA)
  const [guestData, setGuestData] = useState<ICartGiftCertificate>()
  const [amount, setAmount] = useState<number>()
  const router = useRouter()

  useEffect(() => {
    if (!router || !router.isReady) return

    try {
      const certificateAmount = parseInt(router.query.amount as string)
      const isCustom = !!router.query.custom

      if (!certificateAmount || isNaN(certificateAmount)) {
        throw new Error('Gift amount not set')
      }

      const validAmount = giftCards.find((c) => c.amount === certificateAmount)

      if (!validAmount && !isCustom) {
        throw new Error('Invalid gift amount')
      }

      setAmount(certificateAmount)
    } catch (error) {
      router.push(URLS.GIFTS)
    }
  }, [router])

  return (
    <PageContainer
      navigation={navigation}
      seoAttributes={{
        title: 'Checkout',
      }}
    >
      <div className="flex relative self-center h-full bg-snow-white w-full max-w-2xl mt-4">
        <div className="flex relative flex-col w-full">
          {guestStep < 3 && (
            <div className="flex relative flex-col items-center my-4">
              <Pgraph variant="p-12">Step {guestStep} of 4</Pgraph>
            </div>
          )}
          {guestStep === GIFT_STEP.CUSTOMER_DATA && (
            <CheckoutGiftData
              setCheckoutStep={setGuestStep}
              setGuestData={setGuestData}
              userData={guestData}
            />
          )}
          {guestStep === GIFT_STEP.PAYMENT && (
            <CheckoutGiftPayment
              setCheckoutStep={setGuestStep}
              userData={guestData}
            />
          )}
        </div>
      </div>
    </PageContainer>
  )
}

export default GuestChecout
