import React from 'react'
import 'react-multi-carousel/lib/styles.css'
import { Button, Pgraph } from '..'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { URLS } from '../../config'

interface IValuePropProps {
  title?: string
  propsList: any[]
  className?: string
}

function ValuePropMolecule({ title, propsList, className }: IValuePropProps) {
  const router = useRouter()

  const goToItems = () => {
    router.push(URLS.PRODUCTS.INDEX)
  }

  return (
    <section className="w-full flex justify-center bg-neutral-1 pb-7 md:pb-6 lg:pb-7">
      <div
        className={[
          className,
          'pt-6 lg:pt-[72px] pb-3 px-4 w-full flex justify-center flex-col lg:max-w-[1040px]',
        ].join(' ')}
      >
        {!!title && (
          <div className="mb-5 w-full md:mt-1 text-center font-recoleta font-medium mt-3 text-neutral-6 text-28px leading-34 md:text-32px md:leading-38 lg:text-42px lg:leading-50">
            {title}
          </div>
        )}
        <div className="flex md:flex-row flex-col justify-around px-3">
          {propsList.map((prop, idx) => (
            <div
              className="flex flex-col md:w-1/3 mx-3 justify-start"
              key={idx}
            >
              <div className="relative flex justify-center mt-0">
                <img
                  alt={prop.headline}
                  src={prop.imageSrc}
                  width={265}
                  height={265}
                />
                <div className="hidden md:flex absolute w-full top-0 -translate-y-2/3 grow-1 justify-center items-center">
                  <Image
                    alt={prop.headline}
                    src={prop.iconSrc}
                    width={60}
                    height={60}
                    className="w-[60px] h-[60px]"
                  />
                </div>
              </div>
              <div className="text-center font-circular-medium text-18px text-neutral-6 mt-4 mx-3">
                {prop.headline}
              </div>
              <div className="font-circular text-16px mt-[12px] text-center text-neutral-6 mb-5 px-3">
                {prop.text}
              </div>
            </div>
          ))}
        </div>
        <div className="w-full flex justify-center">
          <Button label="Browse Items" onClick={goToItems} primary />
        </div>
      </div>
    </section>
  )
}

export { ValuePropMolecule }
