import React, { useState } from 'react'
import { Pgraph, Headline } from '..'
import { IFAQ } from '../../interfaces'

interface IFAQProps {
  faqs: IFAQ[]
  hideTitle: boolean
}

function FAQ({ faqs, hideTitle = false }: IFAQProps) {
  const [open, setOpen] = useState<boolean[]>(Array(faqs.length))

  const toggleQuestion = (index: number) => {
    const newOpen = [...open]
    newOpen[index] = !newOpen[index]
    setOpen(newOpen)
  }

  return (
    <div className="flex flex-col w-full max-w-4xl items-center">
      {!hideTitle ? (
        <Headline
          variant="recoleta-m"
          className="border-b border-b-neutral-2 w-full text-center pb-5"
        >
          FAQ
        </Headline>
      ) : (
        ''
      )}

      {faqs.map((faq, idx) => (
        <div className="border-b border-b-neutral-2 w-full" key={idx}>
          <div
            className="my-4 flex flex-row justify-between cursor-pointer w-full"
            onClick={() => toggleQuestion(idx)}
          >
            <Pgraph variant="p-14" className="w-full lg:text-16px">
              {faq.question}
            </Pgraph>
            <div>
              <i
                className={`loop-icon-caret-down-bold text-18px block ${
                  open[idx] ? 'rotate-180' : ''
                }`}
              ></i>
            </div>
          </div>

          <div className={`mb-5 text-neutral-5 text-14px ${open[idx] ? 'block' : ' hidden'}`}>
            {Array.isArray(faq.answer)
              ? faq.answer.map((p, idx) => (
                  <Pgraph key={idx} variant="p-14" className="mb-3 text-neutral-5">
                    {p}
                  </Pgraph>
                ))
              : faq.answer}
          </div>
        </div>
      ))}
    </div>
  )
}

export { FAQ }
