import {
  IProduct,
  IProductFetchResponse,
  IProductsFetchResponse,
  IProductSummary,
} from '../interfaces'
import { getRequestHeaders } from '../config'
import {
  IMetaProductResponse,
  IProductFiltersResponse,
} from '../interfaces/product.interface'
import { IQueryParamProps } from '../pages/products'
import { fetchTimeoutRetry } from '../config/utilities'

export const getProductSeoName = (
  product: IProductSummary | IProduct | { title: string }
) => {
  return product.title
    .toLowerCase()
    .replace(/[+,&,",.,~,!,|,/,#,^,*,'\'']/g, '')
    .replace(/\s/g, '-')
    .replace(/--/g, '-')
}

interface IFetchProductsArgs {
  pageParam?: number
  filterOption?: IQueryParamProps
  previousSelectedPos?: number
  itemsPerPage?: number
}

export const fetchProducts = async ({
  pageParam = 1,
  filterOption,
  previousSelectedPos,
  itemsPerPage = parseInt(process.env.NEXT_PUBLIC_LOCAL_ITEMS_PER_PAGE || '15'),
}: IFetchProductsArgs): Promise<IProductsFetchResponse | null> => {
  const MAx_LOAD_ITEMS = 200

  let appendFilterCriteria = ''
  if (filterOption?.ages?.length) {
    filterOption.ages.forEach(function (option: any, index: number) {
      appendFilterCriteria += '&ageIds=' + option
    })
  }
  if (filterOption?.categories?.length) {
    filterOption.categories.forEach(function (option: any, index: number) {
      appendFilterCriteria += '&categoryIds=' + option
    })
  }
  if (filterOption?.brands?.length) {
    filterOption.brands.forEach(function (option: any, index: number) {
      appendFilterCriteria += '&brandIds=' + option
    })
  }
  if (filterOption?.sortByParams) {
    appendFilterCriteria += '&sortBy=' + filterOption.sortByParams
  }
  if (filterOption?.place) {
    appendFilterCriteria += '&location=' + filterOption.place
  }

  let searchTerm = null
  if (filterOption?.searchTerm) {
    searchTerm = filterOption?.searchTerm
  }

  if (previousSelectedPos) {
    itemsPerPage = MAx_LOAD_ITEMS
  }

  try {
    const result = await fetchTimeoutRetry(
      `${
        process.env.NEXT_PUBLIC_PRODUCTS_URL
      }/products?currentPage=${pageParam}&perPage=${itemsPerPage}${appendFilterCriteria}&variantsAsProducts=true${
        searchTerm ? `&searchTerm=${searchTerm}` : ''
      }`,
      {
        method: 'GET',
        mode: 'cors',
        headers: getRequestHeaders(),
      }
    )
    const jsonResponse = (await result.json()) as IProductsFetchResponse
    if (jsonResponse.success) {
      return jsonResponse
    } else {
      return null
    }
  } catch (error) {
    return null
  }
}

export const fetchProductFilters = async (
  params: { showAll?: boolean; homepage?: boolean } = {
    showAll: false,
    homepage: false,
  }
): Promise<IProductFiltersResponse | null> => {
  try {
    const result = await fetchTimeoutRetry(
      `${process.env.NEXT_PUBLIC_PRODUCTS_URL}/product-filters?showAll=${!!params.showAll}&homepage=${params.homepage}`,
      {
        method: 'GET',
        mode: 'cors',
        headers: getRequestHeaders(),
      }
    )
    const jsonResponse = (await result.json()) as IProductFiltersResponse
    if (jsonResponse.success) {
      return jsonResponse
    } else {
      return null
    }
  } catch (error) {
    return null
  }
}

export const fetchProductDetails = async (
  productId: string
): Promise<IProduct | null> => {
  try {
    const result = await fetchTimeoutRetry(
      `${process.env.NEXT_PUBLIC_PRODUCTS_URL}/products/` + productId,
      {
        method: 'GET',
        mode: 'cors',
        headers: getRequestHeaders(),
      }
    )
    const jsonResponse = (await result.json()) as IProductFetchResponse
    if (jsonResponse.success) {
      return jsonResponse.product
    } else {
      return null
    }
  } catch (error) {
    return null
  }
}

export const fetchMetaProducts =
  async (): Promise<IMetaProductResponse | null> => {
    try {
      const result = await fetchTimeoutRetry(
        `${process.env.NEXT_PUBLIC_PRODUCTS_URL}/meta-products`,
        {
          method: 'GET',
          mode: 'cors',
          headers: getRequestHeaders(),
        }
      )
      if (result.ok) {
        const jsonResponse = (await result.json()) as IMetaProductResponse
        return jsonResponse
      } else {
        return null
      }
    } catch (error) {
      return null
    }
  }
