import { DateTime } from 'luxon'
import { IframeHTMLAttributes } from 'react'
import { IChildBirthday, IShopifyArticle, ISignupForm } from '../interfaces'
import { ISignupEndpointRequest } from '../services/auth_api'

export function sanitizeFields(data: any) {
  for (const prop in data) {
    if (typeof data[prop] === 'string' && data[prop]) {
      data[prop] = data[prop].trim()
    }
  }
  return data
}

export function setCookie(name: string, value: string, days: number = 30) {
  let expires = ''
  if (days) {
    var date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
    expires = '; expires=' + date.toUTCString()
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/'
}

export function getCookie(name: string) {
  const nameEQ = name + '='
  const ca = document.cookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) == ' ') c = c.substring(1, c.length)
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length)
  }
  return null
}

export function deleteCookie(name: string) {
  document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'
}

export function parseShopifyArticle(article: IShopifyArticle): IShopifyArticle {
  const body_html = article.body_html
    .replace(/<p>/g, '<p style="margin-bottom:1rem;">')
    .replace(/<h2>/g, "<h2 style='font-size:2rem;margin-bottom:1rem;'>")
    .replace(/<h3>/g, "<h2 style='font-size:1.5rem;margin-bottom:1rem;'>")
    .replace(/<a /g, '<a style="text-decoration-line:underline;" ')
    .replace(
      /<ul/g,
      '<ul style="list-style:bullet;list-style-position:inside;margin-bottom:1rem;"'
    )

  article = {
    ...article,
    body_html,
  }

  return article
}

interface IFetchTimeoutRetryOptions {
  timeoutMs: number
  maxRetries: number
  currentAttempt: number
}

export function fetchTimeoutRetry(
  input: RequestInfo,
  init: RequestInit,
  options: IFetchTimeoutRetryOptions = {
    maxRetries: 3,
    timeoutMs: 30000,
    currentAttempt: 0,
  }
): Promise<Response> {
  const { currentAttempt, maxRetries, timeoutMs } = options
  // Regular request
  const fetchReq = fetch(input, init)

  // Timeout function
  const timeout = (timeoutMs: number) =>
    new Promise((_, reject) =>
      setTimeout(
        () =>
          reject(
            new Error(`${currentAttempt} timeouts for ${input.toString()}`)
          ),
        timeoutMs
      )
    )

  // Return whatever resolves first fetchReq or timeout
  return Promise.race([fetchReq, timeout(timeoutMs)])
    .then((res) => {
      if (res instanceof Response) return res

      throw new Error('fetchTimeoutRetry error: unexcepted value')
    })
    .catch((err) => {
      if (currentAttempt < maxRetries) {
        return fetchTimeoutRetry(input, init, {
          maxRetries,
          timeoutMs,
          currentAttempt: currentAttempt + 1,
        })
      }
      throw err
    })
}

/**
 * Function to parse `ISignupForm` children birthdays to the format expected by the APIs.
 * @param birthdays List of birthdays (usually data.childBirthdays)
 * @returns string with error message or array of formatted birthdays
 */
export function formatChildBDays(
  birthdays: IChildBirthday[]
): ISignupEndpointRequest['childBirthdays'] | string {
  try {
    const fBDays: ISignupEndpointRequest['childBirthdays'] = birthdays.map(
      (bd) => {
        const name = bd.hideSection ? '' : bd.name
        if ((!name || name === 'Not Given') && !bd.birthdayDate) {
          return {
            birthdayDate: {
              day: '',
              month: '',
              year: '',
            },
            name,
          }
        }

        const luxonBDay = DateTime.fromFormat(bd.birthdayDate, 'MM/dd/yyyy')

        if (!luxonBDay.isValid) {
          throw new Error(`Birth date for ${bd.name || 'child'} is invalid.`)
        }

        const birthdayDate = bd.birthdayDate
          ? {
              day: luxonBDay.day.toString(),
              month: luxonBDay.month.toString(),
              year: luxonBDay.year.toString(),
            }
          : {
              day: '',
              month: '',
              year: '',
            }

        return {
          name,
          birthdayDate,
        }
      }
    )

    return fBDays
  } catch (error) {
    return error instanceof Error
      ? error.message
      : 'Unknow error validating birthdays'
  }
}
