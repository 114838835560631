import { IReview } from '../interfaces'

export const LOOP_REVIEWS: IReview[] = [
  {
    comment:
      'We have really loved using Loop - it\'s a great way to try out items and keep an exciting cycle of toys for our kids! The ordering and pickup service is super convenient and the Loop team are a true pleasure to communicate with"',
    author: 'Rob & Sam, parents of 1',
  },
  {
    comment:
      'Loop is a fantastic, personable service with great products. Exchange of products is always easy and they communicate well."',
    author: 'Jessica',
  },
  {
    comment:
      'Love the service! The products and team are great. I appreciate that Loop listens to feedback on product offering. Whenever I\'ve requested a last minute addition to an already scheduled drop off, Loop has always been able to accommodate. Thank you!"',
    author: 'Monica',
  },
]
