import React from 'react'
import { Headline, Pgraph } from '..'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'

function GiftingWhyParentsLoveLoop() {
  return (
    <section className="w-full bg-heavy-cream">
      <div className="md:hidden">
        <div className="mt-[40px]">
          <img
            src="/images/gifting/parents-love-loop.jpg"
            alt="Two sisters on the floor hugging"
            width="100%"
            height="100%"
          />
        </div>
        <div className="bg-heavy-cream p-[20px]">
          <Headline
            variant="recoleta-s"
            className="pb-[32px] pt-[10px] font-normal text-24px leading-29 text-center text-neutral-7"
          >
            Why parents love Loop
          </Headline>

          <Carousel
            swipeable={true}
            showDots={true}
            arrows={false}
            infinite={true}
            partialVisible={false}
            autoPlay={false}
            autoPlaySpeed={5000}
            renderButtonGroupOutside={false}
            responsive={{
              mobile: {
                breakpoint: { max: 640, min: 0 },
                items: 1,
                partialVisibilityGutter: 0,
              },
              tablet: {
                breakpoint: { max: 1024, min: 640 },
                items: 1,
                partialVisibilityGutter: 0,
              },
            }}
          >
            <div>
              <div className="w-[30px] h-[30px] m-auto">
                <img
                  src="/images/clutter.svg"
                  alt="Product Image"
                  width="100%"
                  height="100%"
                />
              </div>
              <Pgraph
                variant="p-16"
                className="mt-[12px] font-normal text-16px leading-22 text-neutral-6 text-center"
              >
                {' '}
                Reduces Clutter
              </Pgraph>
              <Pgraph
                variant="p-14"
                className="mt-[16px] font-circular font-normal text-14px leading-18 text-neutral-5 text-center"
              >
                {' '}
                Keep your closets and storage spaces free of empty boxes and
                unused items.
              </Pgraph>
            </div>
            <div className="h-[170px]">
              <div className="w-[30px] h-[30px] m-auto">
                <img
                  src="/images/sustainability.svg"
                  alt="Product Image"
                  width="100%"
                  height="100%"
                />
              </div>
              <Pgraph
                variant="p-16"
                className="mt-[12px] font-normal text-16px leading-22 text-neutral-6 text-center"
              >
                {' '}
                Sustainable
              </Pgraph>
              <Pgraph
                variant="p-14"
                className="mt-[16px] font-circular font-normal text-14px leading-18 text-neutral-5 text-center"
              >
                {' '}
                Avoid packaging waste that comes with buying new. Extend the
                life of an item by renting in our community.
              </Pgraph>
            </div>
            <div>
              <div className="w-[30px] h-[30px] m-auto">
                <img
                  src="/images/zerowaste.svg"
                  alt="Product Image"
                  width="100%"
                  height="100%"
                />
              </div>
              <Pgraph
                variant="p-16"
                className="mt-[12px] font-normal text-16px leading-22 text-neutral-6 text-center"
              >
                {' '}
                Safe & High Quality
              </Pgraph>
              <Pgraph
                variant="p-14"
                className="mt-[16px] font-circular font-normal text-14px leading-18 text-neutral-5 text-center"
              >
                {' '}
                All items are inspected and sanitized for safety, and verified
                to be in like-new condition.
              </Pgraph>
            </div>
          </Carousel>
        </div>
      </div>

      <div className="hidden md:flex mt-5 mb-6 bg-heavy-cream md:max-w-[1040px] md:mx-auto">
        <div className="md:w-1/2 py-6 pr-6">
          <img
            src="/images/gifting/parents-love-loop.jpg"
            alt="Two sisters on the floor hugging"
          />
        </div>
        <div className="px-4 pb-4 md:w-1/2 mt-4">
          <Headline
            variant="recoleta-s"
            className="p-[32px] text-24px leading-29 lg:text-32px lg:leading-38 text-center text-neutral-7"
          >
            Why parents love Loop
          </Headline>
          <div className="mb-[30px] px-7">
            <div className="w-[30px] h-[30px] m-auto">
              <img
                src="/images/clutter.svg"
                alt="Product Image"
                width="100%"
                height="100%"
              ></img>
            </div>
            <Pgraph
              variant="p-16"
              className="mt-[12px] font-normal text-16px md:leading-22 text-neutral-6 text-center"
            >
              {' '}
              Reduces Clutter
            </Pgraph>
            <Pgraph
              variant="p-14"
              className="mt-[16px] md:leading-22 text-neutral-5 text-center"
            >
              {' '}
              Keep your closets and storage spaces free of empty boxes and
              unused items.
            </Pgraph>
          </div>
          <div className="mb-[30px] px-7">
            <div className="w-[30px] h-[30px] m-auto">
              <img
                src="/images/sustainability.svg"
                alt="Product Image"
                width="100%"
                height="100%"
              />
            </div>
            <Pgraph
              variant="p-16"
              className="mt-[12px] font-normal text-16px leading-22 text-neutral-6 text-center"
            >
              {' '}
              Sustainable
            </Pgraph>
            <Pgraph
              variant="p-14"
              className="mt-[16px] md:leading-22 text-neutral-5 text-center"
            >
              {' '}
              Avoid packaging waste that comes with buying new. Extend the life
              of an item by renting in our community.
            </Pgraph>
          </div>
          <div className="mb-[30px] px-7">
            <div className="w-[30px] h-[30px] m-auto">
              <img
                src="/images/zerowaste.svg"
                alt="Product Image"
                width="100%"
                height="100%"
              />
            </div>
            <Pgraph
              variant="p-16"
              className="mt-[12px] leading-22 text-neutral-6 text-center"
            >
              {' '}
              Safe & High Quality
            </Pgraph>
            <Pgraph
              variant="p-14"
              className="mt-[16px] md:leading-22 text-neutral-5 text-center"
            >
              {' '}
              All items are inspected and sanitized for safety, and verified to
              be in like-new condition.
            </Pgraph>
          </div>
        </div>
      </div>
    </section>
  )
}

export { GiftingWhyParentsLoveLoop }
