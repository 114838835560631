import React from 'react'
import { Headline, Pgraph, Button} from '..'

function StrollerJoinWaitlist() {

  function openWaitlistPopup() {
    (window as any)._klOnsite = (window as any)._klOnsite || [];
    (window as any)._klOnsite.push(['openForm', 'WXVkf8']);
  }

  return (
    <section className='w-full bg-alice -mb-[56px] md:mb-0'>
      <div className="md:max-w-6xl mx-auto py-[100px] relative">
        <div className="flex flex-col gap-2 align-center px-4 text-center md:max-w-[500px] mx-auto">
          <Headline
            variant="recoleta-l"
            className="text-neutral-6 md:text-40px lg:text-52px"
          >
            Join our waitlist today
          </Headline>
          <Pgraph variant="p-16" className="text-neutral-7 mt-3 mb-4">
            Limited rollout coming soon, reserve your spot! Join the waitlist and a Team Member
            will contact you with more information.
          </Pgraph>
          {/* <div className="klaviyo-form-U4Rsxh"></div> */}
          <Button
            onClick={openWaitlistPopup}
            label='Join Waitlist'
            primary
            className='my-10px md:min-w-[200px] max-w-[250px] mx-auto'
            />
        </div>
      <img 
        src={'/images/Illustration-Branch.svg'}
        alt='hero-wave'
        className='hidden absolute bottom-[0px] right-0 md:block'
        />
      </div>
    </section>
  )
}

export {StrollerJoinWaitlist}
