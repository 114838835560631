export interface ICartGiftCertificate {
  name: string
  amount: number
  quantity: number
  sender: {
    name: string
    firstName: string
    lastName: string
    email: string
  }
  recipient: {
    name: string
    email: string
  }
  message: string
  printAtHome: boolean
  serviceAreaConfirmed: boolean
  validForAnnualMembership: boolean
}

export interface IGiftCard {
  amount: number
  formattedAmount: string
  title: string
  type: 'regular' | 'membership' | 'custom'
}

export const giftCards: IGiftCard[] = [
  {
    amount: 50,
    formattedAmount: '$50.00',
    title: 'Loop gift card',
    type: 'regular',
  },
  {
    amount: 75,
    formattedAmount: '$75.00',
    title: 'Loop gift card',
    type: 'regular',
  },
  {
    amount: 100,
    formattedAmount: '$100.00',
    title: 'Loop gift card',
    type: 'regular',
  },
  {
    amount: 150,
    formattedAmount: '$150.00',
    title: 'Loop gift card',
    type: 'regular',
  },
  {
    amount: 0,
    formattedAmount: 'Custom amount',
    title: 'Loop gift card',
    type: 'custom',
  },
]

export interface IGiftcardApiResponse {
  amountConsumed?: number // with decimal e.g. $100.00 = 100.00
  code: number
  success: boolean
  isAuthorized: boolean
  errors: {
    message: string
  }[]
  context: any
}
