import Script from 'next/script'

export const ScriptNextDoorConvertion = () => {
  return (
    <>
      <Script
        id="NextDoorConvertionScript"
        dangerouslySetInnerHTML={{
          __html: `
          (function(win, doc, sdk_url){
            if(win.ndp) return;
            var tr=win.ndp=function(){
            tr.handleRequest? tr.handleRequest.apply(tr, arguments):tr.queue.push(arguments);
          };
            tr.queue = [];
            var s='script';
            var new_script_section=doc.createElement(s);
            new_script_section.async=!0;
            new_script_section.src=sdk_url;
            var insert_pos=doc.getElementsByTagName(s)[0];
            insert_pos.parentNode.insertBefore(new_script_section, insert_pos);
          })(window, document, 'https://ads.nextdoor.com/public/pixel/ndp.js');
            ndp('init','${process.env.NEXT_PUBLIC_NEXT_DOOR_ID}', {})
            ndp('track','CONVERSION');`,
        }}
      ></Script>
      <noscript
        id="NextDoorConvertionNOScript"
        dangerouslySetInnerHTML={{
          __html: `<img height="1" width="1" style="display:none"
          src="https://flask.nextdoor.com/pixel?pid=${process.env.NEXT_PUBLIC_NEXT_DOOR_ID}&ev=CONVERSION&noscript=1"/>`,
        }}
      ></noscript>
    </>
  )
}
