import { getRequestHeaders } from '../config'
import { fetchTimeoutRetry } from '../config/utilities'
import { IApiPaymentMethods, ICartGiftCertificate } from '../interfaces'
import { IPaymentIntent } from '../interfaces/checkout.interfaces'

const fetchPaymentInfo = async (accessToken: string) => {
  try {
    const result = await fetchTimeoutRetry(
      `${process.env.NEXT_PUBLIC_PAYMENTS_URL}/payment-methods`,
      {
        method: 'GET',
        mode: 'cors',
        headers: getRequestHeaders(accessToken),
      }
    )
    if (result.ok) {
      const data = (await result.json()) as IApiPaymentMethods
      return data
    }
    return null
  } catch (error) {
    return null
  }
}

const setDefaultPayment = async (
  accessToken: string,
  paymentMethodId: string
) => {
  try {
    const result = await fetchTimeoutRetry(
      `${process.env.NEXT_PUBLIC_PAYMENTS_URL}/default-payment-method`,
      {
        method: 'POST',
        mode: 'cors',
        headers: getRequestHeaders(accessToken),
        body: JSON.stringify({
          paymentMethodId,
        }),
      }
    )
    return result.ok
  } catch (error) {
    return false
  }
}

const deletePaymentMethod = async (
  accessToken: string,
  paymentMethodId: string
) => {
  try {
    const result = await fetchTimeoutRetry(
      `${process.env.NEXT_PUBLIC_PAYMENTS_URL}/payment-methods/${paymentMethodId}`,
      {
        method: 'DELETE',
        mode: 'cors',
        headers: getRequestHeaders(accessToken),
      }
    )
    return result.ok
  } catch (error) {
    return false
  }
}

async function createPayIntent(
  accessToken: string
): Promise<IPaymentIntent | null> {
  try {
    const result = await fetchTimeoutRetry(
      `${process.env.NEXT_PUBLIC_PAYMENTS_URL}/setup-intents`,
      {
        method: 'POST',
        headers: getRequestHeaders(accessToken),
      },
      {
        currentAttempt: 0,
        maxRetries: 3,
        timeoutMs: 60000,
      }
    )
    if (!result.ok) throw new Error('Error setting up intent')
    const jsonResponse = await result.json()
    if (!jsonResponse) throw new Error('Token error')
    return jsonResponse as IPaymentIntent
  } catch (error) {
    console.error(error)
    return null
  }
}

interface IInitiateGuestCheckout {
  emailAddress: string
  loopRegistryId?: string
  firstName: string
  lastName: string
  lineItems?: {
    productId: number
    quantity: number
    rentalLength: number
    variantId: number
    entryId: number
  }[]
  giftCertificates?: ICartGiftCertificate[]
}

export type CardDiscount = {
  cardAmount: number
  discountPercent: number
  discountAmount: number
}

async function initiateGuestCheckout(params: IInitiateGuestCheckout) {
  try {
    const res = await fetchTimeoutRetry(
      `${process.env.NEXT_PUBLIC_GUEST_CHECKOUT_URL}/guests-checkouts/initiate`,
      {
        method: 'POST',
        headers: getRequestHeaders(),
        body: JSON.stringify(params),
      },
      {
        currentAttempt: 0,
        maxRetries: 3,
        timeoutMs: 60000,
      }
    )
    if (!res.ok) throw new Error('Error fetching')
    const json = await res.json()
    return {
      cardDiscounts: json.cardDiscounts as CardDiscount[],
      stripePaymentIntentSecret: json.paymentIntent
        .stripePaymentIntentSecret as string,
      stripePaymentIntentId: json.paymentIntent.stripePaymentIntentId as string,
    }
  } catch (error) {
    console.error(error)
    return null
  }
}

interface ICompleteGuestOrderParams {
  stripePaymentIntentId: string
  stripePaymentIntentSecret: string
}

interface IGuestCheckoutResponse {
  paymentStatus: string
  orderId: number
}

async function completeGuestOrder(params: ICompleteGuestOrderParams) {
  try {
    const res = await fetchTimeoutRetry(
      `${process.env.NEXT_PUBLIC_GUEST_CHECKOUT_URL}/guests-checkouts/complete`,
      {
        method: 'POST',
        headers: getRequestHeaders(),
        body: JSON.stringify(params),
      },
      {
        currentAttempt: 0,
        maxRetries: 3,
        timeoutMs: 60000,
      }
    )
    if (!res.ok) throw new Error('Respone error')
    const json = await res.json()
    return json as IGuestCheckoutResponse
  } catch (error) {
    return undefined
  }
}

export {
  fetchPaymentInfo,
  setDefaultPayment,
  deletePaymentMethod,
  createPayIntent,
  initiateGuestCheckout,
  completeGuestOrder,
}
