import React from 'react'
import { Headline, Pgraph } from '..'

function StrollerValueProp() {
  return (
    <section className='w-full bg-heavy-cream'>
      <div className='flex flex-col justify-evenly items-center px-5 py-6 gap-5 md:flex-row md'>
        <Headline
          variant='recoleta-m'
          className='text-neutral-6 max-w-[500px] text-center md:text-left md:max-w-[300px] ' 
          >
        Finding the right stroller is hard. Loop can help!  
        </Headline>
        <Pgraph 
          variant='p-16'
          className='text-neutral-6 text-center max-w-[600px] md:text-left'
          >
            According to one Loop family, they bought four strollers and still haven&apos;t found the right one. There are so many choices and things to consider - size, weight, accessories, materials, travel systems, etc. -  it can be overwhelming. And, with fewer retail locations, and the cost & difficulty of shipping strollers it can be very difficult to see and try them in person. <br/> <br/>
            
            <span className='text-poppy'>Wouldn&apos;t it be great to test drive strollers just like a car? Well now you can! <br/> <br/> </span>

            With Loop&apos;s NEW Stroller Showcase, you can get advice from our expert team & community, and then see, touch, and test drive three different strollers in the comfort of your own home. All strollers are like-new and LoopCare-certified. If you found the one for your family, you can buy it at a great price. Or, if you want to test more strollers or need more time, just rent one and can decide to buy later. 

        </Pgraph>

      </div>
    </section>
  )
}

export {StrollerValueProp}