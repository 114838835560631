import { ILocationFilter, IStripeCard, ISubscription } from '.'

export interface IAuthStore {
  user?: IUser
  status: 'initial' | 'authenticated' | 'guest'
  credentials?: {
    accessToken: string
    idToken: string
    refreshToken?: string
    tokenType: string
    expiresIn: number
    expiresAt: string
    scope: string
  }
  error?: string
  returnUrl?: string
  locationFilter?: ILocationFilter | null
  validLocation: boolean
  promptZip: boolean
  paymentMethods?: {
    cards?: IStripeCard[]
    defaultCard?: string
  }
  subscription?: ISubscription
  subscriptionActive: boolean
  isLoading: boolean
  zipModalOpen: boolean
  authLogout: () => void
}

export interface IUser {
  account: {
    loopCustomerId: string
    authProviderCustomerId: string
    stripeCustomerId: string
    bigCommerceCustomerId: string
    childBirthdays?: {
      name: string
      birthdayDate: {
        year: number
        month: number
        day: number
      }
    }[]
    created: number
    lastModified: number
    emailAddress: string
    phone: string
    subscription: string
  }
  customer: {
    loopCustomerId: string
    firstName: string
    middleName: string
    lastName: string
    suffix: string
    homePhone: string
    workPhone?: string
    cellPhone: string
    primaryContactNumbertype: string
    billingAddress: IAddress
    shippingAddress: IAddress
    created: number
    lastModified: number
    version: number
    referralCode?: string
  }
  memberships: [
    {
      status: string
      type: string
    }
  ]
  isAuthorized: boolean
  noAccountFound: boolean
  noAccountFoundByEmail: boolean
  newOrderCredit: number
  subscriptionCredit: number
  balance: number
  registryId?: string
  registryCredit: number
  giftCertificateCredit: number
  success: boolean
  errors: { message: string; code: string }[]
}

export interface IAddress {
  bigCommerceAddressId?: string
  streetAddress1: string
  streetAddress2?: string
  streetAddress3?: string
  city: string
  state: string
  postalCode: string
  country: string
}

export enum AUTH_ACTION {
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
  SET_TOKENS = 'SET_TOKENS',
  SET_USER = 'SET_USER',
  SET_RETURN_URL = 'SET_RETURN_URL',
  SET_LOCATION_FILTER = 'SET_LOCATION_FILTER',
  SET_VALID_LOCATION = 'SET_VALID_LOCATION',
  SET_PROMPT_ZIP = 'SET_PROMPT_ZIP',
  SET_PAYMENT_METHODS = 'SET_PAYMENT_METHODS',
  SET_MEMBERSHIP = 'SET_MEMBERSHIP',
  SET_IS_LOADING = 'SET_IS_LOADING',
  SET_ZIP_MODAL_OPEN = 'SET_ZIP_MODAL_OPEN',
  SET_CLEAR = 'SET_CLEAR',
  SET_STATUS = 'SET_STATUS',
  SET_LOGOUT = 'SET_LOGUOT',
}

export type authActions =
  | {
      type: AUTH_ACTION.LOGIN
      credentials: IAuthStore['credentials']
    }
  | {
      type: AUTH_ACTION.SET_TOKENS
      credentials: IAuthStore['credentials']
    }
  | {
      type: AUTH_ACTION.SET_USER
      user: IAuthStore['user']
    }
  | { type: AUTH_ACTION.SET_RETURN_URL; returnUrl?: string }
  | {
      type: AUTH_ACTION.SET_LOCATION_FILTER
      locationFilter?: ILocationFilter | null
    }
  | {
      type: AUTH_ACTION.SET_VALID_LOCATION
      validLocation: boolean
    }
  | {
      type: AUTH_ACTION.SET_PROMPT_ZIP
      promptZip: boolean
    }
  | {
      type: AUTH_ACTION.SET_PAYMENT_METHODS
      defaultCard?: string
      cards?: IStripeCard[]
    }
  | {
      type: AUTH_ACTION.SET_MEMBERSHIP
      subscription?: ISubscription
      subscriptionActive?: boolean
    }
  | {
      type: AUTH_ACTION.SET_IS_LOADING
      isLoading: boolean
    }
  | {
      type: AUTH_ACTION.SET_ZIP_MODAL_OPEN
      open: boolean
    }
  | {
      type: AUTH_ACTION.SET_CLEAR
      status?: IAuthStore['status']
    }
  | {
      type: AUTH_ACTION.SET_STATUS
      status: IAuthStore['status']
    }
  | {
      type: AUTH_ACTION.SET_LOGOUT
      authLogout: () => void
    }

export interface ISignupEndPointResponse {
  accessToken?: string
  idToken?: string
  tokenType?: 'Bearer'
  expiresIn?: number
  expiresAt?: string
  scope?: string
  error?: undefined
}
