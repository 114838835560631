import React, { useEffect, useState } from 'react'
import ReactTooltip from 'react-tooltip'
import NumberFormat from 'react-number-format'
import { Pgraph } from '../Pgrah'

interface IServiceFeeProps {
  delivery: number
}

function ServiceFeeModule({ delivery }: IServiceFeeProps) {
  const [screenType, setScrennType] = useState<string>()

  useEffect(() => {
    setScrennType(window.innerWidth < 768 ? 'mobile' : 'desktop')
    window.addEventListener('resize', function () {
      setScrennType(window.innerWidth < 768 ? 'mobile' : 'desktop')
    })
  }, [])

  return (
    <div className="flex justify-between items-center mb-4 relative serviceFee">
      <Pgraph variant="p-14">
        {' '}
        Delivery Fee
        {delivery > 0 && (
          <div
            className={`inline-block bottom-0 left-[80px] relative left-[4px] top-[3px] ${screenType}`}
          >
            <a
              data-for="custom-event"
              data-tip="custom show"
              data-class="loop-tooltip"
              className=""
            >
              <img
                className="max-w-full"
                alt="info"
                src="/images/info.png"
                width="17px"
                height="17px"
              />
            </a>
            <ReactTooltip
              class="w-[180px]"
              id="custom-event"
              className="loop-tooltip"
              multiline={true}
              effect="solid"
              type="light"
              place={screenType === 'mobile' ? 'top' : 'bottom'}
              event={'click'}
              globalEventOff="click"
              // offset = {{ left: -80 }}
              offset={{ left: screenType === 'mobile' ? -50 : -80 }}
              // overridePosition = {() => {return { left: 60, top: 590 };}}
            >
              <div className="py-[24px] [px-[12px] bg-snow-white">
                <i className="loop-icon-times text-[22px] cursor-pointer float-right absolute top-[0px] right-[0px] z-index-100 pt-[4px] pr-[4px]" />
                <Pgraph variant="p-14" className="text-neutral-7 w-[180px]">
                  Includes in-home delivery, item assembly, and pick-up.
                </Pgraph>
                <Pgraph
                  variant="p-14"
                  className="text-neutral-7 w-[180px] mt-3"
                >
                  Fee waived for members on orders over $100.
                </Pgraph>
              </div>
            </ReactTooltip>
          </div>
        )}
      </Pgraph>
      <Pgraph variant="p-14">
        {delivery > 0 ? (
          <NumberFormat
            value={delivery}
            displayType={'text'}
            thousandSeparator={true}
            prefix={'$'}
            decimalScale={2}
            fixedDecimalScale={true}
          />
        ) : (
          'Included'
        )}
      </Pgraph>
    </div>
  )
}

export { ServiceFeeModule }
