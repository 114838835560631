import React from 'react'

function Version() {
  const currentVersion = process.env.NEXT_PUBLIC_VERSION
  return (
    <>
      <div className="hidden">{`VERSION: ${currentVersion}`}</div>
    </>
  )
}

export { Version }
