import React from 'react'
import { Pgraph } from '../Pgrah'
import Image from 'next/image'
import { URLS } from '../../config'
import { useRouter } from 'next/router'
import { Headline } from '../Headline'
import { ICategory } from '../../interfaces/product.interface'

interface ICategoryCarouselCardProps {
  /**
   * Product Cart Type
   */
  category: ICategory

  /**
   * Optional click handler
   */
  onClick?: () => void

  /** Optional Additional classes for styling */
  className?: string
}

/**
 * Primary UI component for user interaction
 */
export const CategoryCarouselCard = ({
  category,
  className,
  ...props
}: ICategoryCarouselCardProps) => {
  const router = useRouter()

  const handleViewCategoryClick = (category: ICategory) => {
    router.push(`${URLS.PRODUCTS.INDEX}?categories=${category.id}`)
  }

  return (
    <div className="pl-2 pr-4 pt-4 pb-5 mr-2 lg:pr-1">
      <div
        className="group transition-all duration-500 bg-heavy-cream p-4 pr-0 mr-4 pb-0 hover:cursor-pointer min-h-[220px] hover:bg-snow-white hover:shadow-catbox"
        onClick={() => {
          handleViewCategoryClick(category)
        }}
      >
        <div className="flex flex-col min-h-[165px]">
          <Headline
            variant="recoleta-s"
            className="text-18px text-neutral-7 leading-snug md:text-20px lg:text-24px"
          >
            {category.name}
          </Headline>

          <Pgraph variant="l-12" className="mt-2 flex lg:mt-auto">
            Browse{' '}
            <i className={`loop-icon-arrow-short-bold text-14px ml-1`}></i>
          </Pgraph>
        </div>
        <div className="transition-all duration-500 ml-auto w-[70%] max-w-[180px] absolute -bottom-0 md:bottom-[20px] right-4 group-hover:w-[50%] group-hover:bottom-5 group-hover:right-[20%] lg:right-2">
          <img
            src={category.imageUrl || '/images/loop-red.png'}
            alt={category.name}
            width="100%"
            height="100%"
          ></img>
        </div>
      </div>
    </div>
  )
}
