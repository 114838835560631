import React, { FormEvent, useEffect, useState } from 'react'
import { CART_ACTION } from '../../interfaces'
import { PaymentApi } from '../../services'
import { useAuthStore, useCartStore, useCartDispatch } from '../../store'
import { Headline, Loading } from '../.'
import { Button } from '../Button'
import { Pgraph } from '../.'
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import { useRouter } from 'next/router'
import { URLS } from '../../config'
import getStripe from '../../services/stripe'

const stripePromise = getStripe()

interface IAddCardProps {
  onClose: () => void
}

interface IAddCardFormProps {
  processing: boolean
  setProcessing: React.Dispatch<React.SetStateAction<boolean>>
  setError: React.Dispatch<React.SetStateAction<string | undefined>>
  onClose: () => void
}

function AddCardForm({
  processing,
  setError,
  setProcessing,
  onClose,
}: IAddCardFormProps) {
  const elements = useElements()
  const stripe = useStripe()
  const { credentials } = useAuthStore()
  const [errorMsg, setErrorMsg] = useState<string>()
  const router = useRouter()

  const stripeSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    try {
      setProcessing(true)
      setErrorMsg(undefined)
      if (!stripe || !elements || !credentials?.accessToken) return

      const { error } = await stripe.confirmSetup({
        elements,
        confirmParams: {
          return_url: `${process.env.NEXT_PUBLIC_BASE_URL}/account/update-payment?addNew=true`, // This whould be replace to the api endpoint with the access token as then redirect back to the website
        },
      })
      // If the setup is successful nothing below here gets executed
      if (error) {
        setError(error.message || 'Unknown Error')
        setErrorMsg(error.message || 'Unknown Error')
        throw new Error(error.message || 'Unknown error')
      }
    } catch (error) {
      console.error(error)
    } finally {
      setProcessing(false)
    }
  }

  useEffect(() => {
    const handleEsc = (event: globalThis.KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose()
      }
    }
    window.addEventListener('keydown', handleEsc)

    return () => {
      window.removeEventListener('keydown', handleEsc)
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <form onSubmit={stripeSubmit}>
      <Headline variant="recoleta-s" className="mb-4">
        Add Card
      </Headline>
      <PaymentElement />
      {errorMsg && (
        <Pgraph variant="p-14" className="mt-3 text-center text-caution-2">
          {errorMsg}
          <br />
          <span
            className="cursor-pointer underline"
            onClick={() => router.push(URLS.CONTACT_US)}
          >
            Contact Support
          </span>
        </Pgraph>
      )}
      <div className="flex justify-around mt-4">
        <Button
          label="Cancel"
          onClick={onClose}
          disabled={processing}
          size="small"
        />
        <Button
          label="Confirm"
          primary={true}
          loading={processing}
          disabled={processing}
          type="submit"
          size="small"
        />
      </div>
    </form>
  )
}

function AddCard({ onClose }: IAddCardProps) {
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState<string>()
  const [stripeToken, setStripeToken] = useState<string>()
  const { credentials } = useAuthStore()
  const dispatch = useCartDispatch()

  useEffect(() => {
    const getToken = async () => {
      try {
        if (!credentials?.accessToken) return
        setProcessing(true)
        setError(undefined)

        const token = await PaymentApi.createPayIntent(credentials.accessToken)

        if (token) {
          setStripeToken(token.setupIntent.clientSecret)
        }
      } catch (error) {
        console.error(error)
        setStripeToken(undefined)
        setError(error instanceof Error ? error.message : 'Unknown error')
      } finally {
        setProcessing(false)
      }
    }
    getToken()
  }, [credentials, dispatch])

  return (
    <>
      <div
        className="fixed top-0 left-0 w-full h-screen z-30 opacity-40 bg-black flex justify-center items-center"
        onClick={onClose}
      ></div>
      <div className="fixed top-0 left-0 w-full h-screen z-40 flex justify-center items-center">
        <div className="w-screen h-screen flex justify-center items-center md:p-5 lg:p-7 z-50">
          <div className="bg-snow-white p-5 w-full h-full md:w-auto md:h-auto">
            {!stripeToken && (
              <div className="flex justify-center items-center flex-col h-full">
                <Loading />
                {error && (
                  <Button label="Cancel" onClick={onClose} size="small" />
                )}
              </div>
            )}

            {stripeToken && (
              <div className="flex justify-center items-center flex-col h-full">
                <Elements
                  stripe={stripePromise}
                  options={{
                    clientSecret: stripeToken,
                    appearance: {
                      theme: 'flat',
                      variables: {
                        borderRadius: '0.25rem',
                        colorDanger: '#9F0000',
                        colorBackground: '#fdfdfd',
                        fontFamily:
                          'Circular Medium, Helvetica, Arial, sans-serif',
                        fontSizeBase: '14px',
                        fontWeightNormal: '500',
                        fontLineHeight: '17px',
                      },
                      rules: {
                        '.Input': {
                          border: '1px solid black',
                        },
                      },
                    },
                  }}
                >
                  <AddCardForm
                    onClose={onClose}
                    setError={setError}
                    setProcessing={setProcessing}
                    processing={processing}
                  />
                </Elements>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export { AddCard }
