import React, { ChangeEvent, Fragment, useEffect, useState } from 'react'
import { Pgraph } from '../Pgrah'
import { InputText } from '../InputText'
import { REG_EX } from '../../config'

import {
  FormState,
  UseFieldArrayRemove,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form'
import { IChildBirthday, ISignupForm } from '../../interfaces'
import { Checkbox } from '../Checkbox'

interface IBirthdayFieldsProps {
  register: UseFormRegister<ISignupForm>
  setValue: UseFormSetValue<ISignupForm>
  watch: UseFormWatch<ISignupForm>
  onRemoveCallback: UseFieldArrayRemove
  index: number
  formValue?: IChildBirthday
  errors: FormState<ISignupForm>['errors']
}

function BirthdayFields({
  formValue,
  index,
  register,
  setValue,
  watch,
  onRemoveCallback,
  errors,
}: IBirthdayFieldsProps) {
  useEffect(() => {
    if (formValue) {
      setValue(`childBirthdays.${index}`, formValue)
    }
  }, [formValue, index, setValue])

  const hideName = watch(`childBirthdays.${index}.hideSection`)

  const updateBirthday = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(`childBirthdays.${index}.birthdayDate`, e.target.value)
  }

  return (
    <div className="relative">
      <Fragment key={index}>
        {index != 0 && (
          <div className="absolute -top-[25px] right-[0px] mt-4 inline-block align-top float-right mb-[4px]">
            <Pgraph
              variant="p-14"
              className="underline cursor-pointer"
              onClick={(e) => onRemoveCallback(index)}
            >
              Remove
            </Pgraph>
          </div>
        )}
        <div>
          <div className="mt-4 mb-[25px]">
            <Pgraph variant="l-14" className="text-neutral-6">
              Child&apos;s first name
            </Pgraph>
            {!hideName && (
              <InputText
                type="text"
                placeHolder="Child's first name"
                {...register(`childBirthdays.${index}.name`, {
                  validate: {
                    format: (v) =>
                      v === '' ||
                      REG_EX.lettersAndSpaces.test(v) ||
                      'Only alphabets are allowed',
                  },
                })}
                validationText={errors.childBirthdays?.[index]?.name?.message}
                data-cy="SIGNUP_EMAIL_CHILD_NAME"
                dataCyError="SINGUP_EMAIL_CHILD_NAME_ERROR"
              />
            )}
          </div>
          <div className="mt-[13px] mb-[24px]">
            <Checkbox
              addClassName="xs:peer-checked:after:border-b-[1px] xs:peer-checked:after:border-r-[1px]"
              checkboxText={'Expecting and not quite ready to share'}
              addTextClassName={`mt-[13px] mb-[24px] text-neutral-5`}
              {...register(`childBirthdays.${index}.hideSection`)}
            ></Checkbox>
          </div>
        </div>
        <div className="mt-2">
          <Pgraph variant="l-14" className="text-neutral-6">
            Child&apos;s birth date or due date
          </Pgraph>
          <InputText
            type="date"
            placeHolder="MM/DD/YYYY"
            {...register(`childBirthdays.${index}.birthdayDate`, {
              validate: {
                format: (v) =>
                  v === '' ||
                  REG_EX.date_mm_dd_yyyy.test(v) ||
                  'Use format MM/DD/YYYY',
              },
            })}
            validationText={
              errors.childBirthdays?.[index]?.birthdayDate?.message
            }
            data-cy="SIGNUP_EMAIL_CHILD_BDAY"
            dataCyError="SINGUP_EMAIL_CHILD_BDAY_ERROR"
            onChange={updateBirthday}
          />
          {/*<Pgraph variant="p-12" className="text-neutral-5 mt-1">*/}
          {/*  Optional*/}
          {/*</Pgraph>*/}
        </div>
      </Fragment>
    </div>
  )
}

export { BirthdayFields }
