import Image from 'next/image'
import React, { useState } from 'react'
import { Headline } from '../Headline'
import { ReviewsIO } from '../ReviewsIO'
import { LOOP_REVIEWS } from '../../config'
import { Pgraph } from '../Pgrah'
import Carousel from 'react-multi-carousel'
import { VideoModal } from '../VideoModal'

interface IButtonGroupProps {
  next: () => void
  previous: () => void
  carouselState: {
    currentSlide: number
    totalItems: number
  }
}

const ButtonGroup = ({ next, previous, carouselState }: IButtonGroupProps) => {
  // use carouselState to disable button when in the last slide, for example.
  return (
    <div className="carousel-button-group absolute -bottom-0 right-0 mr-6">
      <span onClick={() => previous()} className="mr-6 cursor-pointer">
        <i className="loop-icon-arrow-long text-24px text-black rotate-180 inline-block"></i>
      </span>
      <span onClick={() => next()} className="cursor-pointer">
        <i className="loop-icon-arrow-long text-24px text-black inline-block"></i>
      </span>
    </div>
  )
}

function VideoPreview() {
  return (
    <div className="w-full h-full block relative">
      <Image
        alt="What our community is saying"
        src="/images/homepage/video-preview.jpg"
        width={800}
        height={800}
      />
    </div>
  )
}

function SocialProofMolecule() {
  const [showVideoModal, setShowVideoModal] = useState(false)

  return (
    <section className="flex justify-center items-center flex-col mt-4">
      {/* Mobile */}
      <Headline
        variant="recoleta-m"
        className="text-neutral-7 mx-auto w-[75%] mb-7 text-center md:hidden"
      >
        What our community is saying
      </Headline>
      <div className="w-full lg:max-w-6xl bg-cold flex flex-col absolue md:hidden">
        <div
          className="mx-4 relative -top-6"
          onClick={() => setShowVideoModal(true)}
        >
          <VideoPreview />
          <div className="absolute flex w-full justify-end">
            <div className="relative top-[47px] -mr-4">
              <img
                src="/images/homepage/social-proof-hand.png"
                alt="Look at these reviews!"
                width={75}
                height={20}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-center w-full relative -top-5">
          <div className="flex justify-center">
            <ReviewsIO variant="social" />
          </div>
          <Carousel
            responsive={{
              sm: {
                items: 1,
                breakpoint: { min: 1, max: 5000 },
              },
            }}
            infinite={true}
            arrows={false}
            showDots={true}
            swipeable={true}
          >
            {LOOP_REVIEWS.map((review, idx) => (
              <div className="flex" key={idx}>
                <div
                  className="flex ml-5 -mt-2 absolute"
                  onClick={() => setShowVideoModal(true)}
                >
                  <div>
                    <img
                      src="/images/double-quotes.svg"
                      width={31}
                      height={27}
                      alt="Double quotes"
                    />
                  </div>
                </div>
                <div className="flex flex-col justify-center">
                  <div className="font-recoleta text-16px leading-22 text-neutral-5 mx-5 text-center">
                    {review.comment}
                  </div>
                  <Pgraph
                    variant="l-14"
                    className="text-center mt-4 mb-[30px] md:mb-6"
                  >
                    {review.author}
                  </Pgraph>
                </div>
              </div>
            ))}
          </Carousel>
        </div>
      </div>

      {/* Tablet and Desktop */}
      <div className="hidden md:flex flex-row bg-cold w-full lg:max-w-6xl">
        <div className="w-1/2">
          <div
            className="-mt-4 mb-4 lg:ml-[24px] cursor-pointer"
            onClick={() => setShowVideoModal(true)}
          >
            <VideoPreview />
          </div>
        </div>
        <div className="w-1/2 flex flex-col">
          <Headline
            variant="recoleta-m"
            className="text-center mt-5 mx-4 lg:hidden"
          >
            What our <br />
            community is saying
          </Headline>
          <Headline
            variant="recoleta-m"
            className="text-center mt-5 mb-4 mx-4 hidden lg:block"
          >
            What our community is saying
          </Headline>
          <div className="relative">
            <div className="absolute mt-[30px] right-0">
              <img
                src="/images/homepage/social-proof-hand.png"
                alt="Look at these reviews!"
                width={126}
                height={33}
              />
            </div>
          </div>
          <div className="flex justify-center">
            <ReviewsIO variant="social" />
            <div></div>
          </div>

          {/* Tablet carousel */}
          <div className="mb-4 lg:hidden">
            <Carousel
              responsive={{
                sm: {
                  items: 1,
                  breakpoint: { min: 1, max: 5000 },
                },
              }}
              infinite={true}
              arrows={false}
              showDots={true}
              swipeable={true}
            >
              {LOOP_REVIEWS.map((review, idx) => (
                <div className="flex" key={idx}>
                  <div className="flex ml-5 absolute">
                    <div>
                      <img
                        src="/images/double-quotes.svg"
                        width={31}
                        height={27}
                        alt="Double quotes"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col justify-center">
                    <div className="font-recoleta text-16px leading-22 text-neutral-5 mx-5 text-center">
                      {review.comment}
                    </div>
                    <Pgraph variant="l-14" className="text-center mt-4 mb-4">
                      {review.author}
                    </Pgraph>
                  </div>
                </div>
              ))}
            </Carousel>
          </div>

          {/* Desktpo carousel */}
          <div className="mb-4 mt-4 hidden lg:block">
            <Carousel
              responsive={{
                sm: {
                  items: 1,
                  breakpoint: { min: 1, max: 5000 },
                },
              }}
              infinite={true}
              arrows={false}
              showDots={false}
              swipeable={true}
              //@ts-expect-error
              customButtonGroup={<ButtonGroup />}
            >
              {LOOP_REVIEWS.map((review, idx) => (
                <div className="flex px-[48px]" key={idx}>
                  <div className="flex ml-5 absolute">
                    <div>
                      <img
                        src="/images/double-quotes.svg"
                        width={31}
                        height={27}
                        alt="Double quotes"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col justify-center max-w-[363px] mx-auto">
                    <div className="font-recoleta text-neutral-5 text-center text-20px leading-[28px]">
                      {review.comment}
                    </div>
                    <Pgraph
                      variant="l-14"
                      className="text-center mt-4 lg:mt-5 mb-4 text-18px"
                    >
                      {review.author}
                    </Pgraph>
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      </div>

      <VideoModal
        videoSrc={''}
        open={showVideoModal}
        onClose={() => setShowVideoModal(false)}
      />
    </section>
  )
}

export { SocialProofMolecule }
