import Script from 'next/script'

export const ScriptGoogleOptimize = () => {
  return (
    <>
      <Script
        // strategy="worker" - Causing recording problems?
        src={`https://www.googleoptimize.com/optimize.js?id=${process.env.NEXT_PUBLIC_GOOGLE_OPTIMIZE}`}
      ></Script>
    </>
  )
}
