import React from 'react'
import { IProductSummary } from '../../interfaces'
import { Pgraph } from '../Pgrah'
import Image from 'next/image'
import NumberFormat from 'react-number-format'
import { PRODUCT_CONFIG, PRODUCT_CUSTOM_FIELDS, URLS } from '../../config'
import { useRouter } from 'next/router'
import { getProductSeoName } from '../../services/products_api'
import { Headline } from '../Headline'

interface IRegistryProductCarouselCardProps {
  /**
   * Product Cart Type
   */
  product: IProductSummary

  /**
   * Optional click handler
   */
  onClick?: () => void

  /** Optional Additional classes for styling */
  className?: string
}

/**
 * Primary UI component for user interaction
 */
export const RegistryProductCarouselCard = ({
  product,
  className,
  ...props
}: IRegistryProductCarouselCardProps) => {
  const router = useRouter()

  const handleViewProductClick = (product: IProductSummary) => {
    router.push(
      `${URLS.PRODUCTS.INDEX}/${getProductSeoName(product)}/${
        product.bigCommerceProductId
      }?variant=${
        product.variants.length ? product.variants[0].bigCommerceVariantId : 0
      }`
    )
  }

  const customPriceLabel = product?.customFields.find(
    (f) => f.name === PRODUCT_CUSTOM_FIELDS.CUSTOM_PRICE_LABEL
  )?.value

  return (
    <div className="pr-3 py-0 md:pr-4">
      <div
        className="bg-white border border-neutral-2 rounded flex flex-col px-4 py-3 hover:cursor-pointer min-h-[282px] md:min-h-[290px] lg:min-h-[308px]"
        onClick={() => {
          handleViewProductClick(product)
        }}
      >
        <div className="mx-auto w-[95%]">
          <Image
            src={product.images[0].url || '/images/loop-red.png'}
            alt={product.images[0].description}
            width="100"
            height="100"
          ></Image>
        </div>
        <div className="mt-auto">
          <Pgraph
            variant="p-12"
            className="text-neutral-6 leading-snug md:text-14px lg:text-16px xs:leading-16 md:leading-17 lg:leading-19"
          >
            {product.title}
          </Pgraph>
          <div className="flex">
            <div className="w-4/5">
              <Pgraph
                variant="l-12"
                className=" text-neutral-7 md:text-14 lg:text-16"
                as="span"
              >
                {!!customPriceLabel ? (
                  customPriceLabel
                ) : (
                  <NumberFormat
                    value={product.price}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'$'}
                    decimalScale={0}
                    fixedDecimalScale={true}
                    suffix={` / ${PRODUCT_CONFIG.rentingPeriod}`}
                  />
                )}
              </Pgraph>
              <Pgraph
                as="span"
                variant="p-12"
                className="xs:text-[11px] line-through text-neutral-4 block lg:text-12px"
              >
                <NumberFormat
                  value={product.retailPrice}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={'Retails for $'}
                  decimalScale={0}
                />
              </Pgraph>
            </div>
            <div className="w-1/5 flex justify-end">
              <div className="bg-heavy-cream rounded-full w-[34px] h-[34px] lg:w-[30px] lg:h-[30px] text-center leading-[36px]">
                <i className="loop-icon-arrow-long text-18px text-black"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
