import React from 'react'
interface IShareSaleProps {
  amount?: number
  order?: number
}

export const ShareSaleTracking = ({
  amount = 0,
  order = 0,
}: IShareSaleProps) => {
  return (
    <img
      src={`https://shareasale.com/sale.cfm?amount=${amount}&tracking=${order}&transtype=sale&merchantID=${process.env.NEXT_PUBLIC_SHARE_SALE_ID}`}
      width="1"
      height="1"
      alt="Share Sale tracking"
    />
  )
}
