import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { AUTH_ACTION, CART_ACTION } from '../../interfaces'
import { AuthApi, CartApi, GiftcardApi } from '../../services'
import {
  useAuthDispatch,
  useAuthStore,
  useCartDispatch,
  useCartStore,
  useHooks,
} from '../../store'
import { Button } from '../Button'
import { Checkbox } from '../Checkbox'
import { InputText } from '../InputText'
import { Pgraph } from '../Pgrah'

interface IGiftcardForm {
  code: string
}

export const GiftcardForm = () => {
  const [showForm, setShowForm] = useState(false)
  const [giftcardError, setGiftcardError] = useState<string>()
  const [erroredCode, setErroredCode] = useState<string>()
  const [appliedText, setAppliedText] = useState<string>()
  const [processing, setProcessing] = useState(false)
  const authDispatch = useAuthDispatch()
  const cartDispatch = useCartDispatch()

  const { credentials } = useAuthStore()
  const { getCartData, reportBug } = useHooks()
  const { cart } = useCartStore()

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<IGiftcardForm>({
    defaultValues: {
      code: '',
    },
  })

  const codeInput = watch('code')

  const applyGiftcard = async ({ code }: IGiftcardForm) => {
    if (!credentials?.accessToken) return

    try {
      setProcessing(true)
      const result = await GiftcardApi.applyGiftCard(
        credentials.accessToken,
        code
      )

      if (!result) {
        reportBug(new Error('Error applying gift card on checkout'), { code })
        throw new Error('Error applying gift card (Api Error)')
      }

      if (!result.success) {
        setErroredCode(code)
        const resultError = result.errors.map((e) => e.message).join('. ')
        throw new Error(`Error: ${resultError}`)
      }

      const newWhoAmI = await AuthApi.whoAmI({
        accessToken: credentials.accessToken,
      })
      if (!newWhoAmI) {
        setErroredCode(code)
        throw new Error(
          'Error fetching updated user. Please refresh this page.'
        )
      }

      const checkout = await CartApi.fetchCheckout(credentials.accessToken)
      cartDispatch({
        type: CART_ACTION.SET_CREDITS,
        creditConsumption: checkout?.creditConsumption,
      })

      authDispatch({
        type: AUTH_ACTION.SET_USER,
        user: newWhoAmI,
      })

      const cartTotal = getCartData({
        cart,
      }).subTotal

      const fullCardAmount = result.amountConsumed || 0

      const usedAmount = fullCardAmount > cartTotal ? cartTotal : fullCardAmount

      setErroredCode(undefined)
      setAppliedText(
        `$${fullCardAmount.toFixed(2)} gift card added, $${usedAmount.toFixed(
          2
        )} has been applied to this order. You can check your gift card balance on your account page.`
      )
    } catch (error) {
      setGiftcardError(error instanceof Error ? error.message : 'Unknow Error')
    } finally {
      setProcessing(false)
    }
  }

  return (
    <div className="mt-3 flex flex-col">
      <Checkbox
        checkboxText="Apply a gift card"
        addTextClassName="text-14px"
        checked={showForm}
        onChange={() => setShowForm(!showForm)}
        disabled={!!appliedText}
      />
      {!appliedText && showForm && (
        <form onSubmit={handleSubmit(applyGiftcard)} className="mt-4">
          <div className="flex flex-col">
            <InputText
              type="text"
              placeHolder="Gift card number"
              {...register('code', {
                required: 'Please enter a gift card number',
                minLength: {
                  message: 'Please check the value typed',
                  value: 9,
                },
              })}
              validationText={
                errors.code?.message || erroredCode === codeInput
                  ? giftcardError
                  : undefined
              }
            />
            <Button
              type="submit"
              label="Apply"
              size="small"
              className="mt-[12px] max-w-[64px] self-end"
              disabled={processing || !(codeInput.length > 0)}
            />
          </div>
        </form>
      )}
      {appliedText && (
        <Pgraph variant="p-14" className="mt-3">
          {appliedText}
        </Pgraph>
      )}
    </div>
  )
}
