import { useRouter } from 'next/router'
import React from 'react'
import { Button, Headline, Pgraph } from '..'
import { HOW_LOOP_WORKS_STEPS, URLS } from '../../config'
import Image from 'next/image'

interface IHLWProps {
  /** Regular for all the site and homepage - 404 has a different color scheme */
  variant?: 'regular' | '404'
}

export function HLWMolecule({ variant = 'regular' }: IHLWProps) {
  const router = useRouter()

  const goToHLW = () => {
    router.push(URLS.HLW)
  }

  return (
    <section className="w-full bg-neutral-1 bg-ilustration-hand-lg bg-no-repeat bg-[length:225px_150px] lg:bg-[length:300px_250px] bg-[position:-130px_-65px] md:bg-[position:-50px_-10px] lg:bg-[position:2%_-30px]">
      <div
        className={`relative ${
          variant === '404' ? 'bg-heavy-cream' : 'max-w-7xl mx-auto'
        }`}
      >
        {/*<div className="w-full h-auto absolute bottom-0">
          <Image
            src="/public/images/homepage/HTL-Wave-Bottom-D1.svg"
            alt="Wave"
            width={1280}
            height={0}
      />
        </div>*/}
        <div>
          <div className="relative mx-auto pt-[30px] pb-[30px] md:pt-6 md:pb-[75px] -mt-3 md:mt-0">
            {variant === '404' && (
              <div className="absolute left-[20px] md:top-[90px] md:left-5 lg:top-[160px] lg:-left-[60px]">
                <Image
                  src="/images/leaf_xxs.svg"
                  alt="image"
                  width="39"
                  height="51"
                />
              </div>
            )}
            <h2 className="font-recoleta font-medium text-neutral-6 text-center mb-5 md:mb-3 text-28px md:text-32px lg:text-42pxpx relative top-[20px] md:top-0">
              How To Loop
            </h2>

            <div className="font-circular text-14px md:text-16px lg:text-18px leading-[20px] lg:leading-24 font-neutral-6 mx-4 mb-[30px] text-center md:max-w-[600px] md:mx-auto">
              Your rentals arrive sparkling clean, packaging-free, and fully
              assembled. You enjoy them, and we pick them up when you&apos;re
              done. See, magic.
            </div>

            <div className="pt-5 md:px-3 md:mx-0">
              <div className="flex flex-col gap-[50px] md:flex-row md:gap-[18px] items-center md:justify-center">
                {HOW_LOOP_WORKS_STEPS.map((step, index) => {
                  return (
                    <div
                      key={index}
                      className={`p-4 w-[250px] h-[220px] md:w-[220px] md:h-[198px] lg:w-[296px] lg:h-[220px] ${
                        variant === '404' ? 'bg-snow-white' : 'bg-heavy-cream'
                      }`}
                    >
                      <div className="w-full relative">
                        <div
                          className={`absolute left-1/2 -translate-x-1/2 ${
                            step.imageYOffest
                              ? step.imageYOffest
                              : `-top-[52px]`
                          }`}
                        >
                          <Image
                            alt={step.title}
                            src={step.image}
                            width={58 * (step.imageSizeMultiplier || 1)}
                            height={58 * (step.imageSizeMultiplier || 1)}
                          />
                        </div>
                      </div>
                      <Pgraph
                        variant="l-16"
                        className="text-neutral-6 mt-5 mb-3 lg:text-18px"
                      >
                        {step.title}
                      </Pgraph>
                      <Pgraph
                        variant="p-14"
                        className="text-neutral-6 xs:leading-18"
                      >
                        {step.summary}
                      </Pgraph>
                    </div>
                  )
                })}
              </div>
            </div>

            <div className="text-center mt-5 md:mt-[50px]">
              <Button
                primary={variant !== '404'}
                type="button"
                label="Learn More"
                onClick={goToHLW}
                className="min-w-[150px] lg:min-w-[190px]"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
