import React, { useEffect, useState } from 'react'
import { Pgraph } from '../Pgrah'
import Image from 'next/image'
import { PRODUCT_CONFIG } from '../../config'
import { useRouter } from 'next/router'
import { IRentalItem } from '../../interfaces/product.interface'
import NumberFormat from 'react-number-format'

interface IRentalCarouselCardProps {
  /**
   * Product Cart Types
   */
  item: IRentalItem

  /**
   * Optional click handler
   */
  onClick?: () => void

  /** Optional Additional classes for styling */
  className?: string
}

/**
 * Primary UI component for user interaction
 */
export const RentalCarouselCard = ({
  item,
  className,
  ...props
}: IRentalCarouselCardProps) => {
  const router = useRouter()

  const [selectedEllipseOption, setEllipseOption] = useState<string>('')

  useEffect(() => {
    setEllipseOption(window.innerWidth > 768 ? 'ellipse-box-1' : '')
    window.addEventListener('resize', function () {
      setEllipseOption(window.innerWidth > 768 ? 'ellipse-box-1' : '')
    })
  }, [])

  return (
    <div className="">
      {item?.pickupRequested && (
        <div className="absolute z-10 my-2 mx-3 py-2 px-[12px] rounded-full text-14px whitespace-nowrap cursor-pointer border font-circular-medium bg-heavy-cream border-neutral-2 right-[20px] top-[5px]">
          Returning
        </div>
      )}
      {item?.status === 'pending' && (
        <div className="absolute z-10 my-2 mx-3 py-2 px-[12px] rounded-full text-14px whitespace-nowrap cursor-pointer border font-circular-medium bg-heavy-cream border-neutral-2 right-[20px] top-[5px]">
          Arriving soon
        </div>
      )}
      <div className="bg-white border-2 border-neutral-2 rounded px-[24px] pt-[29px] mr-4 h-[300px]">
        <div className="max-w-[200px] max-h-[200px]">
          {item?.images?.length ? (
            <Image
              src={item.images[0].url || '../images/loop-red.png'}
              alt={item.images[0].description || ''}
              width="256"
              height="256"
              className="mx-auto max-h-[180px] w-auto"
            ></Image>
          ) : (
            <img
              src={'/images/loop-red.png'}
              alt="Unknown Product"
              width="100%"
            ></img>
          )}
        </div>
        <Pgraph
          variant="p-14"
          className={`font-normal text-14px leading-23 md:text-14px md:leading-18 lg:text-14px lg:leading-18 text-neutral-6 mt-[17px] font-recoleta overflow-hidden text-ellipsis ${selectedEllipseOption}`}
        >
          {item.title || 'Unknown Product'}
        </Pgraph>
        <Pgraph
          variant="l-16"
          className="font-medium text-14px leading-22 md:text-14px md:leading-17 text-neutral-6 font-circular-medium md:mt-[4px]"
        >
          <NumberFormat
            value={item.price}
            displayType={'text'}
            thousandSeparator={true}
            prefix={'$'}
            decimalScale={0}
            fixedDecimalScale={true}
            suffix={` / ${PRODUCT_CONFIG.rentingPeriod}`}
          />
        </Pgraph>
      </div>
    </div>
  )
}
