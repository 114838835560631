export const LOOP_CARE_ITEMS = [
  {
    icon: 'icon-stars.svg',
    text: 'Inspected for safety and like-new functionality by trained technicians',
  },
  {
    icon: 'icon-broom.svg',
    text: "Assembled and maintained according to the manufacturer's guidelines",
  },
  {
    icon: 'icon-spray-bottle.svg',
    text: 'Sanitized with products that are child-safe, eco-friendly, and gentle on the environment',
  },
]
