import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import { URLS } from '../../config'
import { useAuthStore } from '../../store'
import { Pgraph } from '../Pgrah'
import Link from 'next/link'
import { PromoBannerDocument } from '../../.slicemachine/prismicio'

interface PromoBannerProps {
  promoBanner?: PromoBannerDocument
}

function PromoBanner({ promoBanner }: PromoBannerProps) {
  const { subscriptionActive } = useAuthStore()
  const router = useRouter()
  const [showDetails, setShowDetails] = useState<boolean>(false)
  // const [isMobileView, setIsMobileView] = useState<boolean>(false)

  const firstLineClasses = [
    'text-neutral-7 pt-3 px-2',
    showDetails ? 'pb-1' : 'pb-3',
  ].join(' ')
  const detailsClasses = [
    'transition-all ease duration-500 px-2 text-neutral-7 mt-2',
    showDetails ? 'pb-3' : 'h-0 overflow-hidden',
  ].join(' ')

  const goToMembership = () => {
    router.push(URLS.MEMBERSHIP)
  }

  // TO-DO: INTEGRATE WITH CMS
  // Main text with or without link
  // without link, whole banner is clicable to a given page
  // with link, it opens a second line of text (terms)
  // second line has clickable link to page

  // useEffect(() => {
  //   setIsMobileView(window.innerWidth <= 640)
  //   window.addEventListener('resize', function () {
  //     setIsMobileView(window.innerWidth <= 640)
  //   })
  // }, [])

  if (!promoBanner?.data.is_active) return <></>

  if (promoBanner.data.hide_from_members && subscriptionActive) return <></>

  return (
    <div className="bg-neutral-1 text-center">
      <div className={firstLineClasses}>
        <Pgraph variant="p-14" as="span" className="cursor-pointer">
          {promoBanner.data.main_text}{' '}
          <Link href={promoBanner.data.link_destination || '/'} passHref>
            <span className="underline">{promoBanner.data.link_text}</span>
          </Link>{' '}
          {promoBanner.data.trailing_text}
        </Pgraph>
        {promoBanner.data.expandable_text && (
          <Pgraph variant="p-14" as="span">
            {' '}
            <span
              className="inline-block mr-0 underline hover:cursor-pointer"
              onClick={() => {
                setShowDetails((prevState) => !prevState)
              }}
            >
              {promoBanner.data.expandable_cta_text || 'Learn More'}
            </span>
          </Pgraph>
        )}
        {showDetails && (
          <div className={detailsClasses}>
            {promoBanner.data.expandable_link ? (
              <Pgraph variant="p-12" className="cursor-pointer">
                <Link href={promoBanner.data.expandable_link}>
                  {promoBanner.data.expandable_text}
                </Link>
              </Pgraph>
            ) : (
              <Pgraph variant="p-12">{promoBanner.data.expandable_text}</Pgraph>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export { PromoBanner }
