export const PRODUCT_CONFIG = {
  rentingPeriod: 'mo.',
  sortOptions: [
    { name: 'Recommended', key: 'topProducts', checked: true },
    { name: 'New Arrivals', key: 'newest', checked: false },
  ],
  membershipPeriod: {
    month: 'mo.',
    year: 'yr.',
  },
  currencies: {
    USD: 'USD',
  },
}

export const QUANTITY_OPTIONS = [1, 2, 3, 4, 5, 6].map((q) => ({
  value: q.toString(),
  key: q.toString(),
}))

export const GIFT_CARD_IMG_PATH = '/images/gifting/loop-gc-sq.jpg'

export const DELIVERY_OPTIONS = [
  { key: 0, value: 'Next available' },
  { key: 1, value: 'Within a month' },
  { key: 2, value: 'In a few months' },
] as const

export const DELIVERY_OPTION_NOTE_KEY = 'Delivery Option'

export enum PILL_COLORS {
  CATEGORIES = 'bg-[#f3cfdb]',
  AGES = 'bg-[#c8ecc3]',
  BRANDS = 'bg-[#FBEBA8]',
}
