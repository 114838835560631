import React, { useState, useEffect } from 'react'
import { PRODUCT_CONFIG } from '../../config/product.constants'
import { Checkbox } from '../Checkbox/index'
import { IQueryParamProps } from '../../pages/products'
import { useCartStore } from '../../store'
import { CategoryPill } from '../CategoryPill'

interface IProductsFilterProps {
  /** Optional Additional classes for styling */
  className?: string
  filterDataEvent: (
    data: IQueryParamProps,
    clearFilters: boolean,
    clearSearch: boolean
  ) => void
  queryParams: IQueryParamProps
  numberOfFilters: number
  itemCountLabel: string
}

enum FILTERS {
  AGE = 'age',
  CATEGORY = 'category',
  BRAND = 'brand',
  SORT = 'sort',
}

interface IFilterSections {
  [FILTERS.AGE]: boolean
  [FILTERS.BRAND]: boolean
  [FILTERS.CATEGORY]: boolean
  [FILTERS.SORT]: boolean
}

/**
 * Filter component for the catalog page
 */
export const ProductsFilter = ({
  filterDataEvent,
  queryParams,
  className,
  numberOfFilters,
  itemCountLabel,
}: IProductsFilterProps) => {
  const [showFilters, setShowFilters] = useState<IFilterSections>({
    age: false,
    brand: false,
    category: false,
    sort: false,
  })
  const { fullFilters: filters } = useCartStore()

  const openFilter = (toShow?: FILTERS) => {
    const open = {
      age: false,
      brand: false,
      category: false,
      sort: false,
    }
    if (!toShow || showFilters[toShow]) {
      setShowFilters(open)
      return
    }
    open[toShow] = true
    setShowFilters(open)
  }

  const filterGroupClasses = {
    collapsed:
      'transition-[height] ease-in-out duration-1000 overflow-hidden max-h-0',
    expanded:
      'transition-[height] ease-in-out duration-1000 overflow-y-auto max-h-[200px] lg:max-h-[250px]',
  }

  const clearFilters = () => {
    filterDataEvent(queryParams, true, false)
  }

  const checkAgeOption = (id: number) => {
    if (!queryParams.ages) {
      filterDataEvent(
        {
          ...queryParams,
          ages: [id],
        },
        false,
        false
      )
      return
    }
    const ages = isAgeChecked(id)
      ? [...queryParams.ages?.filter((v) => v !== id)]
      : [...queryParams.ages, id]
    if (Array.isArray(ages)) {
      filterDataEvent(
        {
          ...queryParams,
          ages,
        },
        false,
        false
      )
    }
  }

  const checkCategoryOption = (id: number) => {
    if (!queryParams.categories) {
      filterDataEvent(
        {
          ...queryParams,
          categories: [id],
        },
        false,
        false
      )
      return
    }
    const categories = isCategoryChecked(id)
      ? [...queryParams.categories?.filter((v) => v !== id)]
      : [...queryParams.categories, id]
    if (Array.isArray(categories)) {
      filterDataEvent(
        {
          ...queryParams,
          categories,
        },
        false,
        false
      )
    }
  }

  const checkABrandOption = (id: number) => {
    if (!queryParams.brands) {
      filterDataEvent(
        {
          ...queryParams,
          brands: [id],
        },
        false,
        false
      )
      return
    }
    const brands = isBrandChecked(id)
      ? [...queryParams.brands?.filter((v) => v !== id)]
      : [...queryParams.brands, id]
    if (Array.isArray(brands)) {
      filterDataEvent(
        {
          ...queryParams,
          brands: brands,
        },
        false,
        false
      )
    }
  }

  const checkSortOption = (key: string) => {
    filterDataEvent(
      {
        ...queryParams,
        sortByParams: key,
      },
      false,
      false
    )
  }

  const isAgeChecked = (id: number) => {
    if (!queryParams.ages) return false

    const checked = queryParams.ages.find((v) => v === id)
    return !!checked
  }

  const isCategoryChecked = (id: number) => {
    if (!queryParams.categories) return false

    const checked = queryParams.categories.find((v) => v === id)
    return !!checked
  }

  const isBrandChecked = (id: number) => {
    if (!queryParams.brands) return false

    const checked = queryParams.brands.find((v) => v === id)
    return !!checked
  }

  const isSortByChecked = (option: string, index: number) => {
    if (!queryParams.sortByParams) {
      return index === 0
    }

    return queryParams.sortByParams === option
  }

  return (
    <div className={['mt-4', className].join(' ')}>
      <div className="flex pb-[14px] justify-between items-center">
        <span className="text-14px leading-16 text-neutral-7 font-circular-medium lg:hidden">
          {itemCountLabel}
        </span>
        <span className="font-circular">
          Filter & Sort {numberOfFilters > 0 && `(${numberOfFilters})`}
        </span>
        {numberOfFilters > 0 && (
          <span
            className="underline lg:w-[50%] text-right cursor-pointer font-circular"
            onClick={() => clearFilters()}
          >
            Clear All
          </span>
        )}
      </div>
      {numberOfFilters > 0 && (
        <div className="text-12px">
          <div className='mb-2 font-circular'>You filtered for:</div>
          <CategoryPill
            filterDataEvent={filterDataEvent}
            queryParams={queryParams}
            stackOption="flex flex-col"
          />
        </div>
      )}
      {filters && (
        <div className="">
          <ul className="mt-18px">
            <li className="text-14px text-neutral-6 border-t border-neutral-2 py-[24px]">
              <div
                className="flex content-between hover:cursor-pointer"
                onClick={() => openFilter(FILTERS.AGE)}
              >
                <span className="sm:text-14px sm:leading-17 md:text-16px md:leading-19 font-circular">
                  Ages
                </span>{' '}
                {showFilters.age ? (
                  <i className="loop-icon-minus relative -left-[4px] text-neutral-7 text-18px ml-auto"></i>
                ) : (
                  <i className="loop-icon-plus relative left-[0px] text-neutral-7 text-18px ml-auto"></i>
                )}
              </div>

              <ul
                className={
                  showFilters.age
                    ? filterGroupClasses.expanded
                    : filterGroupClasses.collapsed
                }
              >
                {filters?.ages.map((age, index: number) => (
                  <li
                    key={age.id}
                    className={`mb-[12px] ${index === 0 ? 'mt-[14px]' : ''}`}
                  >
                    <Checkbox
                      addTextClassName={`pl-[23px] text-neutral-5 ${
                        age.checked ? 'font-circular-medium' : ''
                      }`}
                      addClassName="xs:peer-checked:after:border-b-[1px] xs:peer-checked:after:border-r-[1px]"
                      checkboxText={age.name}
                      checked={isAgeChecked(age.id)}
                      onChange={() => checkAgeOption(age.id)}
                    ></Checkbox>
                  </li>
                ))}
              </ul>
            </li>
            <li className="text-14px text-neutral-6 border-t border-neutral-2 py-[24px]">
              <div
                className="flex content-between hover:cursor-pointer"
                onClick={() => openFilter(FILTERS.CATEGORY)}
              >
                <span className="sm:text-14px sm:leading-17 md:text-16px md:leading-19 font-circular">
                  Categories
                </span>{' '}
                {showFilters.category ? (
                  <i className="loop-icon-minus relative -left-[4px] text-neutral-7 text-18px ml-auto"></i>
                ) : (
                  <i className="loop-icon-plus relative left-[0px] text-neutral-7 text-18px ml-auto"></i>
                )}
              </div>
              <ul
                className={
                  showFilters.category
                    ? filterGroupClasses.expanded
                    : filterGroupClasses.collapsed
                }
              >
                {filters?.categories.map((category, index: number) => (
                  <li
                    key={category.id}
                    className={`mb-[12px] ${index === 0 ? 'mt-[14px]' : ''}`}
                  >
                    <Checkbox
                      addTextClassName={`pl-[23px] text-neutral-5 ${
                        category.checked ? 'font-circular-medium' : ''
                      }`}
                      addClassName="xs:peer-checked:after:border-b-[1px] xs:peer-checked:after:border-r-[1px]"
                      checkboxText={category.name}
                      checked={isCategoryChecked(category.id)}
                      onChange={() => checkCategoryOption(category.id)}
                    ></Checkbox>
                  </li>
                ))}
              </ul>
            </li>
            <li className="text-14px text-neutral-6 border-t border-neutral-2 py-[24px]">
              <div
                className="flex content-between hover:cursor-pointer"
                onClick={() => openFilter(FILTERS.BRAND)}
              >
                <span className="sm:text-14px sm:leading-17 md:text-16px md:leading-19 font-circular">
                  Brands
                </span>{' '}
                {showFilters.brand ? (
                  <i className="loop-icon-minus relative -left-[4px] text-neutral-7 text-18px ml-auto"></i>
                ) : (
                  <i className="loop-icon-plus relative left-[0px] text-neutral-7 text-18px ml-auto"></i>
                )}
              </div>

              <ul
                className={
                  showFilters.brand
                    ? filterGroupClasses.expanded
                    : filterGroupClasses.collapsed
                }
              >
                {filters?.brands.map((brand, index: number) => (
                  <li
                    key={brand.id}
                    className={`mb-[12px] ${index === 0 ? 'mt-[14px]' : ''}`}
                  >
                    <Checkbox
                      addTextClassName={`pl-[23px] text-neutral-5 ${
                        brand.checked ? 'font-circular-medium' : ''
                      }`}
                      addClassName="xs:peer-checked:after:border-b-[1px] xs:peer-checked:after:border-r-[1px]"
                      checkboxText={brand.name}
                      checked={isBrandChecked(brand.id)}
                      onChange={() => checkABrandOption(brand.id)}
                    ></Checkbox>
                  </li>
                ))}
              </ul>
            </li>
            <li className="text-14px text-neutral-6 border-t border-neutral-2 pt-[24px]">
              <div
                className="flex content-between hover:cursor-pointer"
                onClick={() => openFilter(FILTERS.SORT)}
              >
                <span className="sm:text-14px sm:leading-17 md:text-16px md:leading-19 font-circular">
                  Sort By
                </span>{' '}
                {showFilters.sort ? (
                  <i className="loop-icon-minus relative -left-[4px] text-neutral-7 text-18px ml-auto"></i>
                ) : (
                  <i className="loop-icon-plus relative left-[0px] text-neutral-7 text-18px ml-auto"></i>
                )}
              </div>

              <ul
                className={
                  showFilters.sort
                    ? filterGroupClasses.expanded
                    : filterGroupClasses.collapsed
                }
              >
                {PRODUCT_CONFIG.sortOptions.map((sort, index) => (
                  <li
                    key={sort.key}
                    className={`mb-[12px] text-neutral-5 ${
                      index === 0 ? 'mt-[14px]' : ''
                    }`}
                  >
                    <input
                      className="mr-[5px]"
                      type="radio"
                      name="sort"
                      checked={isSortByChecked(sort.key, index)}
                      onChange={() => checkSortOption(sort.key)}
                    ></input>
                    <span
                      className={` ${
                        isSortByChecked(sort.key, index)
                          ? 'font-circular-medium'
                          : 'font-circular'
                      }`}
                    >
                      {' '}
                      {sort.name}
                    </span>
                  </li>
                ))}
              </ul>
            </li>
          </ul>
        </div>
      )}
    </div>
  )
}
