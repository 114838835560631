import { url } from 'inspector'
import React, { ForwardedRef } from 'react'

interface IDropDownProps {
  /**
   * is field required
   */
  required?: boolean
  /**
   * dropdown options ( type DropDownOptions )
   */
  options?: Array<IDropDownOption>
  /**
   * css class
   */
  className?: string
  /**
   * can the input be interacted
   */
  disabled?: boolean
  /**
   * Optional change handler
   */
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void
  /**
   * Optional value handler
   */
  value: string
  /**
   * Optional variant
   */
  variant?: 'outlined' | 'no-border'
}

interface IDropDownOption {
  key: string
  value: string
}

/**
 * Primary UI component for user interaction
 */
export const Dropdown = ({
  required = false,
  disabled = false,
  options = [],
  className = '',
  onChange,
  variant = 'outlined',
  value,
}: IDropDownProps) => {
  const baseClasses =
    'font-circular appearance-none pr-50px bg-no-repeat bg-dropdown bg-right-1rem-center rounded-r-full rounded-l-full text-neutral-6 font-medium md:text-16px md:leading-19 lg:text-18px lg:leading-22 text-center pl-[16px] '
  const variantClasses =
    variant === 'outlined' ? 'border border-neutral-3 py-11px px-32px ' : ''
  const dropdownClasses: string = baseClasses + variantClasses + className
  return (
    <select
      className={dropdownClasses}
      style={{
        backgroundImage: 'url("/images/chevron-down.svg")',
      }}
      disabled={disabled}
      onChange={onChange}
      required={required}
      value={value}
    >
      {options.map((option, index) => (
        <option key={index} value={option.key}>
          {option.value}
        </option>
      ))}
    </select>
  )
}
