import { LOCAL_STORAGE } from '../../config'
import {
  IAffiliateReferal,
  IAuthStore,
  ICartApiCart,
  ICartQueuedLine,
  IMetaProductResponse,
  IProduct,
  IProductFilters,
  IRecentlyViewedProduct,
  ISubscription,
} from '../../interfaces'
import { calculateSavings } from '../cart/utils'

interface ILocalStorage {
  [LOCAL_STORAGE.CREDENTIALS]: string | null
  [LOCAL_STORAGE.ZIP_CODE]: string | null
  [LOCAL_STORAGE.CART]: string | null
  [LOCAL_STORAGE.ANONYMOUS_CART]: string | null
  [LOCAL_STORAGE.WHO_AM_I]: string | null
  [LOCAL_STORAGE.SAVINGS]: string | null
  [LOCAL_STORAGE.DELIVERY_OPTIONS]: string | null
  [LOCAL_STORAGE.META_PRODUCTS]: string | null
  [LOCAL_STORAGE.SUBSCRIPTION]: string | null
  [LOCAL_STORAGE.BASIC_FILTERS]: string | null
  [LOCAL_STORAGE.FULL_FILTERS]: string | null
  [LOCAL_STORAGE.APP_VERSION]: string | null
  [LOCAL_STORAGE.AFFILIATE]: string | null
  [LOCAL_STORAGE.RECENTLY_VIEWED]: string | null
}

const setLocalStorage = ({
  key,
  value,
}: {
  key: LOCAL_STORAGE
  value: string
}) => {
  localStorage.setItem(key, value)
}

const getLocalStorage = () => {
  const storage: ILocalStorage = {
    [LOCAL_STORAGE.CREDENTIALS]: localStorage.getItem(
      LOCAL_STORAGE.CREDENTIALS
    ),
    [LOCAL_STORAGE.ZIP_CODE]: localStorage.getItem(LOCAL_STORAGE.ZIP_CODE),
    [LOCAL_STORAGE.CART]: localStorage.getItem(LOCAL_STORAGE.CART),
    [LOCAL_STORAGE.ANONYMOUS_CART]: localStorage.getItem(
      LOCAL_STORAGE.ANONYMOUS_CART
    ),
    [LOCAL_STORAGE.WHO_AM_I]: localStorage.getItem(LOCAL_STORAGE.WHO_AM_I),
    [LOCAL_STORAGE.SAVINGS]: localStorage.getItem(LOCAL_STORAGE.SAVINGS),
    [LOCAL_STORAGE.DELIVERY_OPTIONS]: localStorage.getItem(
      LOCAL_STORAGE.DELIVERY_OPTIONS
    ),
    [LOCAL_STORAGE.META_PRODUCTS]: localStorage.getItem(
      LOCAL_STORAGE.META_PRODUCTS
    ),
    [LOCAL_STORAGE.SUBSCRIPTION]: localStorage.getItem(
      LOCAL_STORAGE.SUBSCRIPTION
    ),
    [LOCAL_STORAGE.BASIC_FILTERS]: localStorage.getItem(
      LOCAL_STORAGE.BASIC_FILTERS
    ),
    [LOCAL_STORAGE.FULL_FILTERS]: localStorage.getItem(
      LOCAL_STORAGE.FULL_FILTERS
    ),
    [LOCAL_STORAGE.APP_VERSION]: localStorage.getItem(
      LOCAL_STORAGE.APP_VERSION
    ),
    [LOCAL_STORAGE.AFFILIATE]: localStorage.getItem(LOCAL_STORAGE.AFFILIATE),
    [LOCAL_STORAGE.RECENTLY_VIEWED]: localStorage.getItem(
      LOCAL_STORAGE.RECENTLY_VIEWED
    ),
  }
  return storage
}

const removeLocalStorage = (keys: LOCAL_STORAGE[]) => {
  for (const key of keys) {
    localStorage.removeItem(key)
  }
}

const setLocalCredentials = (
  credentials: IAuthStore['credentials'],
  whoAmI: IAuthStore['user']
) => {
  const value = Array.from(Buffer.from(JSON.stringify(credentials))).join(':')
  const valueWai = Array.from(Buffer.from(JSON.stringify(whoAmI))).join(':')
  setLocalStorage({
    key: LOCAL_STORAGE.CREDENTIALS,
    value,
  })
  setLocalStorage({
    key: LOCAL_STORAGE.WHO_AM_I,
    value: valueWai,
  })
}

const setLocalObject = (key: LOCAL_STORAGE, value: any) => {
  setLocalStorage({
    key,
    value: JSON.stringify(value),
  })
}

const getLocalObject = (key: LOCAL_STORAGE): any => {
  const localString: string = localStorage.getItem(key) || ''
  let localObj = null
  try {
    localObj = JSON.parse(localString)
  } catch (e) {}

  return localObj
}

const getLocalCredentials = () => {
  const { loop_act, loop_wai } = getLocalStorage()
  if (!loop_act) return undefined
  if (!loop_wai) return undefined

  try {
    const decoded = JSON.parse(
      Buffer.from(loop_act.split(':').map((v) => parseInt(v))).toString()
    )
    const whoAmI = JSON.parse(
      Buffer.from(loop_wai?.split(':').map((v) => parseInt(v))).toString()
    )
    return { account: decoded, whoAmI } as {
      account: IAuthStore['credentials']
      whoAmI: IAuthStore['user']
    }
  } catch (error) {
    return undefined
  }
}

const setLocalCart = (cart: ICartApiCart) => {
  const value = Array.from(Buffer.from(JSON.stringify(cart))).join(':')
  setLocalStorage({
    key: LOCAL_STORAGE.CART,
    value,
  })
}

const setLocalACart = (lines: ICartQueuedLine[]) => {
  const value = Array.from(Buffer.from(JSON.stringify(lines))).join(':')
  setLocalStorage({
    key: LOCAL_STORAGE.ANONYMOUS_CART,
    value,
  })
}

const getLocalCart = () => {
  const { loop_cart } = getLocalStorage()

  if (!loop_cart) return undefined

  try {
    const decoded = JSON.parse(
      Buffer.from(loop_cart.split(':').map((v) => parseInt(v))).toString()
    )
    return decoded as ICartApiCart
  } catch (error) {
    console.error(error)
    return undefined
  }
}

const getLocalACart = () => {
  const { loop_a_cart } = getLocalStorage()

  if (!loop_a_cart) return undefined

  try {
    const decoded = JSON.parse(
      Buffer.from(loop_a_cart.split(':').map((v) => parseInt(v))).toString()
    )
    return decoded as ICartQueuedLine[]
  } catch (error) {
    console.error(error)
    return undefined
  }
}

const setLocalSavings = (product: IProduct) => {
  const { loop_savings } = getLocalStorage()
  if (loop_savings) {
    var currSavings = JSON.parse(loop_savings)
  }
  const newSavings = {
    ...currSavings,
    [product.bigCommerceProductId]: calculateSavings(product),
  }
  setLocalStorage({
    key: LOCAL_STORAGE.SAVINGS,
    value: JSON.stringify(newSavings),
  })
}

const getLocalSavings = (productId: number) => {
  try {
    const { loop_savings } = getLocalStorage()
    if (!loop_savings) return 0
    const savings = JSON.parse(loop_savings)
    return savings[productId] || (0 as number)
  } catch (error) {
    return 0
  }
}

const setLocalMetaProducts = (membershipProducts: IMetaProductResponse) => {
  setLocalStorage({
    key: LOCAL_STORAGE.META_PRODUCTS,
    value: JSON.stringify(membershipProducts),
  })
}

const getLocalMetaProducts = (): IMetaProductResponse | undefined => {
  try {
    const { loop_meta_products } = getLocalStorage()
    if (!loop_meta_products) throw new Error('No meta products found')
    return JSON.parse(loop_meta_products) as IMetaProductResponse
  } catch (error) {
    return undefined
  }
}

interface LocalSubscription {
  subscription?: ISubscription
  subscriptionActive: boolean
}

const setLocalSubscription = (data: LocalSubscription) => {
  setLocalStorage({
    key: LOCAL_STORAGE.SUBSCRIPTION,
    value: JSON.stringify(data),
  })
}

const getLocalSubscription = (): LocalSubscription | undefined => {
  try {
    const { loop_subscription } = getLocalStorage()
    if (!loop_subscription) throw new Error('No meta products found')
    return JSON.parse(loop_subscription) as LocalSubscription
  } catch (error) {
    return undefined
  }
}

const setLocalBasicFilters = (filters: IProductFilters) => {
  setLocalStorage({
    key: LOCAL_STORAGE.BASIC_FILTERS,
    value: JSON.stringify(filters),
  })
}

const getLocalBasicFilters = (): IProductFilters | null => {
  const { loop_filters_basic } = getLocalStorage()
  try {
    if (!loop_filters_basic) return null
    return JSON.parse(loop_filters_basic) as IProductFilters
  } catch (error) {
    return null
  }
}

const setLocalFullFilters = (filters: IProductFilters) => {
  setLocalStorage({
    key: LOCAL_STORAGE.FULL_FILTERS,
    value: JSON.stringify(filters),
  })
}

const getLocalFullFilters = (): IProductFilters | null => {
  const { loop_filters_full } = getLocalStorage()
  try {
    if (!loop_filters_full) return null
    return JSON.parse(loop_filters_full) as IProductFilters
  } catch (error) {
    return null
  }
}

const setLocalAppVersion = (version: string) => {
  setLocalStorage({
    key: LOCAL_STORAGE.APP_VERSION,
    value: version,
  })
}

const getLocalAppVersion = (): string => {
  return getLocalStorage()[LOCAL_STORAGE.APP_VERSION] || ''
}

const setLocalAffiliate = (affiliate: IAffiliateReferal) => {
  setLocalStorage({
    key: LOCAL_STORAGE.AFFILIATE,
    value: JSON.stringify(affiliate),
  })
}

const getLocalAffiliate = (): IAffiliateReferal | null => {
  const store = getLocalStorage()[LOCAL_STORAGE.AFFILIATE]

  if (!store) return null

  return JSON.parse(store)
}

const setRecentlyViewedProducts = (products: IRecentlyViewedProduct[]) => {
  setLocalStorage({
    key: LOCAL_STORAGE.RECENTLY_VIEWED,
    value: JSON.stringify(products),
  })
}

const getRecentlyViewedProducts = () => {
  try {
    const json = getLocalStorage()[LOCAL_STORAGE.RECENTLY_VIEWED]
    if (!json) return []

    return JSON.parse(json) as IRecentlyViewedProduct[]
  } catch (err) {
    return []
  }
}

export {
  setLocalStorage,
  getLocalStorage,
  removeLocalStorage,
  setLocalCredentials,
  getLocalCredentials,
  setLocalObject,
  getLocalObject,
  setLocalCart,
  getLocalCart,
  setLocalSavings,
  getLocalSavings,
  setLocalACart,
  getLocalACart,
  setLocalMetaProducts,
  getLocalMetaProducts,
  getLocalSubscription,
  setLocalSubscription,
  setLocalBasicFilters,
  getLocalBasicFilters,
  setLocalFullFilters,
  getLocalFullFilters,
  setLocalAppVersion,
  getLocalAppVersion,
  setLocalAffiliate,
  getLocalAffiliate,
  setRecentlyViewedProducts,
  getRecentlyViewedProducts,
}
