import { useRouter } from 'next/router'
import React from 'react'
import { URLS } from '../../config'
import { IFAQ } from '../../interfaces'
import { Button } from '../Button'
import { FAQ } from '../FAQ'
import { Headline } from '../Headline'
import { Pgraph } from '../Pgrah'

interface IFAQProps {
  faqs: IFAQ[]
}

function FAQFull({ faqs }: IFAQProps) {
  const router = useRouter()
  const goToFAQ = () => {
    router.push(URLS.FAQ)
  }

  return (
    <section className="w-full">
      <div className="max-w-5xl mx-auto py-6 md:pb-7">
        <div className="flex flex-col md:flex-row md:items-center transition-all">
          <div className="md:w-2/4 md:pl-4">
            <Headline
              variant="recoleta-s"
              className="text-center md:text-left md:text-24px lg:text-28"
            >
              FAQs
            </Headline>
            <Pgraph
              variant="p-14"
              className="text-center mt-2 mb-5 md:text-left lg:text-16px"
            >
              You&apos;ve got questions. We&apos;ve got answers
            </Pgraph>

            <div className="hidden mt-5 text-center md:block md:text-left">
              <Button
                label="Read more FAQs"
                primary
                onClick={() => goToFAQ()}
                className="mx-auto md:ml-0"
              ></Button>
            </div>
          </div>

          <div className="px-4 md:w-3/5">
            {faqs && <FAQ faqs={faqs} hideTitle={true} />}

            <div className="mt-5 text-center md:hidden">
              <Button
                label="Read more FAQs"
                primary
                onClick={() => goToFAQ()}
                className="mx-auto"
              ></Button>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export { FAQFull }
