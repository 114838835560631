import '../styles/loop-icons.css'
import '../styles/globals.css'
import type { AppProps } from 'next/app'
import {
  AuthProvider,
  HooksProvider,
  getLocalAffiliate,
  setLocalAffiliate,
} from '../store'
import { CartProvider } from '../store/cart'
import { Cart } from '../components'
import { QueryClient, QueryClientProvider } from 'react-query'
import { useEffect, useState } from 'react'
import { QueryParamProvider } from 'use-query-params'
import { NextAdapter } from 'next-query-params'
import { PrismicProvider } from '@prismicio/react'
import Link from 'next/link'
import { PrismicPreview } from '@prismicio/next'
import { PRISMIC_REPO } from '../services/prismic'

import { useRouter } from 'next/router'
import { DateTime } from 'luxon'
import localFont from 'next/font/local'

if (process.env.NODE_ENV === 'development') {
  console.info('Dev instance. Ignoring Bugsnag')
} else {
  console.info('PROD instance. Ignoring Bugsnag temporary')
  /*Bugsnag.start({
    apiKey: process.env.NEXT_PUBLIC_BUGSNAG_KEY,
    appVersion: process.env.NEXT_PUBLIC_VERSION,
    plugins: [new BugsnagPluginReact()],
    releaseStage:
      process.env.NEXT_PUBLIC_ENV === 'prod' ? 'Production' : 'Development',
  })*/
}

const circular = localFont({
  src: '../public/fonts/circular.woff2',
  display: 'swap',
  weight: 'normal',
  style: 'normal',
  variable: '--font-circular',
})

const circularMedium = localFont({
  src: '../public/fonts/circular-medium.woff2',
  display: 'swap',
  weight: 'normal',
  style: 'normal',
  variable: '--font-circular-medium',
})

const recoleta = localFont({
  src: [
    {
      path: '../public/fonts/Recoleta-Regular.woff2',
      weight: 'normal',
      style: 'normal',
    },
    {
      path: '../public/fonts/Recoleta-Thin.woff2',
      weight: '100',
      style: 'normal',
    },
    {
      path: '../public/fonts/Recoleta-Light.woff2',
      weight: '300',
      style: 'normal',
    },
    {
      path: '../public/fonts/Recoleta-Medium.woff2',
      weight: '500',
      style: 'normal',
    },
    {
      path: '../public/fonts/Recoleta-SemiBold.woff2',
      weight: '600',
      style: 'normal',
    },
    {
      path: '../public/fonts/Recoleta-Bold.woff2',
      weight: 'bold',
      style: 'normal',
    },
    {
      path: '../public/fonts/Recoleta-Black.woff2',
      weight: '900',
      style: 'normal',
    },
  ],
  display: 'swap',
  variable: '--font-recoleta',
})

function MyApp({ Component, pageProps }: AppProps) {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
          },
        },
      })
  )

  const router = useRouter()

  useEffect(() => {
    if (window.Gladly?.navigate) {
      window.Gladly.navigate()
    }
  }, [router.asPath])

  useEffect(() => {
    if (!router.isReady) return

    if (getLocalAffiliate()) return

    if (router.query.transaction || router.query.transaction_id) {
      setLocalAffiliate({
        transactionId:
          (router.query.transaction as string) ||
          (router.query.transaction_id as string),
        createdAt: DateTime.now().toISO(),
      })
    }
  }, [router])

  return (
    <>
      <style jsx global>
        {`
          :root {
            --font-circular: ${circular.style.fontFamily};
            --font-circular-medium: ${circularMedium.style.fontFamily};
            --font-recoleta: ${recoleta.style.fontFamily};
          }
        `}
      </style>
      <PrismicProvider
        internalLinkComponent={({ href, ...props }) => (
          <Link href={href}>
            <a {...props} />
          </Link>
        )}
      >
        <QueryParamProvider adapter={NextAdapter}>
          <AuthProvider>
            <CartProvider>
              <HooksProvider>
                <QueryClientProvider client={queryClient}>
                  <PrismicPreview repositoryName={PRISMIC_REPO}>
                    <Component {...pageProps} />
                  </PrismicPreview>
                </QueryClientProvider>
                <Cart />
              </HooksProvider>
            </CartProvider>
          </AuthProvider>
        </QueryParamProvider>
      </PrismicProvider>
    </>
  )
}

export default MyApp
