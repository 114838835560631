import { IHLWStep } from '../interfaces/hlw.interface'
export const HOW_LOOP_WORKS_STEPS: IHLWStep[] = [
  {
    title: 'You Pick It',
    summary:
      'Browse our curated gear and toys by age, stage, and use. We can even help you choose.',
    fullText:
      'Choose from our large selection of rentable baby gear and toys. Reserve items for your family within minutes.',
    image: '/images/hlw-1.svg',
    processImage: '/images/hlw/hlw-step1.jpg',
    imageSizeMultiplier: 1,
    learnMore: null,
  },
  {
    title: 'We Drop It',
    summary:
      'You say when. We hand-deliver your picks fully assembled…We repeat, fully assembled.',
    fullText:
      "Tell us when you'd like your first visit from our delivery team. We'll set up everything and leave you with no leftover packaging or mess.",
    image: '/images/hlw-2.svg',
    processImage: '/images/hlw/hlw-step2.jpg',
    imageSizeMultiplier: 2.2,
    imageYOffest: '-top-[70px]',
    learnMore: null,
  },
  {
    title: 'We Pick It Up',
    summary:
      "Whenever you're done, we come and take it away. No more storing —poof, it's gone.",
    fullText:
      "We know that what you need can change quickly - we'll pick up or swap your items exactly when you want.",
    image: '/images/hlw-3.svg',
    processImage: '/images/hlw/hlw-step3.jpg',
    imageSizeMultiplier: 1.5,
    imageYOffest: '-top-[63px]',
    learnMore: null,
  },
]

export const HOW_REGISTRY_WORKS_STEPS: IHLWStep[] = [
  {
    title: 'Add to your registry',
    summary:
      'Simply select “Add to Registry” on a product detail page - items will add to your Babylist registry, too!',
    fullText: '',
    image: '',
    learnMore: null,
  },
  {
    title: 'Share with friends & family',
    summary:
      'It’s easy for them to find your registry by searching on Babylist or you can share your Loop registry link.',
    fullText: '',
    image: '',
    learnMore: null,
  },
  {
    title: 'Schedule delivery',
    summary:
      'You’re in control of when items arrive. Once you’re ready, our expert delivery team will set up your items in-home.',
    fullText: '',
    image: '',
    learnMore: null,
  },
  {
    title: 'Swap or return anytime',
    summary:
      'When you’re ready for something new, we’ll come drop off and pick up on your schedule.',

    fullText: '',
    image: '',
    learnMore: null,
  },
]
