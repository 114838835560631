import { authActions, IAuthStore, AUTH_ACTION } from '../../interfaces'
import { initialAuthState } from './../auth/index'

export const authReducer = (
  state: IAuthStore,
  action: authActions
): IAuthStore => {
  switch (action.type) {
    case AUTH_ACTION.LOGIN: {
      return {
        ...state,
        credentials: action.credentials,
      }
    }
    case AUTH_ACTION.SET_TOKENS: {
      return {
        ...state,
        credentials: action.credentials,
      }
    }
    case AUTH_ACTION.SET_USER: {
      return {
        ...state,
        user: action.user,
      }
    }
    case AUTH_ACTION.SET_RETURN_URL: {
      return {
        ...state,
        returnUrl: action.returnUrl,
      }
    }
    case AUTH_ACTION.SET_LOCATION_FILTER: {
      return {
        ...state,
        locationFilter: action.locationFilter,
      }
    }
    case AUTH_ACTION.SET_VALID_LOCATION: {
      return {
        ...state,
        validLocation: action.validLocation,
      }
    }
    case AUTH_ACTION.SET_PROMPT_ZIP: {
      return {
        ...state,
        promptZip: action.promptZip,
      }
    }
    case AUTH_ACTION.SET_PAYMENT_METHODS: {
      return {
        ...state,
        paymentMethods: {
          cards: action.cards,
          defaultCard: action.defaultCard,
        },
      }
    }
    case AUTH_ACTION.SET_MEMBERSHIP: {
      return {
        ...state,
        subscription: action.subscription,
        subscriptionActive: action.subscriptionActive ?? false,
      }
    }
    case AUTH_ACTION.SET_IS_LOADING: {
      return {
        ...state,
        isLoading: action.isLoading,
      }
    }
    case AUTH_ACTION.SET_ZIP_MODAL_OPEN: {
      return {
        ...state,
        zipModalOpen: action.open,
      }
    }
    case AUTH_ACTION.SET_CLEAR: {
      return {
        ...initialAuthState,
        status: action.status || initialAuthState.status,
      }
    }
    case AUTH_ACTION.SET_STATUS: {
      return {
        ...state,
        status: action.status,
      }
    }
    case AUTH_ACTION.SET_LOGOUT: {
      return {
        ...state,
        authLogout: action.authLogout,
      }
    }
    default:
      return state
  }
}
