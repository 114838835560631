import Script from 'next/script'

interface IBabyListAttributes {
  title?: string
  price?: number
  url?: string
  image?: string
  dynamicVal: number
}

export const ScriptBabyList = ({
  title,
  price,
  url,
  image,
  dynamicVal,
  ...props
}: IBabyListAttributes) => {
  return (
    <Script
      id={`BabyListScript_${dynamicVal}`}
      // strategy="worker" - Causing recording problems?
      dangerouslySetInnerHTML={{
        __html: `
                        (function () {
                            let iframeLoaded = false;
                            let routeUrl = "https://www.babylist.com/bookmarklet/?url=${url}&title=${title}&price=$${price}&upc=A&source=button&category_string=general&imgs[]=${image}";
                            if (!document.getElementById('babylist_iframe')) {
                                iframeLoaded = true;
                                var BOOKMARK_REDIRECT_STATUS = 'on' != 'off';
                                var BL_URL = 'https://www.babylist.com/add_reg_item';
                                var loc = window.location;
                                var doc = window.document;
                                var source = 'button';
                                var scriptElem = doc.createElement('script');

                                function redirect() {
                                    loc.href =
                                        BL_URL +
                                        '?u=' +
                                        encodeURIComponent(loc) +
                                        '&t=' +
                                        encodeURIComponent(doc.title)
                                }

                                function init() {
                                    if (
                                        doc['readyState'] &&
                                        doc['readyState'] != 'complete' &&
                                        doc['readyState'] != 'interactive'
                                    ) {
                                        return setTimeout(init, 200)
                                    }
                                    scriptElem['setAttribute']('charset', 'UTF-8')
                                    scriptElem['setAttribute'](
                                        'src',
                                        BL_URL +
                                        '.js?loc=' +
                                        encodeURIComponent(loc) +
                                        '&source=' +
                                        encodeURIComponent(source) +
                                        '&format=js&price=300'
                                    )
                                    if (BOOKMARK_REDIRECT_STATUS) {
                                        scriptElem.addEventListener('error', redirect)
                                    }
                                    doc.body.appendChild(scriptElem)
                                }

                                if (typeof scriptElem != 'object') {
                                    redirect();
                                } else {
                                    init();
                                    const setSrc = setInterval(function () {
                                        var iframeEle = document.getElementById('babylist_iframe');
                                        if (iframeEle) {
                                            iframeEle.setAttribute('src', routeUrl);
                                            clearInterval(setSrc)
                                        }
                                    }, 100);
                                }

                                // const iFrameCheckInterval = setInterval(function () {
                                //     if (
                                //         iframeLoaded &&
                                //         !document.getElementById('babylist_iframe')
                                //     ) {
                                //         clearInterval(iFrameCheckInterval);
                                //         localStorage.setItem('iFrameClosed', 'true')
                                //     }
                                // }, 2000)
                            }
                        })();`,
      }}
    ></Script>
  )
}
