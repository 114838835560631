import React from 'react'
import { Headline, Pgraph } from '..'

function PHLServingArea() {
  return (
    <section className="bg-neutral-1 w-full py-6">
      <div className="flex flex-col content-center justify-center relative md:flex-row lg:max-w-[1074px] lg:mx-auto">
        <div className="md:w-1/2">
          <img
            src="/images/phl/philly-map.jpg"
            alt="Loop Philadelphia Serving Area"
          />
        </div>
        <div className="md:w-1/2">
          <div className="text-center mx-auto px-4 lg:pt-7 lg:max-w-[450px]">
            <Pgraph variant="l-12" className="uppercase mb-3 mt-4">
              SERVING ALL OF GREATER PHILLY
            </Pgraph>
            <Headline
              variant="recoleta-xs"
              className="text-neutral-6 text-center mx-auto md:text-left md:text-32px md:leading-38 px-3"
            >
              Philly, it&apos;s time to stop buying and start Looping
            </Headline>

            <Pgraph
              variant="p-14"
              className="text-neutral-6 text-center md:text-left mt-4 xs:leading-22 px-4"
            >
              Loop will be available in all major Philadelphia neighborhoods
              from Old City to Chestnut Hill, and all those in between.
            </Pgraph>
          </div>
        </div>
      </div>
    </section>
  )
}

export { PHLServingArea }
