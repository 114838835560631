import { RealLifeCard } from './realLifeCard'
import { URLS, lirfItems } from '../../config'
import { useRouter } from 'next/router'
import { IProductFilters } from '../../interfaces'
import { useMediaQuery } from 'react-responsive'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import { Button } from '../Button'

interface ILoopingInRealLifeProps {
  filters: IProductFilters
}

export const LoopingInRealLifeMolecule = ({
  filters,
}: ILoopingInRealLifeProps) => {
  const router = useRouter()

  const isMobile = useMediaQuery({
    maxWidth: 768,
  })

  const goToShopCustomerFavorites = () => {
    if (!filters) return
    router.push(
      `${URLS.PRODUCTS.INDEX}?categories=${filters.topProductsCategoryId}`
    )
  }

  return (
    <section className="w-full bg-neutral-1 pt-7">
      <div className="font-recoleta font-medium text-28px leading-29 md:text-32px md:leading-38px lg:text-42px lg:leading-38 text-neutral-6 text-center">
        Looping in Real Life
      </div>
      <div className="mt-3 font-circular text-14px md:text-16px lg:text-18px leading-18px md:leading-22px text-center">
        Real people. Real rentals. Really really cute kids.
      </div>

      <div className="w-full mx-auto">
        <div className="mt-[20px] ml-4 md:mt-5 relative pb-4 md:pb-5 lg:pb-6">
          <Carousel
            swipeable={true}
            autoPlay={false}
            partialVisbile={true}
            renderButtonGroupOutside={true}
            itemClass="flex"
            arrows={isMobile}
            responsive={{
              xxsmobile: {
                breakpoint: { max: 450, min: 0 },
                items: 1,
                partialVisibilityGutter: 80,
              },
              mobile: {
                breakpoint: { max: 640, min: 450 },
                items: 1,
                partialVisibilityGutter: 200,
              },
              tablet: {
                breakpoint: { max: 1024, min: 640 },
                items: 3,
                partialVisibilityGutter: -50,
              },
              smdesktop: {
                breakpoint: { max: 1400, min: 1024 },
                items: 4,
                partialVisibilityGutter: 10,
              },
              desktop: {
                breakpoint: { max: 1600, min: 1400 },
                items: 5,
                partialVisibilityGutter: 0,
              },
              largeDesktop: {
                breakpoint: { max: 9000, min: 1600 },
                items: 6,
                partialVisibilityGutter: -20,
              },
            }}
            //@ts-ignore
            customButtonGroup={<ButtonGroup />}
          >
            {lirfItems.map((realLifeItem, index) => {
              return <RealLifeCard key={index} realLifeItem={realLifeItem} />
            })}
          </Carousel>
        </div>
        <div className="flex justify-center pb-[72px] md:pb-6 lg:pb-7">
          <Button
            label="Shop Customer Favorites"
            onClick={goToShopCustomerFavorites}
            primary={true}
          />
        </div>
      </div>
    </section>
  )
}

interface IButtonGroupProps {
  next: () => void
  previous: () => void
  carouselState: {
    currentSlide: number
    totalItems: number
  }
}

const ButtonGroup = ({ next, previous, carouselState }: IButtonGroupProps) => {
  // use carouselState to disable button when in the last slide, for example.
  return (
    <div className="absolute -bottom-0 right-0 mr-3 hidden lg:block">
      <span onClick={() => previous()} className="mr-4 cursor-pointer">
        <i
          className={`loop-icon-arrow-long text-24px text-black transition ease-in duration-400 rotate-180 inline-block ${
            carouselState.currentSlide === 0
              ? 'cursor-not-allowed opacity-25'
              : ''
          }`}
        ></i>
      </span>
      <span onClick={() => next()} className="cursor-pointer">
        <i
          className={`loop-icon-arrow-long text-24px text-black transition ease-in duration-400 inline-block ${
            carouselState.totalItems - carouselState.currentSlide === 0
              ? 'cursor-not-allowed opacity-25'
              : ''
          }`}
        ></i>
      </span>
    </div>
  )
}

interface ICustomArrowProps {
  next: () => void
  previous: () => void
  carouselState: {
    itemWidth: number
    slidesToShow: number
    currentSlide: number
    totalItems: number
    deviceType: string
    domLoaded: boolean
    transform: number
    containerWidth: number
  }
}

const CircleRightButton = ({
  next,
  previous,
  carouselState: { currentSlide, totalItems },
}: ICustomArrowProps) => {
  // use carouselState to disable button when in the last slide, for example.
  if (currentSlide >= totalItems) return <></>

  return (
    <div
      className="absolute z-10 right-4 text-white bg-[#868D8E]/[.75] cursor-pointer w-[34px] h-[34px] rounded-full flex items-center justify-center"
      onClick={() => console.log('testing next')}
    >
      <i className="loop-icon-arrow-short text-24px" />
    </div>
  )
}
