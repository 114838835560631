import Image from 'next/image'
import { REASONS_TO_LOVE_LOOP } from '../../../config'
import { Headline } from '../../Headline'
import { Pgraph } from '../../Pgrah'
import { useState } from 'react'

export default function ReasonToLoveLoopDesktop() {
  const [selectedReasonIndex, setSelectedReasonIndex] = useState<number>(0)

  const onMouseEnter = (index: number) => {
    setSelectedReasonIndex(index)
  }
  const onMouseLeave = () => {
    setSelectedReasonIndex(-1)
  }

  return (
    <div className="grid grid-cols-2 mt-6 max-w-7xl mx-auto ">
      <div className="pr-1 xl:pr-5">
        <Headline className="xs:text-[40px] px-4 xl:pr-5">
          A Million Reasons To Love Loop (Here Are Four)
        </Headline>

        <div className="relative mt-4">
          {REASONS_TO_LOVE_LOOP.map((reason, index) => {
            const imageClasses =
              index === selectedReasonIndex ? 'block' : 'hidden'
            const textClasses =
              index === selectedReasonIndex
                ? 'text-neutral-6'
                : 'text-neutral-4'

            return (
              <div
                key={`text-${reason.iconSrc}`}
                className="flex px-4 mb-[35px]"
              >
                <div className="w-3/12 text-right pr-3">
                  <div className={['transition-all',imageClasses].join(' ')}>
                    <Image
                      src={reason.iconSrc}
                      alt={reason.headline}
                      width={reason.iconWidth}
                      height={reason.iconHeight}
                      className='ml-auto'
                    />
                  </div>
                </div>
                <div
                  className="w-9/12"
                  onMouseEnter={() => {
                    onMouseEnter(index)
                  }}
                >
                  <Pgraph
                    variant="p-20"
                    className={['xs:leading-22', textClasses].join(' ')}
                  >
                    {reason.headline}
                  </Pgraph>
                  <Pgraph
                    variant="p-16"
                    className={['mt-[12px]', textClasses].join(' ')}
                  >
                    {reason.text}
                  </Pgraph>
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <div>
        {REASONS_TO_LOVE_LOOP.map((reason, index) => {
          const imageClasses =
            index === selectedReasonIndex ? 'block' : 'hidden'

          return (
            <div
              key={`image-${reason.iconSrc}`}
              className={['px-4 transition-all', imageClasses].join(' ')}
            >
              <Image
                alt={reason.headline}
                src={reason.imageSrc}
                width={700}
                height={780}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}
